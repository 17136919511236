import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import Button from "src/components/Button";
import { colors } from "src/styles";
import { ButtonContainer, Icon, InputContainer, SearchContainer, StyledInput } from "../styles";
import { SearchProps } from "../types";

const Search: React.FC<SearchProps> = ({ value, applied, onChange, onClickCancel, onClickApply, onClickClear }) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };
  return (
    <SearchContainer>
      <InputContainer>
        <StyledInput
          maxLength={100}
          value={value}
          onChange={onInputChange}
          placeholder="Search"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickApply();
            }
          }}
        />
        {(applied || value) && <Icon icon={faTimesCircle} onClick={applied ? onClickClear : onClickCancel} />}
      </InputContainer>

      <ButtonContainer>
        <Button
          additionalStyles={{ backgroundColor: colors.kleenway.green }}
          variant="small-primary"
          text="Search"
          onClick={onClickApply}
          disabled={!value.length}
        />
      </ButtonContainer>
    </SearchContainer>
  );
};

export default Search;
