import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CloseWorkOrderFormView } from "./components/CloseWorkOrderFormView";
import { CloseWorkOrderFormProps, CloseWorkOrderFormValues } from "./types";

export const CloseWorkOrderForm = ({ onSubmit, onCancel, workorder }: CloseWorkOrderFormProps) => {
  const form = useForm<CloseWorkOrderFormValues>();

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <CloseWorkOrderFormView workorder={workorder} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};
