import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import AssignWOResourceFormView from "./components/AssignWOResourceFormView";
import { AssignWOResourceFormProps, AssignWOResourceFormValues } from "./types";

export const AssignWOResourceForm = ({
  defaultValues,
  onCancel,
  onSubmit,
  employees,
  vendors,
  errorMsg,
}: AssignWOResourceFormProps) => {
  const form = useForm<AssignWOResourceFormValues>({ defaultValues });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <AssignWOResourceFormView onCancel={onCancel} employees={employees} vendors={vendors} errorMsg={errorMsg} />
      </form>
    </FormProvider>
  );
};
