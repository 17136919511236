import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import Button from "src/components/Button";
import PayPeriodFilterModal from "src/components/organisms/PayPeriodFilterModal";
import styled from "styled-components";

export const PayPeriodFilter = () => {
  const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false);

  return (
    <StyledStack>
      <Button
        onClick={() => {
          setIsFilterModalVisible(true);
        }}
        text="Filter"
      />
      {isFilterModalVisible && (
        <PayPeriodFilterModal
          closeModal={() => {
            setIsFilterModalVisible(false);
          }}
        />
      )}
    </StyledStack>
  );
};
const StyledStack = styled(Stack)`
  position: absolute;
  top: -10px;
  right: 0;
`;
