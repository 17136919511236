import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "react-bootstrap";
import { colors } from "src/styles";
import styled from "styled-components";

export const SearchInputWrapper = styled(Stack)`
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  border-radius: 8px;
  padding: 10px 35px 10px 12px;
  width: inherit;
  color: ${colors.grey.dark6};
  border: 1px solid ${colors.grey.light3};
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  font-size: 16px;
  right: 12px;
  color: ${colors.grey.dark6};
`;
