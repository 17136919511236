import React from "react";
import styled, { css } from "styled-components";

import Button, { ButtonProps } from "src/components/Button";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { ButtonsApproveClientBlock, iNavigate } from "src/pages/TicketForm/ClientApproval";

export interface ApproveStepProps {
  setNote: (note: string) => void;
  navigate: iNavigate;
  submitButtonProps: ButtonProps;
  inputFieldPlaceholder: string;
  label: string;
}

const NoteStep = ({ setNote, navigate, submitButtonProps, inputFieldPlaceholder, label }: ApproveStepProps) => {
  const { isMobile } = useIsMobile();

  const handleChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    setNote(event.target.value.trim());
  };

  return (
    <>
      <p>{label}</p>
      <InputField placeholder={inputFieldPlaceholder} onChange={handleChangeNote} />
      <ButtonsApproveClientBlock $isMobile={isMobile}>
        <CancelButton $isMobile={isMobile} onClick={navigate.main}>
          Cancel
        </CancelButton>
        <Button {...submitButtonProps} />
      </ButtonsApproveClientBlock>
    </>
  );
};

const InputField = styled.textarea`
  width: 100%;
  height: 100px;
  background: #ffffff;
  border: 2px solid #d3d3d3;
  padding: 20px;
  font-weight: 400;
  font-size: 14px;

  &::placeholder {
    color: #c4c4c4;
  }
`;

const CancelButton = styled.button<MobileProps>`
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-size: 14px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 36px;
      font-size: 12px;
    `}
`;

export default NoteStep;
