import { v4 as uuidv4 } from "uuid";
import { Contact } from "./types";

export const contactFactory = {
  createContact: (): Contact => {
    return {
      id: uuidv4(),
      firstName: "",
      lastName: "",
    };
  },
};
