import React from "react";
import { SelectItemsWrapper, SelectItem, HoursForEmergency } from "../styles";
import { DropdownItem } from "../types";
export interface SelectInputOptionsProps {
  showItems: boolean;
  $isScrollable?: boolean;
  items?: DropdownItem[];
  onClick: (item: DropdownItem) => void;
  selectItem: (item: DropdownItem, e: React.MouseEvent<HTMLDivElement>) => void;
  defaultValueColor?: string;
}
const SelectInputOptions = ({
  $isScrollable,
  defaultValueColor,
  items,
  onClick,
  selectItem,
  showItems,
}: SelectInputOptionsProps) => {
  return (
    <SelectItemsWrapper $showItems={showItems} $isScrollable={!!$isScrollable}>
      {items?.map((item) => (
        <SelectItem
          color={item?.color || defaultValueColor}
          key={item.id}
          onClick={(e) => {
            selectItem(item, e);
            onClick(item);
          }}
        >
          {item.value.includes(":") ? <span className="bold"> {item.value.split(":")[0]}:</span> : item.value}
          {item.value.split(": ")[1]}
          {item.hoursDelay && <HoursForEmergency> (less than {item.hoursDelay} hours)</HoursForEmergency>}
        </SelectItem>
      ))}
    </SelectItemsWrapper>
  );
};

export default SelectInputOptions;
