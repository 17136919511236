import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import SelectSearch from "src/components/SelectSearch";
import { FormLabel } from "src/components/atoms/FormLabel";
import { AdjustmentReasonProps } from "../types";

export const AdjustmentReason = ({ reasons }: AdjustmentReasonProps) => {
  const { control, watch } = useFormContext();

  const fieldName = "adjustmentReasonCode";
  const shouldBeRequired = +watch("adjustmentAmount") !== 0;

  return (
    <Stack gap={2}>
      <FormLabel htmlFor={fieldName} required={shouldBeRequired}>
        Adjustment Reason
      </FormLabel>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: shouldBeRequired }}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <SelectSearch
            id={fieldName}
            options={reasons}
            defaultValue={value}
            placeholder="Select adjustment reason"
            name={name}
            onChange={(option) => onChange(option.id)}
            ref={ref}
            error={error}
          />
        )}
      />
    </Stack>
  );
};
