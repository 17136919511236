import moment from "moment";
import React from "react";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled, { css } from "styled-components";

const Footer: React.FC = () => {
  const { isMobile } = useIsMobile();
  const year = moment().year();

  return (
    <>
      <FooterBox>
        <FooterTitle $isMobile={isMobile}>
          Copyright {year} Kleenway Building Maintenance Inc. | All Rights Reserved
        </FooterTitle>
      </FooterBox>
    </>
  );
};

const FooterTitle = styled.label<MobileProps>`
  font-weight: 400;
  font-size: 10px;
  padding: 10px 0;
  color: ${colors.grey.dark4};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 8px;
    `};
`;

const FooterBox = styled.footer`
  display: flex;
  justify-content: center;
  height: 40px;
  background-color: ${colors.grey.light9};
  position: fixed;
  bottom: 0;
  max-width: inherit;
  width: 100%;
  z-index: 110;
`;

export default Footer;
