import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "src/components/Input";
import { colors } from "src/styles";
import styled from "styled-components";
import { DATEPICKER_VARIANT } from "./types";

export const Wrapper = styled.div<{ error?: boolean }>`
  & .react-datepicker-wrapper {
    width: unset;
  }

  &
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day--selected {
    background-color: ${colors.kleenway.greenLight};
    color: ${colors.grey.dark};
  }
  & .react-datepicker--time-only {
    border-radius: unset;
    border: none;
  }

  & .react-datepicker--time-only > .react-datepicker__input-time-container {
    width: unset;
    margin: 0;

    & > .react-datepicker-time__caption {
      display: none;
    }
    & > .react-datepicker-time__input-container {
      & > .react-datepicker-time__input {
        margin-left: 0;
        & > input {
          padding: 8px 10px;
          border-width: 1px;
          border-style: solid;
          border-color: ${({ error }) => (error ? colors.kleenway.error : colors.grey.light1)};
          border-radius: 4px;
          margin-left: 0;
        }
      }
    }
  }
`;

export const StyledInput = styled(Input)<{ variant: DATEPICKER_VARIANT }>`
  ${(props) => (props.variant === DATEPICKER_VARIANT.DATE_TIME ? "min-width: 185px;" : "")}
  position: relative;
  padding: 8px 10px;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  position: absolute;
  right: 12px;
  top: 8px;
  height: 23px;
  width: 19px;
`;

export const ErrorLabel = styled.label`
  color: ${colors.kleenway.error};
  display: block;
`;
