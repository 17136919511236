import React from "react";
import { InfoMessageForm } from "src/components/molecules/InfoMessageForm";
import { messageFactory } from "src/components/molecules/InfoMessageForm/utils";
import { ListForm } from "src/components/molecules/ListForm";
import { ContactListFormProps } from "../types";

/**
 * A helper component to configure ListForm for Info Messages
 */
export const InfoMessageListForm = ({ name, fontSizes, fontColors }: ContactListFormProps) => {
  return (
    <ListForm
      name={name}
      getNewItem={messageFactory.createMessage}
      renderItem={(item, index) => (
        <InfoMessageForm name={`${name}.${index}`} message={item} fontColors={fontColors} fontSizes={fontSizes} />
      )}
      itemName="Message"
      itemCaption="Info Message"
    />
  );
};
