import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import Alert, { AlertVariants } from "src/components/Alert";
import Button from "src/components/Button";
import Input, { InputTypes } from "src/components/Input";
import { VALIDATION_MESSAGES } from "src/constants/validation";
import { getErrorMsg } from "src/helpers/getErrorMsg";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { setPageNameHeader } from "src/redux/actions/header";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseAuthService } from "src/services/Firebase/auth";
import { colors } from "src/styles";
import ValidateData from "src/utils/validator";

const ChangePassword: React.FC = () => {
  const { firstName, lastName } = useSelector(getUserData);
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const onSubmit = handleSubmit(({ currentPassword, newPassword, repPassword }) =>
    confirmChangePassword(currentPassword, newPassword, repPassword),
  );

  const confirmChangePassword = async (currentPassword: string, newPassword: string, repPassword: string) => {
    setLoading(true);
    setSuccess(false);
    setError("");

    try {
      if (newPassword !== repPassword) {
        throw new Error("The password confirmation does not match the new password.");
      }

      if (!ValidateData.password(newPassword, firstName, lastName)) {
        throw new Error(
          "New password should meet the requirements: at least 8 characters, at least one uppercase letter, one lowercase letter and one number, not too obvious (like your name).",
        );
      }

      await FirebaseAuthService.updatePassword(currentPassword, newPassword);
      setSuccess(true);
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        error.code = "auth/wrong-current-password";
      }
      setError(getErrorMsg(error));
    }
    setLoading(false);
  };

  //set page name to the Header
  useEffect(() => {
    dispatch(setPageNameHeader("Change Password"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  return (
    <Container>
      <Content>
        <Description>To make your account secure, make sure your new password:</Description>

        <List $isMobile={isMobile}>
          <li>is at least 8 characters long</li>
          <li>has at least one uppercase letter, one lowercase letter and one number</li>
          <li>is not too obvious, like your name</li>
        </List>

        <StyledForm $isMobile={isMobile} i onSubmit={onSubmit}>
          <Input
            controlId="change-current-password"
            type={InputTypes.password}
            label="Current Password"
            validation={register("currentPassword", {
              required: {
                value: true,
                message: VALIDATION_MESSAGES.required,
              },
            })}
          />

          <Input
            controlId="reset-new-password"
            type={InputTypes.password}
            label="New Password"
            validation={register("newPassword", {
              required: {
                value: true,
                message: VALIDATION_MESSAGES.required,
              },
            })}
          />

          <Input
            controlId="reset-rep-password"
            type={InputTypes.password}
            label="Confirm New Password"
            validation={register("repPassword", {
              required: {
                value: true,
                message: VALIDATION_MESSAGES.required,
              },
            })}
          />

          {!!error && <Alert text={error} variant={AlertVariants.danger} />}
          {!!success && <Alert text="Password successfully changed!" variant={AlertVariants.success} />}

          <ButtonContainer>
            <Button text="Save" type="submit" disabled={!isValid} loading={loading} />
          </ButtonContainer>
        </StyledForm>
      </Content>
    </Container>
  );
};

//#region styles
const Container = styled.div`
  color: ${colors.grey.dark};
  margin-top: 35px;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 630px;
  padding: 0 25px 60px;
`;

const Description = styled.label`
  text-align: center;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
`;

const List = styled.ul<MobileProps>`
  font-weight: 500;
  font-size: 14px;
  padding-left: 3rem;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-left: 0;
      font-size: 12px;
    `}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledForm = styled(Form)<MobileProps>`
  width: 90%;
  margin-top: 30px;

  & > div {
    margin-bottom: 25px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 20px;
    `}
`;
//#endregion
export { StyledForm };

export default ChangePassword;
