import React from "react";
import { useSelector } from "react-redux";
import DataTable from "src/components/Table";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getIsTicketsLoading, getTickets } from "src/redux/selectors/workRequest";
import { Ticket } from "src/types";
import { vendorTableHeaders } from "../constant";
import VendorListItem from "./VendorListItem";
import VendorTableRow from "./VendorTableRow";

const VendorTicketsWrapper = () => {
  const isTicketsLoading = useSelector(getIsTicketsLoading);
  const tickets = useSelector(getTickets);

  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <>
        {tickets.map((ticket: Ticket) => (
          <VendorListItem ticket={ticket} key={ticket.wonum} />
        ))}
      </>
    );
  }
  return (
    <DataTable isLoading={isTicketsLoading} headers={vendorTableHeaders}>
      {tickets?.map((ticket: Ticket) => <VendorTableRow key={ticket.wonum} ticket={ticket} />)}
    </DataTable>
  );
};

export default VendorTicketsWrapper;
