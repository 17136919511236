import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "src/components/atoms/DatePicker";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { FormLabel } from "src/components/atoms/FormLabel";
import { DateInputRow } from "../styles";
import { DynamicFieldProps } from "../types";
export const DynamicDateTimeField = ({ dynamicField }: DynamicFieldProps) => {
  const { control } = useFormContext();
  // Define validation rules for date-time input
  const getVariant = () => {
    if (dynamicField.datepicker?.variant === DATEPICKER_VARIANT.DATE) {
      return DATEPICKER_VARIANT.DATE;
    } else if (dynamicField.datepicker?.variant === DATEPICKER_VARIANT.TIME) {
      return DATEPICKER_VARIANT.TIME;
    } else {
      return DATEPICKER_VARIANT.DATE_TIME;
    }
  };
  return (
    <DateInputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        rules={dynamicField?.rules}
        defaultValue={dynamicField.defaultValue}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <DatePicker variant={getVariant()} onChange={onChange} value={value} name={name} error={error} ref={ref} />
        )}
      />
    </DateInputRow>
  );
};
