export const debugLog = (...args: any[]) => {
  console.warn.apply(console.log, args);
};

export const debugError = (...args: any[]) => {
  console.error.apply(console.log, args);
};

export const debugInfo = (...args: any[]) => {
  console.log.apply(console.log, args);
};
