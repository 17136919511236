import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { AdjustmentForm } from "src/components/molecules/AdjustmentForm";
import { Icon, ModalContent, ModalHeader, ModalWindow, ModalWrapper } from "./styles";
import { AdjustmentFormModalProps } from "./types";

const AdjustmentFormModal = ({
  defaultValues,
  reasons,
  closeModal: closeModel,
  onSubmit,
}: AdjustmentFormModalProps) => {
  return (
    <ModalWrapper>
      <ModalWindow style={{ maxWidth: "474px" }}>
        <ModalHeader>
          <div>
            <h3>Adjustment</h3>
            <Icon onClick={closeModel} icon={faTimes as IconProp} />
          </div>
        </ModalHeader>
        <ModalContent>
          <AdjustmentForm onSubmit={onSubmit} onCancel={closeModel} reasons={reasons} defaultValues={defaultValues} />
        </ModalContent>
      </ModalWindow>
    </ModalWrapper>
  );
};
export default AdjustmentFormModal;
