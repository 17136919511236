import React from "react";
import { FormLabel, Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { StyledCheckbox } from "../styles";

const Billable = () => {
  const { control } = useFormContext();

  const fieldName = "vendors.woacceptscharges";

  return (
    <Stack direction="horizontal" gap={2}>
      <FormLabel htmlFor={fieldName} style={{ marginBottom: 0 }}>
        Billable?
      </FormLabel>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { name, value, onChange, ref } }) => (
          <StyledCheckbox type={"checkbox"} name={name} checked={value} onChange={onChange} ref={ref} />
        )}
      />
    </Stack>
  );
};

export default Billable;
