import React, { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "src/components/Button/BackButton";
import LoadingContainer from "src/components/LoadingContainer";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { setPageNameHeader } from "src/redux/actions/header";
import { FirebaseDataService } from "src/services/Firebase/data";
import { DBContract } from "src/types";
import ContactsInfo from "./components/ContactsInfo";
import VendorContractInfo from "./components/VendorContractInfo";
import VendorInfo from "./components/VendorInfo";
import { Container, Content, ContentFrame } from "./styles";
import { DBVendorDetails } from "./types";

const VendorDetails = () => {
  const { id: vendorId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [vendorData, setVendorData] = useState<DBVendorDetails>();
  const [contractData, setContractData] = useState<DBContract[]>();
  const [activeTab, setActiveTab] = useState("generalInfo");

  useEffect(() => {
    dispatch(setPageNameHeader("Vendor Details"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  useEffect(() => {
    if (vendorId) {
      fetchVendorData(vendorId);
    }
  }, [vendorId]);

  const fetchVendorData = async (id: string) => {
    setIsLoading(true);
    const res = await FirebaseDataService.getVendorDetails(id);
    if (res.data) {
      const { contract, vendorDetails } = res.data;
      if (vendorDetails) {
        setVendorData(vendorDetails);
      }
      if (contract) {
        setContractData(contract);
      }
      setIsLoading(false);
    }
  };
  if (!vendorData) {
    return null;
  }
  return (
    <LoadingContainer isLoading={isLoading}>
      <Container>
        <Content>
          <Stack
            direction="horizontal"
            className="align-items-center justify-content-between"
            style={{ marginBottom: 30 }}
          >
            <BackButton backHandler={() => navigate("/vendors")} />
          </Stack>
          <ContentFrame>
            <HorizontalTabs
              tabs={[
                {
                  label: "General Information",
                  content: <VendorInfo vendorData={vendorData} />,
                  eventKey: "generalInfo",
                },
                {
                  label: "Contacts",
                  content: <ContactsInfo contacts={Object.values(vendorData.contacts ?? {})} />,
                  eventKey: "contacts",
                },
                {
                  label: "Blanket PO",
                  content: <VendorContractInfo contractData={contractData ?? []} />,
                  eventKey: "blanketPo",
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </ContentFrame>
        </Content>
      </Container>
    </LoadingContainer>
  );
};

export default VendorDetails;
