import isEqual from "lodash.isequal";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { NumberParam, useQueryParam } from "use-query-params";

import { Pagination } from "src/components/Pagination";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ClientSpecificLocationForTable, ID } from "src/types";

import { EmptyContainer, NoTicketsMessage, PaginationContainer } from "../../../components/ListScreen";
import Modal from "../../../components/Modal";
import { compareBy } from "../../../utils";
import ClientDetailsContext from "../context";
import { generateGeneralInfoFormState, removeUniqueFormsFields } from "../utils";
import { LocationsTable } from "./LocationsTable";
import { DEFAULT_PAGE_SIZE, INITIAL_LAST_PAGE, LOCATIONS_TABLE_HEADERS } from "./constants";

export const Locations = () => {
  const navigate = useNavigate();
  const { id: clientId } = useParams();

  const { generalInfoFormState, timezones, messages, kleenwayContacts, client, clientPortalTiles } =
    useContext(ClientDetailsContext);

  const [locations, setLocations] = useState<ClientSpecificLocationForTable[]>([]);
  const [currentLocationsPage, setCurrentLocationsPage] = useState<ClientSpecificLocationForTable[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [openedLocationsPage, setOpenedLocationsPage] = useQueryParam("openedLocationsPage", NumberParam);
  const [lastPage, setLastPage] = useState<number>(INITIAL_LAST_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);
  const [recentlyClickedRawId, setRecentlyClickedRawId] = useState<ID | null>(null);

  const pageNum = openedLocationsPage || 1;

  const getPageSize = async () => {
    try {
      const { data } = await FirebaseDataService.getEmployeeLocationsPageSize();
      setPageSize(data || DEFAULT_PAGE_SIZE);
    } catch (e) {
      setPageSize(DEFAULT_PAGE_SIZE);
    }
  };

  const getLocations = async () => {
    setIsLoading(true);

    if (clientId) {
      if (!lastPage || (lastPage && pageNum <= lastPage)) {
        try {
          const { data: locationsArray } = await FirebaseDataService.getLocationsByClientId(clientId.toString());

          const sortedLocations = locationsArray.sort((location1, location2) =>
            compareBy("label", location1, location2),
          );

          const locationsPageArray = sortedLocations.slice((pageNum - 1) * pageSize, pageNum * pageSize);

          setLocations(sortedLocations);
          setCurrentLocationsPage(locationsPageArray);
          setLastPage(Math.ceil(sortedLocations.length / pageSize));
        } catch (e) {
          console.log(e.message);
        }
      }
    }

    setIsLoading(false);
  };

  const onPageChangeClick = (pageNumber: number) => {
    setOpenedLocationsPage(pageNumber);
    setCurrentLocationsPage(locations.slice((pageNumber - 1) * pageSize, pageNumber * pageSize));
  };

  const onRowClick = (id: ID) => {
    setRecentlyClickedRawId(id);

    const initialGeneralFormState = generateGeneralInfoFormState(
      client,
      timezones,
      messages,
      kleenwayContacts,
      clientPortalTiles,
      [],
      generalInfoFormState,
    );

    const { clientContacts, infoMessages } = removeUniqueFormsFields(generalInfoFormState);
    const initialGeneralInfoFormStateWithoutFormIds = removeUniqueFormsFields(initialGeneralFormState);

    const isClientContactsEqual = isEqual(clientContacts, initialGeneralInfoFormStateWithoutFormIds.clientContacts);

    const infoMessagesWithoutPrioirty = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(infoMessages).map(([key, { priority, ...rest }]) => [key, rest]),
    );

    const infoMessagesFromStateWithoutPriority = Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(initialGeneralInfoFormStateWithoutFormIds.infoMessages).map(([key, { priority, ...rest }]) => [
        key,
        rest,
      ]),
    );
    const isInfoMessagesEqual = isEqual(infoMessagesWithoutPrioirty, infoMessagesFromStateWithoutPriority);

    if (isClientContactsEqual && isInfoMessagesEqual) {
      navigate(`/clients/locations/${id}`, { state: { clientName: client.name } });
      return;
    }
    setShowConfirmClosePopup(true);
  };

  const onConfirmCloseClick = () =>
    recentlyClickedRawId &&
    navigate(`/clients/locations/${recentlyClickedRawId}`, { state: { clientName: client.name } });
  const hideConfirmCloseModal = () => setShowConfirmClosePopup(false);

  useEffect(() => {
    getPageSize().finally(() => getLocations());
  }, [pageSize]);

  return (
    <Content>
      <Modal
        onApproveClick={onConfirmCloseClick}
        onCancelClick={hideConfirmCloseModal}
        isShowing={showConfirmClosePopup}
        hide={hideConfirmCloseModal}
        title="Close without Saving"
        textContent={`Do you want to close the page without saving?\nAll entered or updated information will be lost.`}
        approveButtonText="Close without Saving"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
      />
      {isLoading ? (
        <EmptyContainer>
          <Spinner animation="border" size="sm" />
        </EmptyContainer>
      ) : (
        <>
          {currentLocationsPage?.length ? (
            <LocationsTable
              onRowClick={onRowClick}
              isLoading={isLoading}
              headers={LOCATIONS_TABLE_HEADERS}
              data={currentLocationsPage}
            />
          ) : (
            <NoTicketsMessage>No Locations assigned</NoTicketsMessage>
          )}
          <PaginationContainer>
            <Pagination
              totalCount={locations.length}
              pageSize={pageSize}
              onPageChange={onPageChangeClick}
              currentPage={pageNum}
            />
          </PaginationContainer>
        </>
      )}
    </Content>
  );
};

const Content = styled.div`
  padding: 10px 30px 30px 0px;
  margin-top: -0.6em;
`;
