import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { PhotoContainer } from "src/components/PhotoUpload";
import { Required } from "src/components/atoms/Required";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getBase64File } from "src/utils/convertToBase64";
import { onAttachmentClick, onClickUpload, uploadAttachments } from "../handlers";
import {
  Attachment,
  AttachmentData,
  AttachmentsContainer,
  ErrorMessage,
  InputRow,
  Label,
  PhotoUploadContainer,
  Wrapper,
} from "../styles";
import { DynamicDocument, DynamicFieldProps, FirebaseFile } from "../types";
export const DynamicFileUpload = ({ dynamicField }: DynamicFieldProps) => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { isMobile } = useIsMobile();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<DynamicDocument[]>([]);
  const [fileData, setFileData] = useState<FirebaseFile[]>([]);

  const onChangeUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesUploaded: DynamicDocument[] = Object.values(event.target.files).map((item) => ({
        file: item,
        state: "loading",
        error: "",
        attachmentId: "",
        description: "",
        fileName: item.name,
        href: "",
      }));
      const filesToUploaded: FirebaseFile[] = await Promise.all(
        Object.values(event.target.files).map(async (item) => {
          const base64 = (await getBase64File(item)) as string;
          return {
            base64,
            contentType: item.type,
            fileName: Date.parse(new Date().toString()) + item.name,
            folderName: dynamicField.file?.folderName ?? "",
          };
        }),
      );
      setFileData([...fileData, ...filesToUploaded]);
      await uploadAttachments(filesUploaded, setFiles, files);
      setValue(dynamicField.id, [...fileData, ...filesToUploaded]);
      trigger(dynamicField.id);
      event.target.value = "";
    }
  };

  const onDeleteNonImageFile = (index: number) => {
    const remainingFiles = filteredFiles.nonImages.filter((_data, idx) => idx !== index);
    setFiles([...filteredFiles.images, ...remainingFiles]);
    setValue(dynamicField.id, [...filteredFiles.images, ...remainingFiles]);
    trigger(dynamicField.id);
  };
  const onDeleteImageFile = (index: number) => {
    const remainingFiles = filteredFiles.images.filter((_data, idx) => idx !== index);
    setFiles([...filteredFiles.nonImages, ...remainingFiles]);
    setValue(dynamicField.id, [...filteredFiles.nonImages, ...remainingFiles]);
    trigger(dynamicField.id);
  };
  const filteredFiles = files.reduce(
    (acc, file) => {
      acc[file.file?.type.includes("image") ? "images" : "nonImages"].push(file);
      return acc;
    },
    { images: [] as DynamicDocument[], nonImages: [] as DynamicDocument[] },
  );
  return (
    <InputRow>
      <Label $isMobile={isMobile}>
        {dynamicField.label}:{dynamicField.rules?.required && <Required />}
      </Label>
      <Controller
        control={control}
        name={dynamicField.id}
        render={() => (
          <AttachmentsContainer>
            <PhotoUploadContainer>
              {filteredFiles.images.length > 0 &&
                filteredFiles.images.map((doc, index) => (
                  <PhotoContainer
                    isViewMode={false}
                    key={doc.fileName}
                    image={doc.file ? URL.createObjectURL(doc.file) : undefined}
                    onDeleteClick={(e) => {
                      e.preventDefault();
                      onDeleteImageFile(index);
                    }}
                  />
                ))}
            </PhotoUploadContainer>
            {filteredFiles.nonImages.length > 0 &&
              filteredFiles.nonImages.map((item, index) => (
                <Attachment key={item.fileName}>
                  <AttachmentData onClick={() => onAttachmentClick(item)}>{item.fileName}</AttachmentData>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteNonImageFile(index);
                    }}
                  />
                </Attachment>
              ))}
            {!dynamicField?.file?.multiple && files.length > 0 ? null : (
              <Wrapper>
                <label htmlFor="contained-button-file">
                  <Button
                    type="button"
                    text="Add Files"
                    variant="dotted-border"
                    onClick={() => {
                      onClickUpload(hiddenFileInput);
                    }}
                    icon={faPlus}
                  />
                </label>
                <Controller
                  control={control}
                  name={dynamicField.id}
                  rules={dynamicField.rules}
                  defaultValue={dynamicField.defaultValue}
                  render={() => (
                    <input
                      ref={hiddenFileInput}
                      id="contained-button-file"
                      multiple={dynamicField?.file?.multiple}
                      type="file"
                      onChange={onChangeUpload}
                      style={{ display: "none" }}
                      accept={dynamicField?.file?.accept?.join(",")}
                    />
                  )}
                />
                <input
                  ref={hiddenFileInput}
                  id="contained-button-file"
                  multiple={dynamicField?.file?.multiple}
                  type="file"
                  onChange={onChangeUpload}
                  style={{ display: "none" }}
                  accept={dynamicField?.file?.accept?.join(",")}
                />
              </Wrapper>
            )}
            {errors[dynamicField.id] && (
              <ErrorMessage style={{ marginLeft: 10 }}>{"This field is required"}</ErrorMessage>
            )}
          </AttachmentsContainer>
        )}
      />
    </InputRow>
  );
};
