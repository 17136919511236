import React from "react";
import WorkRequestContainer from "src/components/molecules/WorkRequestContainer";
import { WORK_REQUEST_TYPE } from "../constants";
import { WorkRequestProps } from "../types";

const ClientWorkRequests = ({ renderTable }: WorkRequestProps) => {
  return (
    <WorkRequestContainer
      tabs={[
        {
          eventKey: WORK_REQUEST_TYPE.OPEN,
          label: "Open",
        },
        {
          eventKey: WORK_REQUEST_TYPE.COMPLETED,
          label: "Complete",
        },
        {
          eventKey: WORK_REQUEST_TYPE.PLANNED,
          label: "Planned Maintenance",
        },
        {
          eventKey: WORK_REQUEST_TYPE.APPROVAL_REQUIRED,
          label: "Approval Required",
        },
      ]}
      isServiceTypeVisible={true}
      isRedirectToDetailPageAllowed={true}
      renderTable={renderTable}
    />
  );
};

export default ClientWorkRequests;
