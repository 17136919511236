import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormLabel } from "src/components/atoms/FormLabel";
import { ErrorMessage, InputRow, StyledCheckbox, StyledLabel } from "../styles";
import { DynamicFieldProps } from "../types";
const DynamicCheckboxGroupField = ({ dynamicField }: DynamicFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <InputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        rules={dynamicField.rules}
        defaultValue={dynamicField.defaultValue || []}
        render={({ field: { value, onChange, ref } }) => (
          <div style={{ display: "flex", flexDirection: "column" }} ref={ref}>
            <div ref={ref}>
              {dynamicField?.checkbox?.options?.map((option) => (
                <StyledLabel key={option.value}>
                  <StyledCheckbox
                    type="checkbox"
                    value={option.value}
                    checked={value.includes(option.value)}
                    onChange={(e) => {
                      const newValue = e.target.checked
                        ? [...value, option.value]
                        : value.filter((val: string) => val !== option.value);
                      onChange(newValue);
                    }}
                  />
                  {option.label}
                </StyledLabel>
              ))}
            </div>
            {errors[dynamicField.id] && <ErrorMessage>{"This field is required"}</ErrorMessage>}
          </div>
        )}
      />
    </InputRow>
  );
};
export default DynamicCheckboxGroupField;
