import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

interface PaperProps {
  children?: React.ReactNode;
  onClick?: () => void;
  containerStyle?: any;
}

const Paper: React.FC<PaperProps> = ({ children, onClick, containerStyle = {} }) => {
  return (
    <>
      <Container style={containerStyle} onClick={onClick}>
        {children}
      </Container>
    </>
  );
};

const Container = styled.a`
  min-width: 0;
  background-color: ${colors.white.default};
  color: ${colors.grey.dark};
  padding: 15px;
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 5px;
  border: 1px solid ${colors.grey.light1};
  text-decoration: none;
  cursor: pointer;
  a:hover {
    color: ${colors.grey.dark};
  }
`;

export default Paper;
