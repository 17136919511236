import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { Link } from "react-router-dom";
import DeleteModal from "src/components/DeleteModal";
import Input from "src/components/Input";
import { formatTimezoneToShort } from "src/utils/time";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../../components/Button";
import SelectSearch from "../../../../components/SelectSearch";
import { POSITION_LIMITS, VALIDATION_MESSAGES } from "../../../../constants/validation";
import { FirebaseDataService } from "../../../../services/Firebase/data";
import { colors } from "../../../../styles";
import { DBLocation } from "../../../../types";
import { RowValue } from "../../GeneralInfoForm";
import { InputRow, InputsRow } from "../../GeneralInfoForm/ClientContactForm";
import { LocationContactFormProps } from "../LocationDetailsProvider";
import LocationDetailsContext from "../context";
import { LocationContactForm } from "./LocationContactForm";

enum GeneralInfoFormFields {
  LATITUDE = "latitude",
  LONGITUDE = "longitude",
}

export const GeneralInfoForm = ({
  location: { clientId, label, address, inactive, timezone },
  locationId,
}: {
  location: DBLocation;
  locationId: string;
}) => {
  const {
    generalInfoFormState,
    setGeneralInfoFormState,
    isSupervisorsLoaded,
    supervisors,
    isGeneralInfoFormSaving,
    setIsGeneralInfoFormSaving,
    client,
    setClient,
    dropdownListSizes,
  } = useContext(LocationDetailsContext);

  const {
    control,
    register,
    trigger,
    watch,
    clearErrors,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      latitude: generalInfoFormState.latitude,
      longitude: generalInfoFormState.longitude,
    },
  });

  const lat = useWatch({ control, name: "latitude" });
  const long = useWatch({ control, name: "longitude" });

  const [contactFormToDelete, setContactFormToDelete] = useState<string>("");
  const [isClientLoaded, setIsClientLoaded] = useState<boolean>(false);

  const validateLongitude = (longitude: number | string | undefined) => {
    const isLongitudeFilled = !!longitude;
    const isLatitudeFilled = watch(GeneralInfoFormFields.LATITUDE);
    if (isLatitudeFilled && !isLongitudeFilled) return VALIDATION_MESSAGES.required;

    if (isLongitudeFilled && !longitude.toString().trim().length) return VALIDATION_MESSAGES.required;

    const { min, max } = POSITION_LIMITS.longitude;
    if (longitude) {
      if (typeof longitude == "string") {
        if (parseFloat(longitude) > max || parseFloat(longitude) < min) {
          return VALIDATION_MESSAGES.invalidLongitude;
        }
      } else {
        if (longitude > max || longitude < min) {
          return VALIDATION_MESSAGES.invalidLongitude;
        }
      }
    }

    return true;
  };

  const validateLatitude = (latitude: number | string | undefined) => {
    const isLatitudeFilled = !!latitude;
    const isLongitudeFilled = watch(GeneralInfoFormFields.LONGITUDE);
    if (isLongitudeFilled && !isLatitudeFilled) return VALIDATION_MESSAGES.required;

    if (isLatitudeFilled && !latitude.toString().trim().length) return VALIDATION_MESSAGES.required;

    const { min, max } = POSITION_LIMITS.latitude;
    if (latitude) {
      if (typeof latitude == "string") {
        if (parseFloat(latitude) > max || parseFloat(latitude) < min) {
          return VALIDATION_MESSAGES.invalidLatitude;
        }
      } else {
        if (latitude > max || latitude < min) {
          return VALIDATION_MESSAGES.invalidLatitude;
        }
      }
    }

    return true;
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) =>
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      [fieldName]: e.target.value,
    }));

  const getClientDetails = () => {
    FirebaseDataService.getClientDataById(clientId)
      .then(({ data }) => setClient(data))
      .catch((error) => console.log(error))
      .finally(() => setIsClientLoaded(true));
  };

  useEffect(() => {
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      isValid,
    }));
  }, [isValid]);

  useEffect(() => {
    if (isGeneralInfoFormSaving) {
      trigger(undefined, { shouldFocus: true });
      setIsGeneralInfoFormSaving(false);
    }
  }, [isGeneralInfoFormSaving]);

  useEffect(() => {
    if (!lat && !long) clearErrors();
  }, [lat, long]);

  useEffect(() => {
    if (clientId) getClientDetails();
  }, []);

  if (!isClientLoaded) return <Spinner animation="border" size="sm" />;

  return (
    <div>
      <DeleteModal
        onApproveClick={() => {
          setGeneralInfoFormState(({ locationContacts, ...prevState }) => ({
            ...prevState,
            locationContacts: locationContacts.filter(({ id: formId }) => formId !== contactFormToDelete),
          }));
          setContactFormToDelete("");
        }}
        onCancelClick={() => setContactFormToDelete("")}
        title="Contact"
        isShowing={Boolean(contactFormToDelete)}
        hide={() => setContactFormToDelete("")}
      />
      {client?.name && (
        <InputRow>
          <label>Client: </label>
          <FieldLink to={`/clients/${clientId}`}>{client.name}</FieldLink>
        </InputRow>
      )}
      {locationId && (
        <InputRow>
          <label>Location ID:</label>
          <RowValue>{locationId}</RowValue>
        </InputRow>
      )}
      {label && (
        <InputRow>
          <label>Label:</label>
          <RowValue>{label}</RowValue>
        </InputRow>
      )}
      {address && (
        <InputRow>
          <label>Address:</label>
          <RowValue>{address}</RowValue>
        </InputRow>
      )}
      <InputsRow>
        {inactive !== undefined && (
          <InputRow>
            <label>Status: </label>
            <RowValue>{inactive ? "Inactive" : "Active"}</RowValue>
          </InputRow>
        )}
        <InputRow>
          <label>Time Zone:</label>
          <RowValue>{formatTimezoneToShort(timezone)}</RowValue>
        </InputRow>
      </InputsRow>
      <InputsRow>
        <InputRow $inputRowStyle={"margin-top: 15px; margin-bottom: 15px"}>
          <LabelsContainer>
            <label>{`Latitude:`}</label>
            {!long && !lat && <LabelNote>(optional)</LabelNote>}
          </LabelsContainer>
          <div>
            <Input
              value={generalInfoFormState.latitude}
              placeholder="Enter Latitude"
              validation={register(GeneralInfoFormFields.LATITUDE, {
                onChange: (e) => handleFieldChange(e, GeneralInfoFormFields.LATITUDE),
                shouldUnregister: true,
                validate: validateLatitude,
              })}
            />
            <p>{errors?.[GeneralInfoFormFields.LATITUDE]?.message}</p>
          </div>
        </InputRow>
        <InputRow $inputRowStyle={"margin-top: 15px; margin-bottom: 15px"}>
          <LabelsContainer>
            <label>Longitude:</label>
            {!long && !lat && <LabelNote>(optional)</LabelNote>}
          </LabelsContainer>
          <div>
            <Input
              value={generalInfoFormState.longitude}
              placeholder="Enter Longitude"
              validation={register(GeneralInfoFormFields.LONGITUDE, {
                onChange: (e) => handleFieldChange(e, GeneralInfoFormFields.LONGITUDE),
                shouldUnregister: true,
                validate: validateLongitude,
              })}
            />
            <p>{errors?.[GeneralInfoFormFields.LONGITUDE]?.message}</p>
          </div>
        </InputRow>
      </InputsRow>
      {isSupervisorsLoaded && (
        <InputsRow>
          <InputRow>
            <label>Supervisor: </label>
            <SelectSearch
              defaultValue={generalInfoFormState?.supervisor?.id}
              options={supervisors}
              onChange={(item) =>
                setGeneralInfoFormState((prevState) => ({
                  ...prevState,
                  supervisor: item,
                }))
              }
              listSize={dropdownListSizes?.supervisor}
            />
          </InputRow>
        </InputsRow>
      )}
      <FormsContainer>
        {generalInfoFormState?.locationContacts?.map(({ id }, index) => (
          <LocationContactForm
            key={id}
            formIndex={index}
            defaultValues={generalInfoFormState?.locationContacts?.[index]}
            handleFormDelete={() => setContactFormToDelete(id)}
          />
        ))}
      </FormsContainer>
      <Button
        text="Add Location Contact"
        variant="dotted-border"
        icon={faPlus as any}
        onClick={() =>
          setGeneralInfoFormState(({ locationContacts = [], ...prevState }) => ({
            ...prevState,
            locationContacts: [
              ...locationContacts,
              {
                id: uuidv4(),
                isValid: true,
              } as LocationContactFormProps,
            ],
          }))
        }
      />
    </div>
  );
};

const FieldLink = styled(Link)`
  color: ${colors.grey.dark};
  width: 175px;
  font-weight: 400;
  font-size: 14px;
  font-style: unset;
`;

const FormsContainer = styled.div`
  margin-bottom: 30px;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: row;
`;

export const LabelNote = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.grey.dark};
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 14px;
  width: 100px;
  color: ${colors.grey.dark};
  white-space: nowrap;
  margin-right: 20px;
`;

const LabelsContainer = styled.div`
  width: 120px;
  margin-right: 20px;
`;
