import * as faIconsLight from "@fortawesome/pro-light-svg-icons";
import * as faIconsSolid from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

interface TileProps {
  icon?: string;
  title: string;
  onClick?: () => void;
  hint?: string;
  hintIcon?: string;
}

export const Tile: React.FC<TileProps> = ({ icon = "", title = "", onClick, hint = "", hintIcon = "" }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <Container onClick={onClick}>
        {hintIcon && hint && (
          <HintIcon
            data-tip={hint.replace(/<br ?\/?>/g, "\n")}
            icon={(faIconsLight as any)[hintIcon]}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        <Content>
          {icon && <Icon $isMobile={isMobile ? 1 : 0} icon={(faIconsSolid as any)[icon]} />}
          <Title $isMobile={isMobile ? 1 : 0}>{title}</Title>
          <ReactTooltip clickable={false} className="tooltip" />
        </Content>
      </Container>
    </>
  );
};

interface ContainerProps {
  $isMobile?: number;
  managerstyle?: boolean;
}

const Container = styled.a<ContainerProps>`
  position: relative;
  width: 100%;
  max-width: 200px;
  padding: 20px;
  min-height: 118px;
  z-index: 1;

  background-color: ${colors.white.default};
  border: 1px solid ${colors.grey.light10};
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  .tooltip {
    max-width: 70%;
  }
  &:hover svg,
  &:hover p {
    color: ${colors.kleenway.green};
  }
`;

const Icon = styled(FontAwesomeIcon)<ContainerProps>`
  color: ${colors.kleenway.green};
  height: 32px;
  width: 32px;
  margin-bottom: 16px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 50px;
      margin-bottom: 8px;
    `}
`;

const HintIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  height: 20px;
  width: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Content = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-overflow: ellipsis;
`;

const Title = styled.p<ContainerProps>`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.black.light1};
`;
