import React, { ReactNode, useState } from "react";

import WorkRequestListContext from "./context";
import { Ticket } from "src/types";
import { useQueryParam, StringParam, NumberParam, BooleanParam } from "use-query-params";

function WorkRequestListProvider({ children }: { children: ReactNode }) {
  const [activeTab, setActiveTab] = useQueryParam("activeTab", StringParam);

  const [selectedTicketForCloseAction, setSelectedTicketForCloseAction] = useState<Ticket>();
  const [selectedTicketForAssignResource, setSelectedTicketForAssignResource] = useState<Ticket>();

  const appliedParam = `${activeTab}_APPLIED`;
  const categoryParam = `${activeTab}_CATEGORY`;
  const directionParam = `${activeTab}_DIRECTION`;
  const searchTextParam = `${activeTab}_SEARCH_TEXT`;
  const pageNumberParam = `${activeTab}_PAGE_NUMBER`;

  const [appliedSearch, setAppliedSearch] = useQueryParam(appliedParam, BooleanParam);
  const [category, setCategory] = useQueryParam(categoryParam, StringParam);
  const [direction, setDirection] = useQueryParam(directionParam, StringParam);
  const [searchText, setSearchText] = useQueryParam(searchTextParam, StringParam);
  const [pageNumber, setPageNumber] = useQueryParam(pageNumberParam, NumberParam);
  return (
    <WorkRequestListContext.Provider
      value={{
        setSelectedTicketForCloseAction,
        selectedTicketForCloseAction,
        selectedTicketForAssignResource,
        setSelectedTicketForAssignResource,
        activeTab: activeTab,
        setActiveTab,
        category,
        setCategory,
        direction,
        pageNumber,
        searchText,
        setDirection,
        setPageNumber,
        setSearchText,
        appliedSearch,
        setAppliedSearch,
      }}
    >
      {children}
    </WorkRequestListContext.Provider>
  );
}

export default WorkRequestListProvider;
