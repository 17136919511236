import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

export interface TableCellProps {
  children: ReactNode;
  $isLink?: boolean;
}
interface StyledTableCellProps {
  $isMobile?: boolean;
  $isLink?: boolean;
}
const TableCell = ({ children, $isLink }: TableCellProps) => {
  const { isMobile } = useIsMobile();

  return (
    <StyledTableCell $isLink={$isLink} $isMobile={isMobile}>
      {children}
    </StyledTableCell>
  );
};
export default TableCell;

export const StyledTableCell = styled.td<StyledTableCellProps>`
  padding: 15px;
  font-style: normal;
  font-size: 14px;
  color: ${colors.grey.dark3};
  width: 200px;
  vertical-align: middle;
  border-bottom: 1px solid ${colors.grey.light15};

  & .bold {
    font-weight: 600;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 8px;
    `}
  ${({ $isLink }) =>
    $isLink &&
    css`
      text-decoration: underline;
      cursor: pointer !important;
    `}
`;
