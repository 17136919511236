import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

export const getActiveResources = (state: RootState) => state.resources;
const getWorkRequest = (state: RootState) => state.workRequest;

export const getAllActiveResources = createSelector([getActiveResources], (resources) => resources);

export const getIsTicketsLoading = createSelector([getWorkRequest], (workRequest) => workRequest.isTicketsLoading);

export const getPriorityLevels = createSelector([getWorkRequest], (workRequest) => workRequest.priorityLevels);

export const getTickets = createSelector([getWorkRequest], (workRequest) => workRequest.tickets);

export const getIsSearchApplied = createSelector([getWorkRequest], (workRequest) => workRequest.isSearchApplied);

export const getWorkOrderCount = createSelector([getWorkRequest], (workRequest) => workRequest.workOrderCount);
