import { ButtonCheckboxProps } from "./types";

export const onClickHandler = (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  checked: ButtonCheckboxProps["checked"],
  value: ButtonCheckboxProps["value"],
  onChange: ButtonCheckboxProps["onChange"],
) => {
  event.preventDefault();

  const eventCopy = {
    ...event,
    target: {
      checked: !checked,
      value,
    },
  } as unknown as React.ChangeEvent<HTMLInputElement>;

  onChange?.(eventCopy);
};
