import { faSearch } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { Input, SearchIcon, SearchInputWrapper } from "./styles";
import { SearchInputProps } from "./types";

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onChange }) => {
  return (
    <SearchInputWrapper direction="horizontal">
      <Input onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
      <SearchIcon icon={faSearch} />
    </SearchInputWrapper>
  );
};

export default SearchInput;
