import React from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import { ID } from "src/types";
import { WORKREQUESTS_BREADCRUMBS } from "../../WorkRequestListViewContainer/constant";

const WorkRequestBreadCrumbs = () => {
  const navigate = useNavigate();

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  return <BreadCrumbs folderStack={WORKREQUESTS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />;
};

export default WorkRequestBreadCrumbs;
