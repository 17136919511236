import React, { FC, useEffect, useRef, useState } from "react";

import { listenForOutsideClicks } from "src/helpers/listenForOutsideClicks";
import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import { IDropdown, DropdownItem } from "./types";
import { SelectBox, SelectBoxContainer } from "./styles";
import SelectInputField from "./components/SelectInputField";
import SelectInputOptions from "./components/SelectInputOptions";

const Dropdown: FC<IDropdown> = ({
  items,
  onClick,
  $noRadius,
  placeholder,
  selectedDropdownItem,
  disabled,
  defaultValue,
  sort,
  defaultValueColor = colors.grey.dark3,
  width = "360px",
  inputStyle,
  placeholderStyle,
  $isScrollable,
  id = "",
}: IDropdown) => {
  const [showItems, setShowItems] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(selectedDropdownItem);
  const [listening, setListening] = useState(false);
  const dropdownRef = useRef(null);
  const { isMobile } = useIsMobile();

  const dropDown = () => {
    if (disabled) {
      return;
    }
    setShowItems((prevState) => !prevState);
  };

  useEffect(listenForOutsideClicks(listening, setListening, dropdownRef, setShowItems));

  const selectItem = (item: DropdownItem, e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedItem(item);
    setShowItems(false);
  };

  if (sort) {
    items = items?.sort(function (a, b) {
      if (a.value?.toLowerCase() < b.value?.toLowerCase()) {
        return -1;
      }
      if (a.value?.toLowerCase() > b.value?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  useEffect(() => {
    if (!selectedDropdownItem && !defaultValue) {
      setSelectedItem(undefined);
    }
  }, [defaultValue]);

  return (
    <SelectBox $isMobile={isMobile} disabled={disabled} ref={dropdownRef} onClick={dropDown} width={width} id={id}>
      <SelectBoxContainer inputStyle={inputStyle} $noRadius={$noRadius as boolean}>
        <SelectInputField
          defaultValueColor={defaultValueColor}
          selectedItem={selectedItem}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          placeholderStyle={placeholderStyle}
          showItems={showItems}
        />
        <SelectInputOptions
          showItems={showItems}
          $isScrollable={$isScrollable}
          defaultValueColor={defaultValueColor}
          items={items}
          onClick={onClick}
          selectItem={selectItem}
        />
      </SelectBoxContainer>
    </SelectBox>
  );
};

export default React.memo(Dropdown);
