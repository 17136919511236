import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faDownload, faFolder, faPen, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Button from "src/components/Button";
import Paper from "src/components/Paper";
import { colors } from "src/styles";
import { IOmegaItem } from "src/types";
import styled from "styled-components";

import { getFileIcon } from "src/helpers/getErrorMsg";
import { RenameModalProps } from "../ClientDetailsProvider";

interface FileItemProps {
  item: IOmegaItem;
  disabledButtons: boolean;
  onItemClick: () => void;
  onEditClick: (item: RenameModalProps) => void;
  onDownloadClick: () => void;
  onDeleteClick: (item: RenameModalProps) => void;
}
export const FileItem = ({
  item,
  disabledButtons,
  onEditClick,
  onItemClick,
  onDownloadClick,
  onDeleteClick,
}: FileItemProps) => {
  const { type, name } = item;
  return (
    <PaperItem key={item.id}>
      <Paper onClick={onItemClick} containerStyle={{ width: "calc(100%)", margin: "0", padding: "5px 18px" }}>
        <Icon icon={type === "file" ? getFileIcon(name) : faFolder} />
        <Title>{name}</Title>
      </Paper>

      <PaperControls>
        <Button
          disabled={disabledButtons}
          icon={faDownload as IconDefinition}
          iconPosition={"center"}
          variant={"btn-icon-grey"}
          onClick={onDownloadClick}
        />
        <Button
          disabled={disabledButtons}
          icon={faPen as IconDefinition}
          iconPosition={"center"}
          variant={"btn-icon-grey"}
          onClick={() => onEditClick({ ...item, ...{ modalType: "rename" } })}
        />
        <Button
          disabled={disabledButtons}
          icon={faTimes as IconDefinition}
          iconPosition={"center"}
          variant={"btn-icon-red"}
          onClick={() => onDeleteClick({ ...item, ...{ modalType: "delete" } })}
        />
      </PaperControls>
    </PaperItem>
  );
};

const PaperItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const PaperControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  height: 28px;
  margin-right: 15px;
`;

const Title = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.dark};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
