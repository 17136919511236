import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import DataTable from "src/components/Table";
import TableRow from "src/components/Table/TableRow";
import { noop } from "src/components/atoms/DatePicker/utils";
import { DBContract } from "src/types";
import { roundTo2Decimals } from "src/utils/helpers";
import { dateFormat } from "../../constants";
import { LineCost, LineDescription, NoItems, StyledCell } from "../../styles";
import { ContractsTableProps } from "../../types";

export const ContractTable = ({ headers, data }: ContractsTableProps) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleRowClick = (rowId: number) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  return (
    <DataTable headers={headers}>
      <>
        {data?.map(
          ({ contractnum, description, startdate, enddate, renewaldate, totalcost, contractline }: DBContract) => {
            const isExpanded = expandedRow === +contractnum;

            return (
              <React.Fragment key={contractnum}>
                <TableRow $clickable={true} onClick={() => handleRowClick(+contractnum)}>
                  <StyledCell>
                    <FontAwesomeIcon
                      size={"1x"}
                      style={{ marginRight: 10 }}
                      icon={isExpanded ? faAngleDown : faAngleRight}
                    />
                    {contractnum}
                  </StyledCell>
                  <StyledCell>{description}</StyledCell>
                  <StyledCell>{startdate ? moment(startdate).format(dateFormat) : ""}</StyledCell>
                  <StyledCell>{enddate ? moment(enddate).format(dateFormat) : ""}</StyledCell>
                  <StyledCell>{renewaldate ? moment(renewaldate).format(dateFormat) : ""}</StyledCell>
                  <StyledCell>
                    <span style={{ marginLeft: 15 }}>{roundTo2Decimals(totalcost)}</span>
                  </StyledCell>
                </TableRow>
                {isExpanded && (
                  <>
                    {contractline ? (
                      contractline?.map(({ description, linecost, contractLineId }) => {
                        return (
                          <TableRow key={contractLineId} $clickable={false} onClick={noop}>
                            <LineDescription colSpan={5}>{description}</LineDescription>
                            <LineCost colSpan={1}>
                              <span style={{ marginLeft: 10 }}>{roundTo2Decimals(linecost)}</span>
                            </LineCost>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow $clickable={false} onClick={noop}>
                        <NoItems colSpan={6}>No line items found</NoItems>
                      </TableRow>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          },
        )}
      </>
    </DataTable>
  );
};
