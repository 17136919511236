import Resizer from "react-image-file-resizer";

export const getComressedBase64File = (file: File): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      400,
      "JPEG",
      80,
      0,
      (value: string | File | Blob | ProgressEvent<FileReader>) => {
        resolve(value);
      },
      "base64",
    );
  });

export const getBase64File = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
