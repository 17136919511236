import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssignWOResourceModal from "src/components/organisms/AssignWOResourseModal";
import CloseWorkOrderModal from "src/components/organisms/CloseWorkOrderModal";
import { getUserData } from "src/redux/selectors/user";
import { onAssignResourceHandler } from "./handler";
import { WorkRequestModalsContainerProps } from "./types";
import { getTickets } from "src/redux/selectors/workRequest";

import { getActiveResources } from "src/redux/selectors/resources";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const WorkRequestModalsContainer = ({ onSubmitCloseWorkOrderHandler }: WorkRequestModalsContainerProps) => {
  const [assignResourceErrorOnSubmit, setAssignResourceErrorOnSubmit] = useState<string>("");
  const { id } = useSelector(getUserData);
  const { employees, vendors } = useSelector(getActiveResources);
  const tickets = useSelector(getTickets);
  const dispatch = useDispatch();
  const {
    setSelectedTicketForCloseAction,
    selectedTicketForCloseAction,
    selectedTicketForAssignResource,
    setSelectedTicketForAssignResource,
  } = useContext(WorkRequestListContext);

  return (
    <>
      {selectedTicketForCloseAction && (
        <CloseWorkOrderModal
          closeModal={() => setSelectedTicketForCloseAction(undefined)}
          onSubmit={onSubmitCloseWorkOrderHandler}
          workorder={selectedTicketForCloseAction}
        />
      )}
      {selectedTicketForAssignResource && (
        <AssignWOResourceModal
          closeModal={() => setSelectedTicketForAssignResource(undefined)}
          onSubmit={async (formValue) => {
            await onAssignResourceHandler(
              formValue,
              setAssignResourceErrorOnSubmit,
              tickets,
              dispatch,
              id,
              selectedTicketForAssignResource.wonum,
              setSelectedTicketForAssignResource,
            );
          }}
          employees={employees}
          vendors={vendors.filter(
            (vendor) => vendor.clients && vendor.clients.includes(selectedTicketForAssignResource.clientId),
          )}
          defaultValues={{
            employees: {
              laborCode: selectedTicketForAssignResource.laborId,
            },
            vendors: {
              woacceptscharges: selectedTicketForAssignResource?.woacceptscharges ?? false,
              approvedamount: selectedTicketForAssignResource?.approvedamount,
              vendorId: selectedTicketForAssignResource?.vendorId,
            },
            // commented out temporarily to hide the Employees tab. Will be reverted in the future
            activetab: selectedTicketForAssignResource?.vendorId ? "vendors" : "employees",
            // activetab: "vendors",
          }}
          errorMsg={assignResourceErrorOnSubmit}
        />
      )}
    </>
  );
};

export default WorkRequestModalsContainer;
