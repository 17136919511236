import type { FC } from "react";
import { DragSourceMonitor, useDrag, useDrop } from "react-dnd";

import React from "react";
import { FileType, ID } from "src/types";
import styled from "styled-components";

export const ItemTypes = {
  BOX: "box",
};

export interface BoxProps {
  idDrag: ID;
  idDrop: ID;
  children?: React.ReactNode;
  typeDrag: FileType;
  typeDrop: FileType;
  onDragEnd: (dropResults: DropResult) => void;
}

export interface DropResult {
  idDrop: ID;
  dropEffect?: string;
  idDrag: ID;
  typeDrop: FileType;
  typeDrag: FileType;
}

export const Box: FC<BoxProps> = ({ idDrag, idDrop, children, onDragEnd, typeDrag, typeDrop }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { idDrag, typeDrag },
      end(item, monitor) {
        const dropResult = monitor.getDropResult() as DropResult;
        if (item && dropResult) {
          const { idDrag, typeDrag } = item;
          const { idDrop, typeDrop } = dropResult;
          if (typeDrop !== "file" && idDrag !== idDrop) {
            onDragEnd({ idDrag, typeDrag, idDrop, typeDrop });
          }
        }
      },
      collect: (monitor: DragSourceMonitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [idDrag],
  );

  const [{}, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: () => ({
        idDrag,
        idDrop,
        typeDrop,
        typeDrag,
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [idDrop],
  );

  return (
    <BoxWrapper ref={drop} style={{ opacity }}>
      <BoxDrag ref={drag}>{children}</BoxDrag>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  display: block;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const BoxDrag = styled.div`
  display: block;
  width: 100%;
`;
