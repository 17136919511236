import React from "react";

import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { colors } from "src/styles";
import Button from "../Button";

interface IDeleteCrossButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const DeleteCrossButton = ({ onClick }: IDeleteCrossButtonProps) => {
  return (
    <Button
      additionalStyles={{
        fontWeight: 400,
        fontSize: "16px",
        padding: "12px 15px",
        borderRadius: 0,
        border: `1px solid ${colors.red.dark}`,
        backgroundColor: `${colors.red.light1}`,
        color: `${colors.grey.dark}`,
      }}
      onClick={onClick}
      icon={faTimes}
      iconPosition="center"
    />
  );
};

export default DeleteCrossButton;
