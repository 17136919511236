import { colors } from "src/styles";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  border-bottom: 1px dashed ${colors.grey.light4};
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;

export const FlexContainer = styled.div<{ paddingBottom?: number; shiftsdatepickers?: number }>`
  display: flex;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : 0)};
  justify-content: ${({ shiftsdatepickers }) => (shiftsdatepickers ? "flex-start" : "space-between")};
`;

export const StyledLabel = styled.label<{ paddingRight: number }>`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.dark};
  padding-right: ${({ paddingRight }) => `${paddingRight}px`};
`;

export const DatePickerWrapper = styled.div`
  &:first-of-type {
    margin-right: 83px;
  }
`;
