import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "src/components/Input";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { EMAIL_REGEXP, VALIDATION_MESSAGES } from "../../../../constants/validation";
import { colors } from "../../../../styles";
import { DBClientContact } from "../../../../types";
import ClientDetailsContext from "../../context";

enum ClientContactFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  JOB_DESCRIPTION = "title",
  PHONE = "phone",
  EMAIL = "email",
}

interface ClientContactFormProps {
  formIndex: number;
  defaultValues: DBClientContact;
  handleFormDelete: () => void;
}

export const ClientContactForm = ({ formIndex, defaultValues, handleFormDelete }: ClientContactFormProps) => {
  const { isGeneralInfoFormSaving, setIsGeneralInfoFormSaving, setGeneralInfoFormState } =
    useContext(ClientDetailsContext);

  const {
    register,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setGeneralInfoFormState(({ clientContacts, ...prevState }) => ({
      ...prevState,
      clientContacts: clientContacts.map((state, index) => {
        if (index === formIndex)
          return {
            ...state,
            [fieldName]: e.target.value,
            isValid,
          };
        return state;
      }),
    }));
  };

  useEffect(() => {
    if (isGeneralInfoFormSaving) {
      trigger(undefined, { shouldFocus: true });
      setIsGeneralInfoFormSaving(false);
    }
  }, [isGeneralInfoFormSaving]);

  useEffect(() => {
    if (isValid !== undefined) {
      setGeneralInfoFormState(({ clientContacts, ...prevState }) => ({
        ...prevState,
        clientContacts: clientContacts.map((state, index) => {
          if (index === formIndex)
            return {
              ...state,
              isValid,
            };
          return state;
        }),
      }));
    }
  }, [isValid]);

  return (
    <Container>
      <Header>
        <Title>{`Client Contact ${formIndex + 1}:`}</Title>
        <Button text="Delete Contact" variant="text" onClick={handleFormDelete} />
      </Header>
      <Content>
        <Form>
          <InputsContainer>
            <InputsRow>
              <InputRow>
                <label>First Name:</label>
                <div>
                  <Input
                    value={defaultValues.firstName}
                    maxLength={100}
                    placeholder="Enter First Name"
                    validation={register(ClientContactFields.FIRST_NAME, {
                      onChange: (e) => handleFieldChange(e, ClientContactFields.FIRST_NAME),
                      shouldUnregister: true,
                      validate: {
                        emptyValue: (value: string) => !!value.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[ClientContactFields.FIRST_NAME]?.message}</p>
                </div>
              </InputRow>
              <InputRow>
                <label>Last Name:</label>
                <div>
                  <Input
                    value={defaultValues.lastName}
                    maxLength={100}
                    placeholder="Enter Last Name"
                    validation={register(ClientContactFields.LAST_NAME, {
                      onChange: (e) => handleFieldChange(e, ClientContactFields.LAST_NAME),
                      validate: {
                        emptyValue: (value: string) => !!value.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[ClientContactFields.LAST_NAME]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>

            <InputsRow>
              <InputRow>
                <label>Job Description:</label>
                <div>
                  <Input
                    value={defaultValues.title}
                    maxLength={100}
                    placeholder="Enter Job Description"
                    validation={register(ClientContactFields.JOB_DESCRIPTION, {
                      onChange: (e) => handleFieldChange(e, ClientContactFields.JOB_DESCRIPTION),
                    })}
                  />
                </div>
              </InputRow>
              <InputRow>
                <label>Phone:</label>
                <div>
                  <Input
                    value={defaultValues.phone}
                    maxLength={100}
                    placeholder="Enter Phone"
                    validation={register(ClientContactFields.PHONE, {
                      onChange: (e) => handleFieldChange(e, ClientContactFields.PHONE),
                      validate: {
                        emptyValue: (value, formValues) => {
                          return !!value?.trim() || !!formValues.email.trim() || VALIDATION_MESSAGES.required;
                        },
                      },
                    })}
                  />
                  <p>{errors?.[ClientContactFields.PHONE]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>

            <InputsRow>
              <InputRow>
                <label>Email:</label>
                <div>
                  <Input
                    value={defaultValues.email}
                    maxLength={100}
                    placeholder="Enter Email"
                    validation={register(ClientContactFields.EMAIL, {
                      onChange: (e) => handleFieldChange(e, ClientContactFields.EMAIL),
                      validate: {
                        emptyValue: (value: string, formValues) => {
                          return !!value.trim() || !!formValues?.phone?.trim() || VALIDATION_MESSAGES.required;
                        },
                      },
                      pattern: {
                        value: EMAIL_REGEXP,
                        message: VALIDATION_MESSAGES.invalidEmail,
                      },
                    })}
                  />
                  <p>{errors?.[ClientContactFields.EMAIL]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>
          </InputsContainer>
        </Form>
      </Content>
    </Container>
  );
};

interface InputRowProps {
  $inputRowStyle?: string;
}

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px 0 30px 0;
`;
export const Content = styled.div`
  background-color: ${colors.white.default};
  padding: 10px 0 30px 0;
`;

interface InputRowProps {
  $inputWidth?: string;
}

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: 325px;
  width: 100%;
  position: relative;
  margin-right: 20px;

  div {
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.red.dark};
      max-width: 400px;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    width: 120px;
    color: ${colors.grey.dark};
    white-space: nowrap;
    margin-right: 20px;
  }

  input {
    width: ${(props) => props.$inputWidth || "175"}px;
  }

  a {
    color: ${colors.grey.dark};
  }

  ${(props) => props.$inputRowStyle && `${props.$inputRowStyle}`}
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
