import { DATEPICKER_VARIANT } from "./types";

export const DATEPICKER_FORMAT = {
  [DATEPICKER_VARIANT.DATE]: "MM/DD/YYYY",
  [DATEPICKER_VARIANT.TIME]: "hh:mm A",
  [DATEPICKER_VARIANT.DATE_TIME]: "MM/DD/YYYY hh:mm A",
};

export const DATEPICKER_PLACEHOLDER = {
  [DATEPICKER_VARIANT.DATE]: "Select date",
  [DATEPICKER_VARIANT.TIME]: "_ _ : _ _",
  [DATEPICKER_VARIANT.DATE_TIME]: "Select date and time",
};
