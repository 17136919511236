import React, { useState } from "react";

import { DBContract } from "src/types";
import { ContactContainer } from "../../styles";
import { ContractsListProps } from "../../types";
import { Contract } from "../Contract";

export const ContractsList = ({ data }: ContractsListProps) => {
  const [expandedContract, setExpandedContract] = useState<DBContract>();

  return (
    <>
      {data.map((contract: DBContract) => (
        <ContactContainer onClick={() => setExpandedContract(contract)} key={contract.contractid}>
          <Contract contract={contract} expandedContract={expandedContract} setExpandedContract={setExpandedContract} />
        </ContactContainer>
      ))}
    </>
  );
};
