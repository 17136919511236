import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Footer from "src/components/Footer";
import Header from "src/components/Header";
import { DEFAULT_SESSION_TIME, MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { getErrorMsg } from "src/helpers/getErrorMsg";
import { UserInfo } from "src/pages/NoUserLogin/UserInfo";
import { logInUser, logOutUser } from "src/redux/actions/user";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService, NoAuthDataForTicketCreatePageResponse } from "src/services/Firebase/data";
import FirebaseStorageService from "src/services/Firebase/storage";
import { colors } from "src/styles";
import { debugError } from "src/utils/log";

import { TicketForm } from "../TicketForm";
import SubmittedTicket from "./SubmittedTicket";

interface NoUserLoginProps {
  name: string;
  logo: string;
  setName: (name: string) => void;
  setLogo: (logo: string) => void;
}

export const NoUserLogin: React.FC<NoUserLoginProps> = ({ name, logo, setName, setLogo }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
  });

  const { clientCode } = useParams() || "";
  const { clientId } = useSelector(getUserData);
  const navigate = useNavigate();
  const captchaRef = useRef<ReCAPTCHA>(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [noAuthData, setNoAuthData] = useState<NoAuthDataForTicketCreatePageResponse | undefined>();
  const [sessionStart, setSessionStart] = useState<number>(Date.now());
  const [sessionTimeout, setSessionTimeout] = useState<number | null>(null);
  const [labelMessage, setLabelMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [askCompanyCode, setAskCompanyCode] = useState<boolean>(true);

  const resetFields = () => {
    reset({
      ...getValues(),
      firstName: "",
      lastName: "",
      companyCode: "",
      email: "",
      phoneNumber: "",
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const { firstName, lastName, email, captcha, companyCode } = data;
    try {
      const {
        data: noAuthData,
        status,
        message,
      } = await FirebaseDataService.verifyClientUserInfo({
        companyCode,
        clientId,
        captcha,
      });

      if (status === "success") {
        setNoAuthData(noAuthData);
        dispatch(
          logInUser({
            firstName,
            clientId,
            lastName,
            email,
            phoneNumber: data?.phoneNumber,
          }),
        );
        setStep((old) => old + 1);
        setSessionStart(Date.now());
        resetFields();
      }

      if (status === "error") {
        captchaRef?.current?.reset();

        setValue("captcha", "");
        await trigger(["captcha"]);

        throw new Error(
          message === "Failed captcha verification"
            ? message
            : "The entered Company Code is incorrect. Please, enter the correct Company Code or consult your Kleenway Representative for assistance.",
        );
      }
    } catch (error) {
      setError(getErrorMsg(error));
      debugError(error);
    }

    setLoading(false);
  });

  const onChangeCaptcha = async (value: string) => {
    setValue("captcha", value);
    await trigger(["captcha"]);
  };

  const onClickCreateAnotherTicket = async () => {
    const diff = (Date.now() - sessionStart) / 1000;

    const timeout = sessionTimeout ? sessionTimeout : DEFAULT_SESSION_TIME;
    console.log({ diff, timeout });

    if (diff <= timeout) {
      setStep(1);
    } else {
      dispatch(logOutUser());
      setStep(0);
    }
  };

  const getData = async (code = "") => {
    setLoading(true);
    try {
      const { data } = await FirebaseDataService.getClientDataForNoAuthMode(clientCode || code);
      if (!data) {
        navigate("/404");
      }
      const { clientData, isCompanyCodeRequired, labelMessage, maximoTimezone, sessionTimeout } = data;
      dispatch(logInUser({ clientId: clientData.clientId, maximoTimezone: maximoTimezone || MAXIMO_TIME_ZONE }));
      setSessionTimeout(Number(sessionTimeout));
      setLabelMessage(labelMessage);

      if (clientData?.name) {
        setName(clientData.name);
      }
      if (clientData.logo) {
        try {
          const { data: logoUrl } = await FirebaseStorageService.getDownloadURL(
            process.env.REACT_APP_CLIENT_LOGO_FOLDER,
            clientData.logo,
          );
          setLogo(logoUrl);
        } catch (error) {
          // intentionally left empty as there is no handling required for a missing Client logo
        }
      }
      setAskCompanyCode(isCompanyCodeRequired);
    } catch (error) {
      debugError(error);
    }
    setLoading(false);
  };

  const clearClientData = () => {
    setName("");
    setLogo("");
  };

  useEffect(() => {
    register("captcha", { required: true });
    return clearClientData;
  }, []);

  useEffect(() => {
    !step && getData();
  }, [step]);

  const steps = [
    <UserInfo
      key={0}
      onChangeCaptcha={onChangeCaptcha}
      onSubmit={onSubmit}
      register={register}
      isValid={isValid}
      loading={loading}
      error={error}
      captchaRef={captchaRef}
      errors={errors}
      labelMessage={labelMessage}
      askCompanyCode={askCompanyCode}
    />,

    <Wrapper key={1}>
      <TicketForm setStep={setStep} noAuthMode noAuthData={noAuthData} setSuccessMessage={setSuccessMessage} />
    </Wrapper>,

    <SubmittedTicket key={2} onClickCreateAnotherTicket={onClickCreateAnotherTicket} successMessage={successMessage} />,
  ];

  return (
    <Container>
      {loading || !clientId ? (
        <EmptyContainer>
          <Spinner animation="border" />
        </EmptyContainer>
      ) : (
        <>
          <Header name={name} logo={logo} noLink noUserLoginPage />
          {steps[step]}
          <StickyFooter />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background-color: ${colors.white.default};
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 60px;
`;

const Wrapper = styled.div`
  margin-top: 80px;
`;

const EmptyContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StickyFooter = styled(Footer)`
  position: sticky;
  margin-top: auto;
`;
