import { colors } from "src/styles";
import styled from "styled-components";
import { TablePriorityBadgeProps } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TicketWrapper = styled.div`
  margin-bottom: 10px;
`;
export const TicketContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  width: 100%;
`;

export const TicketHeading = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey.dark};
`;

export const TicketSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketDot = styled.div`
  height: 2px;
  width: 2px;
  background-color: ${colors.grey.dark};
  border-radius: 50%;
  display: inline-block;
`;
export const TicketButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;
export const ResourceAssignedContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;

  &:hover {
    background: ${colors.grey.light1} !important;
  }
`;
export const TablePriorityBadge = styled.div<TablePriorityBadgeProps>`
  font-weight: 600;
  color: ${colors.grey.dark};
  color: ${(props) => props.color};
`;
export const ResourseWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  span {
    color: ${colors.grey.dark};
  }
`;
export const UnAssignedIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding-right: 8px;
`;
export const Mark = styled.mark`
  background-color: inherit;
  color: red;
`;
