import React from "react";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { AssignWOButtonContainer } from "../styles";
import { AssignWOResourceButtonsProps } from "../types";

export const AssignWOResourceButtons = ({ onCancel, onApply }: AssignWOResourceButtonsProps) => {
  const {
    formState: { isSubmitting },
    watch,
  } = useFormContext();

  const isResourceSelected = watch("vendors.vendorId") || watch("employees.laborCode");

  return (
    <AssignWOButtonContainer direction="horizontal">
      <Button
        text="Cancel"
        variant="text"
        onClick={(event) => {
          event.preventDefault();
          onCancel();
        }}
      />
      <Button text="Apply" disabled={!isResourceSelected} loading={isSubmitting} onClick={onApply} />
    </AssignWOButtonContainer>
  );
};
