import React from "react";
import "react-datetime-picker/dist/DateTimePicker.css";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ShareFormView } from "./components/ShareFormView";
import { ShareFormProps, ShareFormValues } from "./types";

export const ShareForm = ({ onSubmit, closeModal, error }: ShareFormProps) => {
  const form = useForm<ShareFormValues>();

  const onSubmitHandler: SubmitHandler<ShareFormValues> = async (data, event) => {
    if (!form.getValues("emailTo.0.value")) {
      form.setError("emailTo.0.value", { type: "required" });
    } else {
      form.clearErrors("emailTo.0.value");
      await onSubmit(data, event);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmitHandler)}>
        <ShareFormView error={error} onCancel={closeModal} />
      </form>
    </FormProvider>
  );
};
