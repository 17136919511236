import { faArrowRight, faCaretUp, faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Spinner, Toast } from "react-bootstrap";
import Badge from "src/components/Badge";
import { OptionItem } from "src/components/SelectSearch";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { WorkOrder } from "src/types";
import styled from "styled-components";

interface StatusSelectProps {
  workOrder: WorkOrder;
  statusList: OptionItem[];
}

const StatusSelect = ({ workOrder, statusList }: StatusSelectProps) => {
  const [statuses, setStatuses] = useState(statusList);
  const [status, setStatus] = useState(workOrder?.status_description || "");
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const boxRef = useRef<HTMLDivElement>(null);

  const openOptionsBox = () => {
    if (isSaving) return;
    setIsOpen((prev) => !prev);
  };

  const onSelectHandler = async (status: OptionItem) => {
    setStatus(status.value);
    setIsSaving(true);
    setIsOpen(false);

    const newWorkOrder = {
      ...workOrder,
      status: status.id,
      status_description: status.value,
    };

    try {
      const { data } = await FirebaseDataService.updateMaximoStatus(newWorkOrder);
      if (!data) return;
      setStatuses(data);
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxRef.current && !boxRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isOpen]);

  return (
    <>
      {error && (
        <StylesToast onClose={() => setError("")} bg="danger">
          <Toast.Header>error</Toast.Header>
        </StylesToast>
      )}
      <Wrapper ref={boxRef}>
        <FlexWrapper>
          <StyledOption issaving={isSaving ? 1 : 0} onClick={openOptionsBox}>
            <Badge status={status} />
            <Icon issaving={isSaving ? 1 : 0} icon={(isOpen ? faChevronUp : faChevronDown) as any} />
          </StyledOption>
          {isSaving && <Spinner size="sm" animation="border" />}
        </FlexWrapper>

        {isOpen && (
          <>
            <TriangaleIcon icon={faCaretUp as any} />

            <OptionsBox>
              {statuses.length ? (
                statuses.map((item) => {
                  return (
                    <ListOption key={item.id} onClick={() => onSelectHandler(item)}>
                      <ListOptionValue>Transition to</ListOptionValue>
                      <div>
                        <OptionIcon icon={faArrowRight as any} />
                        <Badge status={item.value} />
                      </div>
                    </ListOption>
                  );
                })
              ) : (
                <NoStatuses>There are no available statuses</NoStatuses>
              )}
            </OptionsBox>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default StatusSelect;

const Wrapper = styled.div`
  position: relative;
`;

const OptionsBox = styled.div`
  position: absolute;
  width: 100%;
  min-width: 360px;
  top: 40px;
  background-color: ${colors.white.default};
  z-index: 5;
  border: 1px solid ${colors.grey.light4};
  min-height: 30px;
  max-height: 300px;
  overflow: auto;
`;

const StyledOption = styled.div<{ issaving: number }>`
  cursor: ${({ issaving }) => (issaving ? "default" : "pointer")};
  & > div {
    border-radius: 15px;
    padding-right: 40px;
    font-size: 14px;
  }
`;

const Icon = styled(FontAwesomeIcon)<{ issaving: number }>`
  color: ${colors.grey.dark};
  height: 14px;
  position: absolute;
  right: ${({ issaving }) => (issaving ? "34px" : "13px")};
  top: 7px;
`;

const OptionIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  padding-right: 5px;
`;

const TriangaleIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.light4};
  position: absolute;
  height: 22px;
  width: 22px;
  top: 26px;
  left: 30%;
`;

const ListOption = styled.div`
  padding: 16px 10px;
  border-bottom: 1px solid ${colors.grey.light4};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey.light1};
  }
  &:last-child {
    border-bottom: 0;
  }
  & > div > div {
    border-radius: 15px;
    font-size: 14px;
  }
`;

const ListOptionValue = styled.div`
  font-size: 14px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  & > div + div {
    margin-left: 5px;
  }
`;

const StylesToast = styled(Toast)`
  position: absolute;
  left: 60%;
  border: 1px solid ${colors.red.dark};
  & > .toast-header {
    justify-content: space-between;
    border-bottom: 0;
    border-radius: 5px;

    & button {
      margin-right: 0;
    }
  }
`;

const NoStatuses = styled.div`
  font-size: 14px;
  color: ${colors.red.default};
  padding: 8px;
`;
