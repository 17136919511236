import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { IMAGES } from "../../../../assets";
import Checkbox from "../../../../components/Checkbox";
import SinglePhotoUpload from "../../../../components/PhotoUpload/Single";
import FirebaseStorageService from "../../../../services/Firebase/storage";
import { colors } from "../../../../styles";
import { InfoMessagesFormProps } from "../LocationDetailsProvider";
import LocationDetailsContext from "../context";
import { EditableInfoMessagesForm } from "./EditableForm";
import { ReadOnlyInfoMessagesForm } from "./ReadOnlyForm";

const getEvacuationPlanPropType = (logo: File | string) => (typeof logo === "string" ? "fileName" : "file");

interface InfoMessagesFormComponentProps {
  isChecked: boolean;
  setIsChecked: Dispatch<SetStateAction<boolean>>;
}

export const InfoMessagesForm = ({ isChecked, setIsChecked }: InfoMessagesFormComponentProps) => {
  const [initialInfoMessages, setInitialInfoMessages] = useState<InfoMessagesFormProps>();
  const [evacuationPlan, setEvacuationPlan] = useState<File | string | null>(null);
  const [loading, setLoading] = useState(false);

  const { generalInfoFormState, setGeneralInfoFormState, isGeneralInfoFormSaving } = useContext(LocationDetailsContext);

  const getEvacuationPlanDownloadUrl = (logoFileName: string) =>
    FirebaseStorageService.getDownloadURL(process.env.REACT_APP_EVACUATION_PLAN_FOLDER, logoFileName)
      .then(({ data: logoDownloadUrl }) => setEvacuationPlan(logoDownloadUrl))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

  const handleEditModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked((prev) => !prev);

    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      infoMessages: e.target.checked ? initialInfoMessages || null : null,
    }));
  };

  useEffect(() => {
    if (generalInfoFormState?.infoMessages) {
      setIsChecked(!!generalInfoFormState?.infoMessages);
      if (!initialInfoMessages || (initialInfoMessages && isGeneralInfoFormSaving)) {
        setInitialInfoMessages(generalInfoFormState.infoMessages);
      }
    }
  }, [generalInfoFormState?.infoMessages, isGeneralInfoFormSaving]);

  useEffect(() => {
    if (!isChecked && isGeneralInfoFormSaving) {
      setInitialInfoMessages(undefined);
    }
  }, [isChecked, isGeneralInfoFormSaving]);

  useEffect(() => {
    if (generalInfoFormState) {
      const { evacuationPlan: clientEvacuationPlan } = generalInfoFormState;

      if (clientEvacuationPlan) {
        const typeOfEvacuationPlanProp = getEvacuationPlanPropType(clientEvacuationPlan);
        if (typeOfEvacuationPlanProp === "fileName") {
          const evacuationPlanFileName = clientEvacuationPlan as string;
          getEvacuationPlanDownloadUrl(evacuationPlanFileName);
        } else {
          setEvacuationPlan(clientEvacuationPlan);
          setLoading(false);
        }
      } else setLoading(false);
    }
  }, [generalInfoFormState?.evacuationPlan]);

  if (loading) return null;

  return (
    <Container>
      <Header>Info messages:</Header>
      <Checkbox
        onChange={handleEditModeChange}
        checked={isChecked}
        label="Override Client Level Values"
        containerStyle={"justify-content: flex-start; margin-bottom: 20px;"}
        checkboxStyle={"width: 20px; height: 20px;"}
      />
      {isChecked && <EditableInfoMessagesForm />}
      {!isChecked && <ReadOnlyInfoMessagesForm />}
      <Header headerStyle="margin-top: 50px;">Evacuation Plan:</Header>
      <SinglePhotoUpload
        setFile={(file: File | null) => {
          setGeneralInfoFormState((prevState) => ({
            ...prevState,
            evacuationPlan: file,
          }));
          setEvacuationPlan(file);
        }}
        file={evacuationPlan}
        elementName="Evacuation Plan"
        placeholderImage={IMAGES.DEFAULT_EVACUATION_PLAN_ICON}
      />
    </Container>
  );
};

const Container = styled.div``;

interface HeaderProps {
  headerStyle?: string;
}

const Header = styled.p<HeaderProps>`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  color: ${colors.grey.dark};

  ${(props) => props.headerStyle && props.headerStyle}
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-left: 135px;
  }
`;
