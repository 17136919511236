import { groupBy } from "lodash";
import React from "react";
import DataTable from "src/components/Table";
import TableCell, { StyledTableCell } from "src/components/Table/TableCell";
import TableRow from "src/components/Table/TableRow";
import { colors } from "src/styles";
import { ID, TeamMember } from "src/types";
import styled from "styled-components";
import { RoleFilter } from "../TeamMembersFilter";

export interface ClientsTableProps {
  headers: string[];
  data: TeamMember[];
  onRowClick: (id: ID) => void;
  roleFilterData?: RoleFilter;
}

const TeamMembersTable = ({ headers, data, onRowClick, roleFilterData }: ClientsTableProps) => {
  return (
    <DataTable headers={headers}>
      <>
        {data?.map(({ employeeId, employeeDisplayName, clients, locations, roles, onSite }: TeamMember) => {
          const currentRole =
            roleFilterData &&
            Object.keys(roles).map((role) => {
              return role === "employee" ? { label: roles.employee } : roleFilterData[role];
            });

          const groupedByClientLocations = groupBy(locations, ({ clientId }) => clientId) || [];

          return (
            <TableRow onClick={() => onRowClick(employeeId)} $clickable key={employeeId}>
              <TableCell>{employeeId}</TableCell>
              <TableCell>{employeeDisplayName}</TableCell>
              <TableCell>
                {clients.map((client) => {
                  const currentClientLocations = groupedByClientLocations[client.id] || [];
                  return (
                    <ClientTr locationLength={currentClientLocations.length || 1} key={client.id}>
                      {client.name}
                    </ClientTr>
                  );
                })}
              </TableCell>
              <TableCell>
                {clients.map((client) => {
                  const currentClientLocations = groupedByClientLocations[client.id] || [];
                  return currentClientLocations.map((location) => {
                    return <LocationTr key={location.id}>{location.name}</LocationTr>;
                  });
                })}
              </TableCell>

              <TableCell>
                {currentRole?.length
                  ? currentRole.map((role) => <Role key={role?.label}>{role?.label}</Role>)
                  : "Employee"}
              </TableCell>
              <OnSiteCell isonsite={onSite && !!Object.keys(onSite).length}>
                {onSite && Object.keys(onSite).length ? "On Site" : "Not On Site "}
              </OnSiteCell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};

const LocationTr = styled.div`
  min-height: 20px;
`;

const ClientTr = styled.div<{ locationLength: number }>`
  min-height: ${({ locationLength }) => `calc(${locationLength} * 20px)`};
`;

const OnSiteCell = styled(StyledTableCell)<{ isonsite: boolean }>`
  color: ${({ isonsite }) => isonsite && colors.green.default};
`;

const Role = styled.div`
  padding-bottom: 5px;
  &:last-child {
    padding-bottom: 0;
  }
`;

export default TeamMembersTable;
