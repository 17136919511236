import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faRedo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner } from "react-bootstrap";
import { colors } from "src/styles";
import { MaximoDocument } from "src/types";
import styled from "styled-components";

export interface ManagerAttachmentType {
  file: File;
  state: "loading" | "completed" | "error";
  error: string;
}

interface IManagerAttachment {
  isManager: boolean;
  item: MaximoDocument;
  setAttachmentToDelete: React.Dispatch<React.SetStateAction<string>>;
  onRetry: (fileName: string) => Promise<void>;
  onAttachmentClick: (maximoDocument: MaximoDocument) => Promise<void>;
  setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManagerAttachment = ({
  item,
  isManager,
  setAttachmentToDelete,
  onRetry,
  onAttachmentClick,
  setShowDeletePopup,
}: IManagerAttachment) => {
  const fileName = item.fileName;

  const onAttachmentHandler = () => {
    if (!item.attachmentId) return;
    onAttachmentClick(item);
  };

  const deleteHandler = () => {
    setShowDeletePopup(true);
    setAttachmentToDelete(item.attachmentId);
  };

  return (
    <>
      <Attachment islink={item.state === "completed" || !item.state ? 1 : 0}>
        <div onClick={onAttachmentHandler}>{fileName}</div>

        {item.state === "loading" && (
          <SpinnerWrapper>
            <Spinner size="sm" animation="border" />
          </SpinnerWrapper>
        )}
        {(item.state === "completed" || !item.state) && isManager && (
          <FontAwesomeIcon icon={faTimes as any} onClick={deleteHandler} />
        )}
        {item.state == "error" && item.error && <RetryIcon icon={faRedo as any} onClick={() => onRetry(fileName)} />}
      </Attachment>
      {item.error && <ErrorMessage>{item.error}</ErrorMessage>}
    </>
  );
};

const SpinnerWrapper = styled.div`
  position: absolute;
  right: 0;
  & > div {
    border-color: ${colors.kleenway.greenLight};
    border-right-color: transparent;
  }
`;

const RetryIcon = styled(FontAwesomeIcon)`
  color: ${colors.red.dark};
`;

export const Attachment = styled.div<{ islink: number }>`
  padding-bottom: 15px;
  text-decoration: ${({ islink }) => (islink ? "underline" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ islink }) => (islink ? "pointer" : "defualt")};
  & > svg {
    position: absolute;
    cursor: pointer;
    right: 0;
  }
`;

const ErrorMessage = styled.div`
  border: 1px solid ${colors.red.dark};
  border-radius: 10px;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${colors.red.light1};
`;

export default ManagerAttachment;
