import moment from "moment";
import { DEFAULT_TIMEZONE_VALUE } from "../../constants";

export const formatPayPeriodDayDate = (date: string): string =>
  moment(formatToISO(date)).utc().format("ddd MM/DD").toString();

export const formatTimeReportPairDate = (date: string, timezone?: string): string => {
  const zone = timezone || DEFAULT_TIMEZONE_VALUE;
  const formattedDate = moment(formatToISO(date));
  return moment(formattedDate).tz(zone).format("MMM D, YYYY, hh:mm A").toString();
};

export const generatePayPeriodDayId = (date: string): string =>
  moment(formatToISO(date)).utc().format("YYYYMMDD").toString();

export const formatToISO = (date: string) => {
  return moment(new Date(date), moment.ISO_8601);
};
