import { Image } from "react-bootstrap";
import { StyledTableCell } from "src/components/Table/TableCell";
import { colors } from "src/styles";
import styled from "styled-components";

export const StyledCell = styled(StyledTableCell)`
  height: 50px;
  box-sizing: content-box;
  padding: 10px;
`;

export const VendorContainer = styled.div`
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const VendorHeading = styled.div`
  font-size: 14px;
  color: ${colors.grey.dark};
`;

export const VendorSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

export const Logo = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;

export const VendorInfo = styled.div<{ flexSize: number }>`
  flex: ${({ flexSize }) => flexSize};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  flex: 0.15;
  display: flex;
  align-items: center;
`;
