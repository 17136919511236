import { faFile, faFileExcel, faFileImage, faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { debugError } from "src/utils/log";

export const getErrorMsg = (error: any | undefined): string => {
  debugError(error.code);

  switch (error.code) {
    case "auth/user-not-found": {
      return "The entered Email is not found. Please, enter the correct email or consult your Kleenway Representative for assistance.";
    }
    case "auth/wrong-current-password": {
      return "The entered current password is incorrect. Please try again.";
    }
    case "auth/invalid-password":
    case "auth/wrong-password": {
      return "The entered password is incorrect. Please try again.";
    }
    default: {
      return error.message ? error.message : "Unknown error";
    }
  }
};

export const getFileIcon = (filename: string) => {
  const parts = filename.split(".");
  switch (parts[parts.length - 1].toLowerCase()) {
    case "pdf":
      return faFilePdf;
    case "jpeg":
    case "jpg":
    case "png":
    case "gif":
      return faFileImage;
    case "xls":
    case "xlsx":
      return faFileExcel;
    default:
      return faFile;
  }
};

/**
 *
 * Basically this method provide the icons
 * name as per the file type in string
 */
export const getFileIconAsString = (filename: string) => {
  const parts = filename.split(".");
  switch (parts[parts.length - 1].toLowerCase()) {
    case "pdf":
      return "faFilePdf";
    case "jpeg":
    case "jpg":
    case "png":
    case "gif":
      return "faFileImage";
    case "xls":
    case "xlsx":
      return "faFileExcel";
    default:
      return "faFile";
  }
};
