import { createAction } from "@reduxjs/toolkit";
import { PriorityLevelList, Ticket } from "src/types";

const ACTION_PREFIX = "WORK_REQUEST";

type RequestMessage = string;

export const setWorkRequestMessage = createAction<RequestMessage>(`${ACTION_PREFIX}/SET_WORK_REQUEST_MESSAGE`);

export const clearWorkRequestMessage = createAction(`${ACTION_PREFIX}/CLEAR_WORK_REQUEST_MESSAGE`);

export const setWorkRequestAlertVariant = createAction<string>(`${ACTION_PREFIX}/SET_WORK_REQUEST_ALERT_VARIANT`);

export const setIsTicketsLoading = createAction<boolean>(`${ACTION_PREFIX}/SET_IS_TICKETS_LOADING`);

export const setPriorityLevels = createAction<PriorityLevelList>(`${ACTION_PREFIX}/SET_PRIORITY_LEVELS`);

export const setTickets = createAction<Ticket[]>(`${ACTION_PREFIX}/SET_TICKETS`);

export const setIsSearchApplied = createAction<boolean>(`${ACTION_PREFIX}/IS_SEARCH_APPLIED`);

export const setWorkOrderCount = createAction<number>(`${ACTION_PREFIX}/WORK_ORDER_COUNT`);
