import React from "react";
import Button from "src/components/Button";
import { onClickHandler } from "./handlers";
import { ButtonStyles } from "./styles";
import { ButtonCheckboxProps } from "./types";

const ButtonCheckbox = ({ label, value = "on", checked, error, onChange }: ButtonCheckboxProps) => {
  return (
    <Button
      additionalStyles={ButtonStyles(!!error, checked)}
      onClick={(event) => onClickHandler(event, checked, value, onChange)}
      variant="secondary"
      text={label}
    />
  );
};

export default ButtonCheckbox;
