import React from "react";
import { NoTicketsMessage } from "src/components/ListScreen";
import { useIsMobile } from "src/hooks/useIsMobile";
import { contactsTableHeaders } from "../../constants";
import { DBVendorContact } from "../../types";
import { ContactsTable } from "../ContactTable";
import { ContactsList } from "../ContactsList";
export interface ContactsInfoProps {
  contacts: DBVendorContact[];
}
const ContactsInfo = ({ contacts }: ContactsInfoProps) => {
  const { isMobile } = useIsMobile();
  if (contacts.length === 0) {
    return <NoTicketsMessage $isMobile={isMobile}>No Vendor&apos;s contact available in maximo</NoTicketsMessage>;
  }
  return (
    <>
      {isMobile ? <ContactsList data={contacts} /> : <ContactsTable data={contacts} headers={contactsTableHeaders} />}
    </>
  );
};

export default ContactsInfo;
