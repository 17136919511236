import React from "react";
import { FormProvider } from "react-hook-form";
import { EmailMessage } from "../../../../components/atoms/EmailMessage";
import { EmailTo } from "../../../../components/atoms/EmailTo";
import { InputRow, InputsSection } from "../styles";
import { EmailToFormProps } from "../types";

/**
 * A helper component to render the Email To form part
 */
export const EmailToForm = ({ form, disabled, $isMobile }: EmailToFormProps) => {
  return (
    <FormProvider {...form}>
      <InputsSection $isMobile={$isMobile}>
        <InputRow $isMobile={$isMobile}>
          <EmailTo disabled={disabled} />
        </InputRow>

        <InputRow $isMobile={$isMobile}>
          <EmailMessage disabled={disabled} />
        </InputRow>
      </InputsSection>
    </FormProvider>
  );
};
