import React, { forwardRef } from "react";
import { ButtonCheckboxGroup } from "../ButtonCheckboxGroup";
import { WEEK_DAYS } from "./constants";
import { DaysSelectorProps } from "./types";

/**
 * A component to display a group of days as buttons. The group wrappers ButtonCheckboxGroup component.
 */
const DaysSelector = forwardRef<HTMLDivElement, DaysSelectorProps>(function DaysSelector(props, ref) {
  return <ButtonCheckboxGroup options={WEEK_DAYS} {...props} ref={ref} />;
});

export default DaysSelector;
