import React from "react";

import styled, { css } from "styled-components";

export const Field = ({
  label,
  data,
  labelStyle,
  dataStyle,
}: {
  label: string;
  data: string;
  labelStyle?: string;
  dataStyle?: string;
}) => (
  <FieldContainer>
    <FieldLabel labelStyle={labelStyle}>{label}</FieldLabel>
    <FieldData dataStyle={dataStyle}>{data}</FieldData>
  </FieldContainer>
);

export const FieldContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 15px 0 15px 0;
`;

interface LabelProps {
  labelStyle?: string;
}

export const FieldLabel = styled.span<LabelProps>`
  flex: 0.1;
  min-width: 100px;
  font-weight: 600;
  font-size: 14px;

  ${({ labelStyle }) =>
    labelStyle &&
    css`
      ${labelStyle}
    `}
`;

interface DataProps {
  dataStyle?: string;
}

const FieldData = styled.span<DataProps>`
  flex: 0.9;
  font-weight: 400;
  font-size: 14px;
  margin-left: 50px;

  ${(props) => props.dataStyle && props.dataStyle}
`;
