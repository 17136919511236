import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Wrapper = styled(Container)`
  padding: 30px 20px;
  padding-top: 15px;
  & > div:first-child {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 30px 20px;
`;

export const Error = styled.p`
  padding-top: 10px;
`;
