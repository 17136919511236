import React, { useContext } from "react";
import styled from "styled-components";
import { DropdownItem } from "../../../components/Dropdown/types";
import SelectSearch from "../../../components/SelectSearch";
import { colors } from "../../../styles";
import { InputRow, InputsContainer, InputsRow } from "../GeneralInfoForm/ClientContactForm";
import ClientDetailsContext from "../context";

export const InfoMessagesForm = () => {
  const { generalInfoFormState, setGeneralInfoFormState, messages, dropdownListSizes } =
    useContext(ClientDetailsContext);

  const handleFieldChange = (name: string, value: DropdownItem) => {
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      infoMessages: {
        ...(prevState.infoMessages || {}),
        [name]: value,
      },
    }));
  };

  return (
    <div>
      <Header>Info Messages:</Header>
      <InputsContainer>
        <InputsRow>
          <StyledInputRow $inputWidth="360">
            <label>Welcome Text: </label>
            <SelectSearch
              defaultValue={generalInfoFormState?.infoMessages?.welcomeText?.id}
              placeholder="None"
              options={messages}
              onChange={(value) => handleFieldChange("welcomeText", value)}
              caption={generalInfoFormState?.infoMessages?.welcomeText?.label}
              listSize={dropdownListSizes?.welcomeText}
            />
          </StyledInputRow>
        </InputsRow>

        <InputsRow>
          <StyledInputRow $inputWidth="360">
            <label>Left Side Text: </label>
            <SelectSearch
              defaultValue={generalInfoFormState?.infoMessages?.firstText?.id}
              placeholder="None"
              options={messages}
              onChange={(value) => handleFieldChange("firstText", value)}
              caption={generalInfoFormState?.infoMessages?.firstText?.label}
              listSize={dropdownListSizes?.firstText}
            />
          </StyledInputRow>
        </InputsRow>

        <InputsRow>
          <StyledInputRow $inputWidth="360">
            <label>Right Side Text: </label>
            <SelectSearch
              defaultValue={generalInfoFormState?.infoMessages?.secondText?.id}
              placeholder="None"
              options={messages}
              onChange={(value) => handleFieldChange("secondText", value)}
              caption={generalInfoFormState?.infoMessages?.secondText?.label}
              listSize={dropdownListSizes?.secondText}
            />
          </StyledInputRow>
        </InputsRow>
      </InputsContainer>
    </div>
  );
};

const Header = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${colors.grey.dark};
`;

const StyledInputRow = styled(InputRow)`
  max-width: unset;
  & > label {
    font-weight: 600;
  }
`;
