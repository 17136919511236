import React from "react";
import { EMAIL_VALIDATION_PATTERN } from "src/utils/validator";
import { ControlledInput } from "./components/ControlledInput";
import { Container, Content, InputsContainer, InputsRow } from "./styles";
import { ContactFormProps } from "./types";

export const ContactForm = ({ name, contact }: ContactFormProps) => {
  return (
    <Container>
      <Content>
        <InputsContainer>
          <InputsRow>
            <ControlledInput
              label="First Name"
              name={`${name}.firstName`}
              value={contact?.firstName}
              rules={{ required: true }}
            />
            <ControlledInput
              label="Last Name"
              name={`${name}.lastName`}
              value={contact?.lastName}
              rules={{ required: true }}
            />
          </InputsRow>

          <InputsRow>
            <ControlledInput label="Job Description" name={`${name}.title`} value={contact?.title || ""} />
            <ControlledInput label="Phone" name={`${name}.phone`} value={contact?.phone || ""} />
          </InputsRow>

          <InputsRow>
            <ControlledInput
              label="Email"
              name={`${name}.email`}
              value={contact?.email || ""}
              rules={{ pattern: EMAIL_VALIDATION_PATTERN }}
            />
          </InputsRow>
        </InputsContainer>
      </Content>
    </Container>
  );
};
