import { ControllerRenderProps, FieldValues, UseFieldArrayAppend, UseFieldArrayRemove } from "react-hook-form";
import { DynamicRowsProps } from "./types";

/**
 * Handles the logic of adding and removing rows when user changes values
 */
export const onChageHandler = <ItemType>(
  event: React.ChangeEvent<HTMLElement & { value: string }>,
  index: number,
  values: ItemType[],
  append: UseFieldArrayAppend<FieldValues>,
  remove: UseFieldArrayRemove,
  onChange: ControllerRenderProps["onChange"],
  getNewItem: DynamicRowsProps<ItemType>["getNewItem"],
) => {
  if (event.target.value) {
    if (index === values?.length - 1) {
      append(getNewItem(), { shouldFocus: false });
    }

    onChange(event);
  } else {
    remove(index);
  }
};
