import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import DeleteModal from "src/components/DeleteModal";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../components/Button";
import { ClientPortalUserFormProps } from "../ClientDetailsProvider";
import ClientDetailsContext from "../context";
import { ClientPortalUserForm } from "./ClientPortalUserForm";

export const ClientPortalUsersForm = () => {
  const { generalInfoFormState, setGeneralInfoFormState } = useContext(ClientDetailsContext);

  const [contactFormToDelete, setContactFormToDelete] = useState<string>("");

  const { id: clientId } = useParams();

  return (
    <div>
      <DeleteModal
        onApproveClick={() => {
          setGeneralInfoFormState(({ clientPortalUsers, ...prevState }) => ({
            ...prevState,
            clientPortalUsers: clientPortalUsers.map((user) => {
              return user.id === contactFormToDelete
                ? {
                    ...user,
                    isDeleted: true,
                  }
                : user;
            }),
          }));
          setContactFormToDelete("");
        }}
        onCancelClick={() => setContactFormToDelete("")}
        title="User"
        isShowing={!!contactFormToDelete}
        hide={() => setContactFormToDelete("")}
      />

      <FormsContainer>
        {generalInfoFormState?.clientPortalUsers
          ?.filter((item) => !item.isDeleted)
          .map((item, index) => (
            <ClientPortalUserForm
              key={item.id}
              defaultValues={item}
              formIndex={index}
              handleFormDelete={() => setContactFormToDelete(item.id)}
              clientId={clientId}
            />
          ))}
      </FormsContainer>
      <Button
        text="Add User"
        variant="dotted-border"
        icon={faPlus as any}
        onClick={() =>
          setGeneralInfoFormState(({ clientPortalUsers = [], ...prevState }) => ({
            ...prevState,
            clientPortalUsers: [
              ...clientPortalUsers,
              {
                id: uuidv4(),
                isValid: false,
                isSaved: false,
                isDeleted: false,
              } as ClientPortalUserFormProps,
            ],
          }))
        }
      />
    </div>
  );
};

const FormsContainer = styled.div`
  margin-bottom: 30px;
`;
