import { Stack } from "react-bootstrap";
import { FormLabel } from "src/components/atoms/FormLabel";
import { colors } from "src/styles";
import styled from "styled-components";

export const Label = styled(FormLabel)`
  min-width: 145px;
  text-wrap: nowrap;
`;

export const Container = styled(Stack)`
  max-height: 500px;
  overflow-y: auto;
`;

export const ButtonContainer = styled(Stack)`
  justify-content: space-between;
  margin-top: 20px;
`;

export const Text = styled.span`
  min-width: 190px;
  font-size: 14px;
  color: ${colors.grey.dark};
`;
