import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectSearch, { OptionItem } from "src/components/SelectSearch";
import { FormLabel } from "src/components/atoms/FormLabel";
import { InputRow } from "../styles";
import { DynamicDropdown, DynamicFieldProps } from "../types";
export const DynamicDropdownField = ({ dynamicField }: DynamicFieldProps) => {
  const { control } = useFormContext();
  const prepareDropDownValues = (): OptionItem[] =>
    (dynamicField.dropdown as DynamicDropdown).options?.map((option) => ({
      id: option.value,
      value: option.label,
    })) ?? [];
  return (
    <InputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        rules={dynamicField.rules}
        defaultValue={dynamicField.defaultValue}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <SelectSearch
            id={dynamicField.id}
            options={prepareDropDownValues()}
            defaultValue={value}
            placeholder={dynamicField.dropdown?.placeholder ?? ""}
            name={name}
            onChange={(option) => onChange(option.id)}
            ref={ref}
            error={error}
          />
        )}
      />
    </InputRow>
  );
};
