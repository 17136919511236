import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React, { useContext, useEffect, useState } from "react";
import DeleteModal from "src/components/DeleteModal";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../components/Button";
import SinglePhotoUpload from "../../../components/PhotoUpload/Single";
import SelectSearch from "../../../components/SelectSearch";
import FirebaseStorageService from "../../../services/Firebase/storage";
import { Client } from "../../../types";
import { ClientContactFormProps, KleenwayContactFormProps } from "../ClientDetailsProvider";
import ClientDetailsContext from "../context";
import { ClientContactForm, InputRow, InputsRow } from "./ClientContactForm";
import { KleenwayContactForm } from "./KleenwayContactForm";

export const getLogoPropType = (logo: File | string) => (typeof logo === "string" ? "fileName" : "file");

export const GeneralInfoForm = ({ client: { name, address, inactive } }: { client: Client }) => {
  const { generalInfoFormState, setGeneralInfoFormState, timezones, isContextLoaded, dropdownListSizes } =
    useContext(ClientDetailsContext);

  const [contactFormToDelete, setContactFormToDelete] = useState<string>("");
  const [kleenwayContactFormToDelete, setKleenwayContactFormToDelete] = useState<string | boolean>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [logo, setLogo] = useState<File | string | null>(null);

  const getLogoDownloadUrl = (logoFileName: string) =>
    FirebaseStorageService.getDownloadURL(process.env.REACT_APP_CLIENT_LOGO_FOLDER, logoFileName)
      .then(({ data: logoDownloadUrl }) => setLogo(logoDownloadUrl))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

  useEffect(() => {
    if (generalInfoFormState) {
      const { logo: clientLogo } = generalInfoFormState;

      if (clientLogo) {
        const typeOfLogoProp = getLogoPropType(clientLogo);
        if (typeOfLogoProp === "fileName") {
          const logoFileName = clientLogo as string;
          getLogoDownloadUrl(logoFileName);
        } else {
          setLogo(clientLogo);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [generalInfoFormState?.logo]);

  if (loading) {
    return null;
  }
  const onDeleteContact = () => {
    setGeneralInfoFormState(({ clientContacts, ...prevState }) => ({
      ...prevState,
      clientContacts: clientContacts.filter(({ id: formId }) => formId !== contactFormToDelete),
    }));
    setContactFormToDelete("");
  };

  const onDeleteKleenwayContact = () => {
    setGeneralInfoFormState(({ kleenwayContacts, ...prevState }) => ({
      ...prevState,
      kleenwayContacts: kleenwayContacts.filter(
        ({ formId: contactFormId }) => contactFormId !== kleenwayContactFormToDelete,
      ),
    }));
    setKleenwayContactFormToDelete("");
  };

  return (
    <div>
      <DeleteModal
        onApproveClick={onDeleteContact}
        onCancelClick={() => setContactFormToDelete("")}
        title="Contact"
        isShowing={Boolean(contactFormToDelete)}
        hide={() => setContactFormToDelete("")}
      />
      <DeleteModal
        onApproveClick={onDeleteKleenwayContact}
        onCancelClick={() => setKleenwayContactFormToDelete("")}
        title="Contact"
        isShowing={Boolean(kleenwayContactFormToDelete)}
        hide={() => setKleenwayContactFormToDelete("")}
      />
      <SinglePhotoUpload
        setFile={(file: File | null) => {
          setGeneralInfoFormState((prevState) => ({
            ...prevState,
            logo: file,
          }));
          setLogo(file);
        }}
        file={logo}
        elementName="Logo"
      />
      <OneRowWrapper>
        <label>Name: </label>
        <RowValue>{name}</RowValue>
      </OneRowWrapper>
      {address && (
        <OneRowWrapper>
          <label>Address: </label>
          <RowValue>{address}</RowValue>
        </OneRowWrapper>
      )}

      <InputsRow>
        {inactive !== undefined && (
          <InputRow>
            <label>Status: </label>
            <RowValue>{inactive ? "Inactive" : "Active"}</RowValue>
          </InputRow>
        )}
        {isContextLoaded && (
          <InputRow>
            <label>Time Zone: </label>
            <SelectSearch
              defaultValue={
                timezones.some((item) => item.id === generalInfoFormState?.timezone?.id)
                  ? generalInfoFormState?.timezone?.id
                  : 0
              }
              placeholder="Select timezone"
              options={timezones}
              onChange={(item) => {
                setGeneralInfoFormState((prevState) => ({
                  ...prevState,
                  timezone: item,
                }));
              }}
              listSize={dropdownListSizes?.timezone}
            />
          </InputRow>
        )}
      </InputsRow>
      <FormsContainer>
        {generalInfoFormState?.clientContacts?.map(({ id }, index) => (
          <ClientContactForm
            key={id}
            formIndex={index}
            defaultValues={generalInfoFormState?.clientContacts?.[index]}
            handleFormDelete={() => setContactFormToDelete(id)}
          />
        ))}
      </FormsContainer>
      <Button
        text="Add Client Contact"
        variant="dotted-border"
        icon={faPlus as any}
        onClick={() =>
          setGeneralInfoFormState(({ clientContacts = [], ...prevState }) => ({
            ...prevState,
            clientContacts: [
              ...clientContacts,
              {
                id: uuidv4(),
                isValid: false,
              } as ClientContactFormProps,
            ],
          }))
        }
      />
      <FormsContainer>
        {generalInfoFormState?.kleenwayContacts?.map(({ formId }, index) => (
          <KleenwayContactForm
            key={formId}
            formIndex={index}
            handleFormDelete={() => setKleenwayContactFormToDelete(formId)}
          />
        ))}
      </FormsContainer>
      <Button
        text="Add Kleenway Contact"
        variant="dotted-border"
        icon={faPlus as any}
        onClick={() =>
          setGeneralInfoFormState(({ kleenwayContacts = [], ...prevState }) => ({
            ...prevState,
            kleenwayContacts: [
              ...kleenwayContacts,
              {
                formId: uuidv4(),
                isValid: false,
              } as KleenwayContactFormProps,
            ],
          }))
        }
      />
    </div>
  );
};

const FormsContainer = styled.div`
  margin-bottom: 30px;
`;

export const RowValue = styled.p`
  width: 175px;
  font-weight: 400;
  font-size: 14px;
  font-style: unset;
  color: unset;
`;

const OneRowWrapper = styled(InputRow)`
  width: 100%;
  max-width: unset;
  & > p {
    width: 100%;
  }
  & > label {
    width: 135px;
    font-weight: 600;
  }
`;
