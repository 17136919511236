import React, { ReactNode } from "react";
import styled from "styled-components";

import { colors } from "src/styles";

export interface TableRowProps {
  $clickable: boolean;
  children: ReactNode;
  onClick: () => void;
  $bgColor?: string;
}

const TableRow = ({ $clickable: clickable, children, onClick, $bgColor = colors.white.default }: TableRowProps) => {
  return (
    <TableRowComponent $bgColor={$bgColor} onClick={onClick} $clickable={clickable}>
      {children}
    </TableRowComponent>
  );
};

export default TableRow;

const TableRowComponent = styled.tr<TableRowProps>`
  font-style: normal;
  font-size: 14px;
  background-color: ${(props) => props.$bgColor};
  ${(props) => props.$clickable && `cursor: pointer;`};

  &:nth-child(odd) {
    background-color: ${colors.grey.light6};
  }
`;
