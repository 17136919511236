import { createReducer } from "@reduxjs/toolkit";
import { MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { DBClientUser, DBVendorUser, ID } from "../../types";
import { isManager } from "../../utils";
import { logInUser, logOutUser } from "../actions/user";

export type UserState = DBClientUser &
  DBVendorUser & {
    loggedIn: boolean;
    id: ID;
    loginDate: null | string;
    profilePicture?: string;
    maximoTimezone: string;
    isManager: boolean;
  };

export const initialState: UserState = {
  loginDate: null,
  loggedIn: false,
  id: "",
  clientId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  maximoTimezone: MAXIMO_TIME_ZONE,
  isManager: false,
  vendorId: "",
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(logInUser, (state, action) => ({
      ...state,
      ...action.payload,
      isManager: isManager(action.payload),
      loggedIn: !!action.payload.id,
      loginDate: new Date().toISOString(),
    }))
    .addCase(logOutUser, () => ({
      ...initialState,
    }));
});
