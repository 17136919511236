import React from "react";
import { FormItem } from "../FormItem";
import { FormContainer } from "../styles";
import { FormsListProps } from "../types";

export const FormsList = ({ list, onClick }: FormsListProps) => {
  return (
    <>
      {list.map((item) => (
        <FormContainer
          onClick={() => {
            onClick && onClick(item.id);
          }}
          key={item.name}
        >
          <FormItem item={item} />
        </FormContainer>
      ))}
    </>
  );
};
