import React from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import Textarea from "src/components/Textarea";
import { EmailMessageProps } from "../../../pages/TicketForm/CreateForm/types";
import { FormLabel } from "../FormLabel";

/**
 * A helper component to render the optional email message form row.
 *
 * Has to be within the react-hook-form context
 */
export const EmailMessage = ({ disabled, isFieldVisible }: EmailMessageProps) => {
  const { control } = useFormContext();

  const isDisplayField =
    useWatch({ control, name: "emailTo" })?.findIndex((item: Record<string, string>) => item.value) >= 0 ||
    isFieldVisible;

  return isDisplayField ? (
    <>
      <FormLabel>Email message</FormLabel>

      <Controller
        control={control}
        name={"emailMessage"}
        defaultValue={""}
        render={({ field: { onChange, value } }) => (
          <Textarea
            countSymbols
            width="360px"
            value={value}
            disabled={disabled}
            placeholder="Enter message"
            onChange={onChange}
          ></Textarea>
        )}
      />
    </>
  ) : (
    <></>
  );
};
