import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

import Button from "../Button";

export interface ModalProps {
  isShowing: boolean;
  hide: () => void;
  title: string;
  textContent?: string;
  onCancelClick: () => void;
  onApproveClick: React.MouseEventHandler<HTMLButtonElement>;
  approveButtonText?: string;
  cancelButtonText?: string;
  approveButtonStyle?:
    | "primary"
    | "secondary"
    | "text"
    | "middle-text"
    | "decline"
    | "small-primary"
    | "small-secondary"
    | "middle-primary"
    | "dotted-border";
  cancelButtonStyle?:
    | "primary"
    | "secondary"
    | "text"
    | "middle-text"
    | "decline"
    | "small-primary"
    | "small-secondary"
    | "middle-primary"
    | "dotted-border";
  buttonsFlexDirection?: "column" | "row";
  revertButtons?: boolean;
  children?: React.ReactNode;
  disableApproveButton?: boolean;
  disableCancelButton?: boolean;
  maxWidth?: string;
  isLoadingApproveButton?: boolean;
}

const Modal = ({
  isShowing,
  hide,
  title,
  textContent,
  onCancelClick,
  onApproveClick,
  approveButtonText,
  approveButtonStyle,
  cancelButtonText,
  cancelButtonStyle,
  buttonsFlexDirection = "row",
  revertButtons = false,
  children,
  disableApproveButton = false,
  maxWidth = "600px",
  disableCancelButton,
  isLoadingApproveButton,
}: ModalProps) =>
  isShowing ? (
    <React.Fragment>
      <ModalWrapper>
        <ModalWindow style={{ maxWidth }}>
          <ModalHeader>
            <div>
              <h3>{title}</h3>
              <Icon onClick={() => hide()} icon={faTimes as any} />
            </div>
          </ModalHeader>
          <ModalContent>
            {textContent && <p style={{ textAlign: "center" }}>{textContent}</p>}
            {children}
          </ModalContent>
          <ModalActions $flexDirection={revertButtons ? buttonsFlexDirection + "-reverse" : buttonsFlexDirection}>
            <Button
              onClick={onApproveClick}
              text={approveButtonText || "Discard"}
              variant={approveButtonStyle || "primary"}
              disabled={disableApproveButton}
              loading={isLoadingApproveButton}
            />
            <Button
              onClick={onCancelClick}
              text={cancelButtonText || "Cancel"}
              variant={cancelButtonStyle || "secondary"}
              disabled={disableCancelButton}
            />
          </ModalActions>
        </ModalWindow>
      </ModalWrapper>
    </React.Fragment>
  ) : null;

const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const ModalWindow = styled.div`
  background-color: ${colors.white.default};
  z-index: 100;
  max-width: 600px;
  width: 100%;
  margin: 0 20px;
  border-radius: 5px;
`;

const ModalContent = styled.div`
  padding: 35px 15px;

  p {
    margin: 0;
  }
`;

interface ModalActionsProps {
  $flexDirection: string;
}

const ModalActions = styled.div<ModalActionsProps>`
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: center;

  button {
    margin: 0 15px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

const ModalHeader = styled.div`
  color: ${colors.grey.dark};
  border-radius: 5px 5px 0 0;
  font-size: 16px;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  padding: 15px;
  background-color: ${colors.grey.light1};
`;

export default Modal;
