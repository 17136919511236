import { createReducer } from "@reduxjs/toolkit";
import { CompletedStatus } from "src/types";
import { setCompleteStatuses } from "../actions/completeStatuses";

interface StatusState {
  completeStatuses: null | CompletedStatus[];
}

export const initialState: StatusState = {
  completeStatuses: null,
};

export const completeStatusesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setCompleteStatuses, (state, action) => ({
    ...state,
    completeStatuses: action.payload,
  }));
});
