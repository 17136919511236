import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { IMAGES } from "src/assets";
import { colors } from "src/styles";
import styled from "styled-components";

interface FlyDownProps {
  userInfo: { firstName?: string; lastName?: string; photo?: string };
}

const FlyDown = ({ userInfo: { firstName, lastName, photo }, children }: PropsWithChildren<FlyDownProps>) => {
  const [isVisible, setIsVisible] = useState(false);
  const onToggleHandler = (isOpen: boolean) => {
    setIsVisible(isOpen);
  };
  return (
    <Dropdown onToggle={(isOpen) => onToggleHandler(isOpen)}>
      <ToggleButton>
        <ProfileImg src={photo || IMAGES.DEFAULT_PROFILE_ICON_FLY_DOWN} />
        <ProfileInfo>
          {firstName} {lastName}
        </ProfileInfo>
        <Icon icon={isVisible ? faChevronUp : faChevronDown} />
      </ToggleButton>

      <ListWrapper>{children}</ListWrapper>
    </Dropdown>
  );
};

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

const ToggleButton = styled(Dropdown.Toggle)`
  border-radius: 1000px;
  background-color: ${colors.grey.light6};
  color: ${colors.black.dark1};
  font-weight: 800;
  padding: 12px;
  border: 0px;
  display: flex;
  align-items: center;
  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    box-shadow: unset;
    border: 0px;
    background-color: ${colors.grey.light6};
    color: ${colors.black.dark1};
  }
  &::after {
    content: unset;
  }
`;

const ProfileImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50px;
`;

const ProfileInfo = styled.span`
  padding-right: 8px;
`;

const ListWrapper = styled(Dropdown.Menu)`
  min-width: 232px;
  width: 100%;
  border: 0;
  box-shadow: 8px 16px 32px rgba(113, 128, 150, 0.08);
  background-color: ${colors.white.default};
  border-radius: 12px;
  padding: 8px 16px;
`;

export default FlyDown;
