import React from "react";

import { DBVendor } from "src/types";
import { Vendor } from "../Vendor";
import { VendorContainer } from "../styles";
import { VendorsListProps } from "../types";

export const VendorsList = ({ data, onClick }: VendorsListProps) => {
  return (
    <>
      {data.map((vendor: DBVendor) => (
        <VendorContainer
          onClick={() => {
            onClick && onClick(vendor.id);
          }}
          key={vendor.id}
        >
          <Vendor vendor={vendor} />
        </VendorContainer>
      ))}
    </>
  );
};
