import React from "react";
import { useSelector } from "react-redux";
import DataTable from "src/components/Table";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Ticket } from "src/types";
import { managerTableHeaders } from "../constant";
import ManagerListItem from "./ManagerListItem";
import ManagerTableRow from "./ManagerTableRow";
import { getIsTicketsLoading, getTickets } from "src/redux/selectors/resources";

const ManagerTicketsWrapper = () => {
  const isTicketsLoading = useSelector(getIsTicketsLoading);
  const tickets = useSelector(getTickets);

  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <>
        {tickets.map((ticket: Ticket) => (
          <ManagerListItem ticket={ticket} key={ticket.wonum} />
        ))}
      </>
    );
  }
  return (
    <DataTable isLoading={isTicketsLoading} headers={managerTableHeaders}>
      {tickets?.map((ticket: Ticket) => <ManagerTableRow key={ticket.wonum} ticket={ticket} />)}
    </DataTable>
  );
};

export default ManagerTicketsWrapper;
