export interface BreadcrumbsSchemaItem {
  id: string;
  name: string;
}

interface StringObject {
  [key: string]: string;
}

export const generateStringWithParams = (url: string, fields: StringObject) => {
  let res = url;
  Object.keys(fields).forEach((key) => {
    res = res.replace(`:${key}`, String(fields[key]));
  });

  return res;
};

export const generateBreadcrumbs = (schema: BreadcrumbsSchemaItem[], params: StringObject) =>
  schema.map(({ id, name }) => ({
    id: generateStringWithParams(id, params),
    name: generateStringWithParams(name, params),
  }));
