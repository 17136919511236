import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "src/styles";
import styled from "styled-components";

export const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  color: ${colors.grey.dark};
  border-radius: 5px 5px 0 0;
  font-size: 16px;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  padding: 15px;
  background-color: ${colors.grey.light1};
`;
export const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

export const ModalWindow = styled.div`
  background-color: ${colors.white.default};
  z-index: 100;
  max-width: 600px;
  width: 100%;
  margin: 0 20px;
  border-radius: 5px;
`;

export const ModalContent = styled.div`
  padding: 35px 15px;

  p {
    margin: 0;
  }
`;
