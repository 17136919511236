export const MANAGER_CLIENT_DETAILS_BREADCRUMBS = [
  { id: "/", name: "Home" },
  { id: "/clients", name: "Clients" },
  { id: "/clients/:id", name: ":name" },
];

export const SAVE_CHANGES_HINT_MESSAGE =
  "To save entered information, please make sure all required fields are filled in and valid.";

export const DEFAULT_TIMEZONE_VALUE = "America/Louisville";
export const EMPTY_DROPDOWN_OPTION = { id: -1, value: "None", priority: 1 };
export const EMPTY_DROPDOWN_MESSAGES_OPTION = { id: -1, value: "No Message", priority: 1 };

export const DEFAULT_TIMEZONE_DROPDOWN_OPTION = {
  id: -1,
  labelDB: DEFAULT_TIMEZONE_VALUE,
  value: DEFAULT_TIMEZONE_VALUE,
  priority: 1,
}; // America/Louisville will be used for default EST/EDT
export const EMPTY_SUPERVISORS_DROPDOWN_OPTION = { id: -1, value: "Not Assigned", priority: 1 };

export const DEFAULT_PAY_PERIOD_TYPE = "9ANJ";

export const PAY_PERIODS_BY_ABBREVIATURE: { [k: string]: string } = {
  "9ANJ": "twiceAMonth",
  RF3W: "everyTwoWeeks",
};

export const PAY_PERIODS_BY_DESCRIPTION: { [k: string]: string } = {
  twiceAMonth: "9ANJ",
  everyTwoWeeks: "RF3W",
};

export const TABLE_GAP_HEIGHT = 8;
