import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import { colors } from "src/styles";
import styled from "styled-components";
import { ToastProps } from "./types";

const CustomToast = ({ title, message, isToastVisible }: ToastProps) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(isToastVisible);
  }, [isToastVisible]);
  return (
    <ToastContainer
      onClose={() => {
        setIsVisible(false);
      }}
      show={isVisible}
      delay={10000}
      autohide
    >
      <Toast.Header>{title}</Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </ToastContainer>
  );
};

export default CustomToast;
const ToastContainer = styled(Toast)`
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 1000;
  width: 700px;
  border: 1px solid ${colors.red.dark};
  & > .toast-header {
    justify-content: space-between;
    border-bottom: 0;
    border-radius: 5px;

    & button {
      margin-right: 0;
    }
  }
`;
