import { IconProp } from "@fortawesome/fontawesome-svg-core";
import * as faIconsLight from "@fortawesome/pro-light-svg-icons";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionContext, Form, useAccordionButton } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Button from "src/components/Button";
import Modal from "src/components/Modal";
import { OptionItem } from "src/components/SelectSearch";
import { TileInput } from "src/components/TileInput";
import { colors } from "src/styles";
import { DBClientPortalTile } from "src/types";
import styled, { css } from "styled-components";
import ClientDetailsContext from "../../context";

export type UseFormProps = {
  title: string;
  enabled: string;
  sortOrder: string;
  type: string;
  folder: string;
  url: string;
  icon: string;
};

export const selectOptions = {
  enabled: [
    { id: "0", value: "Disabled" },
    { id: "1", value: "Enabled" },
  ],
  type: [
    { id: "folder", value: "Folder" },
    { id: "url", value: "URL" },
  ],
};

const placeholderList = {
  title: "Enter Label",
  sortOrder: "Enter Order No.",
  folder: "Enter Path",
  url: "Enter URL",
  icon: "Enter Icon Code",
};

interface CustomToggleProps {
  children: string;
  eventKey: number;
  callback?: (eventKey: number) => void;
}

const isClientTile = (tileType: string) => tileType === "Client";

function CustomToggle({ eventKey, callback }: CustomToggleProps) {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey.toString(), () => callback?.(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey.toString();
  return (
    <IconButton type="button" onClick={decoratedOnClick}>
      <Icon style={{ transform: isCurrentEventKey ? "rotate(90deg)" : "none" }} icon={faChevronRight as IconProp} />
    </IconButton>
  );
}

export const SingleTile = ({ data, position }: { data: DBClientPortalTile; position: number }) => {
  const { folder, icon = "", id, sortOrder = "", title = "", type, tileType, url, enabled, changes } = data;
  const { clientPortalTiles, setClientPortalTiles, setGeneralInfoFormState } = useContext(ClientDetailsContext);
  const isNewTile = clientPortalTiles[position].isNew;
  const [editMode, setEditMode] = useState(clientPortalTiles[position].editMode || isNewTile || isClientTile(tileType));
  const [tileToDelete, setTileToDelete] = useState(Boolean);

  const currentEnabledGlobal = editMode ? enabled : changes?.enabled;
  const currentEnabled = isClientTile(tileType) ? enabled : currentEnabledGlobal;
  const enabledDigit = currentEnabled === false ? "0" : "1";

  const {
    register,
    formState: { isValid, errors },
    unregister,
    trigger,
  } = useForm<UseFormProps>({ mode: "all" });

  const filteredFoldersNameList = clientPortalTiles
    .filter((item) => item.id !== id && item.folder)
    .map((item) => item.folder?.toLocaleLowerCase());

  const getTileTypeValue = (type: string) => {
    return (type && selectOptions.type.find((elem) => elem.id === type)?.value) || "";
  };
  const getTileType = (type: string) => {
    const currentUrlGlobal = (editMode ? url : (changes?.url as string)) || "";
    const currentFolderGlobal = (editMode ? folder : (changes?.folder as string)) || "";

    const currentUrl = isClientTile(tileType) ? url : currentUrlGlobal;
    const currentFolder = isClientTile(tileType) ? folder : currentFolderGlobal;

    return type === "url" ? currentUrl : currentFolder;
  };
  const getTileTypeKey = (type: string) => {
    return type === "url" ? "url" : "folder";
  };

  const handleEditMode = (value: boolean) => {
    setEditMode(value);
    updateTiles("editMode", value);
  };

  const handleChange = (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    if (field === "sortOrder") {
      value = value.replace(/\D/g, "");
    }
    if (field === "folder") {
      value = value.replace(/[\\/]/g, "");
    }
    if (value.trim() === "") {
      value = "";
    }
    updateTiles(field, field === "sortOrder" && value ? +value : value);
  };

  const handleChangeSelect = (field: string, selectedOption: OptionItem) => {
    updateTiles(field, field === "enabled" ? !!+selectedOption.id : selectedOption.id);
  };

  const updateTiles = (field: string, value: string | number | boolean) => {
    const updatedTiles = clientPortalTiles.map((item) => {
      return item.id === id
        ? {
            ...item,
            [field]: value,
            isValid,
          }
        : item;
    });

    setClientPortalTiles(updatedTiles);
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      clientPortal: {
        tiles: updatedTiles,
      },
    }));
  };

  const currentIconGlobal = editMode ? icon : (changes?.icon as string) || "";
  const currentIcon = isClientTile(tileType) ? icon : currentIconGlobal;

  const currentTypeGlobal = editMode ? type : (changes?.type as string) || "";
  const currentType = isClientTile(tileType) ? type : currentTypeGlobal;

  const currentTitleGlobal = editMode ? title : (changes?.title as string) || "";
  const currentLabel = isClientTile(tileType) ? title : currentTitleGlobal;

  const currentSortGlobal = editMode ? sortOrder.toString() : (changes?.sortOrder as string) || "";
  const currentSort = isClientTile(tileType) ? sortOrder.toString() : currentSortGlobal;

  const currentTileTypeGlobal = editMode ? getTileTypeValue(type) : getTileTypeValue((changes?.type as string) || "");
  const currentTileType = isClientTile(tileType) ? getTileTypeValue(type) : currentTileTypeGlobal;

  useEffect(() => {
    if (isValid !== undefined) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setGeneralInfoFormState(({ clientPortal, ...prevState }) => ({
        ...prevState,
        clientPortal: {
          tiles: clientPortalTiles.map((state) => {
            return state.id === id
              ? {
                  ...state,
                  isValid,
                }
              : state;
          }),
        },
      }));
    }
  }, [isValid]);

  useEffect(() => {
    if (currentType === "url") {
      register("url");
      unregister("folder");
      if (getTileType(currentType)) trigger("url");
    } else {
      register("folder");
      unregister("url");

      if (getTileType(currentType)) trigger("folder");
    }
  }, [currentType]);

  return (
    <>
      <Header>
        {`Tile ${position + 1}`}
        {tileType !== "Global" && <Button text="Delete Tile" variant="text" onClick={() => setTileToDelete(true)} />}
      </Header>
      <Modal
        onApproveClick={() => {
          const list = clientPortalTiles.filter((elem, index) => position !== index);
          setClientPortalTiles(list);
          setGeneralInfoFormState((prevState) => ({
            ...prevState,
            clientPortal: {
              tiles: list,
            },
          }));
          setTileToDelete(false);
        }}
        onCancelClick={() => setTileToDelete(false)}
        title="Delete Tile"
        textContent="Please confirm that you would like to delete the Tile"
        approveButtonText="Delete Tile"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
        revertButtons
        isShowing={Boolean(tileToDelete)}
        hide={() => setTileToDelete(false)}
      />
      <Tile>
        <Form>
          <TileInner>
            <Row>
              <Column>
                <Label>
                  <CustomToggle eventKey={position}>Click me!</CustomToggle>
                  Label:
                </Label>
                <LabelControl>
                  <TileInput
                    label="title"
                    editMode={editMode}
                    value={currentLabel}
                    type="input"
                    required
                    register={register}
                    errors={errors}
                    placeholder={placeholderList["title"]}
                    onChange={(e) => handleChange("title", e)}
                  />
                </LabelControl>
              </Column>
              <Column>
                <Label>Status:</Label>
                <LabelControl>
                  <TileInput
                    label="enabled"
                    editMode={tileType !== "Global" || editMode}
                    value={enabledDigit}
                    type="select"
                    options={selectOptions.enabled}
                    onSelectChange={(e) => handleChangeSelect("enabled", e)}
                  />
                </LabelControl>
                <TileMark>{tileType}</TileMark>
              </Column>
              <Column>
                <Label>Order No.:</Label>
                <LabelControl>
                  <TileInput
                    label="sortOrder"
                    editMode={editMode}
                    value={currentSort}
                    type="input"
                    inputWidth="150px"
                    placeholder={placeholderList["sortOrder"]}
                    register={register}
                    errors={errors}
                    onChange={(e) => handleChange("sortOrder", e)}
                  />
                </LabelControl>
              </Column>
              {tileType === "Global" && (
                <Column>
                  <CustomCheckBox>
                    <Form.Check
                      defaultChecked={editMode}
                      className="custom-checkbox"
                      type="checkbox"
                      label="Override Global Level Values"
                      onChange={(e) => handleEditMode(e.target.checked)}
                    />
                  </CustomCheckBox>
                </Column>
              )}
            </Row>
          </TileInner>
          <TileInnerCollapse>
            <Accordion.Collapse eventKey={position.toString()}>
              <RowCollapse>
                <Column mode="short">
                  <Label>Tile Type:</Label>
                  <LabelControl>
                    <TileInput
                      label="type"
                      editMode={editMode}
                      value={currentTileType}
                      type="select"
                      options={selectOptions.type}
                      onSelectChange={(e) => handleChangeSelect("type", e)}
                    />
                  </LabelControl>
                </Column>
                <Column mode="long">
                  <Label>{currentType === "url" ? "URL:" : "Path:"}</Label>
                  <LabelControl>
                    <TileInput
                      label={getTileTypeKey(currentType)}
                      editMode={editMode}
                      value={getTileType(currentType) || ""}
                      type="input"
                      required
                      register={register}
                      errors={errors}
                      filteredFoldersNameList={filteredFoldersNameList}
                      placeholder={placeholderList[getTileTypeKey(type)]}
                      onChange={(e) => handleChange(getTileTypeKey(type), e)}
                    />
                  </LabelControl>
                </Column>
                <Column mode="short">
                  <Label>Icon Preview: </Label>
                  <LabelControl>
                    {currentIcon && (faIconsLight as any)[currentIcon] && (
                      <IconPreview icon={(faIconsLight as any)[currentIcon]} />
                    )}
                  </LabelControl>
                </Column>
                <Column mode="long">
                  <Label>Icon Code: </Label>
                  <LabelControl>
                    <TileInput
                      label="icon"
                      editMode={editMode}
                      value={currentIcon}
                      type="input"
                      required
                      register={register}
                      errors={errors}
                      placeholder={placeholderList["icon"]}
                      onChange={(e) => handleChange("icon", e)}
                    />
                  </LabelControl>
                </Column>
              </RowCollapse>
            </Accordion.Collapse>
          </TileInnerCollapse>
        </Form>
      </Tile>
    </>
  );
};

const Tile = styled.div`
  margin-bottom: 28px;
`;
const TileInner = styled.div`
  width: 100%;
  border: 1px solid #c4c4c4;
`;
const TileInnerCollapse = styled.div`
  width: 100%;
  border-top: none;
`;
const TileMark = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 94px;
  height: 40px;
  padding: 5px 10px;
  border: 1px solid #c4c4c4;
  background: #ffffff;
  font-size: 14px;
  text-align: center;
  color: #555555;
`;

const Header = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  color: ${colors.grey.dark};
`;

const Column = styled.div<{ mode?: string }>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: calc(50% - 20px);
  gap: 0 10px;

  ${(props) => {
    switch (props.mode) {
      case "short":
        return css`
          width: calc(30% - 20px);
        `;
      case "long":
        return css`
          width: calc(70% - 20px);
        `;
      default:
        return css`
          width: calc(50% - 20px);
        `;
    }
  }}
`;
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 20px 20px 16px;
`;
const RowCollapse = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px 40px;
  padding: 20px 20px 16px;
  border: 1px solid #c4c4c4;
  border-top: none;
`;
const Label = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: ${colors.grey.dark};
  white-space: nowrap;
  gap: 0 10px;
`;
const LabelControl = styled.div`
  display: block;
  flex-grow: 5;
`;
export const CustomCheckBox = styled.div`
  .form-check {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0 10px;
  }

  .form-check-label {
    font-weight: 400;
    font-size: 14px;
    color: ${colors.grey.dark};
    padding-top: 4px;
  }

  .custom-radio input,
  .custom-checkbox input,
  .custom-toggle input {
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.grey.light4};
    border-radius: 2px;
  }

  .custom-radio input:checked,
  .custom-checkbox input:checked,
  .custom-toggle input:checked {
    background-color: ${colors.kleenway.greenLight};
  }

  .custom-radio input:checked:focus,
  .custom-checkbox input:checked:focus,
  .custom-toggle input:checked:focus {
    box-shadow: none;
  }

  .custom-radio input:focus,
  .custom-checkbox input:focus,
  .custom-toggle input:focus {
    box-shadow: none;
  }

  .custom-radio input:active,
  .custom-checkbox input:active,
  .custom-toggle input:active {
    box-shadow: none;
  }
`;

const IconButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  width: 21px;
  height: 21px;
`;
const Icon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 15px;
  color: ${colors.grey.dark};
  transition: all 0.2s linear;
`;

const IconPreview = styled(FontAwesomeIcon)`
  width: 40px;
  height: 40px;
  color: ${colors.grey.dark};
  transition: all 0.2s linear;
  margin-left: 20px;
`;
