import moment from "moment";
import { Dispatch } from "redux";
import {
  clearWorkRequestMessage,
  setIsTicketsLoading,
  setTickets,
  setWorkOrderCount,
  setWorkRequestAlertVariant,
  setWorkRequestMessage,
} from "src/redux/actions/workRequest";
import { FirebaseDataService } from "src/services/Firebase/data";
import { Ticket } from "src/types";
import { getBase64File } from "src/utils/convertToBase64";
import { roundTo2Decimals } from "src/utils/helpers";
import { ActiveEmployee, ActiveVendor } from "../AssignWOResource/types";
import { CloseWorkOrderFormValues } from "../CloseWorkOrderForm/types";
import { managerTableHeaders, tableHeaders, vendorTableHeaders } from "./constant";
import { WorkOrderDataParams, WorkOrderFunctionParams } from "./types";
import { setActiveResources } from "src/redux/actions/resources";

export const getApprovedAmount = (ticket: Ticket) => {
  if (ticket.woacceptscharges) {
    return ticket.approvedamount ? `$ ${roundTo2Decimals(ticket.approvedamount)}` : "Post-completion";
  } else {
    return "Non-billable";
  }
};

export const uploadImages = async (workOrderId: string, files: File[]) => {
  if (!files?.length) {
    return;
  }
  if (workOrderId) {
    for (const item of files) {
      const file = item;
      const folderName = "Technician";

      const convertedFile = (await getBase64File(file)) as string;
      const fileToUpload = convertedFile?.slice(convertedFile.indexOf(",") + 1);
      await FirebaseDataService.addMaximoWorkOrderAttachment(
        fileToUpload,
        workOrderId,
        file.name,
        file.type,
        folderName,
      );
    }
  }
};

export const getWorkOrders = async (
  {
    filters,
    isManager,
    isSearchJustApplied,
    loggedInUserId,
    pageNum,
    searchKeyword,
    vendorId,
    workRequestType,
    workRequestsPage,
    isRedirectToDetailPageAllowed,
  }: WorkOrderDataParams,
  { navigate, setWorkRequestsPage, dispatch }: WorkOrderFunctionParams,
) => {
  dispatch(setIsTicketsLoading(true));
  const { data } = await FirebaseDataService.getMaximoWorkOrders(
    loggedInUserId,
    {
      pageNum,
      orderBy: filters?.orderBy,
      pageSize: filters?.pageSize,
      workRequestType,
    },
    isManager,
    searchKeyword || "",
    vendorId,
  );

  if (data.workOrderPage !== workRequestsPage) {
    setWorkRequestsPage(data.workOrderPage);
  }

  dispatch(setTickets(mergeWorkRequestData(data.workOrders)));
  dispatch(setIsTicketsLoading(false));
  dispatch(setWorkOrderCount(data.workOrderCount));
  dispatch(setActiveResources({ employees: data.employees, vendors: data.vendors }));
  if (!isRedirectToDetailPageAllowed) {
    return;
  }

  const { wonum } = data.workOrders[0] || {};

  if (data?.workOrders?.length === 1 && wonum?.toLowerCase() === searchKeyword?.toLowerCase() && isSearchJustApplied) {
    navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
  }
};

export const onSubmitCloseWorkOrderHandler = async (
  values: CloseWorkOrderFormValues,
  selectedWorkOrder: Ticket | undefined,
  maximoTimezone: string,
  dispatch: Dispatch,
  setSelectedTicketForCloseAction: React.Dispatch<React.SetStateAction<Ticket | undefined>>,
) => {
  let success = false;
  if (!selectedWorkOrder) {
    return success;
  }
  const actstart = moment(values.actstart).tz(maximoTimezone, true).format();
  const actfinish = moment(values.actfinish).tz(maximoTimezone, true).format();
  const { status, message, data } = await FirebaseDataService.closeWorkOrder(
    selectedWorkOrder.wonum,
    values.notes,
    actstart,
    actfinish,
    values.approvedamount,
  );

  if (status === "success") {
    await uploadImages(data.workOrderId, values.attachments);
    dispatch(setWorkRequestMessage(message));
    dispatch(setWorkRequestAlertVariant(status));
    success = true;
  } else {
    const msg = message.split("\n")[1];
    dispatch(setWorkRequestMessage(msg));
    dispatch(setWorkRequestAlertVariant("danger"));
  }
  setSelectedTicketForCloseAction(undefined);

  return success;
};

export const clearWorkRequestMessageTimeout = (dispatch: Dispatch) => {
  const timeout = 10000;
  setTimeout(() => {
    dispatch(clearWorkRequestMessage());
  }, timeout);
};

export const mergeWorkRequestData = (data: Ticket[]) => {
  return data?.map((workOrder: Ticket): Ticket => {
    return {
      ...workOrder,
      dateCreated: moment(workOrder.dateCreated).format("MMMM Do YYYY, h:mm:ss a"),
      location: workOrder.location || "No location specified",
    };
  });
};

export const getTableHeader = (isManager: boolean, isVendor: boolean) => {
  if (isManager) {
    return managerTableHeaders;
  } else if (isVendor) {
    return vendorTableHeaders;
  } else {
    return tableHeaders;
  }
};

export const getResourseData = (
  vendorId: string | undefined,
  laborCode: string | undefined,
  vendors: ActiveVendor[],
  employees: ActiveEmployee[],
): string | undefined => {
  if (vendorId) {
    const vendor = vendors.find((v) => v.vendorId === vendorId);
    return vendor?.displayName ?? "";
  } else if (laborCode) {
    const employee = employees.find((e) => e.laborCode === laborCode);
    return employee?.displayName ?? "";
  } else {
    return;
  }
};
