import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { colors } from "src/styles";

import { FieldError } from "react-hook-form";
import { sortBy } from "../../utils";
import Input from "../Input";
import SelectSearchItem from "./SelectSearchItem";

export type OptionItem = {
  id: string | number;
  value: string;
  priority?: number;
  labelDB?: string;
  disabled?: boolean;
};
export type SelectSearchVariant = "default" | "managerPortal" | "tileSelect";

export interface SelectSearchProps {
  id?: string;
  name?: string;
  options: OptionItem[];
  disabled?: boolean;
  $isMobile?: boolean;
  placeholder?: string;
  defaultValue: string | number;
  onChange?: (option: OptionItem) => void;
  caption?: string;
  inputStyle?: string;
  buttonStyle?: string;
  resultStyle?: string;
  listSize?: number;
  bottomResultsGap?: number;
  error?: FieldError;
}

export const DEFAULT_LIST_SIZE = 10;
const NO_RESULTS_LIST_HEIGHT = 60;
const DEFAULT_INPUT_HEIGHT = 40;
const OPTION_HEIGHT = 28;

export const SELECT_LOCATION_PLACEHOLDER = "Select Location";
export const SELECT_CLIENT_FIRST_PLACEHOLDER = "Select Client First";
export const SELECT_CLIENT_PLACEHOLDER = "Select Client";
export const SELECT_ROLE_PLACEHOLDER = "Select Role";

function SelectSearch(
  {
    id,
    options,
    disabled,
    $isMobile,
    placeholder,
    defaultValue,
    onChange,
    caption,
    inputStyle,
    buttonStyle,
    resultStyle,
    listSize = DEFAULT_LIST_SIZE,
    bottomResultsGap = DEFAULT_INPUT_HEIGHT,
    error,
    name = "search",
  }: SelectSearchProps,
  ref?: React.ForwardedRef<HTMLInputElement>,
) {
  const findSelectedItem = () => options.find((rec) => rec.id === defaultValue);

  const [isVisible, setIsVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState<OptionItem | undefined>(findSelectedItem());

  const [searchText, setSearchText] = useState(selectedItem?.value);
  const [showResultsOnBottom, setShowResultsOnBottom] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [resultsListPosition, setResultsListPosition] = useState(listSize * OPTION_HEIGHT);

  const searchContainerRef = useRef<any>(null);
  const searchResultRef = useRef<any>(null);
  const searchResultContainerRef = useRef<any>(null);

  //added for clearing setState, when default value was cleared in parent component
  useEffect(() => {
    const selectedItem = findSelectedItem();
    setSelectedItem(selectedItem);
    setSearchText(selectedItem?.value);
  }, [defaultValue, options]);

  useEffect(() => {
    if (searchContainerRef?.current) {
      const { bottom } = searchContainerRef?.current?.getBoundingClientRect();
      setShowResultsOnBottom(bottom + listSize * OPTION_HEIGHT < window.innerHeight);
    } else {
      setShowResultsOnBottom(true);
    }
  }, [scrollPosition, window.innerHeight, searchContainerRef?.current]);

  const showSearchList = () => setIsVisible(true);

  const hideSearchList = () => setIsVisible(false);

  const handleClickOutside = (e: any) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(e.target)) {
      hideSearchList();
      setSelectedItem((val) => {
        setSearchText(val?.value || "");
        return val;
      });
    }
  };

  const onBlur = () => {
    // it's not nice implementation but SelectInput:onBlur and SelectSearchItem:onSelectItem asynchronius
    // and we heen to hide list on blur event
    setTimeout(() => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(window.document.activeElement)) {
        hideSearchList();
        // we need actual values, because we are in setTimeout
        setSearchText((actualSearchText) => {
          setSelectedItem((actualSelectedOption) => {
            const item = options.find((rec) => rec.value === actualSearchText);
            if (item) {
              setSelectedItem(item);
              setSearchText(item.value);
            } else {
              setSearchText(actualSelectedOption?.value || "");
            }
            return actualSelectedOption;
          });

          return actualSearchText;
        });
      }
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    setIsVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const suggestedOptions: OptionItem[] = useMemo(() => {
    let result = [];

    if (!searchText || searchText === selectedItem?.value) {
      result = options;
    } else {
      result = options.filter((item) => (item.value || "").toLowerCase().includes(searchText.toLowerCase()));
    }

    const sortedByValueResults = result.sort((a, b) => (a.value || "").localeCompare(b.value || ""));
    const priorityEnrichedResult = sortedByValueResults.map(({ priority, ...option }) => ({
      ...option,
      priority: priority || Number.MAX_SAFE_INTEGER,
    }));

    return sortBy(priorityEnrichedResult, "priority") as OptionItem[];
  }, [options, searchText]);

  useEffect(() => {
    let position =
      options.length * OPTION_HEIGHT >= listSize * OPTION_HEIGHT
        ? listSize * OPTION_HEIGHT
        : options.length * OPTION_HEIGHT;

    if (searchResultContainerRef?.current) {
      const { height: searchResultsListHeight } = searchResultContainerRef?.current.getBoundingClientRect();
      if (!suggestedOptions.length) {
        position = NO_RESULTS_LIST_HEIGHT;
      } else if (searchResultsListHeight >= listSize * OPTION_HEIGHT) {
        position = listSize * OPTION_HEIGHT;
      } else {
        position = searchResultsListHeight;
      }
    }

    if (position !== resultsListPosition && isVisible) {
      setIsVisible(false);
      setResultsListPosition(position);
      setIsVisible(true);
    } else {
      setResultsListPosition(position);
    }
  }, [suggestedOptions.length, isVisible]);

  return (
    <SelectSearchContainer ref={searchContainerRef}>
      <SelectInput
        controlId={id}
        ref={ref}
        $isVisible={isVisible ? 1 : 0}
        $isDisabledField={disabled ? 1 : 0}
        disabled={disabled}
        $isMobile={$isMobile}
        onFocus={() => setSearchText("")}
        onClick={showSearchList}
        value={searchText || ""}
        type="text"
        name={name}
        autoComplete="off"
        placeholder={placeholder}
        onChange={(e) => {
          setSearchText(e.target.value);
          showSearchList();
        }}
        onBlur={onBlur}
        $inputStyle={inputStyle}
        error={error}
      />

      <SelectButton
        onClick={(e) => {
          e.preventDefault();
          setIsVisible(!isVisible);
        }}
        disabled={disabled}
        onBlur={onBlur}
        buttonStyle={buttonStyle}
      >
        <span>{isVisible ? <Icon icon={faChevronUp as any} /> : <Icon icon={faChevronDown as any} />}</span>
      </SelectButton>
      {isVisible && (
        <SearchResult
          $showResultsOnBottom={showResultsOnBottom}
          $resultsListPosition={resultsListPosition}
          $resultStyle={resultStyle}
          $isScrollable={options.length > 10}
          ref={searchResultContainerRef}
          $listHeight={listSize * OPTION_HEIGHT}
          $bottomResultsGap={bottomResultsGap}
        >
          <SearchResultList ref={searchResultRef}>
            {suggestedOptions.length > 0 ? (
              suggestedOptions.map((option) => (
                <SelectSearchItem
                  onSelectItem={() => {
                    if (option.disabled) {
                      return;
                    }
                    setSelectedItem(option);
                    setSearchText(option.value);
                    onChange?.(option);
                    hideSearchList();
                  }}
                  key={option.id}
                  option={option}
                />
              ))
            ) : (
              <InfoWindow>
                <h4>No Result Found</h4>
              </InfoWindow>
            )}
          </SearchResultList>
        </SearchResult>
      )}
      {caption && <p>{caption}</p>}
    </SelectSearchContainer>
  );
}

export default React.memo(forwardRef<HTMLInputElement, SelectSearchProps>(SelectSearch));

interface SearchResultProps {
  $isScrollable?: boolean;
  $resultStyle?: string;
  $showResultsOnBottom: boolean;
  $resultsListPosition?: number;
  $listHeight: number;
  $bottomResultsGap: number;
}

interface SelectInputProps {
  $isMobile?: boolean;
  $inputStyle?: string;
  $isDisabledField: number;
  $isVisible: number;
}

export const SelectSearchContainer = styled.div`
  height: 100%;
  position: relative;
  & > button {
    background-color: unset;
  }
`;

export const InfoWindow = styled.div`
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    font-size: 14px;
    color: ${colors.grey.default};
  }
`;

interface SelectButtonProps {
  buttonStyle?: string;
}

export const SelectButton = styled.button<SelectButtonProps>`
  display: inline-block;
  position: absolute;
  border: none;
  padding-right: 0;
  padding-left: 5px;

  right: 0;
  top: 10px;
  right: 8px;
  cursor: pointer;

  ${(props) => props.buttonStyle && `${props.buttonStyle}`}
`;

export const SelectInput = styled(Input)<SelectInputProps>`
  cursor: ${({ $isDisabledField }) => ($isDisabledField ? "default" : "pointer")};
  padding: 10px 25px 10px 10px;
  height: unset;

  ${(props) => `border: 1px solid ${props.$isVisible ? colors.kleenway.green : colors.grey.light13}`}

  ${(props) => props.$isMobile && `width: 200px !important;`}

  &:disabled {
    background-color: ${colors.grey.light2};
  }

  &:focus {
    outline: none;
  }

  ${(props) => props.$inputStyle && `${props.$inputStyle}`}
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

export const SearchResult = styled.div<SearchResultProps>`
  position: absolute;
  top: ${(props) =>
    props.$showResultsOnBottom ? props.$bottomResultsGap : `-${props.$resultsListPosition}`}px !important;
  width: 100%;
  max-height: ${(props) => props.$listHeight}px;
  overflow: hidden;
  ${(props) => props.$isScrollable && `overflow-y: auto;`}
  z-index: 1000;
  font-size: 14px;
  border-radius: 2px;
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: ${colors.grey.light1};
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: ${colors.grey.default};
  }

  ${(props) => props.$resultStyle && `${props.$resultStyle}`}
`;

export const SearchResultList = styled.ul`
  list-style-type: none;
  background-color: white;
  & > li {
    border-bottom: 0;
    &:hover {
      background-color: ${colors.grey.light14};
    }
  }
`;
