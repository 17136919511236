import React from "react";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { Content } from "src/styles";
import { WorkRequestTabsProps } from "../types";
import WorkRequestTab from "./WorkRequestTab";

const WorkRequestTabs = ({
  activeTab,
  setActiveTab,
  tabs,
  siblingCount,
  pageSize,
  isRedirectToDetailPageAllowed,
  renderTable,
}: WorkRequestTabsProps) => {
  if (tabs.length === 1) {
    return (
      <Content>
        <WorkRequestTab
          siblingCount={siblingCount}
          workRequestType={activeTab}
          pageSize={pageSize}
          renderTable={renderTable}
        />
      </Content>
    );
  }
  return (
    <Content>
      <HorizontalTabs
        tabs={tabs.map((tab) => ({
          eventKey: tab.eventKey,
          label: tab.label,
          content:
            activeTab === tab.eventKey ? (
              <WorkRequestTab
                siblingCount={siblingCount}
                workRequestType={activeTab}
                pageSize={pageSize}
                isRedirectToDetailPageAllowed={isRedirectToDetailPageAllowed}
                renderTable={renderTable}
              />
            ) : null,
        }))}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </Content>
  );
};

export default WorkRequestTabs;
