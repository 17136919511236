import React from "react";
import DataTable from "src/components/Table";
import TableRow from "src/components/Table/TableRow";
import { noop } from "src/components/atoms/DatePicker/utils";
import { StyledCell } from "../../styles";
import { ContactsTableProps, DBVendorContact } from "../../types";

export const ContactsTable = ({ headers, data }: ContactsTableProps) => {
  return (
    <DataTable headers={headers}>
      <>
        {data?.map(({ email, name, phone, position }: DBVendorContact) => {
          return (
            <TableRow $clickable={false} onClick={noop} key={email}>
              <StyledCell>{name}</StyledCell>
              <StyledCell>{position}</StyledCell>
              <StyledCell>{email}</StyledCell>
              <StyledCell>{phone}</StyledCell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};
