import React from "react";
import { useParams } from "react-router-dom";
import { useIsMobile } from "src/hooks/useIsMobile";
import { InputRow, InputsContainer, InputsRow, RawValue, TextAreaRow } from "../../styles";
import { DBVendorDetails } from "../../types";

const VendorInfo = ({ vendorData }: { vendorData: DBVendorDetails }) => {
  const { isMobile } = useIsMobile();
  const { id: vendorId } = useParams();

  return (
    <>
      <InputsContainer $isMobile={isMobile}>
        <InputsRow>
          <InputRow $isMobile={isMobile}>
            <label>Vendor Name:</label>
            <RawValue>{vendorData.name}</RawValue>
          </InputRow>
          <InputRow $isMobile={isMobile}>
            <label>Vendor ID</label>
            <RawValue>{vendorId}</RawValue>
          </InputRow>
        </InputsRow>
        <InputsRow>
          <TextAreaRow $isMobile={isMobile}>
            <label>Address:</label>
            <RawValue>{vendorData?.address?.addressLine1}</RawValue>
          </TextAreaRow>
        </InputsRow>
        <InputsRow>
          <InputRow $isMobile={isMobile}>
            <label>City:</label>
            <RawValue>{vendorData?.address?.city}</RawValue>
          </InputRow>
          <InputRow $isMobile={isMobile}>
            <label>State/Province:</label>
            <RawValue>{vendorData?.address?.state}</RawValue>
          </InputRow>
        </InputsRow>
        <InputsRow>
          <InputRow $isMobile={isMobile}>
            <label>ZIP/Postal Code:</label>
            <RawValue>{vendorData?.address?.postalCode}</RawValue>
          </InputRow>
          <InputRow $isMobile={isMobile}>
            <label>Phone:</label>
            <RawValue>{vendorData?.phone}</RawValue>
          </InputRow>
        </InputsRow>
      </InputsContainer>
    </>
  );
};

export default VendorInfo;
