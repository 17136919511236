import React from "react";
import ContentBox from "src/components/ContentBox";
import { ShiftListForm } from "../ShiftListForm";
import { TemplateLabelInput } from "./components/TemplateLabelInput";
import { TemplateContainer, WarningMessage } from "./styles";
import { ScheduleTemplateFormProps } from "./types";

/**
 * Renders a single Schedule Template form. Requires a `react-hook-form` context.
 */
export const ScheduleTemplateForm = ({ name, template }: ScheduleTemplateFormProps) => {
  return (
    <>
      <ContentBox
        isOpen={template.isNew}
        renderHeader={() => <TemplateLabelInput name={`${name}.label`} value={template.label} />}
      >
        <TemplateContainer>
          {template.isUsedByEmployees && (
            <WarningMessage>
              This Schedule Template is currently used by multiple Kleenway Team Members. Updating the Template will
              also update Team Members&apos; Schedules.
            </WarningMessage>
          )}

          <ShiftListForm name={`${name}.shifts`} />
        </TemplateContainer>
      </ContentBox>
    </>
  );
};
