import { getApp, getApps, initializeApp } from "firebase/app";
import { browserSessionPersistence, connectAuthEmulator, getAuth, setPersistence } from "firebase/auth";
import {
  endAt,
  equalTo as equalToFB,
  get,
  getDatabase,
  limitToFirst as limitToFirstFB,
  limitToLast as limitToLastFB,
  onValue,
  orderByChild as orderByChildFB,
  query,
  ref,
  ref as refFB,
  startAt,
} from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { renderResponseResult } from "src/utils";
import { debugInfo } from "src/utils/log";
import { Environments } from "./types";

type FirebaseQueryParams = {
  ref: string;
  error?: string;
  orderByChild?: string;
  equalTo?: string | number | boolean;
  startAtKey?: string;
  limitToFirst?: number;
  limitToLast?: number;
  applyAllConstraints?: boolean;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

export default class FirebaseService {
  static async init() {
    if (process.env.REACT_APP_ENV !== Environments.production) {
      debugInfo(firebaseConfig);
    }

    const apps = getApps();

    if (!apps.length) {
      const app = initializeApp(firebaseConfig);

      if (process.env.REACT_APP_ENV === Environments.development) {
        if (process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_URL) {
          const functions = getFunctions(app);
          const [host, port] = process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_URL.split(/:|\/\//).slice(-2);
          connectFunctionsEmulator(functions, host, Number(port));
        }
        if (process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_URL) {
          const storage = getStorage(app);
          const [host, port] = process.env.REACT_APP_FIREBASE_STORAGE_EMULATOR_URL.split(/:|\/\//).slice(-2);
          connectStorageEmulator(storage, host, Number(port));
        }

        if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL) {
          const auth = getAuth(app);
          connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_URL);
        }
      }
      const auth = getAuth(app);

      await setPersistence(auth, browserSessionPersistence);
    }
  }

  static async checkConnection() {
    const app = getApp();
    const database = getDatabase(app);
    const connectedRef = ref(database, ".info/connected");
    onValue(
      connectedRef,
      //eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      () => renderResponseResult(false, "Unable to connect to the database"),
    );
  }

  static async getData({
    ref,
    error,
    orderByChild,
    equalTo,
    startAtKey,
    limitToFirst,
    limitToLast,
    applyAllConstraints,
  }: FirebaseQueryParams) {
    try {
      await this.checkConnection();

      const app = getApp();
      const database = getDatabase(app);
      const dbRef = refFB(database, ref);

      const getSnapshot = () => {
        if (applyAllConstraints) {
          const constrains = [];
          if (orderByChild && equalTo && startAtKey)
            constrains.push(orderByChildFB(orderByChild), startAt(equalTo, startAtKey), endAt(equalTo));
          else if (orderByChild && equalTo) constrains.push(orderByChildFB(orderByChild), equalToFB(equalTo));
          if (limitToFirst) constrains.push(limitToFirstFB(limitToFirst));
          if (limitToLast) constrains.push(limitToLastFB(limitToLast));

          return query(dbRef, ...constrains);
        } else {
          if (orderByChild && equalTo) {
            return query(dbRef, orderByChildFB(orderByChild), equalToFB(equalTo));
          }
          if (limitToFirst) {
            return query(dbRef, limitToFirstFB(limitToFirst));
          }
          if (limitToLast) {
            return query(dbRef, limitToLastFB(limitToLast));
          }
        }

        return dbRef;
      };

      const snapshot = await get(getSnapshot());
      if (snapshot.exists()) {
        const data = snapshot.val();
        return renderResponseResult(true, data);
      }
      return renderResponseResult(false, error || `Unable to fetch ${ref}`);
    } catch (error) {
      return renderResponseResult(false, error);
    }
  }
}
