import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "src/components/Input";
import { OptionsWrapper, StyledRadioInput } from "../styles";
import { Option, SearchResourceProps } from "../types";

const SearchResource = ({ options, selectedValue, onSelectOption }: SearchResourceProps) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [value, setValue] = useState<string | null>(null);
  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);
  const handleOnTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setFilteredOptions(options.filter((option) => option.value.toLowerCase().includes(e.target.value.toLowerCase())));
  };
  const handleOptionClick = (option: Option) => {
    const newValue = value === option.id ? null : option.id;
    setValue(newValue);
    onSelectOption(newValue ? option : null);
  };
  return (
    <div>
      <Input onChange={handleOnTextChange} placeholder="Search" />
      <OptionsWrapper>
        {filteredOptions.map((option) => (
          <div onClick={() => handleOptionClick(option)} key={option.id}>
            <StyledRadioInput readOnly type="radio" checked={option.id === value} radioGroup={"radio"} />
            <span>{option.value}</span>
          </div>
        ))}
      </OptionsWrapper>
    </div>
  );
};

export default SearchResource;
