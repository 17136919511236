import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "src/styles";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { StructuredPayPeriods } from "../../ClientDetailsProvider";
import { PAY_PERIODS_BY_ABBREVIATURE, PAY_PERIODS_BY_DESCRIPTION } from "../../constants";

export const PayPeriodsSwitcher = ({
  currentPeriodId,
  payPeriods,
}: {
  currentPeriodId: string;
  payPeriods: StructuredPayPeriods;
}) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const currentPeriodYear = Number(currentPeriodId.split("-")[0]);
  const currentPeriodType = currentPeriodId.split("-")[1];
  const currentPeriodNumber = Number(currentPeriodId.split("-")[2]);

  const generateNextPayPeriodId = () => {
    const payPeriodId = PAY_PERIODS_BY_DESCRIPTION[currentPeriodType];

    const typeSpecificPeriods = payPeriods[payPeriodId];

    const thisYearNextPayPeriod = typeSpecificPeriods[currentPeriodYear]?.find(
      ({ number }) => Number(number) === currentPeriodNumber + 1,
    );
    const nextYearNextPayPeriod = typeSpecificPeriods[currentPeriodYear + 1]?.find(
      ({ number }) => Number(number) === 1,
    );
    const nextPayPeriod = thisYearNextPayPeriod || nextYearNextPayPeriod;

    if (nextPayPeriod) {
      const { number, year, payPeriodTypeCode } = nextPayPeriod;
      return `${year}-${PAY_PERIODS_BY_ABBREVIATURE[payPeriodTypeCode]}-${number}`;
    }

    return null;
  };

  const generatePreviousPayPeriodId = () => {
    const payPeriodId = PAY_PERIODS_BY_DESCRIPTION[currentPeriodType];
    const typeSpecificPeriods = payPeriods[payPeriodId];
    const thisYearPreviousPayPeriod = typeSpecificPeriods[currentPeriodYear]?.find(
      ({ number }) => Number(number) === currentPeriodNumber - 1,
    );

    const previousYearTypeSpecificPayPeriods = typeSpecificPeriods[currentPeriodYear - 1];
    const previousYearPreviousPayPeriod =
      previousYearTypeSpecificPayPeriods?.[previousYearTypeSpecificPayPeriods?.length - 1];
    const previousPayPeriod = thisYearPreviousPayPeriod || previousYearPreviousPayPeriod;

    if (previousPayPeriod) {
      const { number, year, payPeriodTypeCode } = previousPayPeriod;
      return `${year}-${PAY_PERIODS_BY_ABBREVIATURE[payPeriodTypeCode]}-${number}`;
    }

    return null;
  };

  const navigateToPayPeriodPage = (payPeriodId: string) => navigate(`/clients/${id}/${payPeriodId}`);

  const nextPayPeriodId = generateNextPayPeriodId();
  const previousPayPeriodId = generatePreviousPayPeriodId();

  return (
    <SwitcherContainer>
      <Button
        additionalStyles={{ fontSize: "14px", color: colors.grey.dark }}
        variant="text"
        text="Go to previous Pay Period"
        onClick={() => previousPayPeriodId && navigateToPayPeriodPage(previousPayPeriodId)}
        disabled={!previousPayPeriodId}
        icon={faChevronLeft as IconDefinition}
      />
      <Button
        additionalStyles={{ fontSize: "14px", color: colors.grey.dark }}
        variant="text"
        text="Go to next Pay Period"
        onClick={() => nextPayPeriodId && navigateToPayPeriodPage(nextPayPeriodId)}
        disabled={!nextPayPeriodId}
        iconPosition="right"
        icon={faChevronRight as IconDefinition}
      />
    </SwitcherContainer>
  );
};

const SwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 60px;
`;
