import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { FirebaseDataService } from "src/services/Firebase/data";
import { ID } from "src/types";

import BreadCrumbs from "../../components/BreadCrumbs";
import { Container, EmptyContainer } from "../../components/ListScreen";
import { generateBreadcrumbs } from "../../helpers/breadcrumbs";
import { MANAGER_CLIENT_DETAILS_BREADCRUMBS } from "./constants";

import { useDispatch, useSelector } from "react-redux";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { setPageNameHeader } from "src/redux/actions/header";
import { setOpenedClientsPage } from "src/redux/actions/openedPages";
import { getOpenedClientsPage } from "src/redux/selectors/openedPages";
import { getUserData } from "src/redux/selectors/user";
import { formatTimezoneToShort } from "src/utils/time";
import Modal from "../../components/Modal";
import FirebaseStorageService from "../../services/Firebase/storage";
import { Content, ContentFrame, TopBar, colors } from "../../styles";
import { ClientPortalTiles } from "./ClientPortalTiles";
import { ClientPortalUsersForm } from "./ClientPortalUsersForm";
import { FileSharing } from "./FileSharing";
import { GeneralInfoForm } from "./GeneralInfoForm";
import { InfoMessagesForm } from "./InfoMessagesForm";
import { Locations } from "./Locations";
import { TimeAndAttendance } from "./TimeAndAttendance";
import { determineClientLocation, getPayPeriods } from "./TimeAndAttendance/utils";
import ClientDetailsContext from "./context";
import { checkFormsValidity, generateGeneralInfoFormState, generateUpdateClientRequest } from "./utils";

export const ClientDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const openedClientsPage = useSelector(getOpenedClientsPage);
  const { id: loggedInId, isManager } = useSelector(getUserData);
  const dispatch = useDispatch();

  const {
    client,
    setClient,
    setIsGeneralInfoFormSaving,
    generalInfoFormState,
    setGeneralInfoFormState,
    kleenwayContacts,
    timezones,
    setTimezones,
    messages,
    setPayPeriods,
    setLocations,
    isGeneralInfoFormValid,
    isTilesFormValid,
    clientPortalTiles,
  } = useContext(ClientDetailsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);
  const [isUpdateClientLoading, setIsUpdateClientLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("generalInfo");

  const uploadNewLogo = (onSuccess?: () => void) => {
    const fileLogo = generalInfoFormState.logo as File;
    if (fileLogo?.size) {
      FirebaseStorageService.deleteChildFromStorage(client.logo, process.env.REACT_APP_CLIENT_LOGO_FOLDER)
        .then(() => {
          FirebaseStorageService.uploadImageToStorage(
            generalInfoFormState.logo,
            process.env.REACT_APP_CLIENT_LOGO_FOLDER,
            fileLogo.name,
          )
            .then(() => onSuccess && onSuccess())
            .catch((err) => setError(err.message))
            .finally(() => setIsUpdateClientLoading(false));
        })
        .catch((err) => setError(err.message))
        .finally(() => setIsUpdateClientLoading(false));
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const uploadNewClientUserPhoto = async (formIndex: number) => {
    const currentClientUser = generalInfoFormState?.clientPortalUsers?.[formIndex];
    const filePhoto = currentClientUser.photo as File;

    if (currentClientUser.photo === currentClientUser.initialPhoto) {
      return;
    }

    try {
      if (currentClientUser.initialPhoto) {
        await FirebaseStorageService.deleteChildFromStorage(
          currentClientUser.initialPhoto || "",
          process.env.REACT_APP_PROFILE_PICTIRE_FOLDER,
        );
      }

      if (filePhoto?.size) {
        await FirebaseStorageService.uploadImageToStorage(
          filePhoto,
          process.env.REACT_APP_PROFILE_PICTIRE_FOLDER,
          filePhoto.name,
        );
        setGeneralInfoFormState(({ clientPortalUsers, ...prevState }) => ({
          ...prevState,
          clientPortalUsers: clientPortalUsers.map((state) => {
            return state.id === currentClientUser.id
              ? {
                  ...state,
                  initialPhoto: filePhoto.name,
                }
              : state;
          }),
        }));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const getClientPortalUsers = async () => {
    if (!id) {
      return;
    }
    const { data } = await FirebaseDataService.getClientPortalUsers(id);

    return data.map((item) => {
      return {
        ...item,
        isValid: true,
        isSaved: true,
        isDeleted: false,
        initialPhoto: item.photo || null,
        error: "",
      };
    });
  };

  const getClientData = async () => {
    setIsLoading(true);

    if (id) {
      const { data: clientObject } = await FirebaseDataService.getEmployeeClient({ clientId: id });
      const clientUsers = await getClientPortalUsers();

      if (clientObject) {
        if (isManager && clientObject.managerId !== loggedInId) {
          redirectToOpenedPage();
          return;
        }
        setClient(clientObject);

        const tzOptions = timezones;
        if (clientObject?.settings?.timezone) {
          const isOutOfListTimezone = !timezones.find(
            ({ value, labelDB }) =>
              value === clientObject?.settings?.timezone || labelDB === clientObject?.settings?.timezone,
          );
          if (isOutOfListTimezone) {
            tzOptions.push({
              id: -2,
              value: clientObject.settings.timezone,
            });
          }
        }

        const formattedTimezones = tzOptions.map((item) => {
          const newFormat = item.labelDB ? item?.value : formatTimezoneToShort(item?.value);
          return {
            ...item,
            labelDB: item.labelDB || item.value,
            value: newFormat,
          };
        });

        setTimezones(formattedTimezones);

        setGeneralInfoFormState(
          generateGeneralInfoFormState(
            clientObject,
            tzOptions,
            messages,
            kleenwayContacts,
            clientPortalTiles,
            clientUsers || [],
            generalInfoFormState,
          ),
        );

        try {
          const clientLocation = await determineClientLocation(clientObject, id, setLocations);
          await getPayPeriods(clientLocation, setPayPeriods);
        } catch (err) {}
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getClientData();
  }, []);

  const onClickBreadcrumb = (route: ID | null) => {
    if (typeof route === "string" && route === "/clients") {
      dispatch(setOpenedClientsPage(1));
    }
    typeof route === "string" && navigate(route);
  };

  const redirectToOpenedPage = () => navigate(`/clients?openedClientsPage=${openedClientsPage}`);

  const submitClientPortalUsers = async () => {
    const formattedClients = generalInfoFormState.clientPortalUsers.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isSaved, isValid, ...rest } = item;
      return rest;
    });

    if (!id) {
      return;
    }

    const updateClients = formattedClients.map((item) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { initialPhoto, error: err, ...rest } = item;
      const itemToSend =
        typeof item.photo === "string" ? { ...rest, photo: item.photo } : { ...rest, photo: item.photo?.name };
      return FirebaseDataService.updateClientUsers(id, itemToSend);
    });

    const resp = await Promise.all(updateClients);

    const clientUsersAfterSaving = generalInfoFormState.clientPortalUsers
      .map((item, index) => {
        const errorMessage = resp[index].status === "error" ? resp[index].message : "";

        return {
          ...item,
          id: resp[index].data ? resp[index].data : item.id,
          isValid: true,
          isSaved: !errorMessage,
          error: errorMessage,
        };
      })
      .filter((item) => !item.isDeleted);

    setGeneralInfoFormState((prev) => {
      return { ...prev, clientPortalUsers: clientUsersAfterSaving };
    });
  };

  const handleFormSubmit = (onSuccess?: () => void) => {
    setIsGeneralInfoFormSaving(true);

    const isFormsValid =
      checkFormsValidity(generalInfoFormState.clientContacts) &&
      checkFormsValidity(generalInfoFormState.kleenwayContacts) &&
      checkFormsValidity(generalInfoFormState.clientPortalUsers?.filter((item) => !item?.isDeleted));

    if (isFormsValid) {
      if (id) {
        setIsUpdateClientLoading(true);
        const updatedClient = generateUpdateClientRequest(client, generalInfoFormState);
        submitClientPortalUsers();
        FirebaseDataService.updateClient(id, updatedClient)
          .then(() => {
            setClient(updatedClient);
            generalInfoFormState.clientPortalUsers?.forEach((_, index) => uploadNewClientUserPhoto(index));
            uploadNewLogo(onSuccess);
          })
          .catch((err) => setError(err.message))
          .finally(() => setIsUpdateClientLoading(false));
      }
    } else {
      setActiveTab("generalInfo");
    }
  };

  //set page name to the Header
  useEffect(() => {
    dispatch(setPageNameHeader(client.name));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, [client]);

  if (isLoading) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }
  return (
    <Container>
      <Modal
        onApproveClick={redirectToOpenedPage}
        onCancelClick={() => setShowConfirmClosePopup(false)}
        isShowing={showConfirmClosePopup}
        hide={() => setShowConfirmClosePopup(false)}
        title="Close without Saving"
        textContent={`Do you want to close the page without saving?\nAll entered or updated information will be lost.`}
        approveButtonText="Close without Saving"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
      />
      <TopBar>
        <BreadCrumbs
          folderStack={generateBreadcrumbs(MANAGER_CLIENT_DETAILS_BREADCRUMBS, {
            id: id || client.id,
            name: client.name,
          })}
          onClickItem={onClickBreadcrumb}
        />
      </TopBar>
      <Content>
        <ContentFrame>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <FormContainer>
            <HorizontalTabs
              tabs={[
                {
                  eventKey: "generalInfo",
                  label: "General Information",
                  content: <GeneralInfoForm client={client} />,
                  tabColor:
                    checkFormsValidity(generalInfoFormState.clientContacts) &&
                    checkFormsValidity(generalInfoFormState.kleenwayContacts)
                      ? colors.kleenway.green
                      : colors.red.default,
                },
                {
                  eventKey: "infoMessages",
                  label: "Info Messages",
                  content: <InfoMessagesForm />,
                },
                {
                  eventKey: "clientPortalUsers",
                  label: "Client Portal Users",
                  content: <ClientPortalUsersForm />,
                  tabColor: checkFormsValidity(
                    generalInfoFormState.clientPortalUsers?.filter((item) => !item?.isDeleted),
                  )
                    ? colors.kleenway.green
                    : colors.red.default,
                },
                {
                  eventKey: "timeAndAttendance",
                  label: "Time & Attendance",
                  content: <TimeAndAttendance />,
                },
                {
                  eventKey: "locations",
                  label: "Locations",
                  content: <Locations />,
                },
                {
                  eventKey: "clientPortalTiles",
                  label: "Client Portal Tiles",
                  content: <ClientPortalTiles />,
                  tabColor: isTilesFormValid ? colors.kleenway.green : colors.red.default,
                },
                {
                  eventKey: "fileSharing",
                  label: "File Sharing",
                  content: <FileSharing />,
                },
              ]}
              isSaveButtonVisible={true}
              isLoading={isUpdateClientLoading}
              disableSave={isUpdateClientLoading || !isGeneralInfoFormValid || !isTilesFormValid}
              backHandler={() => setShowConfirmClosePopup(true)}
              saveHandler={() => {
                handleFormSubmit();
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </FormContainer>
        </ContentFrame>
      </Content>
    </Container>
  );
};

const FormContainer = styled.div`
  padding: 0;
  background: white;
`;

export const ErrorMessage = styled.div`
  color: ${colors.red};
  padding: 10px;
`;
