import React from "react";
import DeleteCrossButton from "src/components/DeleteCrossButton";
import { ShiftDays } from "./components/ShiftDays";
import { ShiftTime } from "./components/ShiftTime";

import { Container, FlexContainer } from "./styles";
import { ShiftFormProps } from "./types";

/**
 * Displays a single Employee Shift form. Requires a `react-hook-form` context.
 */
export const ShiftForm = ({ canDelete, name, onDelete, shift }: ShiftFormProps) => (
  <Container>
    <FlexContainer paddingBottom={15}>
      <ShiftDays name={`${name}.days`} value={shift.days} />

      {canDelete && <DeleteCrossButton onClick={onDelete} />}
    </FlexContainer>

    <FlexContainer shiftsdatepickers={1}>
      <ShiftTime name={`${name}.start`} value={shift.start} variant="Start" />

      <ShiftTime name={`${name}.end`} value={shift.end} variant="Finish" />
    </FlexContainer>
  </Container>
);
