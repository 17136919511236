import React from "react";
import { Container } from "react-bootstrap";
import BackButton from "src/components/Button/BackButton";
import { StyledForm, InputsContainer, Content, PhotoInput } from "../styles";
import WorkRequestNameInput from "./WorkRequestNameInput";
import { ContentFrame } from "src/styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Tooltip as ReactTooltip } from "react-tooltip";
import WorkRequestSelectClassificationDropdown from "./WorkRequestSelectClassificationDropdown";
import WorkRequestSelectWorkTypeDropdown from "./WorkRequestSelectWorkTypeDropdown";
import WorkRequestServiceTypeSelectSearch from "./WorkRequestServiceTypeSelectSearch";
import WorkRequestShowPriorityLevel from "./WorkRequestShowPriorityLevel";
import WorkRequestSelectLocation from "./WorkRequestSelectLocation";
import ServiceTypeQuestion from "../../ServiceTypeQuestion";
import { CustomWorkRequestFieldsForm } from "./CustomWorkRequestFieldsForm";
import WorkRequestDescriptionInput from "./WorkRequestDescriptionInput";
import { UploadWorkOrderAttachments } from "../../UploadWorkOrderAttachments";
import { ClientCreateFormFieldsProps } from "../types";
import WorkRequestTargetDatesInput from "./WorkRequestTargetDatesInput";
import CreateFormButtonContainer from "./CreateFormButtonContainer";

const ClientCreateFormFields = ({
  formData,
  isVerifyMode,
  setFormData,
  clientId,
  locations,
  serviceTypes,
  workTypes,
  setServiceTypeId,
  serviceTypeId,
  priorityLevels,
  setClassificationId,
  serviceTypeQuestions,
  customWorkRequestFields,
  form,
  setFiles,
  files,
  onExitClick,
  setTimezone,
  maximoTimezone,
  targetFinish,
  targetStart,
  timezone,
  buttonDisabledConditions,
  error,
  isLoading,
  onConfirmClick,
  onSubmit,
  setIsVerifyMode,
}: ClientCreateFormFieldsProps) => {
  const { isMobile } = useIsMobile();
  return (
    <Container>
      <Content>
        <BackButton backHandler={onExitClick} />
        <ContentFrame>
          <StyledForm>
            <InputsContainer $isMobile={isMobile}>
              <WorkRequestNameInput
                isVerifyMode={isVerifyMode}
                setFormData={setFormData}
                ticketName={formData.ticketName}
              />
              <WorkRequestServiceTypeSelectSearch
                disabled={true}
                clientId={clientId}
                isManager={false}
                isVerifyMode={isVerifyMode}
                serviceType={formData.serviceType}
                setFormData={setFormData}
                setServiceTypeId={setServiceTypeId}
                workTypes={workTypes}
                serviceTypes={serviceTypes}
              />
              <WorkRequestSelectClassificationDropdown
                classification={formData.classification}
                disabled={!!isVerifyMode}
                clientId={clientId}
                isManager={false}
                isNoAuth={false}
                serviceTypeId={serviceTypeId}
                serviceTypes={serviceTypes}
                setClassificationId={setClassificationId}
                setFormData={setFormData}
              />

              <ReactTooltip className="tooltip" />
              <WorkRequestSelectWorkTypeDropdown
                isVerifyMode={isVerifyMode}
                setFormData={setFormData}
                workTypes={workTypes}
              />
              <WorkRequestShowPriorityLevel
                clientId={clientId}
                isVerifyMode={isVerifyMode}
                priorityName={formData.priorityName}
                setFormData={setFormData}
                isNoAuth={false}
                priorityLevels={priorityLevels}
              />
              <WorkRequestSelectLocation
                clientId={clientId}
                isVerifyMode={isVerifyMode}
                setFormData={setFormData}
                setTimezone={setTimezone}
                locations={locations}
              />
            </InputsContainer>
            {!!serviceTypeQuestions.length &&
              serviceTypeQuestions.map((el) => (
                <ServiceTypeQuestion
                  key={el.id}
                  question={el}
                  formData={formData}
                  setFormData={setFormData}
                  disabled={isVerifyMode}
                />
              ))}
            <WorkRequestDescriptionInput
              description={formData.description}
              isVerifyMode={isVerifyMode}
              setFormData={setFormData}
            />

            {customWorkRequestFields && (
              <CustomWorkRequestFieldsForm
                customWorkRequestFields={customWorkRequestFields[clientId] ?? []}
                $isMobile={isMobile}
                form={form}
                isPublic={false}
                isClient={true}
              />
            )}
            {isVerifyMode && files?.length === 0 ? null : (
              <PhotoInput>
                <UploadWorkOrderAttachments setFiles={setFiles} files={files} isViewMode={isVerifyMode} />
              </PhotoInput>
            )}
            <WorkRequestTargetDatesInput
              isVerifyMode={isVerifyMode}
              maximoTimezone={maximoTimezone}
              targetFinish={targetFinish}
              targetStart={targetStart}
              timezone={timezone}
              showTopLine={files?.length === 0 ? 1 : 0}
            />
            <CreateFormButtonContainer
              error={error}
              buttonDisabledConditions={buttonDisabledConditions}
              isLoading={isLoading}
              isVerifyMode={isVerifyMode}
              onConfirmClick={onConfirmClick}
              onSubmit={onSubmit}
              setIsVerifyMode={setIsVerifyMode}
            />
          </StyledForm>
        </ContentFrame>
      </Content>
    </Container>
  );
};

export default ClientCreateFormFields;
