import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getErrorMsg } from "src/helpers/getErrorMsg";
import { logInUser } from "src/redux/actions/user";
import { FirebaseAuthService } from "src/services/Firebase/auth";
import { FirebaseDataService } from "src/services/Firebase/data";

import Alert, { AlertVariants } from "src/components/Alert";
import Button from "src/components/Button";
import Input, { InputTypes } from "src/components/Input";
import Logo from "src/components/Logo";

import { VALIDATION_MESSAGES } from "src/constants/validation";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { EMAIL_VALIDATION_PATTERN } from "src/utils/validator";
import styled from "styled-components";
import { isClient, isManager, isVendor } from "../../utils";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
  });

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onPressForgotPassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate("/forgot-password", { replace: true });
  };

  const onSubmit = handleSubmit(({ email, password }) => authUser(email.toLocaleLowerCase(), password));

  const authUser = async (email: string, password: string) => {
    setLoading(true);
    setError("");
    try {
      const uid = await FirebaseAuthService.signInWithEmailAndPassword(email, password);

      if (!uid) {
        throw new Error("Unable to find the user with this email");
      }

      const { data: userData } = await FirebaseDataService.getUserData(uid, email);

      const isUserClient = isClient(userData);
      const isUserVendor = isVendor(userData);
      const isUserManager = isManager(userData);
      if (isUserClient || isUserVendor || isUserManager) {
        dispatch(logInUser(userData));
      } else {
        throw new Error(
          "The entered Email is not found. Please, enter the correct email or consult your Kleenway Representative for assistance.",
        );
      }
      navigate("/", { replace: true });
    } catch (error) {
      setError(getErrorMsg(error));
    }
    setLoading(false);
  };

  return (
    <Container $isMobile={isMobile}>
      <Logo />
      <StyledForm $isMobile={isMobile} onSubmit={onSubmit}>
        <Input
          controlId="sign-in-email"
          type={InputTypes.email}
          label="Email Address"
          validation={register("email", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
            pattern: {
              value: EMAIL_VALIDATION_PATTERN,
              message: "Please, enter correct email.",
            },
          })}
        />

        <Input
          controlId="sign-in-password"
          type={InputTypes.password}
          label="Password"
          validation={register("password", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
          })}
        />

        {!!error && <Alert text={error} variant={AlertVariants.danger} />}

        <ButtonContainer>
          <Button text="Sign In" type="submit" disabled={!isValid} loading={loading} />
          <br />
          <Button text="Forgot your password?" variant="text" onClick={(e) => onPressForgotPassword(e)} />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

//#region styles
const Container = styled.div<MobileProps>`
  margin: ${(props) => (props.$isMobile ? "0" : "0 25%")};
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const StyledForm = styled(Form)<MobileProps>`
  width: ${(props) => (props.$isMobile ? "80%" : "50%")};
  margin-top: 30px;
  & > div {
    margin-bottom: 25px;
  }
`;
//#endregion

export default LoginPage;
