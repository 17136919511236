import React from "react";
import DataTable from "src/components/Table";
import TableRow from "src/components/Table/TableRow";
import { ID, LocationForTable } from "src/types";
import { formatTimezoneToShort } from "src/utils/time";
import { Cell } from "../../../../components/Table/Cell";

export interface LocationsTable {
  isLoading: boolean;
  headers: string[];
  data: LocationForTable[];
  onRowClick: (id: ID) => void;
}

export const LocationsTable = ({ isLoading, headers, data, onRowClick }: LocationsTable) => {
  return (
    <DataTable isLoading={isLoading} headers={headers}>
      <>
        {data?.map(({ id, label, address, clientName, timezone, supervisorName }: LocationForTable) => {
          return (
            <TableRow onClick={() => onRowClick(id)} $clickable key={id}>
              <Cell>{id.toString() ?? "N/A"}</Cell>
              <Cell>{label || "N/A"}</Cell>
              <Cell>{address || "N/A"}</Cell>
              {clientName && <Cell>{clientName}</Cell>}
              <Cell>{formatTimezoneToShort(timezone)}</Cell>
              <Cell>{supervisorName || "-"}</Cell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};
