import React, { FC } from "react";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import { CustomCheckBox } from "src/pages/ClientDetails/ClientPortalTiles/SingleTile";
import { getUserData } from "src/redux/selectors/user";
import styled from "styled-components";
import { CommentRegEx } from ".";
import { DescriptionContainer } from "..";
import { Worklog } from "../../../types";
import { parseDateToFormat } from "../../../utils/time";

type CommentsSectionProps = {
  comments: Worklog[];
  $isMobile: boolean;
};

const getTextToDisplay = (detailsText: string, summaryText: string) => {
  if (summaryText && detailsText) {
    return `${summaryText}: ${detailsText}`;
  }
  if (detailsText) {
    return detailsText;
  }
  if (summaryText) {
    return summaryText;
  }
  return "";
};

const CommentsSection: FC<CommentsSectionProps> = ({ comments, $isMobile }) => {
  const { isManager } = useSelector(getUserData);
  return (
    <CommentsContainer>
      <>
        {comments.map((worklog: Worklog) => {
          const detailsText = (worklog?.description_longdescription || "").replace(CommentRegEx, "");
          const summaryText = worklog.description || "";
          const textToDisplay = getTextToDisplay(detailsText, summaryText);

          return textToDisplay ? (
            <DescriptionContainer $isComments key={worklog._rowstamp} $isMobile={$isMobile}>
              <FlexContainer>
                <WorkLogType>{worklog.logtype_description}</WorkLogType>
                <p>{parseDateToFormat(worklog.createdate, "MMMM Do YYYY, h:mm A")}</p>
              </FlexContainer>

              <ReactQuill readOnly theme="snow" value={textToDisplay} />
              {isManager && !worklog.clientviewable && (
                <CheckBoxWrapper>
                  <Form.Check
                    disabled
                    defaultChecked={true}
                    className="custom-checkbox"
                    type="checkbox"
                    label="Hide comment from clients"
                  />
                </CheckBoxWrapper>
              )}
            </DescriptionContainer>
          ) : null;
        })}
      </>
    </CommentsContainer>
  );
};

const CommentsContainer = styled.div`
  label {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #555555;
  }

  p {
    font-size: 14px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const WorkLogType = styled.div`
  padding-right: 2rem;
  font-size: 14px;
`;

const CheckBoxWrapper = styled(CustomCheckBox)`
  & > div {
    align-items: center;
    > label {
      width: 100%;
      margin-bottom: 0;
    }
  }
`;

export default CommentsSection;
