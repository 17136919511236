import { colors } from "src/styles";
import styled from "styled-components";

interface InputRowProps {
  $inputWidth?: string;
}

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0 30px 0;
`;

export const Content = styled.div`
  background-color: ${colors.white.default};
  padding: 10px 0 30px 0;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: 325px;
  width: 100%;
  position: relative;
  margin-right: 20px;

  div {
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    width: 120px;
    color: ${colors.grey.dark};
    white-space: nowrap;
    margin-right: 20px;
  }

  input {
    width: ${(props) => props.$inputWidth || "175"}px;
  }

  a {
    color: ${colors.grey.dark};
  }
`;
