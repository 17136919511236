import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles";
import Accordion from "react-bootstrap/Accordion";
import { DBPayPeriod } from "../../../types";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { generatePayPeriodIdUrlParam, generatePayPeriodName } from "./utils";
import { sortBy } from "../../../utils";

interface PayPeriodTypeTabProps {
  payPeriods: {
    [key: string]: DBPayPeriod[];
  };
}

export const PayPeriodTypeTab = ({ payPeriods }: PayPeriodTypeTabProps) => {
  const payPeriodsArray = sortBy(
    Object.entries(payPeriods).map(([year, yearSpecificPayPeriods]) => ({
      year,
      payPeriods: yearSpecificPayPeriods,
    })),
    "year",
    false,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const currentYear = moment().year().toString();

  return (
    <Wrapper>
      <Accordion defaultActiveKey={currentYear} alwaysOpen>
        {payPeriodsArray.map(({ year, payPeriods }) => (
          <Accordion.Item key={year} eventKey={year}>
            <StyledAccordionButton>{year}</StyledAccordionButton>
            <Accordion.Body>
              {payPeriods.map((payPeriod: DBPayPeriod) => {
                const payPeriodId = generatePayPeriodIdUrlParam(payPeriod);
                const payPeriodName = generatePayPeriodName(payPeriod);

                return (
                  <PayPeriodContainer onClick={() => navigate(`${location.pathname}/${payPeriodId}`)} key={payPeriodId}>
                    {payPeriodName}
                  </PayPeriodContainer>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .accordion-button {
    background-color: unset;
    border-color: ${colors.grey.light4};
    box-shadow: 0 0 0 0.5px ${colors.grey.light4};
    color: ${colors.grey.dark};
    font-weight: 500;
  }

  .accordion-button:not(.collapsed) {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

const StyledAccordionButton = styled(Accordion.Button)`
  .accordion-button {
    border: 10px;
  }

  :not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }
`;

const PayPeriodContainer = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: ${colors.grey.dark};
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
`;
