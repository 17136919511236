import React, { ChangeEvent, Dispatch, FC, FormEvent, MouseEvent, SetStateAction, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import styled from "styled-components";

import { useSelector } from "react-redux";
import Button from "src/components/Button";
import { CustomCheckBox } from "src/pages/ClientDetails/ClientPortalTiles/SingleTile";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ID } from "src/types";

type AddCommentSectionProps = {
  workOrderId: ID;
  setAddCommentSuccess: Dispatch<SetStateAction<boolean>>;
};
const AddCommentSection: FC<AddCommentSectionProps> = ({ workOrderId, setAddCommentSuccess }) => {
  const { isManager } = useSelector(getUserData);
  const [commentText, setCommentText] = useState<string>("");
  const [showButtonGroup, setShowButtonGroup] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [clientViewable, setClientViewable] = useState(false);

  const checkBoxHandler = () => {
    setClientViewable((prev) => !prev);
  };

  const commentTextChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  };

  const resetButtonClickHandler = (e: MouseEvent<HTMLElement>) => {
    setShowButtonGroup(false);
    setCommentText("");
    setErrorMessage("");
    e.stopPropagation();
  };

  const commentSubmitHadnler = async (e: FormEvent<HTMLFormElement>) => {
    try {
      setErrorMessage("");
      e.preventDefault();
      setLoading(true);
      const data = await FirebaseDataService.createWorklogComment(
        "",
        commentText,
        workOrderId,
        isManager,
        !clientViewable,
      );
      setLoading(false);
      if (data.status === "success") {
        setCommentText("");
        return setAddCommentSuccess(true);
      }

      if (data.status === "error") {
        throw new Error(data.message);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  return (
    <CustomForm onSubmit={commentSubmitHadnler}>
      <Form.Control
        disabled={loading}
        as="textarea"
        rows={3}
        value={commentText}
        onChange={commentTextChangeHandler}
        onClick={(e: MouseEvent<HTMLElement>) => {
          setShowButtonGroup(true);
          e.stopPropagation();
        }}
        placeholder="Write a comment..."
      />
      {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
      {showButtonGroup && (
        <ButtonContainer>
          <Button
            disabled={loading || !commentText}
            text="Save"
            onClick={(e: MouseEvent<HTMLElement>) => {
              setShowButtonGroup(true);
              e.stopPropagation();
            }}
          />
          <Button disabled={loading} text="Cancel" variant="secondary" type="reset" onClick={resetButtonClickHandler} />
          {isManager && (
            <CheckBoxWrapper>
              <Form.Check
                defaultChecked={clientViewable}
                className="custom-checkbox"
                type="checkbox"
                label="Hide comment from clients"
                onChange={checkBoxHandler}
              />
            </CheckBoxWrapper>
          )}
        </ButtonContainer>
      )}
    </CustomForm>
  );
};

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;

  & > *:first-child {
    margin-right: 20px;
  }
`;

const CustomForm = styled(Form)`
  margin-bottom: 20px;
`;

const CheckBoxWrapper = styled(CustomCheckBox)`
  padding-left: 15px;
  display: flex;
  align-items: center;
`;
export default AddCommentSection;
