import React, { useState } from "react";

import VirtualizedList from "src/components/molecules/VirtualizedList";
import { Adjustment, AdjustmentReason, EmployeeAdjustment } from "../../../../../types";
import EmployeeAdjustmentManager from "./component/EmployeeAdjustmentManager";
import SummaryListItem from "./component/SummaryListItem";
import { EmployeesTableHeaderCell, SummaryEmployeesTableHeader, Wrapper } from "./styles";
import { PayPeriodSummaryTabProps } from "./types";

export const PayPeriodSummaryTab = ({
  employeesPayrollData,
  payPeriod,
  adjustmentReasons,
  setEmployeesPayrollData,
}: PayPeriodSummaryTabProps) => {
  const [isAdjustmentModalVisible, setIsAdjustmentModalVisible] = useState<boolean>(false);
  const [employeeAdjustment, setEmployeeAdjustment] = useState<EmployeeAdjustment>();
  const summaryListItem = employeesPayrollData.filter((employee) => !employee.roles?.vendor);

  const initEmployeeAdjustment = (employeeId: string, adjustment: Adjustment) => {
    setEmployeeAdjustment({
      employeeId,
      adjustment: {
        adjustmentAmount: adjustment?.adjustmentAmount ?? "0",
        adjustmentReasonCode: adjustment?.adjustmentReason?.code ?? "",
        adjustmentComment: adjustment?.adjustmentComment ?? "",
      },
    });
    setIsAdjustmentModalVisible(true);
  };

  const prepareAdjustmentReasons = () =>
    adjustmentReasons.map((reason: AdjustmentReason) => ({
      id: reason.code,
      value: reason.label,
      priority: reason?.priority,
    }));

  return (
    <Wrapper>
      {isAdjustmentModalVisible && employeeAdjustment?.employeeId && (
        <EmployeeAdjustmentManager
          payPeriod={payPeriod}
          reasons={prepareAdjustmentReasons()}
          employeeAdjustment={employeeAdjustment}
          employeesPayrollData={employeesPayrollData}
          setEmployeesPayrollData={setEmployeesPayrollData}
          closeModal={() => {
            setIsAdjustmentModalVisible(false);
          }}
        />
      )}
      <SummaryEmployeesTableHeader>
        <EmployeesTableHeaderCell>Employee ID</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>Employee Name</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>Scheduled</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>Regular</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>Overtime</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>STAT</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>STAT Worked</EmployeesTableHeaderCell>
        <EmployeesTableHeaderCell>Adjustment</EmployeesTableHeaderCell>
      </SummaryEmployeesTableHeader>
      <VirtualizedList
        rowHeight={50}
        rowCount={summaryListItem.length}
        rowRenderer={(listRowProps) => (
          <SummaryListItem
            onAdjustmentClick={initEmployeeAdjustment}
            listRowProps={listRowProps}
            list={summaryListItem}
          />
        )}
      />
    </Wrapper>
  );
};
