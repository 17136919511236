import React, { useMemo } from "react";
import { DropdownItem } from "src/components/Dropdown/types";
import SelectSearch from "src/components/SelectSearch";
import { InputRow } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { WorkRequestClientLocationInputProps } from "../types";

const WorkRequestClientLocationInput = ({
  isVerifyMode,
  locations,
  setFormData,
  clientId,
  setClientId,
  clients,
  isManager,
  defaultLocation,
}: WorkRequestClientLocationInputProps) => {
  const { isMobile } = useIsMobile();
  const dropdownLocations = useMemo(() => {
    const newLocations = locations.map((location) => {
      return {
        id: location.id,
        value: location.label || location.address,
        timezone: location?.timezone,
        clientId: location.clientId,
      };
    });

    return isManager ? newLocations.filter((item) => item.clientId === clientId) : newLocations;
  }, [locations, clientId]);
  return (
    <>
      <InputRow $isMobile={isMobile} $isSelectSearch={true}>
        <label>Client:</label>
        <SelectSearch
          defaultValue={clientId}
          placeholder={"Select Client"}
          $isMobile={isMobile}
          disabled={isVerifyMode}
          options={
            clients.length ? (clients as unknown as DropdownItem[]) : [{ id: "0", value: "No Clients assigned." }]
          }
          onChange={(item: DropdownItem) => {
            setClientId(item.id.toString());
          }}
          bottomResultsGap={43}
        />
      </InputRow>
      <InputRow $isMobile={isMobile} $isSelectSearch={true}>
        <label>Location: </label>
        <SelectSearch
          defaultValue={defaultLocation}
          placeholder={"Select Location"}
          disabled={isVerifyMode || !clientId}
          $isMobile={isMobile}
          options={dropdownLocations as DropdownItem[]}
          onChange={(item: DropdownItem) => {
            setFormData((data) => ({ ...data, location: String(item.id) }));
          }}
          bottomResultsGap={43}
        />
      </InputRow>
    </>
  );
};

export default WorkRequestClientLocationInput;
