import React from "react";

import VendorLogo from "../Logo";
import { LogoWrapper, VendorHeading, VendorInfo, VendorSubtitle, Wrapper } from "../styles";
import { VendorProps } from "../types";

export const Vendor = ({ vendor }: VendorProps) => {
  const { logo, address, name, id } = vendor;
  const SIZE = 0.85;
  const flexSize: number = logo ? 1 : SIZE;

  return (
    <Wrapper>
      {logo && (
        <LogoWrapper>
          <VendorLogo logoFileName={logo} />
        </LogoWrapper>
      )}

      <VendorInfo flexSize={flexSize}>
        <VendorHeading>{`${id} - ${name}`}</VendorHeading>
        <VendorSubtitle>
          <p>{address}</p>
        </VendorSubtitle>
      </VendorInfo>
    </Wrapper>
  );
};
