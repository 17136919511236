import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import BreadCrumbs from "src/components/BreadCrumbs";
import Paper from "src/components/Paper";
import { Tile } from "src/components/Tile";
import { getFileIconAsString } from "src/helpers/getErrorMsg";
import { setPageNameHeader } from "src/redux/actions/header";
import { getUserData } from "src/redux/selectors/user";
import { openAuthenticatedWindow } from "src/services/Firebase/auth";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { DBClientPortalTile, IBreadCrumb, ID, IOmegaItem } from "src/types";
import { testGroup } from "src/utils/helpers";
import { debugError, debugLog } from "src/utils/log";

const FileSharing: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const dispatch = useDispatch();

  const currentFolder = location.state?.folderId;
  const folderStack = location.state?.folderStack;
  const parentTile = location.state?.parentTile;

  const { clientId = "", isManager, email, isVendor } = useSelector(getUserData);

  const isTestGroup = testGroup.includes(email ?? "");

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [tiles, setTiles] = useState<DBClientPortalTile[]>([]);
  const [folderContent, setFolderContent] = useState<IOmegaItem[]>([]);
  const [rootFolderName, setRootFolderName] = useState<string>("");

  const getData = async () => {
    setTiles([]);
    setFolderContent([]);
    setLoading(true);
    try {
      if (currentFolder) {
        const {
          data: { children },
        } = await FirebaseDataService.getFolderContent(currentFolder);

        return setFolderContent(children || []);
      }

      const { data } = await FirebaseDataService.getHomepageTiles(clientId, isManager, isVendor);
      const { data: rootFolderConfig } = await FirebaseDataService.getRootFolderConfig();
      setRootFolderName(rootFolderConfig?.folderName || "");
      setTiles(data || []);
    } catch (error) {
      debugError(error);
    } finally {
      setLoading(false);
    }
  };
  const onClickTile = (tile: DBClientPortalTile) => {
    if (tile.type === "folder") {
      if (!tile.folderId) {
        return debugLog("Folder tile without FolderId");
      }

      navigate("/", {
        state: {
          folderId: tile.folderId,
          folderStack: [
            { id: null, name: "Home" },
            { id: tile.folderId, name: tile.title || tile.folder },
          ],
          parentTile: tile.title,
        },
      });
    }

    if (tile.type === "url") {
      const el = document.createElement("A") as HTMLAnchorElement;
      el.href = tile.url;
      if (el.hostname == window.location.hostname) {
        navigate(tile.url);
      } else {
        openAuthenticatedWindow(tile.url);
      }
    }
  };

  /**
   * Create a full file path in Omega based on the given file name
   *
   * @param fileName - file name to create the full path for
   */
  const createFilePath = (fileName: string) => {
    const pathParts: string[] = folderStack.slice(1).map((currentPart: IOmegaItem) => currentPart.name);

    //set root folder Name. For clients it's client ID and for managers it's a constant KORi Managers
    pathParts.unshift(isManager ? rootFolderName : clientId);
    pathParts.push(fileName);

    return pathParts.join("/");
  };

  const onFileClick = async (el: IOmegaItem) => {
    if (el.type === "file") {
      setLoadingFile(true);

      await openAuthenticatedWindow(`/files/${createFilePath(el.name)}`);

      return setLoadingFile(false);
    }

    if (!el.id) {
      return debugLog("Folder tile without FolderId");
    }

    navigate("/", {
      state: {
        folderId: el.id,
        folderStack: [...folderStack, { id: el.id, name: el.name }],
        parentTile,
      },
    });
  };

  const onClickBreadCrumb = (id: ID | null) => {
    if (currentFolder === id) {
      return;
    }

    !id && setFolderContent([]);

    const currentIndex = folderStack.findIndex((item: IBreadCrumb) => item.id === id);
    const newFolderStack = folderStack.slice(0, currentIndex ? currentIndex + 1 : 0);

    navigate("/", {
      state: {
        folderId: id,
        folderStack: newFolderStack,
        parentTile: currentIndex ? parentTile : "",
      },
    });
  };

  useEffect(() => {
    getData();
  }, [currentFolder]);

  useEffect(() => {
    dispatch(setPageNameHeader(`${isTestGroup ? "Files" : "Home"}`));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  return (
    <Container>
      {(loadingFile || loading) && (
        <EmptyContainer style={loading ? {} : { backgroundColor: "rgba(0, 0, 0, 0.1)", zIndex: 0 }}>
          <Spinner animation="border" />
        </EmptyContainer>
      )}

      {!!folderStack?.length && (
        <>
          <FolderTitle>{parentTile}</FolderTitle>

          <BreadCrumbs folderStack={folderStack} onClickItem={(id) => onClickBreadCrumb(id)} />
        </>
      )}

      {currentFolder && !folderContent.length && !loading && (
        <EmptyContainer>
          <Paper
            containerStyle={{
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: colors.yellow.light,
              border: `1px solid ${colors.yellow.default}`,
            }}
          >
            <Title>This folder is empty.</Title>
          </Paper>
        </EmptyContainer>
      )}
      <Content>
        {currentFolder
          ? folderContent.map((el) => (
              <Tile
                key={el.id}
                title={el.name || ""}
                icon={el.type == "file" ? getFileIconAsString(el.name) : "faFolder"}
                onClick={() => onFileClick(el)}
              />
            ))
          : tiles.map((tile) =>
              tile.enabled !== false ? (
                <Tile
                  key={tile.id}
                  title={tile.title || tile.folder || ""}
                  icon={tile.icon}
                  onClick={() => onClickTile(tile)}
                />
              ) : null,
            )}
      </Content>
    </Container>
  );
};

//#region styles
const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const EmptyContainer = styled.div`
  position: absolute;
  width: 100%;
  min-height: 20%;
  top: 200px;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: baseline;
  flex-wrap: wrap;
  padding: 30px;
  height: inherit;

  a {
    margin-bottom: 30px;
    margin-right: 20px;
  }
`;

const Title = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const FolderTitle = styled.label`
  font-weight: 500;
  font-size: 16px;
  padding-left: 30px;
  padding-top: 30px;
  color: ${colors.grey.dark};
`;

//#endregion

export default FileSharing;
