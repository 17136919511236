// Breadcrumbs Constants
export const HOME = "Home";
export const WORK_REQUESTS = "Work Requests";

// Table Header Constants
export const NO = "No.";
export const DATE_CREATED = "Date Created";
export const LOCATION = "Location";
export const WORK_REQUEST_NAME = "Work Request Name";
export const SERVICE_TYPE = "Service Type";
export const CLASSIFICATION = "Classification";
export const WORK_TYPE = "Work type";
export const PRIORITY_LEVEL = "Priority Level";
export const STATUS = "Status";
export const CLIENT = "Client";
export const RESOURCE = "Resource";
export const APPROVED_AMOUNT = "Approved Amount";
export const ACTIONS = "Actions";

// Breadcrumbs
export const WORKREQUESTS_BREADCRUMBS = [
  { id: "/", name: HOME },
  { id: "/workrequests", name: WORK_REQUESTS },
];

// Table Headers
export const tableHeaders = [
  NO,
  DATE_CREATED,
  LOCATION,
  WORK_REQUEST_NAME,
  SERVICE_TYPE,
  CLASSIFICATION,
  WORK_TYPE,
  PRIORITY_LEVEL,
  STATUS,
];

export const managerTableHeaders = [
  NO,
  DATE_CREATED,
  CLIENT,
  LOCATION,
  WORK_REQUEST_NAME,
  SERVICE_TYPE,
  PRIORITY_LEVEL,
  STATUS,
  RESOURCE,
];

export const vendorTableHeaders = [
  NO,
  DATE_CREATED,
  CLIENT,
  LOCATION,
  WORK_REQUEST_NAME,
  SERVICE_TYPE,
  PRIORITY_LEVEL,
  APPROVED_AMOUNT,
  ACTIONS,
];
