import React from "react";
import { ContactTableContainer, ContactWrapper } from "../../styles";
import { ContactsListProps, DBVendorContact } from "../../types";
import { Contact } from "../Contact";

export const ContactsList = ({ data }: ContactsListProps) => {
  return (
    <>
      {data.map((contact: DBVendorContact) => (
        <ContactTableContainer key={contact.id}>
          <ContactWrapper>
            <Contact contact={contact} />
          </ContactWrapper>
        </ContactTableContainer>
      ))}
    </>
  );
};
