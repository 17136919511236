import React from "react";
import Input from "src/components/Input";
import { useIsMobile } from "src/hooks/useIsMobile";
import { InputRow } from "../styles";
import { WorkRequestNameInputProps } from "../types";

const WorkRequestNameInput = ({ isVerifyMode, setFormData, ticketName }: WorkRequestNameInputProps) => {
  const { isMobile } = useIsMobile();
  return (
    <InputRow $isMobile={isMobile}>
      <label>Work Request Name:</label>
      <div>
        <Input
          maxLength={100}
          placeholder="Enter Work Request Name"
          onChange={(e) => setFormData((data) => ({ ...data, ticketName: e.target.value }))}
          value={ticketName}
          disabled={isVerifyMode}
        />
      </div>
    </InputRow>
  );
};

export default WorkRequestNameInput;
