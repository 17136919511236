import { faDownload } from "@fortawesome/pro-light-svg-icons";
import React, { useContext, useState } from "react";
import Button from "src/components/Button";
import { useGetExcel } from "src/hooks/useGetExcel";
import ClientDetailsContext from "src/pages/ClientDetails/context";
import { FirebaseDataService } from "src/services/Firebase/data";
import { determineClientLocation, generatePayPeriodId, getPayPeriodDataFromId } from "../../../utils";
import { ExportErrorMesssage, StyledActionsWrapper, Wrapper } from "../styles";
import { PayPeriodActionButtonsProps } from "../types";
import { EmployeesApprovedHoursFormProps } from "src/pages/ClientDetails/ClientDetailsProvider";

const PayPeriodActionButtons = ({
  id,
  payPeriodId,
  client,
  payPeriodName,
  payPeriod,
  excelType,
  baseURL,
  onSuccessHandler,
  onErrorHandler,
}: PayPeriodActionButtonsProps) => {
  const [isFormSaving, setIsFormSaving] = useState(false);
  const { excelHandler, loading, errorMessage } = useGetExcel({
    baseURL,
    id,
    payPeriod,
    payPeriodName,
    excelType,
    clientName: client.name,
  });
  const { employeesApprovedHoursForm, setLocations } = useContext(ClientDetailsContext);

  const onFormSubmitHandler = async ({ onSuccess }: { onSuccess: () => void }) => {
    setIsFormSaving(true);

    try {
      if (id && payPeriodId) {
        const payPeriodIdParam = generatePayPeriodIdFromIdAndClientLocation(
          await determineClientLocation(client, id, setLocations),
        );
        await updateEmployeeApprovedHoursHandler(payPeriodIdParam, onSuccess);
      }
    } finally {
      setIsFormSaving(false);
    }
  };

  const updateEmployeeApprovedHoursHandler = async (payPeriodIdParam: string, onSuccess: () => void) => {
    try {
      const { status, message } = await updateEmployeeApprovedHours(payPeriodIdParam);

      if (status === "success") {
        onSuccess();
      }
      if (status === "error") {
        onErrorHandler(message);
      }
    } catch (err) {
      onErrorHandler(err);
    }
  };

  const generatePayPeriodIdFromIdAndClientLocation = (clientLocation: string) => {
    const { year, type, number } = getPayPeriodDataFromId(payPeriodId);
    return generatePayPeriodId({
      year: year.toString(),
      payPeriodTypeCode: type,
      number: number.toString(),
      countryCode: clientLocation,
    });
  };

  const updateEmployeeApprovedHours = async (payPeriodIdParam: string) => {
    const filteredData = Object.fromEntries(
      Object.entries(employeesApprovedHoursForm)
        .map(([key, value]) => [
          key,
          Object.fromEntries(Object.entries(value).filter(([, innerValue]) => innerValue.updated === true)),
        ])
        .filter(([, innerValue]) => Object.keys(innerValue).length > 0),
    ) as EmployeesApprovedHoursFormProps;

    return FirebaseDataService.updateEmployeeApprovedHours(filteredData, id, payPeriodIdParam);
  };

  return (
    <Wrapper>
      <ExportErrorMesssage>
        {errorMessage && `An error occurred while downloading: ${errorMessage}`}
      </ExportErrorMesssage>
      <StyledActionsWrapper>
        <Button
          disabled={loading}
          text={"Export to Excel"}
          variant={"dotted-border"}
          icon={faDownload}
          iconPosition={"left"}
          onClick={() => excelHandler()}
          additionalStyles={{ marginRight: 15, height: 40 }}
        />
        <Button
          text="Save Changes"
          onClick={async () => {
            await onFormSubmitHandler({ onSuccess: () => onSuccessHandler() });
          }}
          disabled={isFormSaving}
          loading={isFormSaving}
          variant="middle-primary"
          additionalStyles={{
            padding: "12px 24px",
            width: 146,
            height: 46,
          }}
        />
      </StyledActionsWrapper>
    </Wrapper>
  );
};

export default PayPeriodActionButtons;
