import React from "react";
import { faEllipsisH } from "@fortawesome/pro-light-svg-icons";
import { DotsIcon, PaginationItem } from "../styles";

interface PaginationItemsProps {
  paginationRange: (string | number)[];
  currentPage: number;
  onPageChange: (page: number) => void;
  isMobile: boolean;
}

export const PaginationItems = ({ paginationRange, currentPage, onPageChange, isMobile }: PaginationItemsProps) => {
  return (
    <>
      {paginationRange?.map((pageNumber) => {
        if (pageNumber === "...") {
          return <DotsIcon key={pageNumber} icon={faEllipsisH} />;
        }

        return (
          <PaginationItem
            $isMobile={isMobile}
            key={+pageNumber}
            selected={pageNumber === currentPage}
            onClick={() => onPageChange(+pageNumber)}
          >
            {pageNumber}
          </PaginationItem>
        );
      })}
    </>
  );
};
