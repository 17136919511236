import { UseFormProps } from "react-hook-form";
import { DynamicFormConfig } from "src/components/DynamicFormContainer/types";
import { FirebaseDataService } from "src/services/Firebase/data";
import { Client, DBLocation } from "src/types";

export const fetchFormConfig = async (
  setFormConfig: React.Dispatch<React.SetStateAction<DynamicFormConfig | undefined>>,
  setClientsArray: React.Dispatch<React.SetStateAction<Client[]>>,
  setLocationsArray: React.Dispatch<React.SetStateAction<DBLocation[]>>,
  setDefaultValues: React.Dispatch<React.SetStateAction<UseFormProps["defaultValues"]>>,
  id: string | undefined,
  managerId: string,
) => {
  if (!id) {
    return;
  }
  const res = await FirebaseDataService.getDynamicFormData(id, managerId);

  if (res.data) {
    const { clientsArray, defaultValues, formConfig, locationsArray } = res.data;
    setFormConfig(formConfig);
    setClientsArray(clientsArray ?? []);
    setLocationsArray(locationsArray ?? []);
    setDefaultValues(defaultValues ?? {});
  }
};
