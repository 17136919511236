export const EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const POSITION_LIMITS = {
  latitude: {
    min: -90,
    max: 90,
  },
  longitude: {
    min: -180,
    max: 180,
  },
};

export const VALIDATION_MESSAGES = {
  required: "Field cannot be empty.",
  invalidEmail: "Please, enter correct email.",
  invalidPhone: "Please, enter correct phone.",
  invalidLatitude: "Please, enter correct latitude.",
  invalidLongitude: "Please, enter correct longitude.",
  existFolderName: "A folder exists with the same name as this folder",
  invalidURL: "Please, enter correct URL.",
  invalidIcon: "Invalid Icon Code.",
};
export const enum PAY_TYPE {
  salary = "Salary",
  hourly = "Hourly",
}

export const ALL_FILE_TYPE = ".jpg, .jpeg, .png, .heic, .pdf, .docx, .xlsx";

export const ALL_IMAGE_TYPE = ".jpg, .jpeg, .png, .heic";
