import { ButtonCheckboxGroupProps } from "./types";

export const onChangeHandler = (
  event: React.ChangeEvent<HTMLInputElement>,
  optionValue: string,
  onChange: ButtonCheckboxGroupProps["onChange"],
  value: ButtonCheckboxGroupProps["value"] = [],
) => {
  const newValue = [...value];

  const valueIndex = value.indexOf(optionValue);

  if (event.target.checked && valueIndex == -1) {
    newValue.push(optionValue);
  }

  if (!event.target.checked && valueIndex !== -1) {
    newValue.splice(valueIndex, 1);
  }

  onChange?.(newValue);
};
