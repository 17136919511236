import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import { getResourseData } from "../../WorkRequestListViewContainer/handler";

import { ResourseAssignedProps } from "../types";
import { ResourceAssignedContainer, ResourseWrapper, UnAssignedIcon, Mark } from "../styles";

const ResourseAssigned = ({
  employees,
  vendors,
  laborCode,
  vendorId,
  onClick,
  highlight,
  isTableCell,
}: ResourseAssignedProps) => {
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = getResourseData(vendorId, laborCode, vendors, employees)?.split(regex);
  return (
    <ResourceAssignedContainer>
      <ResourseWrapper
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        {!(vendorId || laborCode) ? (
          <>
            <UnAssignedIcon color="orange" size={"lg"} icon={faTriangleExclamation} />
            <span>Unassigned</span>
          </>
        ) : (
          <span>
            {parts?.filter(String).map((part: string, index: number) => {
              if (isTableCell && highlight) {
                return regex.test(part) ? <Mark key={index}>{part}</Mark> : <span key={part}>{part}</span>;
              }
              return <span key={index}>{part}</span>;
            })}
          </span>
        )}
      </ResourseWrapper>
    </ResourceAssignedContainer>
  );
};

export default ResourseAssigned;
