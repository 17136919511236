import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "src/components/atoms/DatePicker";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { Required } from "src/components/atoms/Required";
import { DatePickerWrapper, StyledLabel } from "../styles";
import { ShiftTimeProps } from "../types";

export const ShiftTime = ({ name, value, variant }: ShiftTimeProps) => {
  const { control } = useFormContext();

  return (
    <>
      <StyledLabel paddingRight={40}>
        {`Shift ${variant}:`} <Required />
      </StyledLabel>
      <DatePickerWrapper>
        <Controller
          control={control}
          name={name}
          defaultValue={value}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <DatePicker
              variant={DATEPICKER_VARIANT.TIME}
              onChange={onChange}
              value={value}
              name={name}
              error={error}
              ref={ref}
            />
          )}
        />
      </DatePickerWrapper>
    </>
  );
};
