import { createReducer } from "@reduxjs/toolkit";
import { ActiveResouces } from "src/types";
import { setActiveResources } from "../actions/resources";

export const initialState: ActiveResouces = {
  employees: [],
  vendors: [],
};

export const activeResourceReducer = createReducer(initialState, (builder) => {
  builder.addCase(setActiveResources, (state, action) => ({
    ...state,
    employees: action.payload.employees,
    vendors: action.payload.vendors,
  }));
});
