import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { roundTo2Decimals } from "src/utils/helpers";
import {
  ContractDetailsContainer,
  ContractDetailsRow,
  ContractIdContainer,
  ContractListContainer,
  ListHeading,
  ListSubtitle,
  Wrapper,
} from "../../styles";
import { ContractProps } from "../../types";

export const Contract = ({ contract, expandedContract, setExpandedContract }: ContractProps) => {
  const { contractnum, description, startdate, enddate, renewaldate, totalcost, contractline } = contract;
  const isExpanded = expandedContract?.contractnum === contractnum;

  return (
    <ContractListContainer>
      <Wrapper onClick={() => setExpandedContract(undefined)} style={{ cursor: "pointer" }}>
        <ContractIdContainer>
          <FontAwesomeIcon size={"1x"} style={{ marginRight: 10 }} icon={isExpanded ? faAngleDown : faAngleRight} />
          <ListHeading>{`${contractnum} - ${description ?? ""}`}</ListHeading>
        </ContractIdContainer>
        <ListSubtitle>
          <p>Start Date: {startdate}</p>
        </ListSubtitle>
        <ListSubtitle>
          <p>End Date: {enddate}</p>
        </ListSubtitle>
        <ListSubtitle>
          <p>Renewal Date: {renewaldate}</p>
        </ListSubtitle>
        <ListSubtitle>
          <p>Total Cost: {totalcost}</p>
        </ListSubtitle>
      </Wrapper>
      {isExpanded && (
        <ContractDetailsContainer>
          {contractline ? (
            contractline.map(({ description, linecost, contractLineId }) => (
              <ContractDetailsRow key={contractLineId}>
                <p>{description}</p>
                <p style={{ marginLeft: 20 }}>{roundTo2Decimals(linecost)}</p>
              </ContractDetailsRow>
            ))
          ) : (
            <ContractDetailsRow>No line items found</ContractDetailsRow>
          )}
        </ContractDetailsContainer>
      )}
    </ContractListContainer>
  );
};
