import { colors } from "src/styles";
import styled from "styled-components";

export const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 580px;
  width: 100%;
  position: relative;

  & div {
    width: 100%;
  }

  div {
    font-size: 14px;

    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
    padding-top: 10px;
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;
