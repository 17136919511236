import React from "react";
import { StyledContainer, StyledFormContainer } from "../styles";
import { PayPeriodFilterFormProps } from "../types";
import { PayPeriodFilterFormButtons } from "./PayPeriodFilterFormButtons";
import { PayPeriodFilterFormField } from "./PayPeriodFilterFormField";

export const PayPeriodFilterFormView = ({ onCancel }: PayPeriodFilterFormProps) => {
  return (
    <StyledFormContainer>
      <StyledContainer>
        <PayPeriodFilterFormField fieldName="fromDate" title="From" />
        <PayPeriodFilterFormField fieldName="toDate" title="To" additionalStyles={{ marginTop: 20 }} />
      </StyledContainer>
      <PayPeriodFilterFormButtons onCancel={onCancel} />
    </StyledFormContainer>
  );
};
