import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { ErrorMsg } from "../styles";
import { AssignWOResourceFormViewProps } from "../types";
import { AssignWOResourceButtons } from "./AssignWOResourceButtons";
import EmployeeWOResourceTab from "./EmployeeWOResourceTab";
import VendorWOResourceTab from "./VendorWOResourceTab";

const AssignWOResourceFormView = ({ employees, vendors, errorMsg, onCancel }: AssignWOResourceFormViewProps) => {
  const {
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();
  const defaultTab = watch("activetab");
  const [activeTab, setActiveTab] = useState(defaultTab);

  const onApplyHandler = async () => {
    const isFormValid = await trigger();

    if (!isFormValid) {
      // if the error(s) are on a tab(s) that is not currently active
      if (!errors[activeTab]) {
        // activate the first tab that has form errors on it
        setActiveTab(Object.keys(errors)[0]);
      }
    }
  };

  return (
    <>
      <HorizontalTabs
        tabs={[
          // commented out temporarily to hide the Employees tab. Will be reverted in the future
          {
            eventKey: "employees",
            label: "Employees",
            content: <EmployeeWOResourceTab employees={employees} />,
          },
          {
            eventKey: "vendors",
            label: "Vendors",
            content: <VendorWOResourceTab isActivetab={activeTab === "vendors"} vendors={vendors} />,
          },
        ]}
        activeTab={activeTab}
        setActiveTab={(tab) => {
          setActiveTab(tab);
          setValue("activetab", tab);
        }}
      />
      <ErrorMsg>{errorMsg}</ErrorMsg>
      <AssignWOResourceButtons onCancel={onCancel} onApply={onApplyHandler} />
    </>
  );
};

export default AssignWOResourceFormView;
