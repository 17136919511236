import React from "react";
import { Table } from "react-bootstrap";
import { roundTo2Decimals } from "src/utils/helpers";
import { PayRollTableCell, PayRollTableHeader } from "../styles";
import { EmployeeSalariedPayPeriodTableProps } from "../types";

export const EmployeeSalariedPayPeriodTable = ({ selectedEmployeePayroll }: EmployeeSalariedPayPeriodTableProps) => {
  return (
    <Table responsive style={{ width: "100%" }}>
      <thead>
        <tr>
          <PayRollTableHeader style={{ textAlign: "end" }}>Approved Salary</PayRollTableHeader>
        </tr>
      </thead>
      <tbody>
        <tr>
          <PayRollTableCell style={{ textAlign: "end" }}>
            ${roundTo2Decimals(selectedEmployeePayroll.salaryAmount)}
          </PayRollTableCell>
        </tr>
      </tbody>
    </Table>
  );
};
