import "react-datetime-picker/dist/DateTimePicker.css";

import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingContainer from "src/components/LoadingContainer";
import { MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { useModal } from "src/hooks/useModal";
import { setWorkRequestMessage } from "src/redux/actions/workRequest";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import {
  Client,
  DBLocation,
  IServiceTypeQuestion,
  MaximoDocument,
  PriorityLevel,
  PriorityLevelList,
  ServiceTypeItem,
  ServiceTypeList,
  WorkType,
} from "src/types";

import { useForm } from "react-hook-form";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import { setPageNameHeader } from "src/redux/actions/header";
import { CustomWorkRequestFieldList, FormData, TicketFormProps } from "./types";
import WorkRequestModals from "./components/WorkRequestModals";
import {
  clearWorkRequestMessageTimeout,
  fetchData,
  getButtonDisableCondition,
  getDropdownClassification,
  getServiceTypeQuestions,
  getTimeZone,
  onSubmitClientForm,
  onSubmitManagerForm,
  onSubmitNoAuthForm,
  uploadImages,
} from "./handler";
import ManagerCreateFormFields from "./components/ManagerCreateFormFields";
import NoAuthCreateFormFields from "./components/NoAuthCreateFormFields";
import ClientCreateFormFields from "./components/ClientCreateFormFields";

export const CreateForm = ({
  setStep,
  isNoAuthMode,
  noAuthData,
  setSuccessMessage,
  isClient,
  isManager,
}: TicketFormProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const form = useForm();

  const {
    id: clientUserId,
    firstName,
    lastName,
    phoneNumber,
    email,
    clientId: clientPortalId,
    maximoTimezone: maximoTimezoneDef,
  } = useSelector(getUserData);
  const [clientId, setClientId] = useState(clientPortalId || "");

  const maximoTimezone = maximoTimezoneDef || MAXIMO_TIME_ZONE;
  const defaultServiceType = location?.state?.serviceType;
  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [priorityLevels, setPriorityLevels] = useState<PriorityLevelList>();
  const [locations, setLocations] = useState<DBLocation[]>([]);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeList>();
  const [clients, setClients] = useState<Client[]>([]);
  const [serviceTypeId, setServiceTypeId] = useState(defaultServiceType?.id);
  const [serviceTypeQuestions, setServiceTypeQuestions] = useState<IServiceTypeQuestion[]>([]);
  const { isShowing, toggle } = useModal();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isVerifyMode, setIsVerifyMode] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    ticketName: "",
    description: "",
    serviceType: defaultServiceType?.description || "",
    classification: "",
    priorityName: "",
    priority: 0,
    workType: "",
    location: "",
    targetStart: new Date(),
    targetFinish: new Date(),
  });
  const [files, setFiles] = useState<MaximoDocument[]>([]);
  const [classificationId, setClassificationId] = useState("");
  const [timezone, setTimezone] = useState("");
  const [customWorkRequestFields, setCustomWorkRequestFields] = useState<CustomWorkRequestFieldList>();
  const [isResourceAssignedModalVisible, setIsResourceAssignedModalVisible] = useState(false);
  const [assignResourceValues, setAssignResourceValues] = useState<AssignWOResourceFormValues>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { targetFinish, targetStart, ...restFormData } = formData;

  const dropdownClassifications = useMemo(() => {
    return getDropdownClassification(
      !!isManager,
      serviceTypes,
      clientId,
      serviceTypeId,
      defaultServiceType,
      !!isNoAuthMode,
      noAuthData,
    );
  }, [defaultServiceType, serviceTypeId, clientId]);

  const buttonDisabledConditions = useMemo(() => getButtonDisableCondition(formData, serviceTypeQuestions), [formData]);

  const onSubmit = async (e: React.MouseEvent) => {
    setIsLoading(true);
    e.preventDefault();
    let workOrder;
    if (isManager) {
      workOrder = onSubmitManagerForm(
        serviceTypes,
        clientId,
        dropdownClassifications,
        classificationId,
        defaultServiceType?.name,
        firstName,
        lastName,
        phoneNumber,
        timezone,
        email,
        formData,
        maximoTimezone,
        serviceTypeQuestions,
        clients,
        form,
        serviceTypeId,
      );
    }
    if (isClient) {
      workOrder = onSubmitClientForm(
        serviceTypes,
        clientId,
        dropdownClassifications,
        classificationId,
        defaultServiceType?.name,
        timezone,
        formData,
        maximoTimezone,
        serviceTypeQuestions,
        clients,
        form,
        serviceTypeId,
      );
    }
    if (isNoAuthMode) {
      workOrder = onSubmitNoAuthForm(
        noAuthData?.serviceTypes?.serviceTypes as unknown as ServiceTypeItem[],
        clientId,
        dropdownClassifications,
        classificationId,
        defaultServiceType?.name,
        firstName,
        lastName,
        phoneNumber,
        email,
        timezone,
        formData,
        maximoTimezone,
        serviceTypeQuestions,
        clients,
        form,
        serviceTypeId,
      );
    }

    const { data, message } = await FirebaseDataService.createMaximoWorkOrder(
      workOrder,
      clientUserId,
      assignResourceValues,
    );
    if (data) {
      await uploadImages(String(data.workorderid), files);

      if (!isNoAuthMode) {
        dispatch(setWorkRequestMessage(data.message));

        navigate("/workrequests");
      } else {
        setStep?.((old: number) => old + 1);
        setSuccessMessage?.(data.message);
      }
    }

    if (!data) {
      setError(message);
    }

    setIsLoading(false);
    clearWorkRequestMessageTimeout(dispatch);
  };

  const onConfirmClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (await form.trigger()) {
      setIsVerifyMode(true);
    }
  };

  const onExitClick = () => {
    toggle();
  };

  useEffect(() => {
    fetchData(
      setIsFormLoading,
      !!isNoAuthMode,
      noAuthData,
      setWorkTypes,
      setPriorityLevels,
      setLocations,
      setCustomWorkRequestFields,
      id,
      clientUserId,
      !!isManager,
      setServiceTypes,
      setClients,
    );
  }, [id]);

  useEffect(() => {
    setTimezone(getTimeZone(clients, locations, location.pathname, maximoTimezone, clientId));
  }, [clientId, formData.location]);
  useEffect(() => {
    // set target start and taget finish for create new ticket
    if (isVerifyMode) {
      const date = moment(new Date());
      const tZone = getTimeZone(clients, locations, location.pathname, maximoTimezone, clientId);
      if (tZone) {
        date.tz(tZone);
      }

      const finishDate = moment(date).add(formData.hoursDelay, "hours");

      setFormData((data) => ({
        ...data,
        targetStart: date,
        targetFinish: finishDate,
      }));
    }
  }, [isVerifyMode]);

  useEffect(() => {
    serviceTypeId && getServiceTypeQuestions(clientId, serviceTypeId, setFormData, setServiceTypeQuestions, setError);
  }, [serviceTypeId]);

  useEffect(() => {
    if (!priorityLevels) return;
    const dataSource = (isManager ? priorityLevels[clientId] : priorityLevels) as PriorityLevel[];

    if (dataSource?.length === 1) {
      setFormData((data) => ({
        ...data,
        priorityName: dataSource[0].name,
        priority: dataSource[0].priority,
        hoursDelay: dataSource[0].hoursDelay,
      }));
    }
  }, [priorityLevels]);

  useEffect(() => {
    if (isManager) {
      setFormData((data) => ({
        ...data,
        location: "",
        serviceType: "",
        priority: 0,
        priorityName: "",
        classification: "",
      }));
      setServiceTypeId("");
      setServiceTypeQuestions([]);
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(setPageNameHeader("Create New Work Request"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  const getWorkRequestForm = () => {
    if (isManager) {
      return (
        <ManagerCreateFormFields
          clientId={clientId}
          clients={clients}
          customWorkRequestFields={customWorkRequestFields}
          files={files}
          form={form}
          formData={formData}
          isVerifyMode={isVerifyMode}
          locations={locations}
          maximoTimezone={maximoTimezone}
          onExitClick={onExitClick}
          serviceTypeId={serviceTypeId}
          serviceTypeQuestions={serviceTypeQuestions}
          serviceTypes={serviceTypes}
          setClassificationId={setClassificationId}
          setClientId={setClientId}
          setFiles={setFiles}
          setFormData={setFormData}
          setIsResourceAssignedModalVisible={setIsResourceAssignedModalVisible}
          setServiceTypeId={setServiceTypeId}
          targetFinish={targetFinish}
          targetStart={targetStart}
          timezone={timezone}
          workTypes={workTypes}
          assignResourceValues={assignResourceValues}
          priorityLevels={priorityLevels}
          error={error}
          buttonDisabledConditions={buttonDisabledConditions}
          isLoading={isLoading}
          onConfirmClick={onConfirmClick}
          onSubmit={onSubmit}
          setIsVerifyMode={setIsVerifyMode}
        />
      );
    }
    if (isNoAuthMode) {
      return (
        <NoAuthCreateFormFields
          clientId={clientId}
          files={files}
          form={form}
          formData={formData}
          isVerifyMode={isVerifyMode}
          locations={locations}
          maximoTimezone={maximoTimezone}
          serviceTypeId={serviceTypeId}
          serviceTypeQuestions={serviceTypeQuestions}
          setClassificationId={setClassificationId}
          setFiles={setFiles}
          setFormData={setFormData}
          setServiceTypeId={setServiceTypeId}
          targetFinish={targetFinish}
          targetStart={targetStart}
          timezone={timezone}
          workTypes={workTypes}
          priorityLevels={priorityLevels}
          error={error}
          buttonDisabledConditions={buttonDisabledConditions}
          isLoading={isLoading}
          onConfirmClick={onConfirmClick}
          onSubmit={onSubmit}
          setIsVerifyMode={setIsVerifyMode}
          customWorkRequestFields={customWorkRequestFields}
          serviceTypes={serviceTypes}
          setTimezone={setTimezone}
          noAuthData={noAuthData}
        />
      );
    }
    if (isClient) {
      return (
        <ClientCreateFormFields
          clientId={clientId}
          files={files}
          form={form}
          formData={formData}
          isVerifyMode={isVerifyMode}
          locations={locations}
          maximoTimezone={maximoTimezone}
          serviceTypeId={serviceTypeId}
          serviceTypeQuestions={serviceTypeQuestions}
          setClassificationId={setClassificationId}
          setFiles={setFiles}
          setFormData={setFormData}
          setServiceTypeId={setServiceTypeId}
          targetFinish={targetFinish}
          targetStart={targetStart}
          timezone={timezone}
          workTypes={workTypes}
          priorityLevels={priorityLevels}
          error={error}
          buttonDisabledConditions={buttonDisabledConditions}
          isLoading={isLoading}
          onConfirmClick={onConfirmClick}
          onSubmit={onSubmit}
          setIsVerifyMode={setIsVerifyMode}
          setTimezone={setTimezone}
          customWorkRequestFields={customWorkRequestFields}
          onExitClick={onExitClick}
          serviceTypes={serviceTypes}
        />
      );
    }
  };
  return (
    <LoadingContainer isLoading={isFormLoading}>
      <WorkRequestModals
        clientId={clientId}
        isResourceAssignedModalVisible={isResourceAssignedModalVisible}
        setAssignResourceValues={setAssignResourceValues}
        setIsResourceAssignedModalVisible={setIsResourceAssignedModalVisible}
        assignResourceValues={assignResourceValues}
        isShowing={isShowing}
        toggle={toggle}
      />

      {getWorkRequestForm()}
    </LoadingContainer>
  );
};
