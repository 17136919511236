import React, { useEffect, useState } from "react";
import FirebaseStorageService from "../../../services/Firebase/storage";
import { Logo, LogoContainer } from "../styles";

const VendorLogo = ({ logoFileName }: { logoFileName?: string }) => {
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    if (logoFileName) {
      getClientLogoUrl();
    }
  }, []);

  const getClientLogoUrl = async () => {
    try {
      const { data } = await FirebaseStorageService.getDownloadURL(
        process.env.REACT_APP_VENDOR_LOGO_FOLDER,
        logoFileName,
      );
      setLogoUrl(data);
    } catch (e) {
      console.log(e);
    }
  };

  if (logoFileName) {
    return (
      <LogoContainer>
        <Logo src={logoUrl} />
      </LogoContainer>
    );
  }
  return null;
};

export default VendorLogo;
