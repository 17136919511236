import React from "react";
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { Required } from "src/components/atoms/Required";

import { Label } from "../styles";
import { MessageLabelProps } from "../types";

export const MessageLabelInput = ({ name, value }: MessageLabelProps) => {
  const { control } = useFormContext();
  const onStopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const renderInput = ({
    field: { onChange, value: data, ref },
    fieldState: { error },
  }: {
    field: ControllerRenderProps;
    fieldState: ControllerFieldState;
  }) => (
    <Input
      value={data}
      onChange={onChange}
      placeholder="Enter Label"
      ref={ref}
      error={error}
      // makes it so the ContentBox accordion does not collapse/expand on click inside the field
      onClick={onStopPropagation}
    />
  );
  return (
    <>
      <Label>
        Label: <Required />
      </Label>
      <Controller control={control} name={name} defaultValue={value} rules={{ required: true }} render={renderInput} />
    </>
  );
};
