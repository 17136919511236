import moment from "moment";
import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "src/components/atoms/DatePicker";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { FormLabel } from "src/components/atoms/FormLabel";
import { PayPeriodFilterFieldProps } from "../types";

export const PayPeriodFilterFormField = ({ fieldName, title, additionalStyles }: PayPeriodFilterFieldProps) => {
  const { control } = useFormContext();

  return (
    <Stack style={additionalStyles} gap={2}>
      <FormLabel htmlFor={fieldName}>{title}</FormLabel>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: true }}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <DatePicker
            variant={DATEPICKER_VARIANT.DATE}
            error={error}
            ref={ref}
            name={name}
            onChange={onChange}
            value={value}
            maxDate={moment().toDate()}
          />
        )}
      />
    </Stack>
  );
};
