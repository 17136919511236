import { useFormContext } from "react-hook-form";
import { colors } from "src/styles";

/**
 * A helper hook to reduce copy-paste in theparent component. Returns a tab configuration object.
 */
export const useTab = (tabId: string, label: string, renderContent: (name: string) => JSX.Element) => {
  const {
    formState: { errors },
  } = useFormContext();

  return {
    eventKey: tabId,
    label: label,
    content: renderContent(tabId),
    tabColor: !errors?.[tabId] ? colors.kleenway.green : colors.red.default,
  };
};
