import React, { useState } from "react";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { useModal } from "src/hooks/useModal";
import MainStep from "src/pages/TicketForm/ClientApproval/MainStep";
import ModalClientApproval from "src/pages/TicketForm/ClientApproval/Modal";
import NoteStep from "src/pages/TicketForm/ClientApproval/NoteStep";
import { FirebaseDataService } from "src/services/Firebase/data";
import { MaximoDocument, WorkOrder } from "src/types";
import { debugError } from "src/utils/log";
import { ClientApprovalStatus, ClientApprovalStatuses, MobileProps } from "../types";

export interface WorkOrderProps {
  workOrder: WorkOrder;
  setError: any;
  setWorkOrder: any;
  setAddCommentSuccess: any;
  documents?: MaximoDocument[];
  clientApprovalStatuses: ClientApprovalStatuses;
}

export interface iNavigate {
  main: () => void;
  reject: () => void;
  approve: () => void;
}

const APPROVED_WO_WORKLOG_SUMMARY = "Work Request Approved";
const REJECTED_WO_WORKLOG_SUMMARY = "Work Request Rejected";

const ClientApprovalWindow = ({
  workOrder,
  documents,
  setError,
  setWorkOrder,
  setAddCommentSuccess,
  clientApprovalStatuses,
}: WorkOrderProps) => {
  const { isShowing, toggle } = useModal();
  const [isWOApproved, setIsWOApproved] = useState(false);
  const [approveWOLoading, setApproveWOLoading] = useState(false);
  const [rejectWOLoading, setRejectWOLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<"main" | "approve" | "reject">("main");
  const [clientNote, setClientNote] = useState("");

  const { isMobile } = useIsMobile();

  const changeWordOrderStatus = async (
    workOrderStatus: ClientApprovalStatus,
    setChangeWOStatusLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isWOApproved: boolean,
  ) => {
    try {
      if (workOrder?.wonum) {
        setChangeWOStatusLoading(true);

        const { status: createWorkLogStatus, message: createWorkLogMessage } =
          await FirebaseDataService.createWorklogComment(
            isWOApproved ? APPROVED_WO_WORKLOG_SUMMARY : REJECTED_WO_WORKLOG_SUMMARY,
            clientNote,
            workOrder.workorderid,
            false,
            true,
          );
        if (createWorkLogStatus === "error") {
          throw new Error(createWorkLogMessage);
        }
        setAddCommentSuccess(true);

        await FirebaseDataService.runWorkOrderAction(workOrder.workorderid.toString(), "wsmethod:changeWOStatus", {
          memo: "Status changed by client",
          date: new Date(),
          status: workOrderStatus.abbreviation,
        });

        toggle();
        setTimeout(() => {
          toggle();
          setWorkOrder((prevState: WorkOrder) => {
            return {
              ...prevState,
              status: workOrderStatus.abbreviation,
              status_description: workOrderStatus.status,
            };
          });
        }, 1000);
      }
    } catch (error) {
      debugError(error);
      setError(error.message);
    } finally {
      setChangeWOStatusLoading(false);
    }
  };

  const navigateToApproveStep = () => setCurrentStep("approve");
  const navigateToRejectStep = () => setCurrentStep("reject");
  const navigateToMainStep = () => setCurrentStep("main");

  const navigate: iNavigate = {
    main: navigateToMainStep,
    reject: navigateToRejectStep,
    approve: navigateToApproveStep,
  };

  const handleApproveClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsWOApproved(true);
    changeWordOrderStatus(clientApprovalStatuses.approved, setApproveWOLoading, true);
  };

  const handleRejectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsWOApproved(false);
    changeWordOrderStatus(clientApprovalStatuses.canceled, setRejectWOLoading, false);
  };

  return (
    <Container $isMobile={isMobile}>
      <ModalClientApproval isShowing={isShowing} isWOApproved={isWOApproved} />
      <h3>This Work Request requires approval</h3>
      {currentStep === "main" && (
        <MainStep navigate={navigate} documents={documents} workOrderId={workOrder.workorderid} />
      )}
      {currentStep === "approve" && (
        <NoteStep
          setNote={setClientNote}
          navigate={navigate}
          label="Approval Notes (optional)"
          inputFieldPlaceholder="Enter approval notes"
          submitButtonProps={{
            text: "Approve",
            variant: "primary",
            loading: approveWOLoading,
            onClick: handleApproveClick,
          }}
        />
      )}
      {currentStep === "reject" && (
        <NoteStep
          setNote={setClientNote}
          navigate={navigate}
          label="Rejection Reason (optional)"
          inputFieldPlaceholder="Enter rejection reason"
          submitButtonProps={{
            text: "Reject",
            variant: "decline",
            loading: rejectWOLoading,
            onClick: handleRejectClick,
          }}
        />
      )}
    </Container>
  );
};

export default ClientApprovalWindow;

const Container = styled.div<MobileProps>`
  background-color: #f4f4f4;
  border: 2px solid #d3d3d3;
  padding: 40px;
  margin: 40px;
  margin-bottom: 0;

  h3 {
    margin-bottom: 20px;
    font-size: 16px;
  }

  p {
    margin-bottom: 40px;
    text-align: justify;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 10px;
      margin: 10px;
      font-size: 12px;
    `}
`;

export const ButtonsApproveClientBlock = styled.div<MobileProps>`
  display: flex;
  justify-content: end;
  margin-top: 40px;

  button {
    margin-right: 40px;

    ${({ $isMobile }) =>
      $isMobile &&
      css`
        margin-right: 10px;
      `}
  }
`;
