import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormLabel } from "src/components/atoms/FormLabel";
import { ErrorMessage, InputRow, StyledLabel } from "../styles";
import { DynamicFieldProps } from "../types";
const DynamicRadioGroupField = ({ dynamicField }: DynamicFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <InputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        rules={dynamicField.rules}
        defaultValue={dynamicField.defaultValue}
        render={({ field: { value, onChange } }) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {dynamicField?.radioGroup?.options?.map((option) => (
                <StyledLabel style={{ flex: "none", display: "flex" }} key={option.value}>
                  <input
                    style={{ marginRight: "5px" }}
                    type="radio"
                    value={option.value}
                    checked={value === option.value}
                    onChange={onChange}
                    id={option.value}
                    name={dynamicField.id}
                  />
                  {option.label}
                </StyledLabel>
              ))}
            </div>
            {errors[dynamicField.id] && <ErrorMessage>{"This field is required"}</ErrorMessage>}
          </div>
        )}
      />
    </InputRow>
  );
};
export default DynamicRadioGroupField;
