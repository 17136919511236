import React, { useMemo } from "react";
import DropdownRow from "../../DropdownRow";
import { WorkRequestSelectWorkTypeDropdownProps } from "../types";
import { WorkType } from "src/types";

const WorkRequestSelectWorkTypeDropdown = ({
  isVerifyMode,
  setFormData,
  workTypes,
}: WorkRequestSelectWorkTypeDropdownProps) => {
  const dropdownWorkTypes = useMemo(
    () =>
      workTypes.map((workType: WorkType) => {
        return {
          id: workType.id,
          value: workType.name,
          type: workType.type,
        };
      }),
    [workTypes],
  );
  return (
    <DropdownRow
      sort
      disabled={isVerifyMode}
      label="Work Type:"
      dropdownItems={dropdownWorkTypes}
      placeholder={"Select Work Type"}
      onClick={(item) => setFormData((data) => ({ ...data, workType: item.type }))}
    />
  );
};

export default WorkRequestSelectWorkTypeDropdown;
