import { useMemo } from "react";

export const usePagination = ({ totalCount, pageSize, siblingCount = 1, currentPage }: any) => {
  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // left + right + current_page
    const totalPageNumbers = siblingCount * 2 + 1;
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    let result = [];

    if (leftSiblingIndex > 1) {
      result.push(1);
    }
    if (leftSiblingIndex > 2) {
      result.push("...");
    }
    result = result.concat(range(leftSiblingIndex, rightSiblingIndex));
    if (rightSiblingIndex < totalPageCount - 1) {
      result.push("...");
    }
    if (rightSiblingIndex < totalPageCount) {
      result.push(totalPageCount);
    }
    return result;
  }, [totalCount, pageSize, siblingCount, currentPage]);
};
