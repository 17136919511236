import React, { useState } from "react";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";

export const DatePicker: React.FC<DateTimePickerProps> = ({ onChange, ...props }) => {
  const [key, setKey] = useState(0);

  const handleOnChange = (val: Date | null) => {
    // this is hack against clear button
    if (!val) {
      setKey(key + 1);
    }

    onChange?.(val);
  };

  return <DateTimePicker {...props} onChange={(e) => handleOnChange(e)} key={key} />;
};
