import { faUser } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { TABLE_GAP_HEIGHT } from "src/pages/ClientDetails/constants";
import { mapRoleWithEmployeeDisplayName } from "../../../utils";
import { EmployeeListItemWrapper, Text, TextContainer, UserIcon } from "../styles";
import { EmployeeListItemProps } from "../types";

const EmployeeListItem = ({ list, listRowProps: rowRender, selectedItem, onSelectItem }: EmployeeListItemProps) => {
  const isVendor = list[rowRender.index].roles && list[rowRender.index]?.roles?.vendor;
  return (
    <EmployeeListItemWrapper
      isselected={selectedItem === list[rowRender.index].employeeId.toString()}
      key={rowRender.key}
      direction="horizontal"
      style={{ ...rowRender.style, height: Number(rowRender.style.height) - TABLE_GAP_HEIGHT }}
      onClick={() => {
        onSelectItem(list[rowRender.index].employeeId.toString());
      }}
    >
      <UserIcon icon={faUser} />
      <TextContainer $isVendor={isVendor}>
        <Text>{list[rowRender.index].employeeId}</Text>
        <Text>{mapRoleWithEmployeeDisplayName(list[rowRender.index])}</Text>
      </TextContainer>
    </EmployeeListItemWrapper>
  );
};

export default EmployeeListItem;
