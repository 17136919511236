import React from "react";

import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { colors } from "src/styles";
import styled from "styled-components";
import Button from "..";

interface BackButtonProps {
  backHandler: () => void;
}

const BackButton = ({ backHandler }: BackButtonProps) => {
  return (
    <ButtonWrapper>
      <Button
        onClick={backHandler}
        text="Back"
        variant="text"
        id="back"
        additionalStyles={{
          fontWeight: 700,
          textDecoration: "unset",
          marginBottom: "30px",
          padding: "0px",
        }}
        icon={faArrowLeft}
      />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  & > button[id="back"] > svg {
    padding: 7px;
    background-color: ${colors.grey.light16};
    border-radius: 8px;
    border: 1px solid ${colors.grey.light13};
  }
`;

export default BackButton;
