import { getApp } from "firebase/app";
import {
  deleteObject,
  getDownloadURL as getDownloadURLFB,
  getStorage,
  ref as refFB,
  uploadBytes,
} from "firebase/storage";

import { renderResponseResult } from "src/utils";

export default class FirebaseStorageService {
  static async getDownloadURL(ref = "", child = "") {
    try {
      const app = getApp();
      const storage = getStorage(app);
      const storageRef = refFB(storage, ref);
      const childRef = refFB(storageRef, child);
      const downloadURL = await getDownloadURLFB(childRef);
      return renderResponseResult(true, downloadURL);
    } catch (error) {
      return renderResponseResult(false, `Unable to fetch (ref: ${ref}, child: ${child})`);
    }
  }

  static async uploadImageToStorage(
    image: File | undefined | null | string,
    folder: string | undefined,
    fileName: string | undefined,
  ) {
    try {
      if (!image || !folder || !fileName || typeof image === "string") return;
      const storage = getStorage();
      const folderRef = refFB(storage, folder);
      const ref = refFB(folderRef, fileName);
      await uploadBytes(ref, image);
      const downloadURL = await getDownloadURLFB(ref);
      return {
        success: true,
        data: downloadURL,
      };
    } catch (error) {
      return {
        success: false,
        error: `Unable to upload file ${fileName} to ${folder} ${JSON.stringify(error)}`,
      };
    }
  }

  static async deleteChildFromStorage(url = "", folder: string | undefined) {
    try {
      if (!url || !folder) return;
      const app = getApp();
      const storage = getStorage(app);
      const folderRef = refFB(storage, folder);
      const ref = refFB(folderRef, url);
      const downloadURL = await deleteObject(ref);
      return {
        success: true,
        data: downloadURL,
      };
    } catch (e) {
      return {
        success: false,
        error: `Unable to delete child by url ${url} ${JSON.stringify(e)}`,
      };
    }
  }
}
