import React from "react";
import { ShiftData } from "src/components/molecules/ShiftForm/types";
import { colors } from "src/styles";
import { DaysByAbbr } from "src/types";
import styled from "styled-components";

export type Schedule = {
  template?: string;
  type?: string;
  shifts?: ShiftData[];
};

interface ScheduleTypeInfoProps {
  scheduleTemplate?: Schedule;
}

const ScheduleTypeInfo = ({ scheduleTemplate }: ScheduleTypeInfoProps) => {
  const shifts = scheduleTemplate?.shifts ?? [];

  return (
    <>
      {shifts.map((shift) => {
        return (
          <ScheduleTemplateWrapper bordertop={shifts.length > 1 ? 1 : 0} key={shift.keyLabel}>
            <ScheduleInfo>
              <Label>Working Days:</Label>
              <DaysWrapper>
                {shift.days.map((item) => {
                  return <p key={item}>{DaysByAbbr[item as keyof typeof DaysByAbbr]}</p>;
                })}
              </DaysWrapper>
            </ScheduleInfo>

            <DatesWrapper>
              <ScheduleInfo>
                <Label>Shift Start:</Label>
                <p>{shift.start || "-"}</p>
              </ScheduleInfo>
              <ScheduleInfo>
                <Label>Shift Finish:</Label>
                <p>{shift.end || "-"}</p>
              </ScheduleInfo>
            </DatesWrapper>
          </ScheduleTemplateWrapper>
        );
      })}
    </>
  );
};

const Label = styled.label`
  width: 155px;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.dark};
`;

const DatesWrapper = styled.div`
  display: flex;
  & > div {
    padding-right: 15px;
    & > label {
      width: 105px;
    }
    & > p {
      width: 80px;
    }
  }
`;

const ScheduleInfo = styled.div`
  display: flex;
  align-items: center;

  & > p {
    font-weight: 400;
    font-size: 14px;
    color: ${colors.grey.dark};
  }
`;

const ScheduleTemplateWrapper = styled.div<{ bordertop: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  &:last-child {
    padding-bottom: 40px;
  }

  border-top: ${({ bordertop }) => bordertop && `1px solid ${colors.grey.light12}`};
`;

const DaysWrapper = styled.div`
  display: flex;
  & > p {
    font-weight: 400;
    font-size: 14px;
    color: ${colors.grey.dark};
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
  }
`;

export default ScheduleTypeInfo;
