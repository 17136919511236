import { colors } from "src/styles";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark};
`;
