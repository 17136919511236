import { MobileProps } from "src/hooks/useIsMobile";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;

const EmptyContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoTicketsMessage = styled.h4<MobileProps>`
  text-align: left;
  margin-top: 10px;
  background-color: #fff1bf;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ffc700;
  font-size: 14px;
`;

const PaginationContainer = styled.div`
  margin-top: 32px;
`;

export { Container, EmptyContainer, NoTicketsMessage, PaginationContainer };
