import React from "react";
import { Stack } from "react-bootstrap";
import { Container } from "../styles";
import { CloseWorkOrderFormViewProps } from "../types";
import { BillableAmount } from "./BillableAmount";
import { CloseWorkOrderAttachments } from "./CloseWorkOrderAttachments";
import { CloseWorkOrderDatePicker } from "./CloseWorkOrderDatePicker";
import { CloseWorkOrderFormButtons } from "./CloseWorkOrderFormButtons";
import { CloseWorkOrderNotes } from "./CloseWorkOrderNotes";
import { CloseWorkOrderTargetDates } from "./CloseWorkOrderTargetDates";

export const CloseWorkOrderFormView = ({ onCancel, workorder }: CloseWorkOrderFormViewProps) => {
  const isRequired = workorder.woacceptscharges && !workorder.approvedamount;
  return (
    <Stack>
      <Container gap={3}>
        <CloseWorkOrderTargetDates label="Target Start Date:" value={workorder?.targstartdate ?? ""} />
        <CloseWorkOrderTargetDates label="Target Finish Date:" value={workorder?.targcompdate ?? ""} />
        <CloseWorkOrderDatePicker fieldName="actstart" label="Actual Start:" />
        <CloseWorkOrderDatePicker fieldName="actfinish" label="Actual Finish:" />
        {isRequired && <BillableAmount required={isRequired} />}
        <CloseWorkOrderNotes />
        <CloseWorkOrderAttachments />
      </Container>
      <CloseWorkOrderFormButtons onCancel={onCancel} />
    </Stack>
  );
};
