import React, { KeyboardEvent, PropsWithChildren } from "react";
import { Accordion } from "react-bootstrap";
import { colors } from "src/styles";
import styled from "styled-components";

interface ContentBoxProps extends PropsWithChildren {
  isOpen?: boolean;
  renderHeader: () => JSX.Element;
}

const ContentBox = ({ isOpen, children, renderHeader }: ContentBoxProps) => {
  const onKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <StyledAccordion onKeyUp={onKeyUp} defaultActiveKey={isOpen ? "0" : undefined}>
      <Accordion.Item eventKey={isOpen ? "0" : ""}>
        <StyledHeader>{renderHeader()}</StyledHeader>

        <StyledBody>{children}</StyledBody>
      </Accordion.Item>
    </StyledAccordion>
  );
};

const StyledAccordion = styled(Accordion)`
  margin: 20px 0;
`;

const StyledHeader = styled(Accordion.Header)`
  & > .accordion-button {
    padding: 20px 20px 20px 52px;
    ::after {
      position: absolute;
      left: 20px;
      transform: rotate(-90deg);
    }
    :not(.collapsed)::after {
      transform: rotate(0deg);
      background-image: var(--bs-accordion-btn-icon);
    }
  }
  & > .accordion-button:focus {
    border-color: ${colors.grey.light4};
    box-shadow: unset;
  }

  & > .accordion-button:not(.collapsed) {
    color: ${colors.grey.dark};
    background-color: unset;
    box-shadow: unset;
    border-bottom: 1px solid ${colors.grey.light4};
  }
`;

const StyledBody = styled(Accordion.Body)`
  padding: 0;
`;

export default ContentBox;
