import React, { useContext } from "react";
import styled from "styled-components";
import { DropdownItem } from "../../../../../components/Dropdown/types";
import SelectSearch from "../../../../../components/SelectSearch";
import { InputRow, InputsContainer, InputsRow } from "../../../GeneralInfoForm/ClientContactForm";
import LocationDetailsContext from "../../context";

export const EditableInfoMessagesForm = () => {
  const { generalInfoFormState, setGeneralInfoFormState, messages, dropdownListSizes } =
    useContext(LocationDetailsContext);

  const handleFieldChange = (name: string, value: DropdownItem) => {
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      infoMessages: {
        ...(prevState.infoMessages || null),
        [name]: value,
      },
    }));
  };

  return (
    <InputsContainer>
      <InputsRow>
        <StyledInputRow $inputWidth="360">
          <label>Welcome Text: </label>
          <SelectSearch
            defaultValue={generalInfoFormState?.infoMessages?.welcomeText?.id || ""}
            placeholder="Select Message"
            options={messages}
            onChange={(value) => handleFieldChange("welcomeText", value)}
            caption={generalInfoFormState?.infoMessages?.welcomeText?.label}
            listSize={dropdownListSizes?.welcomeText}
          />
        </StyledInputRow>
      </InputsRow>
      <InputsRow>
        <StyledInputRow $inputWidth="360">
          <label>Left Side Text: </label>
          <SelectSearch
            defaultValue={generalInfoFormState?.infoMessages?.firstText?.id || ""}
            placeholder="Select Message"
            options={messages}
            onChange={(value) => handleFieldChange("firstText", value)}
            caption={generalInfoFormState?.infoMessages?.firstText?.label}
            listSize={dropdownListSizes?.firstText}
          />
        </StyledInputRow>
      </InputsRow>
      <InputsRow>
        <StyledInputRow $inputWidth="360">
          <label>Right Side Text: </label>
          <SelectSearch
            defaultValue={generalInfoFormState?.infoMessages?.secondText?.id || ""}
            placeholder="Select Message"
            options={messages}
            onChange={(value) => handleFieldChange("secondText", value)}
            caption={generalInfoFormState?.infoMessages?.secondText?.label}
            listSize={dropdownListSizes?.secondText}
          />
        </StyledInputRow>
      </InputsRow>
    </InputsContainer>
  );
};

const StyledInputRow = styled(InputRow)`
  max-width: unset;
`;
