import React from "react";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import styled, { css } from "styled-components";

export interface ModalProps {
  isShowing: boolean;
  isWOApproved: boolean;
}

const APPROVE_WORK_ORDER_SUCCESS_MESSAGE = "Work Request successfully approved.";
const REJECT_WORK_ORDER_SUCCESS_MESSAGE = "Work Request successfully rejected.";

const ModalClientApproval = ({ isShowing, isWOApproved }: ModalProps) => {
  const { isMobile } = useIsMobile();

  if (isShowing)
    return (
      <>
        <ModalWrapper>
          <ModalWindow>
            <ModalContent $isMobile={isMobile}>
              <p>{isWOApproved ? APPROVE_WORK_ORDER_SUCCESS_MESSAGE : REJECT_WORK_ORDER_SUCCESS_MESSAGE}</p>
            </ModalContent>
          </ModalWindow>
        </ModalWrapper>
      </>
    );
  return null;
};

const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const ModalWindow = styled.div`
  background: #e9fae1;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1;
`;

const ModalContent = styled.div<MobileProps>`
  border: 5px solid #81c17d;
  border-radius: 5px;

  p {
    margin: 45px 80px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;

    ${({ $isMobile }) =>
      $isMobile &&
      css`
        margin: 20px;
      `}
  }
`;

export default ModalClientApproval;
