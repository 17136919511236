import React, { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { GeneralSettingsForm } from "src/templates/GeneralSettingsForm";
import { fetchDefaultValues, onSubmitHandler } from "./handlers";
import { Error } from "./styles";
import { FontSettingsProps, GlobalSettingsData } from "./types";

/**
 * Fetches data to render GeneralSettingsForm and handles sending data back to backend upon form submision
 */
export const GlobalSettings = () => {
  const [error, setError] = useState<string>();

  const fontSettings = useRef<FontSettingsProps>({
    fontColors: [],
    fontSizes: [],
  });

  const form = useForm<GlobalSettingsData>({
    defaultValues: async () => fetchDefaultValues(setError, fontSettings),
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(async (data) => onSubmitHandler(form, data))}>
        {error && <Error>{error}</Error>}

        {!error && (
          <GeneralSettingsForm
            fontColors={fontSettings.current.fontColors}
            fontSizes={fontSettings.current.fontSizes}
          />
        )}
      </form>
    </FormProvider>
  );
};
