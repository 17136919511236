import React, { ChangeEvent, useEffect, useState } from "react";
import Input from "src/components/Input";
import styled from "styled-components";

interface IApprovedHoursInputProps {
  initialValue: number;
  onChange: (val: number) => void;
}
const CustomInput = ({ initialValue, onChange }: IApprovedHoursInputProps) => {
  const [value, setValue] = useState<number | string>();
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const filteredValue = value.replace(/[^0-9\.]/g, "");
    const splitValue = filteredValue.split(".");
    if (filteredValue.includes(".") && (splitValue[1].length > 2 || splitValue.length > 2)) {
      return;
    }
    setValue(filteredValue);
    onChange(Number(filteredValue));
  };
  return <ApprovedHoursInput value={value} onChange={handleChange} />;
};

export default CustomInput;

const ApprovedHoursInput = styled(Input)`
  max-width: 65px;
  margin: 0 auto;
  padding: 10px;
  height: unset;
`;
