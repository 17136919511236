import { FirebaseDataService } from "src/services/Firebase/data";
import { Client } from "src/types";

export const fetchClientData = async (id: string): Promise<Client> => {
  if (!id) {
    return {} as Client;
  }
  const { data: clientObject } = await FirebaseDataService.getEmployeeClient({ clientId: id });
  if (!clientObject) {
    return {} as Client;
  }
  return clientObject;
};
