import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import React, { useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import DeleteModal from "src/components/DeleteModal";
import { FullItemHeader } from "./components/FullItemHeader";
import { onDeleteHandler } from "./handlers";
import { ListFormNewProps } from "./types";

/**
 * Implements list management's consistent look and feel with delete confirmation based on individual entity form.
 *
 * It is required to be used within the `react-hook-form` context.
 *
 * The initial list of items should be passed to the `useForm()` as `defaultValues`.
 */
export const ListForm = <ItemType,>({
  name,
  keyName = "id",
  renderItem,
  getNewItem,
  itemName,
  itemCaption,
  variant = "full",
}: ListFormNewProps<ItemType>) => {
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);

  const itemToDelete = useRef<number>();

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({ control, name, keyName });

  return (
    <>
      <DeleteModal
        onApproveClick={() => {
          remove(itemToDelete.current);
          setIsDeleteConfirmation(false);
        }}
        onCancelClick={() => setIsDeleteConfirmation(false)}
        isShowing={isDeleteConfirmation}
        hide={() => setIsDeleteConfirmation(false)}
        title={itemName}
      />

      {fields.map((item, index) => (
        <div key={item[keyName]}>
          {variant == "full" && (
            <FullItemHeader
              caption={itemCaption}
              name={itemName}
              index={index}
              onDelete={(event) => onDeleteHandler(event, itemToDelete, index, setIsDeleteConfirmation)}
            />
          )}

          {renderItem(item as unknown as ItemType, index, fields.length > 1, (event) =>
            onDeleteHandler(event, itemToDelete, index, setIsDeleteConfirmation),
          )}
        </div>
      ))}

      <Button
        onClick={(event) => {
          event.preventDefault();
          append(getNewItem());
        }}
        additionalStyles={{
          marginTop: "20px",
        }}
        icon={faPlus as any}
        text={`Add ${itemCaption ?? itemName}`}
        variant="dotted-border"
      />
    </>
  );
};
