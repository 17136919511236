import { colors } from "src/styles";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;
export const Content = styled.div`
  margin: 30px;
  background-color: ${colors.white.default};
`;
export const ContentFrame = styled.div`
  padding: 30px;
  background-color: ${colors.white.default};
  filter: drop-shadow(0px 4px 120px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  /* margin: 0 10px; */
`;
