import { HEIC_TYPE, HEIF_TYPE, MAX_PHOTO_SIZE } from "src/config/сonstants";
import convertHeicToJpg from "src/helpers/convertHeicToJPG";
import { DynamicDocument } from "./types";

export const onAttachmentClick = (doc: DynamicDocument) => {
  if (doc.file) {
    window.open(URL.createObjectURL(doc.file));
  }
};

export const onClickUpload = (hiddenFileInput: React.RefObject<HTMLInputElement>) => {
  hiddenFileInput.current?.click();
};

export const uploadAttachments = async (
  documents: DynamicDocument[],
  setFiles: React.Dispatch<React.SetStateAction<DynamicDocument[]>>,
  files: DynamicDocument[],
) => {
  let docs = documents;
  for (const item of documents) {
    const file = item.file;
    if (!file) {
      return;
    }
    if (file.size > MAX_PHOTO_SIZE) {
      alert("File size should not exceed 7 MB");
      docs = docs.filter((newFile) => newFile.fileName !== file.name);
      continue;
    }
    if ([HEIC_TYPE, HEIF_TYPE].includes(file.type)) {
      const convertedImage = await convertHeicToJpg(file);
      if (convertedImage) {
        if (convertedImage.size > MAX_PHOTO_SIZE) {
          alert("File size should not exceed 7 MB");
          docs = docs.filter((newFile) => newFile.fileName !== file.name);
        } else {
          docs = docs.map((newFile) =>
            newFile.fileName === file.name ? { ...newFile, file: convertedImage, state: "completed" } : newFile,
          );
        }
      }
    } else {
      docs = docs.map((newFile) => (newFile.fileName === file.name ? { ...newFile, state: "completed" } : newFile));
    }
  }
  setFiles([...files, ...docs]);
};
