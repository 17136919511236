import React, { useState } from "react";
import Checkbox from "src/components/Checkbox";
import { ButtonContainer, ErrorMessage, SubmitContainer } from "../styles";
import Button from "src/components/Button";
import { useIsMobile } from "src/hooks/useIsMobile";
import { ButtonContainerProps } from "../types";

const CreateFormButtonContainer = ({
  isVerifyMode,
  setIsVerifyMode,
  isLoading,
  onSubmit,
  onConfirmClick,
  buttonDisabledConditions,
  error,
}: ButtonContainerProps) => {
  const [isVerifyChecked, setIsVerifyChecked] = useState(false);

  const { isMobile } = useIsMobile();
  return (
    <>
      <ErrorMessage>{error && error}</ErrorMessage>
      {isVerifyMode ? (
        <SubmitContainer $isMobile={isMobile}>
          <Checkbox
            onChange={(e) => setIsVerifyChecked(e.target.checked)}
            checked={isVerifyChecked}
            label="I verify the above entered details to be true and correct."
          />
          <ButtonContainer>
            <Button onClick={() => setIsVerifyMode(false)} text="Make changes" variant="secondary" />
            <Button
              loading={isLoading}
              disabled={!isVerifyChecked}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                onSubmit(e);
              }}
              text="Submit"
            />
          </ButtonContainer>
        </SubmitContainer>
      ) : (
        <ButtonContainer>
          <Button disabled={buttonDisabledConditions} onClick={onConfirmClick} text="Confirm" />
        </ButtonContainer>
      )}
    </>
  );
};

export default CreateFormButtonContainer;
