import { DynamicFormConfig } from "src/components/DynamicFormContainer/types";
import { FileFilterResult } from "src/types";

export const roundTo2Decimals = (num: number) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

/**
 * Splits a given file path into file name and an array of folders
 *
 * @param filePath
 */
export const extractFileNameParts = (filePath: string) => {
  const filePathParts = filePath.split("/");

  // the file name is always the last element of the path and guarantied to not be undefined
  // after this point the `filePathParts` will have only folders left, if any
  const fileName = filePathParts.pop() as string;

  return {
    fileName,
    folders: filePathParts,
  };
};

/**
 * Checks if a data object has a valida date.
 */
export const isValidDate = (date: Date | null | undefined) =>
  !!(date && date instanceof Date && !isNaN(date?.getTime() || NaN));

/**
 * Converts a string value to a valid number or null if the string does not contain a number or empty
 */
export const toNumber = (value?: string | number): number | undefined => {
  if (!value || Number.isNaN(value)) {
    return undefined;
  }

  return Number(value);
};

export const testGroup: string[] = [
  // "manager@mail.com",
  // "client@mail.com",
  // "billgermanis@gmail.com",
  // "bgermanis@kleenwayservices.com",
  // "denis@julullc.com",
  // "dennis.client@julullc.com",
  // "dennis.manager@julullc.com",
];

export const fetchDefaultValuesFromDynamicFormConfig = (formConfig: DynamicFormConfig) => {
  const { fields } = formConfig;
  const defaultValues: { [key: string]: string } = {};
  if (fields) {
    fields.forEach((field) => {
      if (field) {
        defaultValues[field.id] = field.defaultValue ?? "";
      }
    });
  }
  return defaultValues;
};
export function filterFiles(files: FileList, supportedFileTypesString: string): FileFilterResult {
  // Convert supported file types to a Set for efficient lookup
  const supportedFileTypes: string[] = supportedFileTypesString.split(",").map((type) => type.trim());

  const supportedFileExtensions: Set<string> = new Set(supportedFileTypes.map((ext) => ext.toLowerCase()));

  // Function to check if a file is supported
  function isSupported(file: File): boolean {
    const fileExtension = getFileExtension(file.name).toLowerCase();
    return supportedFileExtensions.has(fileExtension);
  }

  // Helper function to get file extension
  function getFileExtension(filename: string): string {
    return filename.slice(filename.lastIndexOf("."));
  }

  // Initialize arrays for supported and unsupported files
  const supportedFiles: File[] = [];
  const unsupportedFiles: File[] = [];

  // Iterate through the FileList and categorize files
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (isSupported(file)) {
      supportedFiles.push(file);
    } else {
      unsupportedFiles.push(file);
    }
  }

  return {
    supportedFiles,
    unsupportedFiles,
  };
}
