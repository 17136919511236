import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

interface BadgeProps {
  status: string;
}

const Badge = ({ status }: BadgeProps) => {
  switch (status) {
    case "New":
      return <TableStatusBadge $isNew={true}>{status}</TableStatusBadge>;
    case "In progress":
      return <TableStatusBadge $isInProgress={true}>{status}</TableStatusBadge>;
    case "Completed":
      return <TableStatusBadge $isCompleted={true}>{status}</TableStatusBadge>;
    case "Approved":
      return <TableStatusBadge $isApproved={true}>{status}</TableStatusBadge>;
    default:
      return <TableStatusBadge>{status}</TableStatusBadge>;
  }
};

export interface TableStatusBadgeProps {
  $isClosed?: boolean;
  $isApproved?: boolean;
  $isNew?: boolean;
  $isInProgress?: boolean;
  $isWaitingOnMaterial?: boolean;
  $isCompleted?: boolean;
}

const TableStatusBadge = styled.div<TableStatusBadgeProps>`
  padding: 5px 7px;
  border: 1px solid ${colors.grey.dark};
  color: ${colors.grey.dark};
  font-size: 14px;
  font-weight: 600;
  border-radius: 12px;
  display: inline-block;

  ${(props) =>
    props.$isNew &&
    `
        border: 1px solid ${colors.yellow.light1};
        color: ${colors.yellow.light1};
      `}

  ${(props) =>
    props.$isInProgress &&
    `
        border: 1px solid ${colors.green.light};
        color: ${colors.green.light}`}
    
      ${(props) =>
    props.$isApproved &&
    `
        border: 1px solid ${colors.green.default};
        color: ${colors.green.default};`}
    
      ${(props) =>
    props.$isCompleted &&
    `
        border: 1px solid ${colors.grey.dark};
        color: ${colors.grey.dark};`}
`;

export default Badge;
