import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import { Container, EmptyContainer, NoTicketsMessage, PaginationContainer } from "src/components/ListScreen";
import { Pagination } from "src/components/Pagination";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { Content, ContentFrame, TopBar } from "src/styles";
import { DBVendor, ID } from "src/types";
import { NumberParam, useQueryParam } from "use-query-params";
import { DEFAULT_PAGE_SIZE, INITIAL_LAST_PAGE } from "../Clients/constants";
import { setOpenedVendorsPage as setPageStore } from "./../../redux/actions/openedPages";
import { MANAGER_VENDORS_BREADCRUMBS, vendorsTableHeaders } from "./constants";

import { useIsMobile } from "src/hooks/useIsMobile";
import { VendorsList } from "./VendorsList";
import { VendorsTable } from "./VendorsTable";

const Vendors = () => {
  const [lastPage, setLastPage] = useState<number>(INITIAL_LAST_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [openedVendorsPage, setOpenedVendorsPage] = useQueryParam("openedVendorsPage", NumberParam);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState<DBVendor[]>([]);

  const [currentVendorsPage, setCurrentVendorsPage] = useState<DBVendor[]>([]);
  const pageNum = openedVendorsPage || 1;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);
  const { id } = useSelector(getUserData);

  useEffect(() => {
    setIsLoading(true);
    getPageSize().finally(() => getVendors());
  }, [pageSize]);
  const getPageSize = async () => {
    try {
      const { data } = await FirebaseDataService.getEmployeeVendorsPageSize();
      setPageSize(data || DEFAULT_PAGE_SIZE);
    } catch (e) {
      setPageSize(DEFAULT_PAGE_SIZE);
    }
  };
  const getVendors = async () => {
    setIsLoading(true);

    if (!lastPage || (lastPage && pageNum <= lastPage)) {
      try {
        const { data: allVendors } = await FirebaseDataService.getEmployeeVendors(id.toString());
        const resultVendorsPageArray = allVendors.slice((pageNum - 1) * pageSize, pageNum * pageSize);
        setVendors(allVendors);
        setCurrentVendorsPage(resultVendorsPageArray);
        setLastPage(Math.ceil(allVendors.length / pageSize));
      } catch (e) {
        console.log(e.message);
      }
    }

    setIsLoading(false);
  };
  const onPageChangeClick = (pageNumber: number) => {
    setOpenedVendorsPage(pageNumber);
    dispatch(setPageStore(pageNumber));
    setCurrentVendorsPage(vendors.slice((pageNumber - 1) * pageSize, pageNumber * pageSize));
  };

  const onRowClick = (vendorID: ID) => navigate(`/vendors/${vendorID}`);
  return (
    <Container>
      <TopBar>
        <BreadCrumbs folderStack={MANAGER_VENDORS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />
      </TopBar>
      <Content>
        <ContentFrame>
          {isLoading ? (
            <EmptyContainer>
              <Spinner animation="border" size="sm" />
            </EmptyContainer>
          ) : (
            <>
              {currentVendorsPage?.length ? (
                <>
                  {isMobile ? (
                    <VendorsList onClick={onRowClick} data={currentVendorsPage} />
                  ) : (
                    <VendorsTable
                      onRowClick={onRowClick}
                      isLoading={isLoading}
                      headers={vendorsTableHeaders}
                      data={currentVendorsPage}
                    />
                  )}
                </>
              ) : (
                <NoTicketsMessage $isMobile={isMobile}>No Vendors assigned</NoTicketsMessage>
              )}
              <PaginationContainer>
                <Pagination
                  totalCount={vendors.length}
                  pageSize={pageSize}
                  onPageChange={onPageChangeClick}
                  currentPage={pageNum}
                />
              </PaginationContainer>
            </>
          )}
        </ContentFrame>
      </Content>
    </Container>
  );
};

export default Vendors;
