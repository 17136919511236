import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectSearch, { OptionItem } from "src/components/SelectSearch";
import { FormLabel } from "src/components/atoms/FormLabel";
import { InputRow } from "../styles";
import { ClientAndLocationDropdownFieldProps } from "../types";
export const ClientAndLocationDropdownField = ({
  clientsArray,
  locationsArray,
  dynamicField,
}: ClientAndLocationDropdownFieldProps) => {
  const { control, setValue } = useFormContext();
  const [locationDropDown, setLocationDropdown] = useState<OptionItem[]>([]);
  const prepareLocationsDropDownValues = (client: string) => {
    const options = locationsArray
      .filter((item) => item.clientId === client)
      ?.map((data) => ({
        id: data.id ?? "",
        value: data.label !== "" ? data.label : data.address ?? "",
      }));
    setLocationDropdown(options);
  };
  const locationId = "location";
  const clientId = "client";
  return (
    <>
      <InputRow>
        <FormLabel htmlFor={clientId} required={dynamicField.rules?.required}>
          {dynamicField.label}:
        </FormLabel>
        <Controller
          control={control}
          name={clientId}
          rules={dynamicField.rules}
          render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
            <SelectSearch
              id={clientId}
              options={
                clientsArray?.map((option) => ({
                  id: option.id,
                  value: option.name,
                })) ?? []
              }
              defaultValue={value}
              placeholder={dynamicField.clientAndLocationPicker?.clientPlaceholder}
              name={name}
              onChange={(option) => {
                prepareLocationsDropDownValues(option.id as string);
                onChange(option.id);
                setValue("location", "");
              }}
              ref={ref}
              error={error}
            />
          )}
        />
      </InputRow>
      <InputRow>
        <FormLabel htmlFor={locationId} required={dynamicField.rules?.required}>
          {dynamicField.subLabel}:
        </FormLabel>
        <Controller
          rules={dynamicField.rules}
          control={control}
          name={locationId}
          render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
            <SelectSearch
              id={locationId}
              options={locationDropDown}
              defaultValue={value}
              placeholder={dynamicField.clientAndLocationPicker?.locationPlaceholder}
              name={name}
              onChange={(option) => onChange(option.value)}
              ref={ref}
              error={error}
            />
          )}
        />
      </InputRow>
    </>
  );
};
