import moment, { Moment } from "moment-timezone";
import React from "react";
import styled from "styled-components";

import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import { isTimezoneOffsetsEqual } from "src/utils";
import Input from "../Input";

interface TimeLabelProps {
  date: Date | Moment | string | null;
  maximoTimezone: string;
  timezone?: string;
  valueComponent?: "input" | "p";
}

const TimeLabel: React.FC<TimeLabelProps> = ({ date, maximoTimezone, timezone, valueComponent = "input" }) => {
  const localTimeZone = moment.tz.guess();
  const isTimeSimilar = isTimezoneOffsetsEqual(localTimeZone, timezone || localTimeZone);
  const maximoMoment = moment(date);
  maximoMoment.tz(maximoTimezone).format("MMMM Do YYYY, h:mm:ss a z");

  if (!timezone || isTimeSimilar) {
    const displayTime = moment(date).tz(localTimeZone).format("MMMM Do YYYY, h:mm a z");
    return (
      <Container>
        {valueComponent === "p" ? <RawValue>{displayTime}</RawValue> : <Input disabled value={displayTime} />}
      </Container>
    );
  }

  const locationMoment = moment(maximoMoment).tz(timezone);
  const locationTime = locationMoment.format("MMMM Do YYYY, h:mm a z");
  const localTime = locationMoment.tz(localTimeZone).format("MMMM Do YYYY, h:mm a z*");
  return (
    <Container>
      {valueComponent === "p" ? <p>{localTime}</p> : <Input disabled value={localTime} />}
      <Label>*{locationTime}</Label>
    </Container>
  );
};

const Container = styled.div<MobileProps>`
  width: 100%;
  ${(props) => props.$isMobile && `font-size: 12px;`}
`;

const Label = styled.p<MobileProps>`
  font-style: italic;
  font-size: 14px;
  margin: 8px 0 8px 0;
  color: ${colors.grey.dark};
  max-width: 300px;
  ${(props) => props.$isMobile && `font-size: 12px;`}
`;

const RawValue = styled.p`
  border-bottom: 1px solid ${colors.grey.light4};
  width: 100%;
  font-size: 14px;
  padding-bottom: 4px;
`;

export default TimeLabel;
