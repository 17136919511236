import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Textarea from "src/components/Textarea";
import { FormLabel } from "src/components/atoms/FormLabel";
import { InputRow } from "../styles";
import { DynamicFieldProps } from "../types";
export const DynamicTextAreaField = ({ dynamicField }: DynamicFieldProps) => {
  const { control } = useFormContext();
  return (
    <InputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        defaultValue={dynamicField.defaultValue}
        rules={dynamicField.rules}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Textarea
            id={dynamicField.id}
            width="100%"
            name={name}
            value={value}
            placeholder={typeof dynamicField.text === "object" ? dynamicField.text?.placeholder : ""}
            onChange={onChange}
            ref={ref}
            error={error}
          />
        )}
      />
    </InputRow>
  );
};
