import * as faIconsLight from "@fortawesome/pro-light-svg-icons";
import React from "react";
import Input from "src/components/Input";
import { VALIDATION_MESSAGES } from "src/constants/validation";
import { ErrorMessage } from "../styles";
import { InputFieldProps } from "../types";

const absoluteReg =
  /(http(s)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const relativeReg = /^\/[-a-zA-Z0-9_]+/;

const InputField = ({
  inputWidth,
  value,
  editMode,
  placeholder,
  register,
  label,
  required,
  pattern,
  onChange,
  errors,
  filteredFoldersNameList,
}: InputFieldProps) => {
  const validationRules = {
    onChange,
    required: {
      value: required,
      message: VALIDATION_MESSAGES.required,
    },
    pattern: {
      value: label === "url" ? /.+/ : pattern,
      message: VALIDATION_MESSAGES.required,
    },
    validate: {
      ...(label === "url" &&
        value && {
          url: (urlValue: string) => {
            if (/\s/g.test(urlValue)) {
              return VALIDATION_MESSAGES.invalidURL;
            }
            return (
              (urlValue[0] !== "/" ? absoluteReg.test(urlValue) : relativeReg.test(urlValue)) ||
              VALIDATION_MESSAGES.invalidURL
            );
          },
        }),
      ...(label === "folder" && {
        folder: (folderValue: string) =>
          folderValue.length > 0 && !filteredFoldersNameList.includes(folderValue.toLocaleLowerCase()),
      }),
      ...(label === "icon" && {
        icon: (iconValue: string) => (faIconsLight as any)[iconValue] || VALIDATION_MESSAGES.invalidIcon,
      }),
    },
  };

  return (
    <>
      <Input
        type="text"
        style={{ maxWidth: inputWidth }}
        value={value?.toString()}
        disabled={!editMode}
        placeholder={placeholder}
        validation={register?.(label, validationRules)}
      />
      {errors?.[label]?.type === "folder" && <ErrorMessage>{VALIDATION_MESSAGES.existFolderName}</ErrorMessage>}
      {errors[label] && <ErrorMessage>{errors?.[label]?.message?.toString()}</ErrorMessage>}
    </>
  );
};

export default InputField;
