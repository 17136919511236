import React from "react";
import WorkRequestContainer from "src/components/molecules/WorkRequestContainer";
import { WORK_REQUEST_TYPE } from "../constants";
import { WorkRequestProps } from "../types";

const VendorWorkRequests = ({ renderTable }: WorkRequestProps) => {
  return (
    <WorkRequestContainer
      tabs={[
        {
          eventKey: WORK_REQUEST_TYPE.OPEN,
          label: "Open",
        },
      ]}
      isBreadCumVisible={true}
      renderTable={renderTable}
    />
  );
};

export default VendorWorkRequests;
