import React, { forwardRef } from "react";
import ButtonCheckbox from "../ButtonCheckbox";
import { onChangeHandler } from "./handlers";
import { GroupWrapper } from "./styles";
import { ButtonCheckboxGroupProps } from "./types";

/**
 * A component to display a group of checkboxes as buttons. The group acts as a single form element with one value.
 */
export const ButtonCheckboxGroup = forwardRef<HTMLDivElement, ButtonCheckboxGroupProps>(function ButtonCheckboxGroup(
  { options, value = [], error, onChange },
  ref,
) {
  // Ensures that all options have values.
  // Those that do not have values provided will be defaulted to their labels
  const optionsWithValues = options.map((option) => ({ ...option, value: option.value ?? option.label }));

  return (
    // tabindex=-1 is required to be able to programmaticaly focus the underlying <div> while not alowing the user to
    // navigate to it via pressing `Tab`
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
    <GroupWrapper tabIndex={-1} ref={ref}>
      {optionsWithValues.map((option) => (
        <ButtonCheckbox
          key={option.value}
          label={option.label}
          checked={value.includes(option.value)}
          error={error}
          onChange={(event) => onChangeHandler(event, option.value, onChange, value)}
        />
      ))}
    </GroupWrapper>
  );
});
