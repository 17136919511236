import React from "react";
import { Alert as BootstrapAlert } from "react-bootstrap";

interface AlertProps {
  text: string;
  variant?: string;
}

export enum AlertVariants {
  success = "success",
  warning = "warning",
  danger = "danger",
  light = "light",
}

const Alert: React.FC<AlertProps> = ({ text = "", variant = AlertVariants.success }) => {
  return (
    <>
      <BootstrapAlert variant={variant}>
        <p>{text}</p>
      </BootstrapAlert>
    </>
  );
};

export default Alert;
