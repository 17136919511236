import { combineReducers } from "@reduxjs/toolkit";
import { completeStatusesReducer } from "./completeStatuses";

import { userReducer } from "./user";

import { headerReducer } from "./header";
import { openedPagesReducer } from "./openedPages";
import { activeResourceReducer } from "./resources";
import { workRequestReducer } from "./workRequest";
import { dynamicFormReducer } from "./dynamicForm";

export const rootReducer = combineReducers({
  user: userReducer,
  workRequest: workRequestReducer,
  completeStatuses: completeStatusesReducer,
  openedPages: openedPagesReducer,
  header: headerReducer,
  resources: activeResourceReducer,
  dynamicForm: dynamicFormReducer,
});
