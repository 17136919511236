import "react-quill/dist/quill.snow.css";

import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import PhotoUpload from "src/components/PhotoUpload";
import { MAXIMO_TIME_ZONE } from "src/config/сonstants";
import { getUserData } from "src/redux/selectors/user";
import { colors } from "src/styles";
import { Worklog, WorkOrder } from "src/types";

import Input from "src/components/Input";
import { InputRow, InputsContainer, PhotoInput } from "../index";
import ClickInputDatePicker from "./ClickInputDatePicker";
import CommentsWrapper from "./CommentsWrapper";
import TechnicianNotes from "./TechnicianNOtes";

export const CommentRegEx = /<!--[\s\S]*?-->/g;

export interface DetailsSectionProps {
  $isMobile: boolean;
  workOrder: WorkOrder;
  timezone: string;
  technicianPhotos: string[];
  isViewMode: boolean;
  areImagesLoading: boolean;
  workOrderWorklog: Worklog[];
  commentsSectionWorklog: Worklog[];
  addCommentSuccess: boolean;
  setAddCommentSuccess: any;
}

const DetailsSection = ({
  addCommentSuccess,
  setAddCommentSuccess,
  $isMobile,
  workOrder,
  technicianPhotos,
  isViewMode,
  areImagesLoading,
  timezone,
  workOrderWorklog,
}: DetailsSectionProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const personDetails =
    workOrder?.assignment &&
    workOrder?.assignment[0] &&
    workOrder.assignment[0].labor &&
    workOrder.assignment[0].labor[0] &&
    workOrder.assignment[0].labor[0].person &&
    workOrder.assignment[0].labor[0].person[0];

  const { maximoTimezone: maximoTimezoneDef, isManager } = useSelector(getUserData);

  const maximoTimezone = maximoTimezoneDef || MAXIMO_TIME_ZONE;

  const displayName = personDetails?.displayname;
  const phoneNum = personDetails?.phone && personDetails.phone[0].phonenum;
  const email = personDetails?.email && personDetails.email[0].emailaddress;

  const isServiceDetailsSectionEmpty =
    !displayName && !phoneNum && !email && !workOrder?.schedstart && !workOrder?.schedfinish;

  const isCompletionDetailsSectionEmpty =
    !workOrder?.actstart && !workOrder?.actfinish && !workOrderWorklog?.length && !technicianPhotos?.length;
  const workOrderId = workOrder.href?.split("/").pop() ?? "";
  return (
    <InputsContainer $isMobile={$isMobile}>
      <InputsRow>
        {workOrder?.targstartdate ? (
          <ClickInputDatePicker
            date={workOrder?.targstartdate}
            $isMobile={$isMobile}
            label="Target Start:"
            timezone={timezone}
            maximoTimezone={maximoTimezone}
            valueComponent="p"
            fieldName="targstartdate"
            height="65px"
            id={workOrderId}
          />
        ) : null}
        {workOrder?.targcompdate ? (
          <ClickInputDatePicker
            date={workOrder?.targcompdate}
            $isMobile={$isMobile}
            label="Target Finish:"
            timezone={timezone}
            maximoTimezone={maximoTimezone}
            valueComponent="p"
            fieldName="targcompdate"
            height="65px"
            id={workOrderId}
          />
        ) : null}
      </InputsRow>
      <>
        {isManager || !isServiceDetailsSectionEmpty ? <Title>Service Details</Title> : null}
        <InputsRow>
          {isManager || workOrder?.schedstart ? (
            <ClickInputDatePicker
              date={workOrder?.schedstart}
              $isMobile={$isMobile}
              label="Scheduled Start:"
              timezone={timezone}
              maximoTimezone={maximoTimezone}
              valueComponent="p"
              fieldName="schedstart"
              height="65px"
              id={workOrderId}
            />
          ) : null}
          {isManager || workOrder?.schedfinish ? (
            <ClickInputDatePicker
              date={workOrder?.schedfinish}
              $isMobile={$isMobile}
              label="Scheduled Finish:"
              timezone={timezone}
              maximoTimezone={maximoTimezone}
              valueComponent="p"
              fieldName="schedfinish"
              height="65px"
              id={workOrderId}
            />
          ) : null}
        </InputsRow>
        {!isServiceDetailsSectionEmpty ? (
          <>
            <InputsRow>
              {displayName && (
                <InputRow $isMobile={$isMobile}>
                  <label>Technician Name:</label>
                  <Input disabled value={displayName as string}></Input>
                </InputRow>
              )}
              {phoneNum && (
                <InputRow $isMobile={$isMobile}>
                  <label>Contact Phone:</label>
                  <Input disabled value={phoneNum}></Input>
                </InputRow>
              )}
            </InputsRow>
            <InputsRow>
              {email && (
                <InputRow $isMobile={$isMobile}>
                  <label>Contact Email:</label>
                  <Input disabled value={email}></Input>
                </InputRow>
              )}
            </InputsRow>
          </>
        ) : null}
      </>
      {isManager || !isCompletionDetailsSectionEmpty ? <Title>Completion Notes</Title> : null}
      <InputsRow>
        {isManager || workOrder?.actstart ? (
          <ClickInputDatePicker
            date={workOrder?.actstart}
            $isMobile={$isMobile}
            label="Actual Start:"
            timezone={timezone}
            maximoTimezone={maximoTimezone}
            valueComponent="p"
            fieldName="actstart"
            height="65px"
            id={workOrderId}
            maxDate={new Date()}
          />
        ) : null}
        {isManager || workOrder?.actfinish ? (
          <ClickInputDatePicker
            date={workOrder.actfinish}
            $isMobile={$isMobile}
            label="Actual Finish:"
            timezone={timezone}
            maximoTimezone={maximoTimezone}
            valueComponent="p"
            fieldName="actfinish"
            height="65px"
            id={workOrderId}
            maxDate={new Date()}
          />
        ) : null}
      </InputsRow>
      {!isCompletionDetailsSectionEmpty ? (
        <>
          <TechnicianNotes
            comments={workOrderWorklog}
            timezone={timezone}
            $isMobile={$isMobile}
            maximoTimezone={maximoTimezone}
          />
          {isViewMode && technicianPhotos?.length === 0 ? null : (
            <InputsRow>
              <PhotoInput>
                <label>
                  Technician <br /> Photos:
                </label>
                <PhotoUpload
                  files={files}
                  isLoading={areImagesLoading}
                  images={technicianPhotos}
                  setFiles={setFiles}
                  isViewMode={isViewMode}
                />
              </PhotoInput>
            </InputsRow>
          )}
        </>
      ) : null}
      <Title>Comments</Title>
      <CommentsWrapper
        $isMobile={$isMobile}
        workOrderId={workOrder?.workorderid}
        addCommentSuccess={addCommentSuccess}
        setAddCommentSuccess={setAddCommentSuccess}
      />
    </InputsContainer>
  );
};

export default DetailsSection;

const Title = styled.h3`
  text-align: center;
  font-size: 16px;
  color: ${colors.grey.dark};
  margin-bottom: 32px;
`;

const InputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tooltip {
    max-width: 70%;
  }
`;
