import React from "react";
import styled from "styled-components";

import { Client as ClientType, ID } from "src/types";

import { Client } from "../Client";

export interface ClientsListProps {
  data: ClientType[];
  onClick?: (id: ID) => void;
}

export const ClientsList = ({ data, onClick }: ClientsListProps) => {
  const onContainerClickHandler = (id: string) => {
    if (onClick) {
      onClick(id);
    }
  };
  return (
    <>
      {data.map((client: ClientType) => (
        <ClientContainer onClick={() => onContainerClickHandler(client.id)} key={client.id}>
          <Client client={client} />
        </ClientContainer>
      ))}
    </>
  );
};

const ClientContainer = styled.div`
  margin-bottom: 10px;
`;
