import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { ShareForm } from "src/components/molecules/ShareForm";
import { Icon, ModalContent, ModalHeader, ModalWindow, ModalWrapper } from "./styles";
import { ShareFormModalProps } from "./types";

const ShareFormModal = ({ closeModal, onSubmit, error }: ShareFormModalProps) => {
  return (
    <ModalWrapper>
      <ModalWindow style={{ maxWidth: "550px" }}>
        <ModalHeader>
          <div>
            <h3>Share Work Request</h3>
            <Icon onClick={closeModal} icon={faTimes as IconProp} />
          </div>
        </ModalHeader>
        <ModalContent>
          <ShareForm error={error} closeModal={closeModal} onSubmit={onSubmit} />
        </ModalContent>
      </ModalWindow>
    </ModalWrapper>
  );
};
export default ShareFormModal;
