import { Ticket } from "src/types";
import { roundTo2Decimals } from "src/utils/helpers";

export const getApprovedAmount = (ticket: Ticket) => {
  if (ticket.woacceptscharges) {
    return ticket.approvedamount ? `$ ${roundTo2Decimals(ticket.approvedamount)}` : "Post-completion";
  } else {
    return "Non-billable";
  }
};
