import React from "react";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { ButtonContainer } from "../styles";
import { CloseWorkOrderFormButtonsProps } from "../types";

export const CloseWorkOrderFormButtons = ({ onCancel }: CloseWorkOrderFormButtonsProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  return (
    <ButtonContainer direction="horizontal">
      <Button
        text="Cancel"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault();
          onCancel?.();
        }}
      />
      <Button text="Save Changes" loading={isSubmitting} />
    </ButtonContainer>
  );
};
