import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";
import FirebaseStorageService from "../../../services/Firebase/storage";

const ClientLogo = ({ logoFileName }: { logoFileName?: string }) => {
  const [logoUrl, setLogoUrl] = useState("");

  const getClientLogoUrl = async () => {
    try {
      const { data } = await FirebaseStorageService.getDownloadURL(
        process.env.REACT_APP_CLIENT_LOGO_FOLDER,
        logoFileName,
      );
      setLogoUrl(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (logoFileName) {
      getClientLogoUrl();
    }
  }, []);

  if (logoFileName) {
    return (
      <LogoContainer>
        <Logo src={logoUrl} />
      </LogoContainer>
    );
  }

  return null;
};

const LogoContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const Logo = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;

export default ClientLogo;
