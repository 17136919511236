import React from "react";
import DateRow from "../../DateRow";
import { InputsContainer } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { WorkRequestTargetDatesInputProps } from "../types";

const WorkRequestTargetDatesInput = ({
  isVerifyMode,
  maximoTimezone,
  targetFinish,
  targetStart,
  timezone,
  showTopLine,
}: WorkRequestTargetDatesInputProps) => {
  const { isMobile } = useIsMobile();
  if (!isVerifyMode) {
    return null;
  }
  return (
    <InputsContainer showTopLine={showTopLine}>
      <DateRow
        timezone={timezone}
        date={targetStart}
        $isMobile={isMobile}
        label="Target Start:"
        maximoTimezone={maximoTimezone}
      />

      <DateRow
        timezone={timezone}
        date={targetFinish}
        $isMobile={isMobile}
        label="Target Finish:"
        maximoTimezone={maximoTimezone}
      />
    </InputsContainer>
  );
};

export default WorkRequestTargetDatesInput;
