import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { FormLabel } from "src/components/atoms/FormLabel";
import { removeQuotesFromRegex } from "src/utils";
import { InputRow } from "../styles";
import { DynamicFieldProps, DynamicRules } from "../types";

export const DynamicInputField = ({ dynamicField }: DynamicFieldProps) => {
  const { control } = useFormContext();

  // Define validation rules based on the type of input
  const getValidationRules = () => {
    const rules = dynamicField.rules ?? ({} as DynamicRules);
    if (typeof dynamicField.text === "object" && dynamicField.text !== null) {
      if (dynamicField.text.type === "email") {
        rules.pattern = {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Invalid email address",
        };
      } else if (dynamicField.text.type === "decimal") {
        rules.pattern = {
          value: /^\d+(\.\d+)?$/,
          message: "Invalid decimal number",
        };
      } else if (dynamicField.text.type === "number") {
        rules.pattern = {
          value: /^\d+$/,
          message: "Invalid number",
        };
      } else if (dynamicField.rules?.pattern?.value) {
        rules.pattern = {
          value: removeQuotesFromRegex("" + dynamicField.rules?.pattern?.value),
          message: dynamicField.rules?.pattern?.message ?? "",
        };
      }
    }
    return rules;
  };
  return (
    <InputRow>
      <FormLabel htmlFor={dynamicField.id} required={dynamicField.rules?.required}>
        {dynamicField.label}:
      </FormLabel>
      <Controller
        control={control}
        name={dynamicField.id}
        rules={getValidationRules()}
        defaultValue={dynamicField.defaultValue || ""} // Ensure defaultValue is set
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Input
            controlId={dynamicField.id}
            name={name}
            value={value} // Ensure value is passed correctly
            placeholder={typeof dynamicField.text === "object" ? dynamicField.text.placeholder : ""}
            onChange={onChange}
            ref={ref}
            error={error}
          />
        )}
      />
    </InputRow>
  );
};
