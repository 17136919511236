import React from "react";

import { ClientAndLocationDropdownField } from "./components/ClientAndLocationDropdownField";
import DynamicCheckboxGroupField from "./components/DynamicCheckboxGroupField";
import { DynamicDateTimeField } from "./components/DynamicDatePickerField";
import { DynamicDropdownField } from "./components/DynamicDropdownField";
import { DynamicFileUpload } from "./components/DynamicFileUpload";
import { DynamicInputField } from "./components/DynamicInputField";
import DynamicRadioGroupField from "./components/DynamicRadioGroupField";
import { DynamicTextAreaField } from "./components/DynamicTextAreaField";
import { DynamicField, DynamicFormProps } from "./types";
const DynamicForms = ({ dynamicFields, clientsArray, locationsArray }: DynamicFormProps) => {
  const sortedField = dynamicFields?.sort((a, b) => a.sortOrder - b.sortOrder);
  return (
    <>
      {sortedField.map((field: DynamicField, index: number) => {
        return (
          <React.Fragment key={index}>
            {field.text && <DynamicInputField dynamicField={field} />}
            {field.textarea && <DynamicTextAreaField dynamicField={field} />}
            {field.dropdown && <DynamicDropdownField dynamicField={field} />}
            {field.radioGroup && <DynamicRadioGroupField dynamicField={field} />}
            {field.checkbox && <DynamicCheckboxGroupField dynamicField={field} />}
            {field.file && <DynamicFileUpload dynamicField={field} />}
            {field.datepicker && <DynamicDateTimeField dynamicField={field} />}
            {field.clientAndLocationPicker && (
              <ClientAndLocationDropdownField
                clientsArray={clientsArray}
                locationsArray={locationsArray}
                dynamicField={field}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
export default DynamicForms;
