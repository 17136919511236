import { StyledForm } from "src/pages/ChangePassword";
import styled from "styled-components";

export const Content = styled.div`
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1369px) {
    padding-top: 100px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 617px;
  display: flex;
  flex-direction: column-reverse;
`;

export const FormLabel = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #555555;
`;

export const InputForm = styled(StyledForm)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    margin-bottom: 15px;
    width: 100%;
    max-width: 617px;
    text-align: left;
  }
`;
