import React from "react";
import styled, { css } from "styled-components";

import Footer from "src/components/Footer";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";

export const PageNotFound: React.FC = () => {
  const { isMobile } = useIsMobile();

  return (
    <Container $isMobile={isMobile}>
      <NotFoundContent>
        <Number>
          4<span>0</span>4
        </Number>
        <Message>
          Oooops...
          <br />
          <span>page not found</span>
        </Message>
      </NotFoundContent>
      <StickyFooter />
    </Container>
  );
};

const Container = styled.div<MobileProps>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 0;
    `}
`;

const NotFoundContent = styled.div`
  max-width: 717px;
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;

  text-align: center;
`;

const Number = styled.p`
  height: 200px;
  line-height: 200px;
  font-size: 120px;
  span {
    color: rgb(185, 224, 165);
  }
`;

const Message = styled.p`
  font-family: "Roboto";
  font-style: italic;
  font-size: 40px;
  font-weight: ita;
  padding-bottom: 26px;
`;

const StickyFooter = styled(Footer)`
position:sticky
margin-top: auto;
`;
