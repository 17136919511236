import React, { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "src/components/Input";
import { colors } from "src/styles";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import { EMAIL_REGEXP, VALIDATION_MESSAGES } from "../../../../../constants/validation";
import { DBClientContact } from "../../../../../types";
import {
  Container,
  Content,
  Header,
  InputRow,
  InputsContainer,
  InputsRow,
  Title,
} from "../../../GeneralInfoForm/ClientContactForm";
import LocationDetailsContext from "../../context";

enum LocationContactFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  JOB_DESCRIPTION = "jobDescription",
  PHONE = "phone",
  EMAIL = "email",
}

interface LocationContactFormProps {
  formIndex: number;
  defaultValues: DBClientContact;
  handleFormDelete: () => void;
}

export const LocationContactForm = ({ formIndex, defaultValues, handleFormDelete }: LocationContactFormProps) => {
  const { isGeneralInfoFormSaving, setIsGeneralInfoFormSaving, setGeneralInfoFormState } =
    useContext(LocationDetailsContext);

  const {
    register,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setGeneralInfoFormState(({ locationContacts, ...prevState }) => ({
      ...prevState,
      locationContacts: locationContacts.map((state, index) => {
        if (index === formIndex)
          return {
            ...state,
            [fieldName]: e.target.value,
            isValid,
          };
        return state;
      }),
    }));
  };

  useEffect(() => {
    if (isGeneralInfoFormSaving) {
      trigger(undefined, { shouldFocus: true });
      setIsGeneralInfoFormSaving(false);
    }
  }, [isGeneralInfoFormSaving]);

  useEffect(() => {
    if (isValid !== undefined) {
      setGeneralInfoFormState(({ locationContacts, ...prevState }) => ({
        ...prevState,
        locationContacts: locationContacts.map((state, index) => {
          if (index === formIndex)
            return {
              ...state,
              isValid,
            };
          return state;
        }),
      }));
    }
  }, [isValid]);

  return (
    <Container>
      <Header>
        <Title>{`Location Contact ${formIndex + 1}:`}</Title>
        <Button text="Delete Contact" variant="text" onClick={handleFormDelete} />
      </Header>
      <Content>
        <Form>
          <InputsContainer>
            <InputsRow>
              <InputRow>
                <label>First Name:</label>
                <div>
                  <Input
                    value={defaultValues.firstName}
                    maxLength={100}
                    placeholder="Enter First Name"
                    validation={register(LocationContactFields.FIRST_NAME, {
                      onChange: (e) => handleFieldChange(e, LocationContactFields.FIRST_NAME),
                      shouldUnregister: true,
                      validate: {
                        emptyValue: (value: string) => !!value.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[LocationContactFields.FIRST_NAME]?.message}</p>
                </div>
              </InputRow>
              <InputRow>
                <label>Last Name:</label>
                <div>
                  <Input
                    value={defaultValues.lastName}
                    maxLength={100}
                    placeholder="Enter Last Name"
                    validation={register(LocationContactFields.LAST_NAME, {
                      onChange: (e) => handleFieldChange(e, LocationContactFields.LAST_NAME),
                      validate: {
                        emptyValue: (value: string) => !!value.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[LocationContactFields.LAST_NAME]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>

            <InputsRow>
              <InputRow>
                <LabelContainer>
                  <label>Job Description:</label>
                  <span>(optional)</span>
                </LabelContainer>

                <div>
                  <Input
                    value={defaultValues.title}
                    maxLength={100}
                    placeholder="Enter Job Description"
                    validation={register(LocationContactFields.JOB_DESCRIPTION, {
                      onChange: (e) => handleFieldChange(e, LocationContactFields.JOB_DESCRIPTION),
                    })}
                  />
                  <p>{errors?.[LocationContactFields.JOB_DESCRIPTION]?.message}</p>
                </div>
              </InputRow>
              <InputRow>
                <label>Phone:</label>
                <div>
                  <Input
                    value={defaultValues.phone}
                    maxLength={100}
                    placeholder="Enter Phone"
                    validation={register(LocationContactFields.PHONE, {
                      onChange: (e) => handleFieldChange(e, LocationContactFields.PHONE),
                      validate: {
                        emptyValue: (value) => !!value?.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[LocationContactFields.PHONE]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>

            <InputsRow>
              <InputRow>
                <label>Email:</label>
                <div>
                  <Input
                    value={defaultValues.email}
                    maxLength={100}
                    placeholder="Enter Email"
                    validation={register(LocationContactFields.EMAIL, {
                      onChange: (e) => handleFieldChange(e, LocationContactFields.EMAIL),
                      validate: {
                        emptyValue: (value: string) => !!value.trim() || VALIDATION_MESSAGES.required,
                      },
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                      pattern: {
                        value: EMAIL_REGEXP,
                        message: VALIDATION_MESSAGES.invalidEmail,
                      },
                    })}
                  />
                  <p>{errors?.[LocationContactFields.EMAIL]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>
          </InputsContainer>
        </Form>
      </Content>
    </Container>
  );
};

const LabelContainer = styled.div`
  width: 120px;
  margin-right: 20px;
  & span {
    font-size: 14px;
    font-style: italic;
    color: ${colors.grey.dark};
  }
`;
