import { createReducer } from "@reduxjs/toolkit";
import { setOnSiteTeamMemberHeader, setPageNameHeader } from "../actions/header";

interface HeaderState {
  pageName: string;
  onSite: boolean;
}

export const initialState: HeaderState = {
  pageName: "",
  onSite: false,
};

export const headerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setPageNameHeader, (state, action) => ({
      ...state,
      pageName: action.payload,
    }))
    .addCase(setOnSiteTeamMemberHeader, (state, action) => ({
      ...state,
      onSite: action.payload,
    }));
});
