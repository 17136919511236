import styled from "styled-components";

import { colors } from "src/styles";

export const ClientContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const ClientHeading = styled.div`
  font-size: 14px;
  color: ${colors.grey.dark};
`;

export const ClientSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;
