import React, { useEffect, useState } from "react";

import { PAY_TYPE } from "src/constants/validation";
import { colors } from "src/styles";
import styled from "styled-components";
import { HourlyRateInput } from "./component/HourlyRateInput";
import { PayTypeSelectDropDown } from "./component/PayTypeSelectDropDown";
import { SalaryAmountInput } from "./component/SalaryAmountInput";
import { ACURATE_RATE_DECIMAL_DIGIT, ACURATE_RATE_PERCENTAGE_MULTIPLIER } from "./constant";
import { TeamMemberPayrollProps } from "./types";

const TeamMemberPayroll = ({ payPeriodType, vacationAccrualRate, form }: TeamMemberPayrollProps) => {
  //convert initial value from 0.9873 to 98.37
  const [accrualRate, setAccrualRate] = useState<number | string>(vacationAccrualRate);
  useEffect(() => {
    setAccrualRate(
      vacationAccrualRate
        ? (+vacationAccrualRate * ACURATE_RATE_PERCENTAGE_MULTIPLIER).toFixed(ACURATE_RATE_DECIMAL_DIGIT).slice(0, -1)
        : "",
    );
  }, [vacationAccrualRate]);

  const watchPayType = form.watch("payType");

  return (
    <>
      <FieldWrapper>
        <label>Pay Period Type:</label>
        <p>{payPeriodType}</p>
      </FieldWrapper>
      <FieldWrapper>
        <label>Vacation Accrual Rate:</label>
        <p>{accrualRate}</p>
        {accrualRate && <span>%</span>}
      </FieldWrapper>
      <FieldWrapper>
        <label>Pay Type:</label>
        <PayTypeSelectDropDown $isMobile={false} form={form} />
      </FieldWrapper>
      {watchPayType === PAY_TYPE.salary && (
        <FieldWrapper>
          <label>Salary Amount:</label>
          <SalaryAmountInput $isMobile={false} form={form} />
        </FieldWrapper>
      )}
      {watchPayType === PAY_TYPE.hourly && (
        <FieldWrapper>
          <label>Hourly Rate:</label>
          <HourlyRateInput $isMobile={false} form={form} />
        </FieldWrapper>
      )}
    </>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey.dark};
  padding-top: 15px;
  font-size: 14px;

  & label {
    font-weight: 600;
    padding-right: 35px;
    max-width: 260px;
    width: 100%;

    & span {
      padding-left: 0;
      font-weight: 500;
      font-style: italic;
    }
  }

  & div {
    width: 100px;

    & input {
      text-align: right;
    }
  }
  & span {
    padding-left: 10px;
    color: ${colors.black.light1};
  }
`;

export default TeamMemberPayroll;
