import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import React, { useMemo } from "react";
import { DropdownItem } from "src/components/Dropdown/types";
import SelectSearch, { OptionItem } from "src/components/SelectSearch";
import { InputRow, HintIcon } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { ServiceTypeItem } from "src/types";
import Input from "src/components/Input";
import { useLocation } from "react-router-dom";
import { WorkRequestServiceTypeSelectSearchProps } from "../types";

const WorkRequestServiceTypeSelectSearch = ({
  serviceType,
  isVerifyMode,
  clientId,
  isManager,
  workTypes,
  serviceTypes,
  setFormData,
  disabled,
  noAuthServiceList,
  setServiceTypeId,
}: WorkRequestServiceTypeSelectSearchProps) => {
  const { isMobile } = useIsMobile();
  const location = useLocation();

  const defaultServiceType = location?.state?.serviceType;
  const dropdownServiceTypes = useMemo(() => {
    const serviceTypesManager = isManager && clientId ? serviceTypes?.[clientId] : [];

    const dataSource = (isManager ? serviceTypesManager : noAuthServiceList) || [];

    return (dataSource as ServiceTypeItem[])
      ?.filter(
        (item: ServiceTypeItem) =>
          item.showOnPublic !== false &&
          Object.values(item?.classifications || {}).some((classification) => classification?.showOnPublic !== false),
      )
      .map((item: ServiceTypeItem) => {
        return {
          id: item.id,
          value: item.description,
          longDescription: item.longDescription,
        };
      });
  }, [workTypes, serviceTypes, clientId]);
  if (disabled) {
    return (
      <InputRow $isMobile={isMobile}>
        <label>Service Type:</label>
        <div>
          <Input placeholder={"Select Service Type"} disabled value={serviceType}></Input>
        </div>

        <HintIcon
          style={{
            visibility: defaultServiceType?.longDescription ? "visible" : "hidden",
          }}
          title="Edit Mode"
          data-tip={
            defaultServiceType?.longDescription ? (defaultServiceType?.longDescription).replace(/<br ?\/?>/g, "\n") : ""
          }
          icon={faQuestionCircle}
        />
      </InputRow>
    );
  }

  return (
    <InputRow $isMobile={isMobile} $isSelectSearch>
      <label>Service Type:</label>
      <SelectSearch
        defaultValue={serviceType}
        placeholder={"Select Service Type"}
        disabled={isVerifyMode || !clientId}
        $isMobile={isMobile}
        options={dropdownServiceTypes as OptionItem[]}
        onChange={(item: DropdownItem) => {
          setFormData((data) => ({ ...data, serviceType: item.id as string, classification: "" }));
          setServiceTypeId(String(item.id));
        }}
        bottomResultsGap={43}
      />

      <HintIcon
        style={{
          visibility: !!dropdownServiceTypes?.find((el) => el.id === serviceType)?.longDescription
            ? "visible"
            : "hidden",
        }}
        data-tip={`<div style="overflow:hidden">${(
          dropdownServiceTypes?.find((el) => el.id === serviceType)?.longDescription || ""
        ).replace(/<br ?\/?>/g, "\n")}</div>`}
        icon={faQuestionCircle}
      />
    </InputRow>
  );
};

export default WorkRequestServiceTypeSelectSearch;
