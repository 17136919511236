import moment from "moment/moment";
import React from "react";
import { FirebaseDataService } from "../../../services/Firebase/data";
import { Client, DBLocation, DBPayPeriod, EmployeePayrollData, ID } from "../../../types";
import { groupArray, sortBy } from "../../../utils";
import { EmployeesApprovedHoursFormProps, StructuredPayPeriods } from "../ClientDetailsProvider";
import { DEFAULT_TIMEZONE_VALUE, PAY_PERIODS_BY_ABBREVIATURE } from "../constants";
import { formatToISO, generatePayPeriodDayId } from "./PayPeriod/utils";

export const getPayPeriodDataFromId = (payPeriodId: string) => {
  const YEAR_INDEX = 0;
  const TYPE_INDEX = 1;
  const NUMBER_INDEX = 2;

  return {
    year: Number(payPeriodId.split("-")[YEAR_INDEX]),
    type: payPeriodId.split("-")[TYPE_INDEX],
    number: Number(payPeriodId.split("-")[NUMBER_INDEX]),
  };
};

export const generatePayPeriodName = ({ number, startDate, endDate }: DBPayPeriod) => {
  return `Pay Period ${number}: ${moment(formatToISO(startDate))
    .tz(DEFAULT_TIMEZONE_VALUE)
    .format("MM/DD/YYYY")} - ${moment(formatToISO(endDate)).tz(DEFAULT_TIMEZONE_VALUE).format("MM/DD/YYYY")}`;
};

export const generatePayPeriodIdUrlParam = ({ year, payPeriodTypeCode, number }: DBPayPeriod) =>
  `${year}-${PAY_PERIODS_BY_ABBREVIATURE[payPeriodTypeCode]}-${number}`;

export const generatePayPeriodId = ({ year, payPeriodTypeCode, number, countryCode }: Partial<DBPayPeriod>) =>
  `${countryCode}${year}${payPeriodTypeCode}${number}`;

export const getClientLocations = async (
  clientId: ID,
  setLocations: React.Dispatch<React.SetStateAction<DBLocation[]>>,
): Promise<DBLocation[]> => {
  const { data } = await FirebaseDataService.getLocationByClientId(clientId);
  if (data) {
    setLocations(Object.values(data));
  }

  return Object.values(data);
};

export const getPayPeriods = async (
  countryCode: string,
  setPayPeriods: React.Dispatch<React.SetStateAction<StructuredPayPeriods>>,
) => {
  const { data } = await FirebaseDataService.getPayPeriodsByCountryCode(countryCode);
  if (data) {
    const groupedByPayPeriodTypeCode: { [key: string]: DBPayPeriod[] } = groupArray(
      Object.values(data),
      "payPeriodTypeCode",
    );
    const groupedByYear = Object.entries(groupedByPayPeriodTypeCode).reduce((acc, [payPeriodTypeCode, payPeriods]) => {
      const formattedPayPeriods = payPeriods.map((period) => ({ ...period, number: +period.number }));
      return {
        ...acc,
        [payPeriodTypeCode]: groupArray(sortBy(formattedPayPeriods, "number", false), "year"),
      };
    }, {});
    setPayPeriods(groupedByYear);
  }
};

export const determineClientLocation = async (
  clientObject: Client,
  clientId: string,
  setLocations: React.Dispatch<React.SetStateAction<DBLocation[]>>,
) => {
  const locations = await getClientLocations(clientId, setLocations);
  const { countryCode: clientCountryCode } = clientObject;
  const locationsCountryCodes = locations.map(({ countryCode }) => countryCode).filter((countryCode) => countryCode);

  return clientCountryCode || locationsCountryCodes?.[0] || "CA";
};

export const generateEmployeesApprovedHoursForm = (clientPayrollData: EmployeePayrollData[]) =>
  clientPayrollData.reduce((outerAcc, { employeeId, payPeriodDays }): EmployeesApprovedHoursFormProps => {
    const employeeApprovedHours = payPeriodDays.reduce(
      (acc, { date, approvedHours, rawHours, classification }) => ({
        ...acc,
        [generatePayPeriodDayId(date)]: {
          approvedHours,
          rawHours,
          classification,
        },
      }),
      {},
    );

    return {
      ...outerAcc,
      [employeeId]: employeeApprovedHours,
    };
  }, {});

export const mapRoleWithEmployeeDisplayName = ({ employeeDisplayName, roles }: EmployeePayrollData) => {
  if (roles && roles.vendor) {
    return `(V) ${employeeDisplayName}`;
  }
  return employeeDisplayName;
};
