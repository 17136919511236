import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatToISO } from "src/pages/ClientDetails/TimeAndAttendance/PayPeriod/utils";
import { colors } from "src/styles";
import styled from "styled-components";
import Input from "../Input";

interface DatePickerCustomProps {
  date: string;
  placeholder: string;
  onChangeHandler: (value: string, fieldName: string) => void;
  dateFormat: string;
  showTimeOnly?: boolean;
  fieldName: string;
  icon: IconProp;
}

const DatePickerCustom = ({
  dateFormat,
  date,
  placeholder,
  showTimeOnly,
  onChangeHandler,
  fieldName,
  icon,
}: DatePickerCustomProps) => {
  const format24Htime = showTimeOnly && date ? moment(date, ["h:mm A"]).format("HH:mm") : "";
  const currentDateWithTime = moment().set({
    hour: format24Htime ? parseInt(format24Htime.split(":")[0]) : undefined,
    minutes: format24Htime ? parseInt(format24Htime.split(":")[1]) : undefined,
    seconds: 0,
  });

  const inputValueForDate = !showTimeOnly && date ? moment(formatToISO(date)).format(dateFormat) : "";
  const inputValue = showTimeOnly ? moment(currentDateWithTime).format(dateFormat) : inputValueForDate;

  const selectedValue = showTimeOnly ? currentDateWithTime.toDate() : new Date(date);
  const onlyTimeSelected = showTimeOnly ? null : new Date();

  const onDateChange = (data: Date) => {
    const showTimeValue = data && (!showTimeOnly ? formatToISO(data.toString()) : data);
    const value = data ? showTimeValue : "";
    onChangeHandler(value.toString(), fieldName);
  };
  return (
    <Wrapper>
      <DatePicker
        selected={date ? selectedValue : onlyTimeSelected}
        showTimeInput={showTimeOnly}
        showTimeSelectOnly={showTimeOnly}
        inline={showTimeOnly}
        timeIntervals={1}
        dateFormat="h:mm aa"
        disabledKeyboardNavigation
        onChange={onDateChange}
        customInput={
          <div>
            <CustomInputDate
              onChange={(e) => onChangeHandler(e.target.value, fieldName)}
              placeholder={placeholder}
              value={date ? inputValue : ""}
            />
            <span>
              <Icon icon={icon} />
            </span>
          </div>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &
    .react-datepicker__tab-loop
    .react-datepicker-popper
    .react-datepicker
    .react-datepicker__month-container
    .react-datepicker__month
    .react-datepicker__week
    .react-datepicker__day--selected {
    background-color: ${colors.kleenway.greenLight};
    color: ${colors.grey.dark};
  }
  & .react-datepicker--time-only {
    border-radius: unset;
  }

  & .react-datepicker--time-only > .react-datepicker__input-time-container {
    width: unset;
    margin: 0;

    & > .react-datepicker-time__caption {
      display: none;
    }
    & > .react-datepicker-time__input-container {
      & > .react-datepicker-time__input {
        margin-left: 0;
        & > input {
          padding: 5px;
          border: 0;
          margin-left: 0;
        }
      }
    }
  }
`;

const CustomInputDate = styled(Input)`
  position: relative;
  padding: 8px 10px;
  font-size: 14px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  position: absolute;
  right: 12px;
  top: 8px;
  height: 23px;
  width: 19px;
`;
export default DatePickerCustom;
