import axios from "axios";
import { useState } from "react";
import { excelExport, ExcelExportDateProps } from "src/types";
import { debugError } from "src/utils/log";

export const useGetExcel = ({ baseURL, id, payPeriod, clientName, payPeriodName, excelType }: excelExport) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const generateDownloadedFileName = ({ fromDate, toDate }: ExcelExportDateProps) =>
    `${
      payPeriodName ?? `Custom Period "${fromDate?.replace("/", "_")} - ${toDate?.replace("/", "_")}`
    } - ${clientName.replace(/\./g, "")} - ${excelType}`;

  const getExcel = async ({ fromDate, toDate }: ExcelExportDateProps) => {
    try {
      setLoading(true);
      /* sending request */
      const xlsxData = await axios.get(baseURL, {
        params: {
          clientID: id,
          payPeriod,
          fromDate,
          toDate,
        },
        responseType: "arraybuffer",
      });

      /* creating file without saving to server */
      const blob = new Blob([xlsxData.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const a = document.createElement("a");
      const url = URL.createObjectURL(blob);
      a.href = url;
      /* setting up file name */
      a.download = generateDownloadedFileName({ fromDate, toDate });
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      debugError(error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const excelHandler = async (fromDate?: string, toDate?: string) => {
    return getExcel({ fromDate, toDate });
  };

  return { excelHandler, loading, errorMessage: error };
};
