import React, { createContext } from "react";
import { Ticket } from "src/types";

interface WorkRequestListContextProps {
  selectedTicketForCloseAction?: Ticket;
  setSelectedTicketForCloseAction: React.Dispatch<React.SetStateAction<Ticket | undefined>>;
  selectedTicketForAssignResource: Ticket | undefined;
  setSelectedTicketForAssignResource: React.Dispatch<React.SetStateAction<Ticket | undefined>>;
  activeTab: string | null | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  category: string | null | undefined;
  setCategory: (newValue: string | null | undefined) => void;
  direction: string | null | undefined;
  setDirection: (newValue: string | null | undefined) => void;
  searchText: string | null | undefined;
  setSearchText: (newValue: string | null | undefined) => void;
  pageNumber: number | null | undefined;
  setPageNumber: (newValue: number | null | undefined) => void;
  appliedSearch: boolean | null | undefined;
  setAppliedSearch: (newValue: boolean | null | undefined) => void;
}

const WorkRequestListContext = createContext<WorkRequestListContextProps>({} as WorkRequestListContextProps);

export default WorkRequestListContext;
