import React from "react";
import { default as _Button } from "src/components/Button";
import { colors } from "src/styles";
import styled from "styled-components";

interface SubmittedTicketProps {
  onClickCreateAnotherTicket: () => void;
  successMessage: string;
}

const SubmittedTicket: React.FC<SubmittedTicketProps> = ({ onClickCreateAnotherTicket, successMessage }) => {
  return (
    <Container>
      <Text>{successMessage}</Text>
      <Button onClick={onClickCreateAnotherTicket} text={"Create Another Work Request"} />
    </Container>
  );
};
export default SubmittedTicket;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.white.default};
`;

const Text = styled.div`
  margin-bottom: 60px;
  padding: 32px 24px;
  max-width: 600px;
  max-height: 120px;
  background: #e9fae1;
  border: 5px solid #81c17d;
  border-radius: 5px;
`;

const Button = styled(_Button)`
  padding: 14px 33px;
  max-width: 300px;
  max-height: 55px;
  background: #b9e0a5;
  border: 1px solid #b9e0a5;
  border-radius: 5px;
  outline: none;
`;
