import React from "react";
import { useFormContext } from "react-hook-form";
import { WOTab } from "../styles";
import { VendorWOResourceTabProps } from "../types";
import { ApprovedAmount } from "./ApprovedAmount";
import Billable from "./Billable";
import SearchResource from "./SearchResource";

const VendorWOResourceTab = ({ vendors }: VendorWOResourceTabProps) => {
  const { watch, setValue } = useFormContext();
  const vendorId = watch("vendors.vendorId");
  const isDisplayApprovedAmount = watch("vendors.woacceptscharges");

  return (
    <WOTab gap={3}>
      <Billable />
      {isDisplayApprovedAmount && <ApprovedAmount />}
      <SearchResource
        options={vendors.map((emp) => {
          return {
            id: emp.vendorId,
            value: emp.displayName,
          };
        })}
        selectedValue={vendorId}
        onSelectOption={(option) => {
          setValue("vendors.vendorId", option?.id);
          setValue("employees.laborCode", undefined);
        }}
      />
    </WOTab>
  );
};

export default VendorWOResourceTab;
