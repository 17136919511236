import React from "react";
import { Stack } from "react-bootstrap";
import { AdjustmentFormViewProps } from "../types";
import { AdjustmentAmount } from "./AdjustmentAmount";
import { AdjustmentComment } from "./AdjustmentComment";
import { AdjustmentFormButtons } from "./AdjustmentFormButtons";
import { AdjustmentReason } from "./AdjustmentReason";

export const AdjustmentFormView = ({ reasons, onCancel }: AdjustmentFormViewProps) => {
  return (
    <Stack gap={3}>
      <AdjustmentAmount />
      <AdjustmentReason reasons={reasons} />
      <AdjustmentComment />
      <AdjustmentFormButtons onCancel={onCancel} />
    </Stack>
  );
};
