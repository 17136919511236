import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DBKleenwayContact } from "src/types";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { DropdownItem } from "../../../../components/Dropdown/types";
import SelectSearch from "../../../../components/SelectSearch";
import { colors } from "../../../../styles";
import ClientDetailsContext from "../../context";

interface KleenwayContactFormProps {
  formIndex: number;
  handleFormDelete: () => void;
}

export const KleenwayContactForm = ({ formIndex, handleFormDelete }: KleenwayContactFormProps) => {
  const { generalInfoFormState, setGeneralInfoFormState, kleenwayContacts, dropdownListSizes } =
    useContext(ClientDetailsContext);

  const [selectedContact, setSelectedContact] = useState<DBKleenwayContact | undefined>({} as DBKleenwayContact);

  const handleNameChange = (contact: DropdownItem) => {
    setGeneralInfoFormState(({ kleenwayContacts, ...prevState }) => ({
      ...prevState,
      kleenwayContacts: [
        ...kleenwayContacts.slice(0, formIndex),
        { ...kleenwayContacts[formIndex], isValid: true, ...contact },
        ...kleenwayContacts.slice(formIndex + 1),
      ],
    }));
  };

  useEffect(() => {
    setSelectedContact(
      kleenwayContacts?.find(({ id }) => id === generalInfoFormState?.kleenwayContacts?.[formIndex]?.id),
    );
  }, [kleenwayContacts, generalInfoFormState, formIndex]);

  return (
    <Container>
      <Header>
        <Title>{`Kleenway Contact ${formIndex + 1}:`}</Title>
        <Button text="Delete Contact" variant="text" onClick={handleFormDelete} />
      </Header>
      <Content>
        <Form>
          <InputsContainer>
            <InputsRow>
              <InputRow>
                <label>Name: </label>
                <SelectSearch
                  defaultValue={generalInfoFormState?.kleenwayContacts?.[formIndex]?.id}
                  placeholder="Select Contact"
                  options={kleenwayContacts.map((contact) => ({
                    ...contact,
                    value: `${contact.firstName} ${contact.lastName}`,
                  }))}
                  onChange={handleNameChange}
                  listSize={dropdownListSizes?.kleenwayContact}
                  bottomResultsGap={45}
                />
              </InputRow>
              {selectedContact?.title && (
                <InputRow>
                  <label>Job Description:</label>
                  <div>
                    <p>{selectedContact?.title}</p>
                  </div>
                </InputRow>
              )}
            </InputsRow>

            <InputsRow>
              {selectedContact?.email && (
                <InputRow>
                  <label>Email:</label>
                  <div>
                    <p>{selectedContact.email}</p>
                  </div>
                </InputRow>
              )}
              {selectedContact?.phone && (
                <InputRow>
                  <label>Phone:</label>
                  <div>
                    <p>{selectedContact?.phone}</p>
                  </div>
                </InputRow>
              )}
            </InputsRow>
          </InputsContainer>
        </Form>
      </Content>
    </Container>
  );
};

const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px 0 30px 0;
`;

const Content = styled.div`
  background-color: ${colors.white.default};
  padding: 10px 0 30px 0;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: 325px;
  width: 100%;
  position: relative;
  margin-right: 20px;

  div {
    p {
      font-size: 14px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    color: ${colors.grey.dark};
    white-space: nowrap;
    margin-right: 20px;
  }

  input {
    width: 175px;
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
