import React, { useContext } from "react";
import { Cell } from "src/components/Table/Cell";
import TableCell from "src/components/Table/TableCell";
import TableRow from "src/components/Table/TableRow";
import { colors } from "src/styles";
import { Ticket } from "src/types";
import { useSelector } from "react-redux";
import { getUserData } from "src/redux/selectors/user";
import Badge from "src/components/Badge";
import { useNavigate } from "react-router-dom";
import { TablePriorityBadge } from "../styles";
import TooltipComponent from "src/components/TooltipComponent";
import { getPriorityLevels } from "src/redux/selectors/resources";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const ClientTableRow = ({ ticket }: { ticket: Ticket }) => {
  const navigate = useNavigate();
  const { searchText } = useContext(WorkRequestListContext);

  const { clientId } = useSelector(getUserData);
  const priorityLevels = useSelector(getPriorityLevels);

  const pickedPriorityLevel = priorityLevels?.[ticket.clientId ?? clientId]?.find(
    ({ priority }) => priority === ticket.priorityId,
  );

  const splittedDate = ticket.dateCreated.split(", ");
  const onRowClick = (wonum: string) => {
    navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
  };
  return (
    <TableRow
      onClick={() => {
        onRowClick && onRowClick(ticket.wonum);
      }}
      $clickable={true}
      key={ticket.wonum}
    >
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.wonum}
      </Cell>
      <Cell highlight={searchText ?? ""} bold={splittedDate[0] ?? ""}>
        {searchText ?? ""?.trim().length ? ticket.dateCreated : `, ${splittedDate[1] ?? ""}`}
      </Cell>

      <Cell showMark highlight={searchText ?? ""}>
        {ticket.location}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.description ?? ""}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.serviceType}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.classification}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.workType ?? ""}
      </Cell>
      <TableCell>
        <TablePriorityBadge color={pickedPriorityLevel ? pickedPriorityLevel.color : colors.grey.default}>
          {ticket.priority}
        </TablePriorityBadge>
      </TableCell>
      <TableCell>
        <TooltipComponent
          text={ticket.statusDescription as string}
          renderChildren={<Badge status={ticket.statusDescription as string} />}
        />
      </TableCell>
    </TableRow>
  );
};

export default ClientTableRow;
