import React, { createContext } from "react";
import { GeneralInfoFormStateProps, SupervisorOption } from "./LocationDetailsProvider";
import { DropdownItem } from "../../../components/Dropdown/types";
import { Client, DBDropdownListSizes, DBLocation } from "../../../types";

interface LocationDetailsContextProps {
  location: DBLocation;
  setLocation: React.Dispatch<React.SetStateAction<DBLocation>>;
  client: Client | null;
  setClient: React.Dispatch<React.SetStateAction<Client | null>>;
  isGeneralInfoFormSaving: boolean;
  setIsGeneralInfoFormSaving: React.Dispatch<React.SetStateAction<boolean>>;
  generalInfoFormState: GeneralInfoFormStateProps;
  setGeneralInfoFormState: React.Dispatch<React.SetStateAction<GeneralInfoFormStateProps>>;
  dropdownListSizes: DBDropdownListSizes;
  setDropdownListSizes: React.Dispatch<React.SetStateAction<DBDropdownListSizes>>;
  isGeneralInfoFormValid: boolean;
  setIsGeneralInfoFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  supervisors: SupervisorOption[];
  messages: DropdownItem[];
  isSupervisorsLoaded: boolean;
  isMessagesLoaded: boolean;
}

const LocationDetailsContext = createContext<LocationDetailsContextProps>({} as LocationDetailsContextProps);

export default LocationDetailsContext;
