import { colors } from "src/styles";

// a weird fix for now, will refactor some time later
export const ButtonStyles = (error: boolean, checked?: boolean) => {
  const regularBorderColor = checked ? colors.green.default : colors.grey.light1;

  return {
    marginLeft: "20px",
    padding: "10px",
    fontWeight: 400,
    fontSize: "16px",
    borderRadius: 0,
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: `${error ? colors.kleenway.error : regularBorderColor}`,
    backgroundColor: checked ? colors.kleenway.greenLight : colors.grey.light2,
  };
};
