import React, { useEffect, useMemo } from "react";
import { Col, Spinner, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setPageNameHeader } from "src/redux/actions/header";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService, GetWidgetsDataResponse } from "src/services/Firebase/data";
import { Content, colors } from "src/styles";
import { testGroup } from "src/utils/helpers";
import styled from "styled-components";
import FileSharing from "../FileSharing";

interface WidgetProps {
  caption: string;
}

const getStatusLink = (statusCode: string, status: string) => {
  const closedStatuses = ["COMP", "CLOSE", "CAN"];
  if (closedStatuses.includes(statusCode)) {
    return `/workrequests?closedWorkRequestsPage=1&searchTerm=${status}`;
  }

  return `/workrequests?openedWorkRequestsPage=1&searchTerm=${status}`;
};

const WorkRequestStatistics = ({ caption }: WidgetProps) => {
  const { id: loggedInUserId, isManager } = useSelector(getUserData);
  const [data, setData] = React.useState<GetWidgetsDataResponse>();

  const fetchData = async () => {
    const { data } = await FirebaseDataService.getWidgetsData(loggedInUserId, isManager);

    setData(data);
  };

  const total = useMemo(() => {
    return data?.widgetsData.workRequestsStatistics.reduce((acc, item) => acc + item.count, 0) ?? 0;
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <WidgetContainer>
      <Stack gap={3}>
        <h1 style={{ fontWeight: 600 }}>{caption}</h1>
        {data?.widgetsData?.workRequestsStatistics ? (
          <Grid>
            <GridHeader>Status</GridHeader>
            <GridHeader>Count</GridHeader>
            <GridHeader>Percentage</GridHeader>
            {data.widgetsData.workRequestsStatistics.map((item) => (
              <>
                <GridCell>
                  <Status $maxvalue={item.maxvalue}>{item.status}</Status>
                </GridCell>
                <GridCell>
                  <StyledLink to={getStatusLink(item.statusCode, item.status)}>{item.count}</StyledLink>
                </GridCell>
                <GridCell>
                  <Stack direction="horizontal" gap={3}>
                    <ProgressContainer>
                      <ProgressBar $percentage={Math.round((item.count * 100) / total)} />
                    </ProgressContainer>
                    {Math.round((item.count * 100) / total)}%
                  </Stack>
                </GridCell>
              </>
            ))}
            <GridFooter>Total</GridFooter>
            <GridFooter>
              <StyledLink to="/workrequests">{total}</StyledLink>
            </GridFooter>
            <GridFooter></GridFooter>
          </Grid>
        ) : (
          <Spinner animation="border" />
        )}
      </Stack>
    </WidgetContainer>
  );
};

const Home = () => {
  const dispatch = useDispatch();

  const { email } = useSelector(getUserData);

  const isTestGroup = testGroup.includes(email ?? "");

  useEffect(() => {
    dispatch(setPageNameHeader("Home"));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, []);

  return (
    <>
      {isTestGroup ? (
        <Content>
          <StyledStack direction="horizontal" gap={5}>
            <Col>
              <WorkRequestStatistics caption="Work Requests Statistics"></WorkRequestStatistics>
            </Col>
            <Col></Col>
          </StyledStack>
        </Content>
      ) : (
        <FileSharing />
      )}
    </>
  );
};

export default Home;

const StyledStack = styled(Stack)``;

const WidgetContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.grey.light11};
  border-top: 4px solid ${colors.kleenway.green};
  padding: 16px;
  border-radius: 6px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 5fr;
`;

const GridHeader = styled.div`
  border-bottom: 2px solid ${colors.grey.light11};
  padding: 4px 4px 8px 7px;
  font-size: 12px;
  margin-bottom: 4px;
`;

const GridFooter = styled.div`
  border-top: 2px solid ${colors.grey.light11};
  padding: 8px 4px 4px 7px;
  font-size: 12px;
  margin-top: 4px;
`;

const GridCell = styled.div`
  padding: 8px 8px 8px 7px;
  font-size: 12px;
`;

const statusColors: { [key: string]: string } = {
  WAPPR: colors.grey.light10,
  APPR: "#deebff",
  WSCH: "#deebff",
  WMATL: "#deebff",
  WPCOND: "#deebff",
  INPRG: "#deebff",
  COMP: colors.kleenway.greenLight,
  CLOSE: colors.kleenway.greenLight,
  CAN: colors.kleenway.greenLight,
  HISTEDIT: colors.grey.default,
};

const Status = styled.div<{ $maxvalue: string }>`
  background-color: ${({ $maxvalue }) => statusColors[$maxvalue] ?? colors.grey.light10};
  border-radius: 30px;
  padding: 4px 10px;
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  white-space: nowrap;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.grey.light3};
  height: 5px;
  border-radius: 5px;
`;

const ProgressBar = styled.div<{ $percentage?: number }>`
  width: ${({ $percentage }) => $percentage}%;
  background-color: ${colors.kleenway.green};
  border-radius: 5px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;
