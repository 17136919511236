import React from "react";
import { ListForm } from "../ListForm";
import { ShiftForm } from "../ShiftForm";
import { shiftFactory } from "../ShiftForm/utils";
import { ShiftListProps } from "./types";

/**
 * Renders and manages a list of Shifts.
 */
export const ShiftListForm = ({ name }: ShiftListProps) => (
  <ListForm
    name={name}
    keyName="keyLabel"
    getNewItem={shiftFactory.createShift}
    renderItem={(item, index, canDelete, onDelete) => (
      <ShiftForm name={`${name}.${index}`} shift={item} canDelete={canDelete} onDelete={onDelete} />
    )}
    itemName="Shift"
    variant="compact"
  />
);
