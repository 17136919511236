import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../store";

export const getUser = (state: RootState) => state.user;

export const getLoggedIn = createSelector([getUser], (user) => user.loggedIn);
export const getLoginDate = createSelector([getUser], (user) => user.loginDate);
export const getUserData = createSelector([getUser], (user) => ({
  id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  clientId: user.clientId,
  vendorId: user.vendorId,
  photo: user.photo,
  profilePicture: user.profilePicture,
  maximoTimezone: user.maximoTimezone,
  loggedIn: user.loggedIn,
  phoneNumber: user.phoneNumber,
  isManager: user.isManager,
  isVendor: user.vendorId !== "",
  isClient: user.clientId !== "",
}));
