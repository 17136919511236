//@ts-ignore
import APP_LOGO from "./logo_h320.png";
//@ts-ignore
import COLLAPSED_LOGO_NAV from "./collapsedLogoNav.svg";
//@ts-ignore
import EXPANDED_LOGO_NAV from "./expandedLogoNav.png";

//@ts-ignore
import EXPANDED_LOGO_WHITE from "./expandedLogoWhite.png";

//@ts-ignore
import DEFAULT_PROFILE_ICON from "./defaultProfileIcon.svg";
//@ts-ignore
import DEFAULT_EVACUATION_PLAN_ICON from "./defaultEvacuationPlanIcon.svg";
//@ts-ignore

//after all updates, this icon will be used instead of  DEFAULT_PROFILE_ICON
import DEFAULT_PROFILE_ICON_FLY_DOWN from "./NoProfileImg.svg";

interface Assets {
  IMAGES: {
    APP_LOGO: string;
    COLLAPSED_LOGO_NAV: string;
    EXPANDED_LOGO_NAV: string;
    EXPANDED_LOGO_WHITE: string;
    DEFAULT_PROFILE_ICON: string;
    DEFAULT_EVACUATION_PLAN_ICON: string;
    DEFAULT_PROFILE_ICON_FLY_DOWN: string;
  };
}

export const IMAGES: Assets["IMAGES"] = {
  APP_LOGO,
  COLLAPSED_LOGO_NAV,
  EXPANDED_LOGO_NAV,
  EXPANDED_LOGO_WHITE,
  DEFAULT_PROFILE_ICON,
  DEFAULT_EVACUATION_PLAN_ICON,
  DEFAULT_PROFILE_ICON_FLY_DOWN,
};

export default {
  IMAGES,
} as Assets;
