import { Stack } from "react-bootstrap";
import { colors } from "src/styles";
import styled from "styled-components";

export const WOTab = styled(Stack)`
  height: 300px;
  overflow: auto;
`;

export const StyledRadioInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #4caf50;
  outline: none;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border-color: #4caf50;
    background-color: #4caf50;
  }
`;
export const StyledCheckbox = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  border: 2px solid #4caf50;
  outline: none;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border-color: #4caf50;
    background-color: #4caf50; /* Optional: Change background color when checked */
  }

  &:checked::after {
    content: "\u2714"; /* Unicode checkmark symbol */
    display: block;
    width: 12px;
    height: 12px;
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    color: white;
  }
`;

export const AssignWOButtonContainer = styled(Stack)`
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
`;

export const ErrorMsg = styled.p`
  font-size: 10px;
  color: ${colors.red.dark};
`;

export const OptionsWrapper = styled(Stack)`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  span {
    font-size: 14px;
    color: ${colors.grey.dark};
    padding-left: 8px;
  }
`;
