import React, { useRef } from "react";
import { GoToPage } from "../styles";

interface GoToPageInputProps {
  goToPage: (page: number) => void;
  pageNum: string | number;
  setPageNum: React.Dispatch<React.SetStateAction<string | number>>;
}

export const GoToPageInput = ({ goToPage, pageNum, setPageNum }: GoToPageInputProps) => {
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === "Enter") {
      event.preventDefault();
      goToPage(pageNum as number);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  return (
    <GoToPage>
      <label htmlFor="goToPage">
        Go to page
        <input
          ref={inputRef as React.LegacyRef<HTMLInputElement> | undefined}
          onKeyPress={handleKeyDown}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            goToPage(parseInt(e.target.value));
          }}
          onChange={(e) => setPageNum(parseInt(e.target.value))}
          placeholder="#"
          id="goToPage"
        />
      </label>
    </GoToPage>
  );
};
