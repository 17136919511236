import { colors } from "src/styles";
import styled from "styled-components";

export const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  padding-right: 40px;
  white-space: nowrap;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  & > div {
    width: 100%;
  }
`;

export const StyledLabel = styled.label`
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
  min-width: 129px;
  margin-bottom: auto;
  padding-top: 6px;
  color: ${colors.grey.dark};
`;

export const TextareaInput = styled.textarea<{ $error: boolean }>`
  padding-right: 14px;
  font-size: 14px;
  color: ${colors.grey.dark};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $error }) => ($error ? colors.kleenway.error : colors.grey.light1)};
  border-radius: 4px;
  width: 100%;
  min-height: 80px;
  max-height: 240px;
  padding: 10px 12px;
`;
