import React, { useEffect } from "react";
import { FormProvider, UseFormProps, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from "src/components/Button";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Client, DBLocation } from "src/types";
import DynamicForms from "../molecules/DynamicForms";
import { onSubmitHandler } from "./handlers";
import { ButtonContainer, Form, SubmitContainer } from "./styles";
import { DynamicFormConfig, FormData } from "./types";
import { useDispatch } from "react-redux";
import { setSubmittedPageName } from "src/redux/actions/dynamicForms";

const DynamicFormContainer = ({
  formConfig,
  clientsArray,
  defaultValues,
  locationsArray,
}: {
  formConfig: DynamicFormConfig;
  defaultValues: UseFormProps["defaultValues"];
  clientsArray: Client[];
  locationsArray: DBLocation[];
}) => {
  const { isMobile } = useIsMobile();

  const form = useForm<FormData>({ defaultValues });
  const { isSubmitSuccessful } = form.formState;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(setSubmittedPageName(formConfig.name));
      navigate(-1);
    } else {
      dispatch(setSubmittedPageName(""));
    }
  }, [isSubmitSuccessful]);
  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit((data) => onSubmitHandler(id ?? "", data, clientsArray ?? []))}>
        <div>
          <DynamicForms
            clientsArray={clientsArray}
            locationsArray={locationsArray}
            dynamicFields={formConfig?.fields ?? []}
          />
          <SubmitContainer $isMobile={isMobile}>
            <ButtonContainer>
              <Button disabled={form.formState.isSubmitting} loading={form.formState.isSubmitting} text="Submit" />
            </ButtonContainer>
          </SubmitContainer>
        </div>
      </Form>
    </FormProvider>
  );
};
export default DynamicFormContainer;
