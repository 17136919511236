import React, { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DropdownItem } from "src/components/Dropdown/types";
import { PaginationContainer } from "src/components/ListScreen";
import { Pagination } from "src/components/Pagination";
import { clearWorkRequestMessage } from "src/redux/actions/workRequest";
import { getUserData } from "src/redux/selectors/user";

import { CloseWorkOrderFormValues } from "../CloseWorkOrderForm/types";
import WorkRequestActionsHeader from "./components/WorkRequestActionsHeader";
import { clearWorkRequestMessageTimeout, getWorkOrders, onSubmitCloseWorkOrderHandler } from "./handler";
import { TabsWrapper } from "./styles";
import { WorkRequestListViewContainerProps } from "./types";
import WorkRequestModalsContainer from "../WorkRequestModalsContainer";
import { initialSortingOption } from "./constant";
import {
  getWorkRequestMessage,
  getWorkRequestMessageVariant,
  getWorkOrderCount,
} from "src/redux/selectors/workRequest";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const WorkRequestListViewContainer = ({
  workRequestsPage,
  setWorkRequestsPage,
  workRequestType,
  directions,
  defaultItem,
  defaultDirection,
  items,
  category,
  direction,
  setCategory,
  setDirection,
  setSearchText,
  searchText,
  sibCount,
  pageSize,
  isRedirectToDetailPageAllowed,
  renderTable,
}: WorkRequestListViewContainerProps) => {
  const [searchInput, setSearchInput] = useState<string>(searchText);
  const { id: loggedInUserId, isManager, vendorId, maximoTimezone } = useSelector(getUserData);
  const workRequestMessage = useSelector(getWorkRequestMessage);
  const workRequestAlertvariant = useSelector(getWorkRequestMessageVariant);
  const counts = useSelector(getWorkOrderCount);
  const {
    selectedTicketForCloseAction,
    setSelectedTicketForCloseAction,
    setSearchText: setSearchTextValue,
    setAppliedSearch,
    appliedSearch,
  } = useContext(WorkRequestListContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (workRequestType) {
      getWorkOrders(
        {
          isManager,
          loggedInUserId,
          vendorId,
          workRequestsPage,
          workRequestType,
          isRedirectToDetailPageAllowed,
          isSearchJustApplied: false,
          filters: {
            pageSize,
            orderBy: [[category ?? initialSortingOption.sort, direction ?? initialSortingOption.direction]],
          },
          searchKeyword: searchText,
          pageNum: workRequestsPage || 1,
        },
        {
          setWorkRequestsPage,
          navigate,
          dispatch,
        },
      );
    }
  }, [workRequestType]);

  const onSubmitCloseWorkOrder = async (values: CloseWorkOrderFormValues) => {
    const res = await onSubmitCloseWorkOrderHandler(
      values,
      selectedTicketForCloseAction,
      maximoTimezone,
      dispatch,
      setSelectedTicketForCloseAction,
    );
    if (res) {
      await getWorkOrders(
        {
          isManager,
          loggedInUserId,
          vendorId,
          workRequestsPage,
          workRequestType,
          isRedirectToDetailPageAllowed,
          searchKeyword: searchText,
          filters: {
            pageSize,
            orderBy: [[category ?? initialSortingOption.sort, direction ?? initialSortingOption.direction]],
          },
          pageNum: workRequestsPage || 1,
        },
        {
          setWorkRequestsPage,
          navigate,
          dispatch,
        },
      );
    }
    clearWorkRequestMessageTimeout(dispatch);
  };

  const onClickApply = async () => {
    setAppliedSearch(true);
    setWorkRequestsPage(1);
    setSearchText(searchInput);
    setSearchTextValue(searchInput);
    await getWorkOrders(
      {
        isManager,
        loggedInUserId,
        vendorId,
        workRequestsPage,
        workRequestType,
        isRedirectToDetailPageAllowed,
        filters: {
          pageSize,
          orderBy: [[category ?? initialSortingOption.sort, direction ?? initialSortingOption.direction]],
        },
        pageNum: 1,
        searchKeyword: searchInput,
        isSearchJustApplied: true,
      },
      {
        setWorkRequestsPage,
        navigate,
        dispatch,
      },
    );
  };
  const onClickCancel = () => {
    setAppliedSearch(false);
    setSearchInput("");
    setSearchTextValue("");
  };
  const onClickClear = async () => {
    setAppliedSearch(false);
    setSearchInput("");
    setSearchTextValue("");

    setWorkRequestsPage(1);
    await getWorkOrders(
      {
        isManager,
        loggedInUserId,
        vendorId,
        workRequestsPage,
        workRequestType,
        isRedirectToDetailPageAllowed,
        filters: {
          pageSize,
          orderBy: [[category ?? initialSortingOption.sort, direction ?? initialSortingOption.direction]],
        },
        searchKeyword: "",
        pageNum: 1,
        isSearchJustApplied: false,
      },
      {
        setWorkRequestsPage,
        navigate,
        dispatch,
      },
    );
  };
  const onDropDownItemClick = async (sortingOption: DropdownItem) => {
    const dropDownCategory = sortingOption.filter[0][0];
    const queryFilter = [[dropDownCategory, direction ?? initialSortingOption.direction]];
    setCategory(dropDownCategory);
    setAppliedSearch(false);
    await getWorkOrders(
      {
        isManager,
        loggedInUserId,
        vendorId,
        workRequestsPage,
        workRequestType,
        isRedirectToDetailPageAllowed,
        filters: {
          pageSize,
          orderBy: queryFilter,
        },
        searchKeyword: searchText,
        pageNum: workRequestsPage || 1,
        isSearchJustApplied: false,
      },
      {
        setWorkRequestsPage,
        navigate,
        dispatch,
      },
    );
  };
  const onDropdownDirectionClick = async (sortingDirection: DropdownItem) => {
    const dropdownDirection = sortingDirection.filter[0];
    const queryFilter = [[category ?? initialSortingOption.sort, dropdownDirection]];
    setDirection(dropdownDirection);
    setAppliedSearch(false);
    await getWorkOrders(
      {
        isManager,
        loggedInUserId,
        vendorId,
        workRequestsPage,
        workRequestType,
        isRedirectToDetailPageAllowed,
        filters: {
          pageSize,
          orderBy: queryFilter,
        },
        searchKeyword: searchText,
        pageNum: workRequestsPage || 1,
        isSearchJustApplied: false,
      },
      {
        dispatch,
        navigate,
        setWorkRequestsPage,
      },
    );
  };
  const onPageChangeClick = async (pageNumber: number) => {
    setAppliedSearch(false);
    setWorkRequestsPage(pageNumber);
    await getWorkOrders(
      {
        isManager,
        loggedInUserId,
        vendorId,
        workRequestsPage,
        workRequestType,
        filters: {
          pageSize: pageSize || 1,
          orderBy: [[category ?? initialSortingOption.sort, direction ?? initialSortingOption.direction]],
        },
        searchKeyword: searchText,
        isRedirectToDetailPageAllowed,
        pageNum: pageNumber || 1,
        isSearchJustApplied: false,
      },
      {
        setWorkRequestsPage,
        navigate,
        dispatch,
      },
    );
  };
  return (
    <TabsWrapper>
      <WorkRequestActionsHeader
        applied={appliedSearch ?? false}
        defaultDirection={defaultDirection}
        defaultItem={defaultItem}
        directions={directions}
        items={items}
        onChange={setSearchInput}
        onClickApply={onClickApply}
        onClickCancel={onClickCancel}
        onClickClear={onClickClear}
        onDropDownItemClick={onDropDownItemClick}
        onDropdownDirectionClick={onDropdownDirectionClick}
        value={searchInput}
      />
      {workRequestMessage && (
        <Alert variant={workRequestAlertvariant} onClose={() => dispatch(clearWorkRequestMessage())} dismissible>
          <Alert.Heading>{workRequestMessage}</Alert.Heading>
        </Alert>
      )}
      {renderTable}
      <WorkRequestModalsContainer onSubmitCloseWorkOrderHandler={onSubmitCloseWorkOrder} />
      <PaginationContainer>
        <Pagination
          totalCount={counts}
          pageSize={pageSize}
          onPageChange={onPageChangeClick}
          currentPage={workRequestsPage}
          siblingCount={sibCount}
        />
      </PaginationContainer>
    </TabsWrapper>
  );
};

export default WorkRequestListViewContainer;
