import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPencil } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { roundTo2Decimals } from "src/utils/helpers";
import { EditCellStyle, EmployeesTableCell } from "../styles";
import { EmployeeAdjustementColumnProps } from "../types";

const EmployeeAdjustementColumn = ({ employeePayrollData, onClick }: EmployeeAdjustementColumnProps) => {
  const onClickCallback = useCallback(() => {
    onClick(employeePayrollData.employeeId.toString(), employeePayrollData.adjustment);
  }, [employeePayrollData]);
  return (
    <EmployeesTableCell>
      <EditCellStyle onClick={onClickCallback}>
        <span>
          $
          {`${
            employeePayrollData.adjustment?.adjustmentAmount
              ? roundTo2Decimals(parseFloat(employeePayrollData.adjustment?.adjustmentAmount.toString()))
              : "0.00"
          }`}
        </span>
        <FontAwesomeIcon icon={faPencil as IconProp} />
      </EditCellStyle>
    </EmployeesTableCell>
  );
};

export default EmployeeAdjustementColumn;
