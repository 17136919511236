import React from "react";
import { InputRow, InputsRow, RawValue } from "..";
import { InputsContainer } from "../..";
import { CustomWorkRequestField } from "../../CreateForm/types";
import { CustomWorkRequestFieldsViewFormProps } from "../../types";

/**
 * A helper component to render the View Form of Custom Workrequest Fields
 */
export const CustomWorkRequestFieldsViewForm = ({
  customWorkRequestFields,
  isClient,
  $isMobile,
  workOrder,
}: CustomWorkRequestFieldsViewFormProps) => {
  const isAllowedToView = (customField: CustomWorkRequestField) => {
    if (!customField.isActive) {
      return false;
    }

    if (isClient) {
      return customField.isVisibleToClient;
    }

    return true;
  };

  return (
    <InputsContainer $isMobile={$isMobile}>
      <InputsRow>
        {customWorkRequestFields?.map((customField: CustomWorkRequestField) => {
          return (
            isAllowedToView(customField) && (
              <InputRow key={customField.id}>
                <label>{customField.label}</label>
                <RawValue>{`${workOrder?.[customField.id] ?? "N/A"}`} </RawValue>
              </InputRow>
            )
          );
        })}
      </InputsRow>
    </InputsContainer>
  );
};
