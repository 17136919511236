import React from "react";

import { Controller, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { FormLabel } from "src/components/atoms/FormLabel";
import { InputRow } from "../styles";
import { CustomWorkRequestTextProps } from "../types";

export const CustomWorkRequestInput = ({ customField }: CustomWorkRequestTextProps) => {
  const { control } = useFormContext();
  return (
    <InputRow>
      <FormLabel htmlFor={customField.id} required={customField.rules?.required}>
        {customField.label}
      </FormLabel>
      <Controller
        control={control}
        name={customField.id}
        rules={customField.rules}
        defaultValue={customField.defaultValue}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Input
            controlId={customField.id}
            name={name}
            value={value}
            placeholder={typeof customField.text === "object" ? customField.text?.placeholder : ""}
            onChange={onChange}
            ref={ref}
            error={error}
          />
        )}
      />
    </InputRow>
  );
};
