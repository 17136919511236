import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlaceholderProps } from "react-bootstrap";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
import { SelectBoxProps, SelectBoxContainerProps, SelectButtonProps } from "./types";

export const SelectedItem = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;

  & > .bold {
    font-weight: 700;
  }

  color: ${(props) => props.color};
`;

export const SelectItem = styled.div`
  background-color: white;
  padding: 4px;
  padding-left: 12px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.grey.light14};
  }

  & > .bold {
    font-weight: 700;
  }

  color: ${(props) => props.color};
`;

export const Placeholder = styled.div<PlaceholderProps>`
  color: ${colors.grey.default};
  font-style: italic;
  font-size: 14px;
  color: ${colors.grey.light5};

  ${(props) => props.placeholderStyle && `${props.placeholderStyle}`}
`;

export const SelectBox = styled.div<SelectBoxProps>`
  position: relative;
  cursor: pointer;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 200px;
    `}

  ${(props) => props.disabled && `cursor: auto;`}
  ${(props) => props.width && `min-width: ${props.width};`}
`;

export const SelectInput = styled.div<{ $showItems: number; disabled: number }>`
  display: flex;
  padding: 8px;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background-color: ${({ disabled }) => disabled && colors.grey.light2};

  ${(props) => `border: 1px solid ${props.$showItems ? colors.kleenway.green : colors.grey.light13}`};
`;

export const SelectBoxContainer = styled.div<SelectBoxContainerProps>`
  box-shadow: none;
  outline: none;
  height: 28px;
  width: 100%;

  ${(props) => props.$noRadius && `border-radius: 0px;`}
  ${(props) => props.inputStyle && `${props.inputStyle}`}
`;

export const Icon = styled(FontAwesomeIcon)<SelectButtonProps>`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

export const SelectButton = styled.div<SelectButtonProps>`
  position: absolute;

  top: 7px;
  right: 7px;
  cursor: pointer;

  ${(props) => props.disabled && `cursor: auto;`}
`;

export const SelectItemsWrapper = styled.div<{ $showItems: boolean; $isScrollable: boolean }>`
  display: ${({ $showItems }) => ($showItems ? "block" : "none")};
  z-index: 1000;
  position: relative;
  overflow-y: ${({ $isScrollable }) => ($isScrollable ? "auto" : "initial")};
  max-height: 270px;
  box-shadow:
    0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08),
    0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 2px;

  & :last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  & :first-child {
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
`;

export const HoursForEmergency = styled.span`
  color: #a2a2a2;
  font-weight: 400;
`;
