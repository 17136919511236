import React from "react";
import { ClientProps } from "../types";
import { FormItemHeading, Wrapper } from "./styles";

export const FormItem: React.FC<ClientProps> = ({ item }) => {
  return (
    <Wrapper>
      <FormItemHeading>{item.name}</FormItemHeading>
    </Wrapper>
  );
};
