import React, { ChangeEvent, useRef, useState } from "react";

import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import { PhotoContainer, PhotoUploadContainer } from "src/components/PhotoUpload";
import { HEIC_TYPE, HEIF_TYPE, MAX_PHOTO_SIZE } from "src/config/сonstants";
import { ALL_FILE_TYPE } from "src/constants/validation";
import convertHeicToJpg from "src/helpers/convertHeicToJPG";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { InputRow } from "src/pages/TicketForm";
import { getUserData } from "src/redux/selectors/user";
import { MaximoDocument } from "src/types";
import { filterFiles } from "src/utils/helpers";
import styled, { css } from "styled-components";
import { AttachmentsContainer, Container, ErrorMessage } from "../Attachments";
import { Attachment } from "../Attachments/ManagerAttachment";

interface UploadWorkOrderAttachments {
  files: MaximoDocument[];
  setFiles: (files: MaximoDocument[]) => void;
  isViewMode: boolean;
}

export const UploadWorkOrderAttachments = ({ files, setFiles, isViewMode }: UploadWorkOrderAttachments) => {
  const { isMobile } = useIsMobile();
  const [isError, setIsError] = useState<boolean>(false);

  const { isManager } = useSelector(getUserData);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const onAttachmentClick = async (doc: MaximoDocument) => {
    if (doc.file) {
      window.open(URL.createObjectURL(doc.file));
    }
  };

  const onClickUpload = () => {
    hiddenFileInput.current?.click();
  };

  const onChangeUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const { supportedFiles, unsupportedFiles } = filterFiles(event.target.files, ALL_FILE_TYPE);
      setIsError(!!unsupportedFiles.length);
      const filesUploaded: MaximoDocument[] = Object.values(supportedFiles).map((item) => {
        return {
          file: item,
          state: "loading",
          error: "",
          attachmentId: "",
          description: "",
          fileName: item.name,
          href: "",
        };
      });
      Promise.resolve()
        .then(() => {
          setFiles([...files, ...filesUploaded]);
        })
        .then(() => uploadAttachments(filesUploaded));
      event.target.value = "";
    }
  };
  const uploadAttachments = async (documents: MaximoDocument[]) => {
    let docs = documents;
    for (const item of documents) {
      const file = item.file;
      if (item.state === "completed" || item.state === "error" || !item.state) {
        continue;
      } else if (!file) {
        return;
      } else if (file.size > MAX_PHOTO_SIZE) {
        alert("file size should not exceed 7 MB");
        docs = docs.filter((newFile) => newFile.fileName !== file.name);
      } else if (file.type === HEIC_TYPE || file.type === HEIF_TYPE) {
        const convertedImage = await convertHeicToJpg(file);

        if (convertedImage) {
          if (convertedImage?.size > MAX_PHOTO_SIZE) {
            alert("file size should not exceed 7 MB");
            docs = docs.filter((newFile) => newFile.fileName !== file.name);
          } else {
            docs = docs.map((newFile) => {
              if (newFile.fileName === file.name) {
                return {
                  file: convertedImage,
                  state: "completed",
                  error: "",
                  attachmentId: "",
                  description: "",
                  fileName: file.name,
                  href: "",
                };
              } else {
                return newFile;
              }
            });
          }
        }
      } else {
        docs = docs.map((newFile) => {
          if (newFile.fileName === file.name) {
            return {
              file,
              state: "completed",
              error: "",
              attachmentId: "",
              description: "",
              fileName: file.name,
              href: "",
            };
          } else {
            return newFile;
          }
        });
      }
    }
    setFiles([...files, ...docs]);
  };

  const onDeleteNonImageFile = (index: number) => {
    const remainingFiles = filteredFiles.nonImages.filter((_data: MaximoDocument, idx: number) => idx !== index);
    setFiles([...filteredFiles.images, ...remainingFiles]);
  };

  const onDeleteImageFile = (index: number) => {
    const remainingFiles = filteredFiles.images.filter((_data: MaximoDocument, idx: number) => idx !== index);
    setFiles([...filteredFiles.nonImages, ...remainingFiles]);
  };

  const filteredFiles = files.reduce(
    (acc, file) => {
      acc[file.file?.type.includes("image") ? "images" : "nonImages"].push(file);
      return acc;
    },
    { images: [] as MaximoDocument[], nonImages: [] as MaximoDocument[] },
  );

  return (
    <InputRow
      style={{
        marginBottom: 0,
        ...(isManager && { position: "relative", maxWidth: "unset" }),
      }}
    >
      <Container $isMobile={isMobile}>
        <Label>
          Attachments<span>(optional):</span>
        </Label>

        <AttachmentsContainer>
          <PhotoUploadContainer>
            {filteredFiles.images.length > 0 &&
              filteredFiles.images.map((doc: MaximoDocument, index: number) => (
                <PhotoContainer
                  isViewMode={isViewMode}
                  key={doc.fileName}
                  image={doc.file ? URL.createObjectURL(doc.file) : undefined}
                  onDeleteClick={(e) => {
                    e.preventDefault();
                    onDeleteImageFile(index);
                  }}
                />
              ))}
          </PhotoUploadContainer>
          {filteredFiles.nonImages.length > 0 &&
            filteredFiles.nonImages.map((item, index) => (
              <Attachment key={item.fileName} islink={0}>
                <AttachmentData onClick={() => onAttachmentClick(item)}>{item.fileName}</AttachmentData>
                {!isViewMode && (
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteNonImageFile(index);
                    }}
                  />
                )}
              </Attachment>
            ))}
          {isError && <ErrorMessage>{`Please upload the supported files only ${ALL_FILE_TYPE}`}</ErrorMessage>}

          {!isViewMode && (
            <Wrapper className="ml-8">
              <label htmlFor="contained-button-file">
                <Button type="button" text="Add Files" variant="dotted-border" onClick={onClickUpload} icon={faPlus} />
              </label>
              <input
                ref={hiddenFileInput}
                id="contained-button-file"
                multiple
                type="file"
                accept={ALL_FILE_TYPE}
                onChange={onChangeUpload}
                style={{ display: "none" }}
              />
            </Wrapper>
          )}
        </AttachmentsContainer>
      </Container>
    </InputRow>
  );
};

const Label = styled.label<MobileProps>`
  align-self: flex-start;
  margin-right: 12px !important;
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-bottom: 10px;
    `}
`;

const AttachmentData = styled.div`
  margin-left: 8px;
`;

const Wrapper = styled.div`
  margin-left: 8px;
  & label {
    width: unset;
  }
`;
