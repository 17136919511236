import { Dispatch } from "redux";
import { setPriorityLevels } from "src/redux/actions/workRequest";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ServiceTypeList } from "src/types";

export const fetchDataHandler = async ({
  loggedInUserId,
  isManager,
  isVendor,
  clientId,
  setSiblingCount,
  setServiceTypes,
  setCountOfServiceType,
  setPageSize,
  setInited,
  dispatch,
}: {
  loggedInUserId: string;
  isManager: boolean;
  isVendor: boolean;
  clientId: string;
  setSiblingCount: React.Dispatch<React.SetStateAction<number>>;
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceTypeList | undefined>>;
  setCountOfServiceType: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setInited: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: Dispatch;
}) => {
  const { data } = await FirebaseDataService.getDataForTicketListPage(loggedInUserId, isManager, isVendor);

  const serviceTypesLength = data.serviceTypes.serviceTypes[clientId]?.length ?? 0;
  const serviceTypesAmount = data.serviceTypes.serviceTypesAmount;

  setSiblingCount(data.siblings);
  setServiceTypes(data.serviceTypes.serviceTypes);

  if (serviceTypesAmount && serviceTypesLength - serviceTypesAmount > 1) {
    setCountOfServiceType(serviceTypesAmount);
  } else {
    setCountOfServiceType(serviceTypesLength);
  }

  dispatch(setPriorityLevels(data.priorityLevels));
  setPageSize(data.ticketsPerPage);
  setInited(true);
};
