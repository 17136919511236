import moment from "moment";
import { DATEPICKER_FORMAT, DATEPICKER_PLACEHOLDER } from "./constants";
import { DATEPICKER_VARIANT } from "./types";

export const validateValue = (value: string | undefined, dateFormat: string) => {
  if (value && !moment(value, dateFormat, true).isValid)
    throw new Error(`Date value does not conform to the ${dateFormat} format`);
};

export const getVariantProps = (variant: DATEPICKER_VARIANT) => {
  return {
    dateFormat: DATEPICKER_FORMAT[variant],
    placeholder: DATEPICKER_PLACEHOLDER[variant],
  };
};

export const noop = () => {};
