import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AdjustmentFormView } from "./components/AdjustmentFormView";
import { AdjustmentFormProps, AdjustmentFormValues } from "./types";

export const AdjustmentForm = ({ defaultValues, reasons, onCancel, onSubmit }: AdjustmentFormProps) => {
  const form = useForm<AdjustmentFormValues>({ defaultValues });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <AdjustmentFormView reasons={reasons} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};
