import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import DaysSelector from "src/components/atoms/DaysSelector";
import { Required } from "src/components/atoms/Required";
import { ButtonsContainer, FlexContainer, StyledLabel } from "../styles";
import { ShiftDaysProps } from "../types";

export const ShiftDays = ({ name, value }: ShiftDaysProps) => {
  const { control } = useFormContext();

  return (
    <FlexContainer>
      <StyledLabel paddingRight={1}>
        Working Days: <Required />
      </StyledLabel>

      <ButtonsContainer>
        <Controller
          control={control}
          name={name}
          defaultValue={value}
          rules={{ required: true }}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <DaysSelector value={value} onChange={onChange} error={error} ref={ref} />
          )}
        />
      </ButtonsContainer>
    </FlexContainer>
  );
};
