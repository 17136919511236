import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Required } from "src/components/atoms/Required";
import { FlexWrapper, StyledLabel, TextareaInput } from "../styles";
import { MessageTextInputProps } from "../types";

export const MessageTextInput = ({ name, value }: MessageTextInputProps) => {
  const { control } = useFormContext();

  return (
    <FlexWrapper>
      <StyledLabel>
        Text: <Required />
      </StyledLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={value}
        rules={{ required: true }}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
          <TextareaInput
            defaultValue={value}
            name={name}
            placeholder={"Enter Text"}
            onChange={onChange}
            ref={ref}
            $error={!!error}
          />
        )}
      />
    </FlexWrapper>
  );
};
