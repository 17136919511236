import React from "react";
import { Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { ShareFormButtonProps } from "../types";

export const ShareFormButtons = ({ onCancel }: ShareFormButtonProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Stack direction="horizontal" className="mx-auto" gap={4}>
      <Button
        text="Cancel"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault();
          onCancel?.();
        }}
      />
      <Button text="Submit" loading={isSubmitting} />
    </Stack>
  );
};
