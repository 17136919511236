import { Moment } from "moment-timezone";
import React from "react";

import TimeLabel from "src/components/TimeLabel";
import { InputRow } from "../ViewForm";

interface DateRowProps {
  $isMobile: boolean;
  date: Date | Moment | string | null;
  timezone?: string;
  label: string;
  maximoTimezone: string;
  valueComponent?: "input" | "p";
}

const DateRow = ({ $isMobile, date, timezone, label, maximoTimezone, valueComponent }: DateRowProps) => {
  if (!date) {
    return <></>;
  }

  return (
    <InputRow $isMobile={$isMobile}>
      <label>{label}</label>
      <TimeLabel date={date} maximoTimezone={maximoTimezone} timezone={timezone} valueComponent={valueComponent} />
    </InputRow>
  );
};

export default React.memo(DateRow);
