import React, { useContext } from "react";
import WorkRequestListViewContainer from "../../WorkRequestListViewContainer";
import { sortingDirections, sortingOptions } from "../constants";
import { WorkRequestTabProps } from "../types";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const WorkRequestTab = ({
  workRequestType,
  siblingCount,
  pageSize,
  isRedirectToDetailPageAllowed,
  renderTable,
}: WorkRequestTabProps) => {
  const { setPageNumber, pageNumber, category, setCategory, direction, setDirection, searchText, setSearchText } =
    useContext(WorkRequestListContext);
  return (
    <WorkRequestListViewContainer
      setWorkRequestsPage={setPageNumber}
      workRequestsPage={pageNumber ?? 1}
      workRequestType={workRequestType}
      defaultDirection={sortingDirections.find((option) => option.filter[0] === direction) ?? sortingDirections[0]}
      defaultItem={
        sortingOptions.find((option) => option.filter[0][0] === category) ||
        sortingOptions.filter((e) => !e.onlyManager)[0]
      }
      directions={sortingDirections}
      items={sortingOptions.filter((e) => !e.onlyManager)}
      category={category as string}
      direction={direction as string}
      setCategory={setCategory}
      setDirection={setDirection}
      setSearchText={setSearchText}
      searchText={searchText ?? ""}
      sibCount={siblingCount}
      pageSize={pageSize}
      isRedirectToDetailPageAllowed={isRedirectToDetailPageAllowed}
      renderTable={renderTable}
    />
  );
};

export default WorkRequestTab;
