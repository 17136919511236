import React from "react";
import styled from "styled-components";
import TableCell from "../TableCell";

export const Cell: React.FC<{ children: string; highlight?: string; showMark?: boolean; bold?: string }> = ({
  children: text,
  highlight = "",
  showMark,
  bold,
}) => {
  if (!highlight.trim()) {
    return (
      <TableCell>
        {bold && <span className="bold">{bold}</span>}
        <span>{text}</span>
      </TableCell>
    );
  }
  const regex = new RegExp(`(${highlight})`, "gi");
  const parts = text.split(regex);

  return (
    <TableCell>
      <span>
        {parts.filter(String).map((part: string) => {
          if (showMark) {
            return regex.test(part) ? <Mark key={part}>{part}</Mark> : <span key={part}>{part}</span>;
          }

          return <span key={part}>{part}</span>;
        })}
      </span>
    </TableCell>
  );
};

const Mark = styled.mark`
  background-color: inherit;
  color: red;
`;
