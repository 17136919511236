import { Field } from "../../../../../components/Field";
import React, { useContext } from "react";
import LocationDetailsContext from "../../context";
import styled from "styled-components";
import { colors } from "../../../../../styles";

export const ReadOnlyInfoMessagesForm = () => {
  const { client, messages } = useContext(LocationDetailsContext);

  const welcomeTextLabel =
    client?.infoMessages?.welcomeText &&
    messages.find(({ value }) => value === client?.infoMessages?.welcomeText)?.label;
  const firstTextLabel =
    client?.infoMessages?.firstText && messages.find(({ value }) => value === client?.infoMessages?.firstText)?.label;
  const secondTextLabel =
    client?.infoMessages?.secondText && messages.find(({ value }) => value === client?.infoMessages?.secondText)?.label;

  return (
    <div>
      <Field
        label="Welcome Text: "
        labelStyle={"min-width: 125px;"}
        dataStyle={"margin-left: 0;"}
        data={client?.infoMessages?.welcomeText || "No Message"}
      />
      {welcomeTextLabel && <Label>{welcomeTextLabel}</Label>}
      <Field
        label="Left Side Text: "
        labelStyle={"min-width: 125px;"}
        dataStyle={"margin-left: 0;"}
        data={client?.infoMessages?.firstText || "No Message"}
      />
      {firstTextLabel && <Label>{firstTextLabel}</Label>}
      <Field
        label="Right Side Text: "
        labelStyle={"min-width: 125px;"}
        dataStyle={"margin-left: 0;"}
        data={client?.infoMessages?.secondText || "No Message"}
      />
      {secondTextLabel && <Label>{secondTextLabel}</Label>}
    </div>
  );
};

const Label = styled.p`
  font-style: italic;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 125px;
  color: ${colors.grey.dark};
`;
