import moment from "moment-timezone";
import { DEFAULT_TIMEZONE_VALUE } from "src/pages/ClientDetails/constants";

export const parseDateToFormat = (date: Date | string, format = "MMMM Do YYYY, h:mm A") => moment(date).format(format);

export const parseTimezoneDateToFormat = (time: Date | string, timezone: string, format = "MMMM Do YYYY, h:mm A z") => {
  return moment(time).tz(timezone).format(format);
};

export const formatTimezoneToShort = (timezone: string | undefined) =>
  moment.tz(timezone || DEFAULT_TIMEZONE_VALUE).format("z");
