import React from "react";
import { ListForm } from "src/components/molecules/ListForm";
import { ScheduleTemplateForm } from "src/components/molecules/ShiftTemplateForm";
import { scheduleTemplateFactory } from "src/components/molecules/ShiftTemplateForm/utils";
import { ListFormConfigurator } from "../types";

/**
 * A helper component to configure ListForm for Schedule Templates
 */
export const ScheduleTemplateListForm = ({ name }: ListFormConfigurator) => {
  return (
    <ListForm
      name={name}
      getNewItem={scheduleTemplateFactory.createTemplate}
      renderItem={(item, index) => <ScheduleTemplateForm name={`${name}.${index}`} template={item} />}
      itemName="Template"
      itemCaption="Schedule Template"
    />
  );
};
