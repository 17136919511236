import { faChevronUp, faChevronDown } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { SelectedItem, HoursForEmergency, SelectButton, Icon, SelectInput, Placeholder } from "../styles";
import { DropdownItem } from "../types";
export interface SelectInputFieldProps {
  selectedItem?: DropdownItem;
  showItems?: boolean;
  disabled?: boolean;
  defaultValueColor: string;
  defaultValue?: string | boolean;
  placeholderStyle?: string;
  placeholder?: string;
}
const SelectInputField = ({
  selectedItem,
  showItems,
  disabled,
  defaultValueColor,
  defaultValue,
  placeholderStyle,
  placeholder,
}: SelectInputFieldProps) => {
  return (
    <SelectInput $showItems={showItems ? 1 : 0} disabled={disabled ? 1 : 0}>
      <SelectedItem color={selectedItem?.color || defaultValueColor}>
        {defaultValue ? (
          defaultValue
        ) : selectedItem ? (
          <>
            {selectedItem.value.includes(":") ? (
              <span className="bold"> {selectedItem.value.split(":")[0]}:</span>
            ) : (
              selectedItem.value
            )}
            {selectedItem.value.split(":")[1]}
            {selectedItem.hoursDelay && (
              <HoursForEmergency> (less than {selectedItem?.hoursDelay} hours)</HoursForEmergency>
            )}
          </>
        ) : (
          <Placeholder placeholderStyle={placeholderStyle}>{placeholder}</Placeholder>
        )}
      </SelectedItem>
      <SelectButton disabled={disabled}>
        <span>
          {showItems ? <Icon icon={faChevronUp as any} /> : <Icon disabled={disabled} icon={faChevronDown as any} />}
        </span>
      </SelectButton>
    </SelectInput>
  );
};

export default SelectInputField;
