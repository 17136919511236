import React, { createContext } from "react";
import { OptionItem } from "../../components/SelectSearch";
import {
  Client,
  DBClientPortalTile,
  DBDropdownListSizes,
  DBKleenwayContact,
  DBLocation,
  IOmegaItem,
  PayPeriodType,
} from "../../types";
import {
  EmployeesApprovedHoursFormProps,
  GeneralInfoFormStateProps,
  RenameModalProps,
  StructuredPayPeriods,
} from "./ClientDetailsProvider";

interface ClientDetailsContextProps {
  client: Client;
  setClient: React.Dispatch<React.SetStateAction<Client>>;
  isGeneralInfoFormSaving: boolean;
  setIsGeneralInfoFormSaving: React.Dispatch<React.SetStateAction<boolean>>;
  generalInfoFormState: GeneralInfoFormStateProps;
  setGeneralInfoFormState: React.Dispatch<React.SetStateAction<GeneralInfoFormStateProps>>;
  clientPortalTiles: DBClientPortalTile[];
  setClientPortalTiles: React.Dispatch<React.SetStateAction<DBClientPortalTile[]>>;
  dropdownListSizes: DBDropdownListSizes;
  setDropdownListSizes: React.Dispatch<React.SetStateAction<DBDropdownListSizes>>;
  kleenwayContacts: DBKleenwayContact[];
  timezones: OptionItem[];
  setTimezones: React.Dispatch<React.SetStateAction<OptionItem[]>>;
  messages: OptionItem[];
  payPeriods: StructuredPayPeriods;
  setPayPeriods: React.Dispatch<React.SetStateAction<StructuredPayPeriods>>;
  locations: DBLocation[];
  setLocations: React.Dispatch<React.SetStateAction<DBLocation[]>>;
  payPeriodsTypes: PayPeriodType[];
  setPayPeriodsTypes: React.Dispatch<React.SetStateAction<PayPeriodType[]>>;
  isContextLoaded: boolean;
  isGeneralInfoFormValid: boolean;
  setIsGeneralInfoFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  isTilesFormValid: boolean;
  setIsTilesFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  employeesApprovedHoursForm: EmployeesApprovedHoursFormProps;
  setEmployeesApprovedHoursForm: React.Dispatch<React.SetStateAction<EmployeesApprovedHoursFormProps>>;
  folderContent: IOmegaItem[];
  setFolderContent: React.Dispatch<React.SetStateAction<IOmegaItem[]>>;
  modalRename: RenameModalProps;
  setModalRename: React.Dispatch<React.SetStateAction<RenameModalProps>>;
  dayClassifications: OptionItem[];
}

const ClientDetailsContext = createContext<ClientDetailsContextProps>({} as ClientDetailsContextProps);

export default ClientDetailsContext;
