import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingContainer from "src/components/LoadingContainer";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ID, Worklog } from "src/types";

import { useSelector } from "react-redux";
import { getUserData } from "src/redux/selectors/user";
import AddCommentSection from "./AddCommentSection";
import CommentsSection from "./CommentsSection";

type CommentsWrapperProps = {
  $isMobile: boolean;
  workOrderId: ID;
  addCommentSuccess: boolean;
  setAddCommentSuccess: any;
};

const CommentsWrapper: FC<CommentsWrapperProps> = ({
  addCommentSuccess,
  setAddCommentSuccess,
  $isMobile,
  workOrderId,
}) => {
  const { id } = useParams();
  const { isManager } = useSelector(getUserData);

  const [worklogs, setWorklogs] = useState<Worklog[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchWorkLogs = async () => {
    setLoading(true);
    const { data } = await FirebaseDataService.getWorkLogComments(id as string, isManager);
    setLoading(false);
    setWorklogs(data.worklogComments);
    setAddCommentSuccess(false);
  };

  useEffect(() => {
    fetchWorkLogs();
  }, [id]);

  useEffect(() => {
    if (addCommentSuccess) {
      fetchWorkLogs();
    }
  }, [addCommentSuccess]);

  return (
    <>
      <LoadingContainer isLoading={loading}>
        <AddCommentSection workOrderId={workOrderId} setAddCommentSuccess={setAddCommentSuccess} />
        {worklogs.length ? <CommentsSection comments={worklogs} $isMobile={$isMobile} /> : null}
      </LoadingContainer>
    </>
  );
};

export default CommentsWrapper;
