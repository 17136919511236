import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;
export const Content = styled.div`
  padding: 15px 30px;
`;
export const TabsWrapper = styled(Content)`
  padding: 0;
`;
export const CreateButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-right: 60px;
`;
export const Subtitle = styled.h3<MobileProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark6};
  margin-right: 10px;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 12px;
    `}
`;

export const ServiceTypesRow = styled.div<MobileProps>`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  a {
    margin-bottom: 30px;
    margin-right: 20px;

    ${({ $isMobile }) =>
      $isMobile &&
      css`
        width: 76px;
        height: 76px;
        border-radius: 10px;
        font-size: 10px;
        margin-bottom: 10px;
        margin-right: 5px;

        p {
          text-overflow: clip;
          font-size: 11px;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      `}
  }
`;
