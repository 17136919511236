import { colors } from "src/styles";
import styled from "styled-components";
export const ClientContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;
export const FormItemHeading = styled.div`
  font-size: 14px;
  color: ${colors.grey.dark};
  font-weight: 600;
`;

export const Wrapper = styled.div`
  flex: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid rgb(203, 203, 203);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;
