import { ShiftData } from "./types";
import { v4 as uuidv4 } from "uuid";

export const shiftFactory = {
  createShift: (): ShiftData => {
    return {
      keyLabel: uuidv4(),
      days: [],
      start: "",
      end: "",
    };
  },
};
