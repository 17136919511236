import React from "react";
import { ContactForm } from "src/components/molecules/ContactForm";
import { contactFactory } from "src/components/molecules/ContactForm/utils";
import { ListForm } from "src/components/molecules/ListForm";
import { ListFormConfigurator } from "../types";

/**
 * A helper component to configure ListForm for Kleenway Contacts
 */
export const ContactListForm = ({ name }: ListFormConfigurator) => {
  return (
    <ListForm
      name={name}
      getNewItem={contactFactory.createContact}
      renderItem={(item, index) => <ContactForm name={`${name}.${index}`} contact={item} />}
      itemName="Contact"
      itemCaption="Kleenway Contact"
    />
  );
};
