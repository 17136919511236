import { createReducer } from "@reduxjs/toolkit";
import { setSubmittedPageName } from "../actions/dynamicForms";

interface StatusState {
  submittedPageName: string;
}

export const initialState: StatusState = {
  submittedPageName: "",
};

export const dynamicFormReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSubmittedPageName, (state, action) => ({
    ...state,
    submittedPageName: action.payload,
  }));
});
