import React, { useContext, useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Input from "src/components/Input";
import styled from "styled-components";
import Button from "../../../../components/Button";
import { EmptyContainer } from "../../../../components/ListScreen";
import SinglePhotoUpload from "../../../../components/PhotoUpload/Single";
import { EMAIL_REGEXP, VALIDATION_MESSAGES } from "../../../../constants/validation";
import { getErrorMsg } from "../../../../helpers/getErrorMsg";
import { FirebaseAuthService } from "../../../../services/Firebase/auth";
import FirebaseStorageService from "../../../../services/Firebase/storage";
import { colors } from "../../../../styles";
import { ClientPortalUserFormProps } from "../../ClientDetailsProvider";
import { getLogoPropType } from "../../GeneralInfoForm";
import ClientDetailsContext from "../../context";
import { ErrorMessage } from "../../index";

enum ClientPortalUserFields {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  EMAIL = "email",
}

interface FormProps {
  formIndex: number;
  defaultValues: ClientPortalUserFormProps;
  handleFormDelete: () => void;
  clientId?: string;
}

export const ClientPortalUserForm = ({ formIndex, defaultValues, handleFormDelete, clientId }: FormProps) => {
  const { isGeneralInfoFormSaving, setIsGeneralInfoFormSaving, generalInfoFormState, setGeneralInfoFormState } =
    useContext(ClientDetailsContext);

  const {
    register,
    trigger,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [photo, setPhoto] = useState<File | string | null>(null);
  const [error, setError] = useState("");
  const [isSuccessReset, setIsSuccessReset] = useState(false);

  const sendResetPasswordEmail = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!defaultValues.email) return;

    setResetPasswordLoading(true);
    setResetPasswordError("");
    setIsSuccessReset(false);

    try {
      const { status, message } = await FirebaseAuthService.sendPasswordResetEmail(defaultValues.email, clientId);
      if (status === "error") {
        setResetPasswordError(message);
        return;
      }
      setIsSuccessReset(true);
    } catch (resetPasswordError) {
      setResetPasswordError(getErrorMsg(resetPasswordError));
    }
    setResetPasswordLoading(false);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    setGeneralInfoFormState(({ clientPortalUsers, ...prevState }) => ({
      ...prevState,
      clientPortalUsers: clientPortalUsers.map((state) => {
        if (state.id === defaultValues.id)
          return {
            ...state,
            [fieldName]: e.target.value,
            isValid,
            isSaved: false,
          };
        return state;
      }),
    }));
  };

  const getPhotoDownloadUrl = (photoFileName: string) =>
    FirebaseStorageService.getDownloadURL(process.env.REACT_APP_PROFILE_PICTIRE_FOLDER, photoFileName)
      .then(({ data: photoDownloadUrl }) => setPhoto(photoDownloadUrl))
      .catch((error) => setError(error.message))
      .finally(() => setPhotoLoading(false));

  useEffect(() => {
    if (!generalInfoFormState) return;

    const { clientPortalUsers } = generalInfoFormState;

    const currentUser = clientPortalUsers.find((item) => item.id === defaultValues.id);

    if (!currentUser) return;

    const { photo: clientUserPhoto } = currentUser;

    if (!clientUserPhoto) {
      setPhotoLoading(false);
      return;
    }

    const typeOfPhotoProp = getLogoPropType(clientUserPhoto);

    if (typeOfPhotoProp !== "fileName") {
      setPhoto(clientUserPhoto);
      setPhotoLoading(false);
      return;
    }

    const photoFileName = clientUserPhoto as string;
    getPhotoDownloadUrl(photoFileName);
  }, [generalInfoFormState?.clientPortalUsers?.[formIndex]?.photo]);

  useEffect(() => {
    if (isGeneralInfoFormSaving) {
      trigger(undefined, { shouldFocus: true });
      setIsGeneralInfoFormSaving(false);
    }
  }, [isGeneralInfoFormSaving]);

  useEffect(() => {
    if (defaultValues.isDeleted) return;
    if (isValid !== undefined) {
      setGeneralInfoFormState(({ clientPortalUsers, ...prevState }) => ({
        ...prevState,
        clientPortalUsers: clientPortalUsers.map((state) => {
          if (state.id === defaultValues.id)
            return {
              ...state,
              isValid,
            };
          return state;
        }),
      }));
    }
  }, [isValid]);

  return (
    <Container>
      {error && <p>{error}</p>}
      <Header>
        <Title>{`User ${formIndex + 1}:`}</Title>
        <Button text="Delete User" variant="text" onClick={handleFormDelete} />
      </Header>
      <Content>
        <Form>
          {photoLoading ? (
            <EmptyContainer>
              <Spinner animation="border" size="sm" />
            </EmptyContainer>
          ) : (
            <SinglePhotoUpload
              key={defaultValues.id}
              renamedInfo={defaultValues.email}
              setFile={(file: File | null) => {
                setGeneralInfoFormState(({ clientPortalUsers, ...prevState }) => ({
                  ...prevState,
                  clientPortalUsers: clientPortalUsers.map((state) => {
                    if (state.id === defaultValues.id)
                      return {
                        ...state,
                        photo: file,
                      };
                    return state;
                  }),
                }));
                setPhoto(file);
              }}
              file={photo}
              elementName="Profile Photo"
            />
          )}
          <InputsContainer>
            <InputsRow>
              <InputRow>
                <label>First Name:</label>
                <div>
                  <Input
                    value={defaultValues.firstName}
                    maxLength={100}
                    placeholder="Enter First Name"
                    validation={register(ClientPortalUserFields.FIRST_NAME, {
                      onChange: (e) => handleFieldChange(e, ClientPortalUserFields.FIRST_NAME),
                      shouldUnregister: true,
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[ClientPortalUserFields.FIRST_NAME]?.message}</p>
                </div>
              </InputRow>
              <InputRow>
                <label>Last Name:</label>
                <div>
                  <Input
                    value={defaultValues.lastName}
                    maxLength={100}
                    placeholder="Enter Last Name"
                    validation={register(ClientPortalUserFields.LAST_NAME, {
                      onChange: (e) => handleFieldChange(e, ClientPortalUserFields.LAST_NAME),
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                    })}
                  />
                  <p>{errors?.[ClientPortalUserFields.LAST_NAME]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>

            <InputsRow>
              <InputRow $inputWidth="520">
                <label>Email:</label>
                <div>
                  <Input
                    value={defaultValues.email}
                    maxLength={100}
                    placeholder="Enter Email"
                    validation={register(ClientPortalUserFields.EMAIL, {
                      onChange: (e) => handleFieldChange(e, ClientPortalUserFields.EMAIL),
                      required: {
                        value: true,
                        message: VALIDATION_MESSAGES.required,
                      },
                      pattern: {
                        value: EMAIL_REGEXP,
                        message: VALIDATION_MESSAGES.invalidEmail,
                      },
                    })}
                  />
                  <p>{errors?.[ClientPortalUserFields.EMAIL]?.message}</p>
                </div>
              </InputRow>
            </InputsRow>
          </InputsContainer>
          {defaultValues.error && <ErrorUser>{defaultValues.error}</ErrorUser>}
          {defaultValues.isSaved && (
            <Button
              disabled={resetPasswordLoading}
              text="Reset User Password"
              additionalStyles={{
                marginLeft: "120px",
                marginTop: "20px",
                padding: "12px 24px",
              }}
              onClick={sendResetPasswordEmail}
              variant="middle-primary"
            />
          )}
          {resetPasswordError && <ErrorMessage>{resetPasswordError}</ErrorMessage>}
          {defaultValues.isSaved && isSuccessReset && (
            <SuccessMessage>Password reset instructions successfully sent.</SuccessMessage>
          )}
        </Form>
      </Content>
    </Container>
  );
};

interface InputRowProps {
  $inputRowStyle?: string;
}

const SuccessMessage = styled.div`
  padding-top: 25px;
  padding-left: 10px;
  display: inline-block;
`;

const ErrorUser = styled.p`
  padding-top: 10px;
  padding-left: 120px;
`;

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 30px 0px;
`;
export const Content = styled.div`
  background-color: ${colors.white.default};
  padding: 10px 0 30px 0;
`;

interface InputRowProps {
  $inputWidth?: string;
}

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: center;
  margin-top: 20px;
  max-width: ${(props) => (props.$inputWidth ? String(Number(props.$inputWidth) * 1.85) : "325")}px;
  width: 100%;
  position: relative;
  margin-right: 20px;

  div {
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    color: ${colors.grey.dark};
    white-space: nowrap;
    margin-right: 20px;
  }

  input {
    width: ${(props) => props.$inputWidth || "175"}px;
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }

  ${(props) => props.$inputRowStyle && `${props.$inputRowStyle}`}
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
