import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { FirebaseDataService } from "src/services/Firebase/data";
import { ID } from "src/types";

import BreadCrumbs from "../../../components/BreadCrumbs";
import { Container, EmptyContainer } from "../../../components/ListScreen";
import { generateBreadcrumbs } from "../../../helpers/breadcrumbs";
import { MANAGER_LOCATION_DETAILS_BREADCRUMBS } from "./constants";

import { useDispatch } from "react-redux";
import { setPageNameHeader } from "src/redux/actions/header";
import Modal from "../../../components/Modal";
import FirebaseStorageService from "../../../services/Firebase/storage";
import { Content, ContentFrame, TopBar, colors } from "../../../styles";
import { checkFormsValidity } from "../utils";
import { GeneralInfoForm } from "./GeneralInfoForm";
import { InfoMessagesForm } from "./InfoMessagesForm";
import LocationDetailsContext from "./context";
import { generateLocationDetailsFormState, generateUpdateLocationRequest, isLocationWithState } from "./utils";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";

export interface StateWithClientName {
  clientName: string | null;
}

export const LocationDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const routeLocation = useLocation();
  const dispatch = useDispatch();
  const state = isLocationWithState(routeLocation) ? routeLocation.state : { clientName: null };
  const { clientName: locationStateClientName } = state;
  const [clientName, setClientName] = useState<React.ComponentState>(
    (prevState: StateWithClientName) => locationStateClientName || prevState,
  );

  const {
    location,
    setLocation,
    setIsGeneralInfoFormSaving,
    generalInfoFormState,
    setGeneralInfoFormState,
    isSupervisorsLoaded,
    isMessagesLoaded,
    messages,
    supervisors,
    isGeneralInfoFormValid,
  } = useContext(LocationDetailsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);
  const [isUpdateLocationLoading, setIsUpdateLocationLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("generalInfo");

  const [isChecked, setIsChecked] = useState(!!generalInfoFormState.infoMessages);

  const getLocationData = async () => {
    if (id) {
      const { data: locationObject } = await FirebaseDataService.getLocationById(id);

      if (locationObject) {
        setLocation(locationObject);
        setGeneralInfoFormState(generateLocationDetailsFormState(locationObject, supervisors, messages));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (isSupervisorsLoaded && isMessagesLoaded) {
      getLocationData();
    }
  }, [isSupervisorsLoaded, isMessagesLoaded, id]);

  useEffect(() => {
    const locationState = isLocationWithState(routeLocation) ? routeLocation.state : { clientName: null };
    setClientName(locationState.clientName);
  }, [routeLocation]);

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  const uploadNewEvacuationPlan = async (onSuccess?: () => void): Promise<void> => {
    const evacuationPlanFile = generalInfoFormState.evacuationPlan as File;

    if (evacuationPlanFile?.size) {
      try {
        await FirebaseStorageService.deleteChildFromStorage(
          location.evacuationPlan,
          process.env.REACT_APP_EVACUATION_PLAN_FOLDER,
        );
        await FirebaseStorageService.uploadImageToStorage(
          generalInfoFormState.evacuationPlan,
          process.env.REACT_APP_EVACUATION_PLAN_FOLDER,
          evacuationPlanFile.name,
        );
        if (onSuccess) {
          onSuccess();
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsUpdateLocationLoading(false);
      }
    } else {
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const handleFormSubmit = async ({ onSuccess }: { onSuccess?: () => void }) => {
    setIsGeneralInfoFormSaving(true);

    if (!isGeneralInfoFormValid) {
      setActiveTab("generalInfo");
      return;
    }

    if (!id) {
      return;
    }

    setIsUpdateLocationLoading(true);

    FirebaseDataService.updateLocation(
      id,
      generateUpdateLocationRequest({ location, formState: generalInfoFormState, locationId: id }),
    )
      .then(async () => await uploadNewEvacuationPlan(onSuccess))
      .catch((err) => setError(err.message))
      .finally(() => setIsUpdateLocationLoading(false));
  };

  const disabledSavingButtons = !generalInfoFormState.infoMessages && isChecked;

  //set page name to the Header
  useEffect(() => {
    dispatch(setPageNameHeader(location.label || location.address || ""));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, [location]);

  if (isLoading || !id) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }

  return (
    <Container>
      <Modal
        onApproveClick={() => navigate(`/clients/${location.clientId?.toString()}`)}
        onCancelClick={() => setShowConfirmClosePopup(false)}
        isShowing={showConfirmClosePopup}
        hide={() => setShowConfirmClosePopup(false)}
        title="Close without Saving"
        textContent={`Do you want to close the page without saving?\nAll entered or updated information will be lost.`}
        approveButtonText="Close without Saving"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
      />
      <TopBar>
        <BreadCrumbs
          folderStack={generateBreadcrumbs(MANAGER_LOCATION_DETAILS_BREADCRUMBS, {
            id,
            clientId: location.clientId?.toString(),
            name: location.label || location.address || "",
            clientName: clientName || "",
          })}
          onClickItem={onClickBreadcrumb}
        />
      </TopBar>

      <Content>
        <ContentFrame>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <FormContainer>
            <HorizontalTabs
              tabs={[
                {
                  eventKey: "generalInfo",
                  label: "General Information",
                  content: <GeneralInfoForm locationId={id} location={location} />,
                  tabColor:
                    checkFormsValidity(generalInfoFormState.locationContacts) && generalInfoFormState.isValid
                      ? colors.kleenway.green
                      : colors.red.default,
                },
                {
                  eventKey: "infoMessages",
                  label: "Info Messages",
                  content: <InfoMessagesForm isChecked={isChecked} setIsChecked={setIsChecked} />,
                  tabColor:
                    isChecked && !generalInfoFormState.infoMessages ? colors.red.default : colors.kleenway.green,
                },
              ]}
              isSaveButtonVisible={true}
              isLoading={isUpdateLocationLoading}
              disableSave={isUpdateLocationLoading || !isGeneralInfoFormValid || disabledSavingButtons}
              backHandler={() => setShowConfirmClosePopup(true)}
              saveHandler={() => {
                handleFormSubmit({});
              }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </FormContainer>
        </ContentFrame>
      </Content>
    </Container>
  );
};

const FormContainer = styled.div`
  background: white;
`;

const ErrorMessage = styled.div`
  color: ${colors.red};
  padding: 10px;
`;
