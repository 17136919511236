import React from "react";
import ContentBox from "src/components/ContentBox";
import { ControlledDropdown } from "./components/ControlledDropdown";
import { MessageLabelInput } from "./components/MessageLabelInput";
import { MessageTextInput } from "./components/MessageTextInput";
import { InfoMessageFormProps } from "./types";

export const InfoMessageForm = ({ name, message, fontSizes, fontColors }: InfoMessageFormProps) => {
  return (
    <ContentBox
      isOpen={message?.isNew}
      renderHeader={() => <MessageLabelInput name={`${name}.label`} value={message.label} />}
    >
      <MessageTextInput name={`${name}.text`} value={message.text} />
      <ControlledDropdown label="Font Size" name={`${name}.fontSize`} value={message.fontSize} options={fontSizes} />
      <ControlledDropdown label="Font Color" name={`${name}.color`} value={message.color} options={fontColors} />
    </ContentBox>
  );
};
