import React from "react";
import { useFormContext } from "react-hook-form";
import { WOTab } from "../styles";
import { EmployeeWOResourceTabProps } from "../types";
import SearchResource from "./SearchResource";

const EmployeeWOResourceTab = ({ employees }: EmployeeWOResourceTabProps) => {
  const { watch, setValue } = useFormContext();
  const laborCode = watch("employees.laborCode");
  return (
    <WOTab>
      <SearchResource
        options={employees.map((emp) => {
          return {
            id: emp.laborCode,
            value: emp.displayName,
          };
        })}
        selectedValue={laborCode}
        onSelectOption={(option) => {
          setValue("employees.laborCode", option?.id);
          setValue("vendors.vendorId", undefined);
        }}
      />
    </WOTab>
  );
};

export default EmployeeWOResourceTab;
