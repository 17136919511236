import React from "react";
import { FormProvider } from "react-hook-form";
import { InputsContainer } from "../styles";
import { CustomWorkRequestField, CustomWorkRequestFieldsFormProps } from "../types";
import { CustomWorkRequestDropdown } from "./CustomWorkRequestDropdownField";
import { CustomWorkRequestInput } from "./CustomWorkRequestInputField";

/**
 * A helper component to render the Custom Workrequest Fields Form
 */
export const CustomWorkRequestFieldsForm = ({
  form,
  $isMobile,
  customWorkRequestFields,
  isClient,
  isPublic,
}: CustomWorkRequestFieldsFormProps) => {
  const isAllowedToView = (customField: CustomWorkRequestField) => {
    if (!customField.isActive) {
      return false;
    }

    if (isClient) {
      return customField.isVisibleToClient;
    }

    if (isPublic) {
      return customField.isVisibleToPublic;
    }

    return true;
  };

  return (
    <InputsContainer $isMobile={$isMobile}>
      <FormProvider {...form}>
        {customWorkRequestFields?.map((customField: CustomWorkRequestField) => {
          return (
            isAllowedToView(customField) && (
              <React.Fragment key={customField.id}>
                {customField.text && <CustomWorkRequestInput customField={customField} />}
                {customField.dropdown && <CustomWorkRequestDropdown customField={customField} />}
              </React.Fragment>
            )
          );
        })}
      </FormProvider>
    </InputsContainer>
  );
};
