import React from "react";

import { Spinner, Tabs } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import styled from "styled-components";

import { colors } from "../../styles";
import { TabsProps } from "./index";
import { SAVE_CHANGES_HINT_MESSAGE } from "src/pages/ClientDetails/constants";
import Button from "../Button";
interface HorizontalTabProps extends TabsProps {
  isSaveButtonVisible?: boolean;
}
export const HorizontalTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  $showBorder,
  saveHandler,
  disableSave,
  isLoading,
  isSaveButtonVisible,
}: HorizontalTabProps) => {
  return (
    <Wrapper $showBorder={!!$showBorder}>
      <Tabs id="justify-tab-example" justify activeKey={activeTab} onSelect={(tab) => tab && setActiveTab(tab)}>
        {tabs.map(({ eventKey, content, label }) => (
          <Tab style={{ height: "100%" }} key={eventKey} eventKey={eventKey} title={label}>
            {content}
          </Tab>
        ))}
      </Tabs>
      {isSaveButtonVisible && (
        <SaveWrapper data-tip={disableSave && !isLoading ? SAVE_CHANGES_HINT_MESSAGE : ""}>
          {isLoading && <Spinner style={{ marginRight: 10 }} animation="border" size="sm" />}
          <Button
            text="Save Changes"
            onClick={saveHandler}
            disabled={disableSave || isLoading}
            additionalStyles={{
              color: colors.white.default,
              padding: "12px 24px",
              backgroundColor: colors.kleenway.green,
            }}
            variant="middle-primary"
          />
        </SaveWrapper>
      )}
    </Wrapper>
  );
};

const SaveWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  width: 100%;
  float: right;
  justify-content: flex-end;
  border-top: 1px solid ${colors.grey.light8};
`;
const Wrapper = styled.div<{ $showBorder: boolean }>`
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  .nav-tabs {
    border-bottom-color: ${colors.grey.light18};
    & .nav-item {
      flex-grow: 0;
      flex-basis: auto;
    }
  }
  .nav-tabs .nav-link {
    color: ${colors.grey.dark};
    border-radius: 0;
    border-width: 0px;
    border-color: ${colors.grey.light1};
    color: ${colors.grey.dark4};
    font-size: 14px;
    font-weight: 700;
    padding: 10px 40px;
  }

  .nav-tabs .nav-link.active {
    background-color: ${colors.white.default};
    border-radius: 0;
    border-width: 0px 0px 5px 0px;
    color: ${colors.black.light1};
    border-bottom-color: ${colors.kleenway.green};
  }
  .tab-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  ${({ $showBorder }) =>
    $showBorder &&
    `
     & > .tab-content {
    border-bottom: 1px solid ${colors.grey.light13};
    padding-bottom: 20px;

  }
  `}
`;
