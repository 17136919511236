import { colors } from "src/styles";
import styled from "styled-components";

export const TileInputValue = styled.p`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  color: ${colors.grey.dark};
  padding: 10px;
`;

export const ErrorMessage = styled.p`
  font-style: italic;
  font-size: 14px;
  margin-top: 8px;
  color: ${colors.grey.dark};
  max-width: 400px;
`;
