import { createReducer } from "@reduxjs/toolkit";
import {
  clearWorkRequestMessage,
  setIsSearchApplied,
  setIsTicketsLoading,
  setPriorityLevels,
  setTickets,
  setWorkOrderCount,
  setWorkRequestAlertVariant,
  setWorkRequestMessage,
} from "../actions/workRequest";
import { PriorityLevelList, Ticket } from "src/types";

type WorkRequestState = {
  workRequestMessage: string;
  workRequestMessageVariant: "success" | "danger";
  isTicketsLoading: boolean;
  priorityLevels: PriorityLevelList;
  tickets: Ticket[];
  isSearchApplied: boolean;
  workOrderCount: number;
};

const initialState: WorkRequestState = {
  workRequestMessage: "",
  workRequestMessageVariant: "success",
  isTicketsLoading: false,
  priorityLevels: {},
  tickets: [],
  isSearchApplied: false,
  workOrderCount: 0,
};
export const workRequestReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setWorkRequestMessage, (state, action) => {
      state.workRequestMessage = action.payload;
    })
    .addCase(clearWorkRequestMessage, (state) => {
      state.workRequestMessage = "";
    })
    .addCase(setWorkRequestAlertVariant, (state, action) => {
      state.workRequestMessageVariant = action.payload === "danger" ? "danger" : "success";
    })
    .addCase(setIsTicketsLoading, (state, action) => {
      state.isTicketsLoading = action.payload;
    })
    .addCase(setPriorityLevels, (state, action) => {
      state.priorityLevels = action.payload;
    })
    .addCase(setTickets, (state, action) => {
      state.tickets = action.payload;
    })
    .addCase(setIsSearchApplied, (state, action) => {
      state.isSearchApplied = action.payload;
    })
    .addCase(setWorkOrderCount, (state, action) => {
      state.workOrderCount = action.payload;
    });
});
