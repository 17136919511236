import React from "react";
import Dropdown from "src/components/Dropdown";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Dropdowns, TableHeader } from "../styles";
import { WorkRequestActionsHeaderProps } from "../types";
import Search from "./Search";

const WorkRequestActionsHeader = ({
  applied,
  onChange,
  onClickApply,
  onClickCancel,
  onClickClear,
  onDropDownItemClick,
  value,
  defaultDirection,
  defaultItem,
  items,
  directions,
  onDropdownDirectionClick,
}: WorkRequestActionsHeaderProps) => {
  const { isMobile } = useIsMobile();

  return (
    <TableHeader>
      <Search
        value={value}
        applied={applied}
        onChange={onChange}
        onClickCancel={onClickCancel}
        onClickApply={onClickApply}
        onClickClear={onClickClear}
      />

      <Dropdowns $isMobile={isMobile}>
        <Dropdown
          id="filters-dropdown"
          width="220px"
          onClick={onDropDownItemClick}
          items={items}
          selectedDropdownItem={defaultItem}
        />
        <Dropdown
          width="220px"
          id="filters-dropdown"
          onClick={onDropdownDirectionClick}
          items={directions}
          selectedDropdownItem={defaultDirection}
        />
      </Dropdowns>
    </TableHeader>
  );
};

export default WorkRequestActionsHeader;
