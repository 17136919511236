import { getApp } from "firebase/app";
import {
  EmailAuthProvider,
  browserSessionPersistence,
  checkActionCode as checkActionCodeFB,
  confirmPasswordReset as confirmPasswordResetFB,
  getAuth,
  reauthenticateWithCredential,
  setPersistence,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFB,
  signOut as signOutFB,
  updatePassword as updatePasswordFB,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

export class FirebaseAuthService {
  static async signInWithEmailAndPassword(email: string, password: string) {
    const app = getApp();
    const auth = getAuth(app);
    await setPersistence(auth, browserSessionPersistence);

    const userCredential = await signInWithEmailAndPasswordFB(auth, email, password);

    return userCredential.user?.uid;
  }

  static async signOut() {
    const app = getApp();
    const auth = getAuth(app);
    await signOutFB(auth);
    document.cookie = "__session=";
  }

  static async sendPasswordResetEmail(email: string, clientId?: string): Promise<{ status: string; message: string }> {
    const app = getApp();
    const functions = getFunctions(app);
    const cloudFunction = httpsCallable<{ email: string; clientId?: string }, { status: string; message: string }>(
      functions,
      "clientPortal-resetPassword",
    );
    const res = await cloudFunction({ email, clientId });
    return res.data;
  }

  static async confirmPasswordReset(code: string, password: string) {
    const app = getApp();
    const auth = getAuth(app);
    return await confirmPasswordResetFB(auth, code, password);
  }

  static async checkActionCode(code: string) {
    const app = getApp();
    const auth = getAuth(app);
    return await checkActionCodeFB(auth, code);
  }

  static async updatePassword(oldPassword: string, newPassword: string) {
    const app = getApp();
    const auth = getAuth(app);
    const currentUser = auth.currentUser;

    const credential = EmailAuthProvider.credential(currentUser?.email || "", oldPassword);

    if (currentUser) {
      await reauthenticateWithCredential(currentUser, credential);
      return await updatePasswordFB(currentUser, newPassword);
    }
  }

  /**
   * Returns a user token for later authentication
   *
   * @param forceRefresh - forses generation of a fresh token
   * @returns
   */
  static async getUserToken(forceRefresh?: boolean) {
    const app = getApp();
    const auth = getAuth(app);

    return auth.currentUser?.getIdToken(forceRefresh);
  }
}

/**
 * Open a new window with a short-lived session cookie set so that the backend firebase function can verify that the
 * user is authenticated
 *
 * @param url - see https://developer.mozilla.org/en-US/docs/Web/API/Window/open#parameters
 * @param target - see https://developer.mozilla.org/en-US/docs/Web/API/Window/open#parameters
 * @param features see https://developer.mozilla.org/en-US/docs/Web/API/Window/open#parameters
 */
export const openAuthenticatedWindow = async (url?: string | URL, target?: string, features?: string) => {
  const userToken = await FirebaseAuthService.getUserToken(true);

  document.cookie = "__session=" + userToken + ";max-age=3000";

  window.open(url, target, features);
};
