import {
  faCheckCircle,
  faFileLines,
  faFolder,
  faHomeAlt,
  faUser,
  faUserFriends,
  faUserGear,
} from "@fortawesome/pro-light-svg-icons";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { useLocation } from "react-router-dom";
import { IMAGES } from "src/assets";
import { useIsMobile } from "src/hooks/useIsMobile";
import { setOpenedClientsPage } from "src/redux/actions/openedPages";
import { getUserData } from "src/redux/selectors/user";
import { colors } from "src/styles";
import { testGroup } from "src/utils/helpers";
import { NavbarItem } from "./NavbarItem";
import { NavbarToggle } from "./NavbarToggle";
import { ToggleArrow } from "./ToggleArrow";

interface NavbarProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}
const Navbar: React.FC<NavbarProps> = ({ isVisible, setIsVisible }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isMobile } = useIsMobile();

  const { isManager, email } = useSelector(getUserData);

  const isTestGroup = testGroup.includes(email ?? "");

  useEffect(() => {
    setIsVisible(!isMobile);
  }, [isMobile]);

  return (
    <div style={{ width: !isMobile ? (isVisible ? "270px" : "100px") : "0px" }}>
      <Container $isMobile={isMobile} $isVisible={isVisible}>
        <NavbarToggle $isMobile={isMobile} $isVisible={isVisible} setIsVisible={setIsVisible} />

        {!isMobile && <ToggleArrow isVisible={isVisible} setIsVisible={setIsVisible} />}

        <Content $isMobile={isMobile} $isVisible={isVisible}>
          {isVisible ? (
            <ExpandedLogo src={IMAGES.EXPANDED_LOGO_NAV} />
          ) : (
            <CollapsedLogo src={IMAGES.COLLAPSED_LOGO_NAV} />
          )}
          <NavbarItem
            title="Home"
            href="/"
            isActive={pathname === "/"}
            icon={faHomeAlt}
            isVisible={isVisible}
            onClick={() => dispatch(setOpenedClientsPage(1))}
          />
          {isManager && (
            <>
              <NavbarItem
                title="Clients"
                href="/clients"
                isActive={pathname.includes("/clients")}
                icon={faUserFriends}
                isVisible={isVisible}
                onClick={() => dispatch(setOpenedClientsPage(1))}
              />
              <NavbarItem
                title="Team Members"
                href="/team-members"
                isActive={pathname.includes("/team-members")}
                icon={faUser}
                isVisible={isVisible}
                onClick={() => dispatch(setOpenedClientsPage(1))}
              />
              <NavbarItem
                title="Vendors"
                href="/vendors"
                isActive={pathname.includes("/vendors")}
                icon={faUserGear}
                isVisible={isVisible}
                onClick={() => dispatch(setOpenedClientsPage(1))}
              />
              <NavbarItem
                title="Forms"
                href="/dynamic-forms"
                isActive={pathname.includes("/dynamic-forms")}
                icon={faFileLines}
                isVisible={isVisible}
                onClick={() => dispatch(setOpenedClientsPage(1))}
              />
            </>
          )}
          {isTestGroup && (
            <NavbarItem
              title="Files"
              href="/files"
              isActive={pathname.includes("/files")}
              icon={faFolder}
              isVisible={isVisible}
              onClick={() => dispatch(setOpenedClientsPage(1))}
            />
          )}
          <NavbarItem
            title="Work Requests"
            href="/workrequests"
            isActive={pathname.includes("/workrequests")}
            icon={faCheckCircle}
            isVisible={isVisible}
            onClick={() => dispatch(setOpenedClientsPage(1))}
          />
        </Content>
      </Container>
    </div>
  );
};

export interface ContainerProps {
  $isVisible: boolean;
  $isMobile: boolean;
}

const Container = styled.nav<ContainerProps>`
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  background-color: ${colors.kleenway.backgroundDark};
  border-right: 1px solid ${colors.grey.light11};
  width: ${({ $isVisible }) => ($isVisible ? "250px" : "100px")};
  padding: ${({ $isVisible }) => ($isVisible ? "36px 18px" : "40px 26px")};
  height: ${({ $isVisible, $isMobile }) => ($isMobile ? ($isVisible ? "100%" : "80px") : "100%")};
  position: fixed;
  top: 0;
  z-index: ${({ $isMobile }) => ($isMobile ? 100 : 50)};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      background-color: ${colors.white.default};
      margin-top: 0;
      padding-bottom: 0;
    `};
`;

const Content = styled.div<ContainerProps>`
  overflow-y: ${({ $isMobile }) => ($isMobile ? "auto" : "initial")};
  display: ${({ $isMobile, $isVisible }) => ($isMobile ? ($isVisible ? "block" : "none") : "block")};
`;

const ExpandedLogo = styled.img`
  width: 200px;
  padding-bottom: 36px;
`;
const CollapsedLogo = styled.img`
  width: 28px;
  padding-bottom: 54px;
  margin: 0 auto;
  display: flex;
`;

export default Navbar;
