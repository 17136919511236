import { DBMessage } from "src/types";
import { v4 as uuidv4 } from "uuid";

export const messageFactory = {
  createMessage: (): DBMessage => {
    return {
      id: uuidv4(),
      text: "",
      label: "",
      isNew: true,
    };
  },
};
