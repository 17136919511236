import { createReducer } from "@reduxjs/toolkit";
import { setOpenedClientsPage, setOpenedVendorsPage, setPreviousURLTeamMembers } from "../actions/openedPages";

interface IOpenedPage {
  openedClientsPage: number;
  openedVendorsPage: number;
  previousURLTeamMembers: string;
}

export const initialState: IOpenedPage = {
  openedClientsPage: 1,
  openedVendorsPage: 1,
  previousURLTeamMembers: "",
};

export const openedPagesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setOpenedClientsPage, (state, action) => ({
      ...state,
      openedClientsPage: action.payload,
    }))
    .addCase(setPreviousURLTeamMembers, (state, action) => ({
      ...state,
      previousURLTeamMembers: action.payload,
    }))
    .addCase(setOpenedVendorsPage, (state, action) => ({
      ...state,
      openedVendorsPage: action.payload,
    }));
});
