import { FirebaseDataService } from "src/services/Firebase/data";
import { Client } from "src/types";
import { FormData } from "./types";

export const onSubmitHandler = async (id: string, data: FormData, clientsArray: Client[]) => {
  if (data["client"]) {
    data["client"] = clientsArray.find((client) => client.id === data["client"])?.name ?? "";
  }
  await FirebaseDataService.saveDynamicFormData(id, data);
};
