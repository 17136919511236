import React, { useContext } from "react";
import Input from "src/components/Input";
import Modal from "src/components/Modal";
import styled, { css } from "styled-components";
import ClientDetailsContext from "../../context";

export type ModalTypeFile = "rename" | "create" | "delete";

interface FileModalProps {
  onApproveClick: (modalType: ModalTypeFile) => void;
  modalType?: ModalTypeFile;
}

const modalText = {
  rename: {
    title: "Rename Folder",
    cancelButtonText: "Close",
    approveButtonText: "Rename",
    inputPlaceholder: "Folder name",
  },
  create: {
    title: "Create Folder",
    cancelButtonText: "Close",
    approveButtonText: "Create",
    inputPlaceholder: "New Folder",
  },
  delete: {
    title: "Delete Folder",
    cancelButtonText: "Close",
    approveButtonText: "Delete",
    inputPlaceholder: "New Folder",
    textContent: "Please confirm that you would like to delete",
  },
};

export const FileModal = ({ onApproveClick }: FileModalProps) => {
  const { modalRename, setModalRename, folderContent } = useContext(ClientDetailsContext);

  const { modalType = "rename", name, type, id, error } = modalRename;

  const extFile = name && type === "file" ? "." + name.split(".")[1] : "";
  const inputValue = name && type === "file" ? name.split(".")[0] : name;

  const { title, cancelButtonText, approveButtonText, inputPlaceholder } = modalText[modalType];

  const currentInputPlaceholder = type === "file" && modalType === "rename" ? "File Name" : inputPlaceholder;

  const fileModalTitle = modalType === "rename" ? "Rename File" : "Delete File";
  const currentTitle = type === "file" ? fileModalTitle : title;

  const namesList = folderContent.filter((item) => item.id !== id && item.type === type).map((item) => item.name);

  const handleChangeModalInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[\\/\.]/g, "").replace(/^ /, "");
    if (modalRename) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setModalRename(({ name, ...prevState }) => {
        return {
          ...prevState,
          name: value + extFile,
          error: "",
        };
      });
    }
  };

  const isNameExist = !!name && namesList.some((item) => item === name.trim());

  return (
    <Modal
      onApproveClick={() => onApproveClick(modalType)}
      onCancelClick={() => setModalRename({})}
      title={currentTitle}
      approveButtonText={approveButtonText}
      approveButtonStyle="middle-primary"
      cancelButtonText={cancelButtonText}
      cancelButtonStyle="middle-text"
      buttonsFlexDirection="row"
      isShowing={Boolean(modalRename?.isVisible)}
      hide={() => setModalRename({})}
      textContent={modalType === "delete" ? `${modalText[modalType].textContent} ${type}` : ""}
      disableApproveButton={!name || isNameExist || !!error || !inputValue}
      maxWidth={"520px"}
    >
      <ModalInner>
        {modalType !== "delete" ? (
          <InputItemWrapper>
            <InputItem
              type="text"
              value={inputValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeModalInput(e)}
              placeholder={currentInputPlaceholder}
              isFile={type === "file"}
            />
            {!!extFile && <InputItemExt>{extFile}</InputItemExt>}
            {isNameExist && <ErrorExist>{`A ${type} exists with the same name as this ${type}`}</ErrorExist>}
            {!isNameExist && error && <ErrorExist>{error}</ErrorExist>}
          </InputItemWrapper>
        ) : (
          <p style={{ fontWeight: "500" }}>{name}</p>
        )}
      </ModalInner>
    </Modal>
  );
};

const InputItem = styled(Input)<{ isFile?: boolean }>`
  ${({ isFile }) => {
    return isFile
      ? css`
          padding-right: 50px;
        `
      : "";
  }}
`;
const InputItemWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;
`;

const InputItemExt = styled.div`
  display: block;
  position: absolute;
  top: 10px;
  right: 12px;
  height: 21px;
  text-align: right;
  font-weight: 500;
  color: #555555;
  opacity: 0.5;
`;
const ErrorExist = styled.div`
  display: block;
  position: absolute;
  max-width: 100%;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 4px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  background: #f9b4ae;
  border: 1px solid #ec6d62;
  border-radius: 5px;
  white-space: nowrap;
  margin-top: 13px;
`;

const ModalInner = styled.div`
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #555555;
  text-align: center;
`;
