import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent, useRef, useState } from "react";
import { Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { PhotoContainer } from "src/components/PhotoUpload";
import { HEIC_TYPE, HEIF_TYPE, MAX_PHOTO_SIZE } from "src/config/сonstants";
import { ALL_FILE_TYPE } from "src/constants/validation";
import convertHeicToJpg from "src/helpers/convertHeicToJPG";
import { ErrorMessage } from "src/pages/TicketForm/Attachments";
import { filterFiles } from "src/utils/helpers";
import styled from "styled-components";
import { Label } from "../styles";

export const CloseWorkOrderAttachments = () => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  const fieldName = "attachments";
  const { setValue } = useFormContext();

  const onAttachmentClick = async (file: File) => {
    window.open(URL.createObjectURL(file));
  };

  const onClickUpload = () => {
    hiddenFileInput.current?.click();
  };

  const onChangeUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const { supportedFiles, unsupportedFiles } = filterFiles(event.target.files, ALL_FILE_TYPE);
      setIsError(!!unsupportedFiles.length);
      setFiles([...files, ...(await getFileIntoArray(supportedFiles))]);
      setValue(fieldName, [...files, ...(await getFileIntoArray(supportedFiles))]);
    }
  };
  const getFileIntoArray = async (newFiles: File[]) => {
    const allFiles = [];
    for (const file of newFiles) {
      if (file.size > MAX_PHOTO_SIZE) {
        alert("file size should not exceed 7 MB");
        return [];
      }

      if (file.type === HEIC_TYPE || file.type === HEIF_TYPE) {
        const convertedImage = await convertHeicToJpg(file);
        if (convertedImage) {
          allFiles.push(convertedImage);
        }
      } else {
        allFiles.push(file);
      }
    }
    return allFiles;
  };
  const filteredFiles = files.reduce(
    (acc, file) => {
      acc[file.type.includes("image") ? "images" : "nonImages"].push(file);
      return acc;
    },
    { images: [] as File[], nonImages: [] as File[] },
  );

  const onDeleteNonImageFile = (index: number) => {
    const remainingFiles = filteredFiles.nonImages.filter((_data: File, idx: number) => idx !== index);
    setFiles([...filteredFiles.images, ...remainingFiles]);
    setValue(fieldName, [...filteredFiles.images, ...remainingFiles]);
  };

  const onDeleteImageFile = (index: number) => {
    const remainingFiles = filteredFiles.images.filter((_data: File, idx: number) => idx !== index);
    setFiles([...filteredFiles.nonImages, ...remainingFiles]);
    setValue(fieldName, [...filteredFiles.nonImages, ...remainingFiles]);
  };
  return (
    <Stack direction="horizontal" style={{ alignItems: "flex-start", width: "100%" }} gap={2}>
      <Label htmlFor={fieldName}>Attachments:</Label>
      <AttachmentsContainer>
        <Wrapper className="ml-8">
          <label htmlFor="contained-button-file">
            <Button type="button" text="Add Files" variant="dotted-border" onClick={onClickUpload} icon={faPlus} />
          </label>
          <input
            ref={hiddenFileInput}
            id="contained-button-file"
            multiple
            type="file"
            accept={ALL_FILE_TYPE}
            onChange={onChangeUpload}
            style={{ display: "none" }}
          />
        </Wrapper>
        <PhotoUploadContainer>
          {filteredFiles.images.length > 0 &&
            filteredFiles.images.map((file: File, index: number) => (
              <PhotoContainer
                isViewMode={false}
                key={file.name}
                image={URL.createObjectURL(file)}
                onDeleteClick={(e) => {
                  e.preventDefault();
                  onDeleteImageFile(index);
                }}
              />
            ))}
        </PhotoUploadContainer>
        {filteredFiles.nonImages.length > 0 &&
          filteredFiles.nonImages.map((item, index) => (
            <Attachment key={item.name} islink={0}>
              <AttachmentData onClick={() => onAttachmentClick(item)}>{item.name}</AttachmentData>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={(e) => {
                  e.preventDefault();
                  onDeleteNonImageFile(index);
                }}
              />
            </Attachment>
          ))}
        {isError && <ErrorMessage>{`Please upload the supported files only ${ALL_FILE_TYPE}`}</ErrorMessage>}
      </AttachmentsContainer>
    </Stack>
  );
};
const Wrapper = styled.div`
  margin-left: 8px;
  & label {
    width: unset;
  }
`;
export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > label {
    width: unset;
  }
`;
export const PhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const AttachmentData = styled.div`
  margin-left: 8px;
`;
export const Attachment = styled.div<{ islink: number }>`
  padding-bottom: 15px;
  width: 100%;
  text-decoration: ${({ islink }) => (islink ? "underline" : "none")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ islink }) => (islink ? "pointer" : "defualt")};
  position: relative;
  & > svg {
    position: absolute;
    cursor: pointer;
    right: 0;
  }
`;
