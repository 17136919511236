import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectSearch from "src/components/SelectSearch";
import { FlexWrapper, StyledLabel } from "../styles";
import { MessageFontSizeDropdownProps } from "../types";

export const ControlledDropdown = ({ name, options, value, label }: MessageFontSizeDropdownProps) => {
  const { control } = useFormContext();

  return (
    <FlexWrapper>
      <StyledLabel>{label}:</StyledLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={value}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
          <SelectSearch
            buttonStyle="top: 12px;"
            options={options || []}
            defaultValue={value}
            placeholder={`Select ${label}`}
            onChange={(option) => onChange(option.id)}
            ref={ref}
            bottomResultsGap={45}
            error={error}
          />
        )}
      />
    </FlexWrapper>
  );
};
