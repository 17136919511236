import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePickerCustom from "src/components/DatePicker";
import Input from "src/components/Input";
import { EMAIL_REGEXP, VALIDATION_MESSAGES } from "src/constants/validation";
import FirebaseStorageService from "src/services/Firebase/storage";
import { colors } from "src/styles";
import { TeamMemberById } from "src/types";
import styled from "styled-components";
import SinglePhotoUpload from "../../../components/PhotoUpload/Single";

enum TeamMemberFields {
  PHONE = "phone",
  EMAIL = "email",
  ADDRESS = "address",
  TITLE = "title",
}

interface TeamMemberGeneralInfoProps {
  teamMemberData: TeamMemberById;
  setTeamMemberData: React.Dispatch<React.SetStateAction<TeamMemberById>>;
  logo: File | string | null;
  setLogo: React.Dispatch<React.SetStateAction<string | File | null>>;
  setIsValidForm: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const getLogoPropType = (logo: File | string) => (typeof logo === "string" ? "fileName" : "file");

const TeamMemberGeneralInfo = ({
  setIsValidForm,
  setTeamMemberData,
  teamMemberData,
  logo,
  setLogo,
}: TeamMemberGeneralInfoProps) => {
  const {
    firstName,
    lastName,
    middleName,
    employeeId,
    roles,
    inductionDate,
    clearanceDate,
    profilePicture,
    trainingDate,
  } = teamMemberData;
  const [loadingLogo, setLoadingLogo] = useState(true);

  const handleDatesChange = (value: string, fieldName: string) => {
    setTeamMemberData((prev) => {
      return { ...prev, [fieldName]: moment(value).toISOString() };
    });
  };

  const getUrlPicture = (logoFileName: string) => {
    FirebaseStorageService.getDownloadURL(process.env.REACT_APP_CLIENT_LOGO_FOLDER, logoFileName)
      .then(({ data: logoDownloadUrl }) => setLogo(logoDownloadUrl))
      .catch((error) => console.log(error.message))
      .finally(() => setLoadingLogo(false));
  };

  useEffect(() => {
    if (!profilePicture) {
      setLoadingLogo(false);
      return;
    }

    const typeOfLogoProp = getLogoPropType(profilePicture);
    if (typeOfLogoProp === "fileName" && typeof profilePicture === "string") {
      getUrlPicture(profilePicture);
    } else {
      setLogo(profilePicture);
      setLoadingLogo(false);
    }
  }, [profilePicture]);

  const {
    register,
    formState: { isValid, errors },
    reset,
    trigger,
  } = useForm({
    mode: "all",
    defaultValues: teamMemberData,
  });

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: keyof TeamMemberById) => {
    //undefined - trigger all fields
    trigger(undefined);
    setTeamMemberData((prev) => {
      return { ...prev, [fieldName]: e.target.value };
    });
  };

  useEffect(() => {
    reset(teamMemberData);
  }, [teamMemberData]);

  useEffect(() => {
    setIsValidForm(isValid);
  }, [isValid]);

  if (loadingLogo) return null;

  return (
    <div>
      <SinglePhotoUpload
        setFile={(file: File | null) => {
          setTeamMemberData((prev) => {
            return {
              ...prev,
              profilePicture: file,
            };
          });
          setLogo(file);
        }}
        file={logo}
        elementName="Profile Photo"
      />
      <div>
        <FlexWrapper>
          <RowValue>
            <Label>First Name:</Label>
            <p>{firstName}</p>
          </RowValue>
          <RowValue>
            <Label>Middle Name: </Label>
            <p>{middleName}</p>
          </RowValue>
        </FlexWrapper>
        <FlexWrapper>
          <RowValue>
            <Label>Last Name: </Label>
            <p>{lastName}</p>
          </RowValue>
          <RowValue>
            <Label>Employee ID: </Label>
            <p>{employeeId}</p>
          </RowValue>
        </FlexWrapper>
        <FlexWrapper>
          <RowValue>
            <Label>Role: </Label>
            <RolesWrapper>
              {(roles ?? []).map((role, index) => {
                return <p key={index}>{role}</p>;
              })}
            </RolesWrapper>
          </RowValue>
          <RowValue>
            <Label>
              Title: <span>(optional)</span>
            </Label>
            <div id="title">
              <StyledInput
                value={teamMemberData.title || ""}
                placeholder="Enter Title"
                {...register(TeamMemberFields.TITLE, {
                  onChange: (e) => handleFieldChange(e, TeamMemberFields.TITLE),
                })}
              />
              {errors && errors[TeamMemberFields.TITLE]?.message && (
                <ErrorText>{errors[TeamMemberFields.TITLE]?.message}</ErrorText>
              )}
            </div>
          </RowValue>
        </FlexWrapper>
        <FlexWrapper>
          <RowValue>
            <Label>
              Hire Date: <span>(optional)</span>
            </Label>
            <DatePickerWrapper>
              <DatePickerCustom
                dateFormat="MMMM D, yyyy"
                placeholder="Select Date"
                fieldName="inductionDate"
                onChangeHandler={handleDatesChange}
                date={inductionDate}
                icon={faCalendar as IconProp}
              />
            </DatePickerWrapper>
          </RowValue>
          <RowValue>
            <Label>
              Clearance Date: <span>(optional)</span>
            </Label>
            <DatePickerWrapper>
              <DatePickerCustom
                dateFormat="MMMM D, yyyy"
                placeholder="Select Date"
                onChangeHandler={handleDatesChange}
                fieldName="clearanceDate"
                date={clearanceDate}
                icon={faCalendar as IconProp}
              />
            </DatePickerWrapper>
          </RowValue>
        </FlexWrapper>
        <FlexWrapper>
          <RowValue>
            <Label>
              Training Date: <span>(optional)</span>
            </Label>
            <DatePickerWrapper>
              <DatePickerCustom
                dateFormat="MMMM D, yyyy"
                placeholder="Select Date"
                fieldName="trainingDate"
                onChangeHandler={handleDatesChange}
                date={trainingDate}
                icon={faCalendar as IconProp}
              />
            </DatePickerWrapper>
          </RowValue>
        </FlexWrapper>
      </div>
      <div>
        <Title>Contact Information: </Title>
        <FlexWrapper>
          <RowValue>
            <Label>
              Phone: <span>(optional)</span>
            </Label>
            <div id="phone">
              <StyledInput
                value={teamMemberData.phone || ""}
                maxLength={100}
                placeholder="Enter Phone"
                validation={register(TeamMemberFields.PHONE, {
                  onChange: (e) => handleFieldChange(e, TeamMemberFields.PHONE),
                })}
              />
              {errors && errors[TeamMemberFields.PHONE]?.message && (
                <ErrorText>{errors[TeamMemberFields.PHONE]?.message}</ErrorText>
              )}
            </div>
          </RowValue>
          <RowValue>
            <Label>
              Email: <span>(optional)</span>
            </Label>
            <div id="email">
              <StyledInput
                value={teamMemberData.email || ""}
                maxLength={100}
                placeholder="Enter Email"
                validation={register(TeamMemberFields.EMAIL, {
                  onChange: (e) => handleFieldChange(e, TeamMemberFields.EMAIL),
                  pattern: {
                    value: EMAIL_REGEXP,
                    message: VALIDATION_MESSAGES.invalidEmail,
                  },
                })}
              />
              {errors[TeamMemberFields.EMAIL]?.message && (
                <ErrorText>{errors[TeamMemberFields.EMAIL]?.message}</ErrorText>
              )}
            </div>
          </RowValue>
        </FlexWrapper>
        <RowValue>
          <Label>
            Address: <span>(optional)</span>
          </Label>
          <AddressInputWraper>
            <StyledInput
              value={teamMemberData.address || ""}
              maxLength={100}
              placeholder="Enter Address"
              validation={register(TeamMemberFields.ADDRESS, {
                onChange: (e) => handleFieldChange(e, TeamMemberFields.ADDRESS),
              })}
            />
            {errors[TeamMemberFields.ADDRESS]?.message && (
              <ErrorText>{errors[TeamMemberFields.ADDRESS]?.message}</ErrorText>
            )}
          </AddressInputWraper>
        </RowValue>
      </div>
    </div>
  );
};

const FlexWrapper = styled.div`
  display: flex;
`;

const RowValue = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0.1;
  flex-basis: auto;

  padding-bottom: 40px;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.grey.dark};
  &:first-child {
    margin-right: 60px;
  }
  & > p {
    width: 180px;
  }

  & > div#email,
  > div#phone,
  > div#title {
    max-width: 210px;
    width: 100%;
    & > input {
      width: 100%;
    }
  }
`;

const RolesWrapper = styled.div`
  width: 180px;
  & > p {
    text-transform: capitalize;
  }
`;

const DatePickerWrapper = styled.div`
  max-width: 210px;
  width: 100%;
  & > div > .react-datepicker-wrapper > .react-datepicker__input-container > div > input {
    width: 100%;
  }
`;

const Label = styled.label`
  font-weight: 600;
  min-width: 200px;
  font-size: 14px;
  color: ${colors.grey.dark};

  & span {
    font-weight: 500;
    font-style: italic;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark};
  padding-bottom: 30px;
`;

const StyledInput = styled(Input)`
  position: relative;
`;

const AddressInputWraper = styled.div`
  max-width: 700px;
  width: 100%;
  & > input {
    width: 100%;
  }
`;

const ErrorText = styled.p`
  padding-top: 8px;
`;

export default TeamMemberGeneralInfo;
