import React from "react";
import ResourseAssigned from "src/components/molecules/TicketsContainer/components/ResourseAssigned";
import { InputsContainer, InputRow } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { WorkRequestResourceInputProps } from "../types";

const WorkRequestResourceInput = ({
  activeResources,
  isVerifyMode,
  assignResourceValues,
  setIsResourceAssignedModalVisible,
}: WorkRequestResourceInputProps) => {
  const { isMobile } = useIsMobile();
  return (
    <InputsContainer>
      <InputRow $isMobile={isMobile}>
        <label style={{ marginRight: 5 }}>Resource (optional) :</label>
        <ResourseAssigned
          employees={activeResources.employees}
          vendors={activeResources.vendors}
          onClick={() => {
            if (!isVerifyMode) {
              setIsResourceAssignedModalVisible(true);
            }
          }}
          vendorId={assignResourceValues?.vendors?.vendorId ? assignResourceValues.vendors.vendorId : undefined}
          laborCode={assignResourceValues?.employees?.laborCode ? assignResourceValues.employees.laborCode : undefined}
        />
      </InputRow>
    </InputsContainer>
  );
};

export default WorkRequestResourceInput;
