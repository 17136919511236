import { createAction } from "@reduxjs/toolkit";

import { DBClientUser, DBEmployeeUser } from "../../types";

const ACTION_PREFIX = "USER";

type DBUser = DBClientUser | DBEmployeeUser;

export const logInUser = createAction<
  DBUser & {
    id?: string;
    maximoTimezone?: string;
  }
>(`${ACTION_PREFIX}/LOG_IN_USER`);
export const logOutUser = createAction(`${ACTION_PREFIX}/LOG_OUT_USER`);
