import React, { useMemo } from "react";
import { WorkRequestSelectClassificationDropdownProps } from "../types";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import DropdownRow from "../../DropdownRow";
import { HintIcon } from "../styles";
import { objToArray } from "src/utils/objToArray";
import { useLocation } from "react-router-dom";

const WorkRequestSelectClassificationDropdown = ({
  classification,
  disabled,
  serviceTypeId,
  setClassificationId,
  setFormData,
  isManager,
  serviceTypes,
  clientId,
  isNoAuth,
  noAuthServiceList,
}: WorkRequestSelectClassificationDropdownProps) => {
  const location = useLocation();
  const defaultServiceType = location?.state?.serviceType;

  const dropdownClassifications = useMemo(() => {
    const managerClassifications = isManager
      ? serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)
      : defaultServiceType;

    const targetServiceType = isNoAuth
      ? noAuthServiceList?.find((serviceType) => serviceType.id === serviceTypeId)
      : managerClassifications;

    const classifications = objToArray(targetServiceType?.classifications, (item, id) => ({
      ...item,
      id,
    }));

    const filteredClassifications = isNoAuth
      ? classifications.filter((item) => item.showOnPublic !== false)
      : classifications;

    return filteredClassifications.map((classification) => {
      return {
        id: classification.name,
        value: classification.description,
        hierarchypath: classification.hierarchypath,
        classstructureid: classification.classstructureid,
        longDescription: classification.longDescription,
      };
    });
  }, [defaultServiceType, serviceTypeId, clientId]);

  return (
    <DropdownRow
      sort
      disabled={disabled}
      label="Classification:"
      defaultValue={classification}
      dropdownItems={dropdownClassifications}
      placeholder={"Select Classification"}
      onClick={(item) => {
        setFormData((data) => ({ ...data, classification: item.value }));
        setClassificationId(String(item.id));
      }}
    >
      <HintIcon
        style={{
          visibility: !!dropdownClassifications.find((el) => el.value === classification)?.longDescription
            ? "visible"
            : "hidden",
        }}
        data-tip={`<div style="overflow: hidden">${(
          dropdownClassifications.find((el) => el.value === classification)?.longDescription || ""
        ).replace(/<br ?\/?>/g, "\n")}</div>`}
        icon={faQuestionCircle}
      />
    </DropdownRow>
  );
};

export default WorkRequestSelectClassificationDropdown;
