import React, { useContext, useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { EmptyContainer, NoTicketsMessage } from "src/components/ListScreen";
import { FirebaseDataService } from "src/services/Firebase/data";
import { DBClientPortalTile } from "src/types";
import styled from "styled-components";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import Button from "src/components/Button";
import { v4 as uuidv4 } from "uuid";

import ClientDetailsContext from "../context";
import { SingleTile } from "./SingleTile";

export const ClientPortalTiles = () => {
  const { clientPortalTiles, setClientPortalTiles, setGeneralInfoFormState } = useContext(ClientDetailsContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const getTiles = async () => {
    if (clientPortalTiles.length) {
      return;
    }
    setIsLoading(true);
    setError("");

    if (id) {
      try {
        const [clientTiles, globalTiles] = await Promise.all([
          FirebaseDataService.getClientPortalTiles(id),
          FirebaseDataService.getGlobalTiles(id),
        ]);

        const { data } = clientTiles;
        const { data: gData } = globalTiles;

        const newTiles =
          data
            .map((item) => {
              const currentTile = gData.find((oldItem) => oldItem.id === item.id);
              if (item.tileType === "Global" && (!currentTile || item.enabled === false))
                return {} as DBClientPortalTile;
              return {
                ...(item.tileType === "Global"
                  ? {
                      ...item,
                      isValid: true,
                      changes: { ...currentTile },
                    }
                  : { ...item, isValid: true }),
              };
            })
            .filter((item) => item.id) || [];

        setClientPortalTiles(newTiles);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    getTiles();
  }, []);

  const addNewTile = () => {
    const newTileTemplate: DBClientPortalTile = {
      id: uuidv4(),
      tileType: "Client",
      isNew: true,
      isValid: false,
      type: "folder",
      folder: "",
      title: "",
    };
    setClientPortalTiles([...clientPortalTiles, newTileTemplate]);
    setGeneralInfoFormState((prevState) => ({
      ...prevState,
      clientPortal: {
        tiles: [...clientPortalTiles, newTileTemplate],
      },
    }));
  };

  return (
    <Content>
      {!isLoading && error && <Error>{error}</Error>}
      {isLoading ? (
        <EmptyContainer>
          <Spinner animation="border" size="sm" />
        </EmptyContainer>
      ) : clientPortalTiles?.length ? (
        <>
          {clientPortalTiles?.map((item, key) => {
            return (
              //condition is necessary to open new added tile accordion
              <Accordion key={item.id} {...(item.isNew ? { defaultActiveKey: key.toString() } : {})}>
                <SingleTile position={key} data={item} />
              </Accordion>
            );
          })}
        </>
      ) : (
        <NoTicketsMessage style={{ marginBottom: "25px" }}>No tiles</NoTicketsMessage>
      )}

      <Button text="Add Tile" variant="dotted-border" icon={faPlus as IconDefinition} onClick={() => addNewTile()} />
    </Content>
  );
};

const Content = styled.div`
  padding: 0 30px 30px 0px;
`;

const Error = styled.p`
  padding: 30px 0 0 30px;
`;
