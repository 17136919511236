import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import TimeLabel from "src/components/TimeLabel";
import ClickInput from "src/components/atoms/ClickInput";
import CustomToast from "src/components/atoms/CustomToast";
import { DatePicker } from "src/components/atoms/DatePicker";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { DatePickerWrapper } from "src/components/molecules/ShiftForm/styles";
import { FirebaseDataService, Response } from "src/services/Firebase/data";
import { stripMaximoTimeZone } from "src/utils";
import { InputRow } from "../ViewForm";

interface ClickInputDatePickerProps {
  $isMobile: boolean;
  date: string | undefined;
  timezone?: string;
  label: string;
  maximoTimezone: string;
  valueComponent?: "input" | "p";
  fieldName: string;
  id: string;
  height?: string;
  maxDate?: Date;
}

const ClickInputDatePicker = ({
  $isMobile,
  date,
  timezone,
  label,
  maximoTimezone,
  valueComponent,
  fieldName,
  id,
  height,
  maxDate,
}: ClickInputDatePickerProps) => {
  const [newDate, setNewDate] = useState(
    date ? moment(date).tz(maximoTimezone, true).format("MM/DD/YYYY, h:mm:ss a") : undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Response>();
  const datePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    datePickerRef.current?.click();
  }, [datePickerRef]);

  const onUpdate = async () => {
    let updatedDate = null;
    setNewDate((prev) => {
      updatedDate = prev;
      return prev;
    });
    setError(undefined);
    setIsLoading(true);
    const displayTime = moment(updatedDate).tz(maximoTimezone).format();

    const res = await FirebaseDataService.updateMaximoWorkOrderField(id, fieldName, displayTime);

    if (res && res.status === "error") {
      const message = res.message;
      const regex = /Error:\s(.+?)(\n|$)/;
      const match = message.match(regex);
      if (match) {
        setError({
          status: res.status,
          message: match[1].trim(),
        });
      } else {
        setError({
          status: res.status,
          message: res.message,
        });
      }
      setNewDate(undefined);
    }
    setIsLoading(false);
  };

  return (
    <>
      <InputRow style={height ? { height } : {}} $isMobile={$isMobile}>
        <label style={{ marginRight: 0 }}>{label}</label>
        <ClickInput
          isLoading={isLoading}
          onRemoveFocusFromInput={onUpdate}
          inputComponent={
            <DatePickerWrapper>
              <DatePicker
                variant={DATEPICKER_VARIANT.DATE_TIME}
                onChange={setNewDate}
                value={newDate}
                name={fieldName}
                ref={datePickerRef}
                maxDate={maxDate}
              />
            </DatePickerWrapper>
          }
          outputComponent={
            newDate ? (
              <TimeLabel
                date={stripMaximoTimeZone(newDate, maximoTimezone, timezone)}
                maximoTimezone={maximoTimezone}
                timezone={timezone}
                valueComponent={valueComponent}
              />
            ) : (
              <p>N/A</p>
            )
          }
        />
      </InputRow>
      <CustomToast
        isToastVisible={error?.status === "error"}
        title={<strong>Error</strong>}
        message={<p>{error?.message}</p>}
      />
    </>
  );
};

export default ClickInputDatePicker;
