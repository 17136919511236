import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import React, { useEffect } from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { ensureEmptyElement } from "./effects";
import { onChageHandler } from "./handlers";
import { DynamicRowsProps } from "./types";

/**
 * Renders a dynamic list of form fields handling log of adding and removing new items.
 *
 * Accepts the form element to use in each row (e.g. input, dropdown, etc.) as a render prop.
 *
 * Must be inside `reach-hook-form` context.
 */
export const DynamicRows = <ItemType extends Record<string, string>>({
  name,
  getNewItem,
  rules,
  renderItem,
  keyName = "id",
  valueName = "value",
}: DynamicRowsProps<ItemType>) => {
  const { control, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name, keyName });

  useEffect(() => {
    ensureEmptyElement(getValues(name)?.map((item: ItemType) => item[valueName]), append, getNewItem);
  }, [fields]);

  return (
    <Stack gap={3}>
      {fields.map((item, index) => (
        <Stack key={item[keyName]} direction="horizontal">
          <div className="me-auto">
            <Controller
              control={control}
              name={`${name}.${index}.${valueName}`}
              defaultValue={item[valueName]}
              rules={rules}
              render={({ field: { onChange, value, ref }, fieldState: { error } }) =>
                renderItem(
                  value,
                  (event) => onChageHandler(event, index, getValues(name), append, remove, onChange, getNewItem),
                  error,
                  ref,
                )
              }
            />
          </div>
          <Button
            onClick={(event) => {
              event.preventDefault();
              remove(index);
            }}
            icon={faTimesCircle as any}
            iconPosition="center"
            variant="text"
          />
        </Stack>
      ))}
    </Stack>
  );
};
