import React from "react";

import { Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { SAVE_CHANGES_HINT_MESSAGE } from "src/pages/ClientDetails/constants";
import styled from "styled-components";
import { colors } from "../../styles";
import Button from "../Button";

interface Tab {
  eventKey: string;
  label: string;
  content: React.ReactNode;
  tabColor?: string;
}

export interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  setActiveTab: (value: string) => void;
  backHandler?: () => void;
  saveHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disableSave?: boolean;
  isLoading?: boolean;
  $showBorder?: boolean;
}

export const Tabs = ({ tabs, activeTab, setActiveTab, saveHandler, disableSave, isLoading }: TabsProps) => {
  return (
    <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(tab) => tab && setActiveTab(tab)}>
      <Row>
        <MenuWrapper sm={4}>
          <Nav variant="pills" className="flex-column">
            {tabs.map(({ eventKey, label, tabColor = colors.kleenway.green }) => (
              <StyledNavItem key={eventKey} $tabColor={tabColor}>
                <Nav.Link eventKey={eventKey}>{label}</Nav.Link>
              </StyledNavItem>
            ))}
          </Nav>
        </MenuWrapper>
        <ChildrenWrapper sm={8}>
          <Tab.Content>
            {tabs.map(({ eventKey, content }) => (
              <TabContent key={eventKey} eventKey={eventKey}>
                {content}
              </TabContent>
            ))}
            <SaveWrapper data-tip={disableSave && !isLoading ? SAVE_CHANGES_HINT_MESSAGE : ""}>
              {isLoading && <Spinner animation="border" size="sm" />}
              <Button
                text="Save Changes"
                onClick={saveHandler}
                disabled={disableSave || isLoading}
                additionalStyles={{
                  color: colors.white.default,
                  padding: "12px 24px",
                  backgroundColor: colors.kleenway.green,
                }}
                variant="middle-primary"
              />
            </SaveWrapper>
            <ReactTooltip place="bottom" border={"lightgray"} />
          </Tab.Content>
        </ChildrenWrapper>
      </Row>
    </Tab.Container>
  );
};

const StyledNavItem = styled(Nav.Item)`
  background-color: ${(props) => props.$tabColor};
  border: 1px solid ${colors.grey.light8};
  border-top: 0;
  font-size: 14px;

  &:first-child {
    border-top: 1px solid ${colors.grey.light8};
  }

  .nav-link {
    color: ${colors.black.light1};
    background-color: ${colors.white.default};
    border-radius: 0;
    margin-left: ${(props) => (props.$tabColor === colors.red.default ? "5px" : 0)};
    padding: 20px;
  }

  .nav-link.active {
    font-weight: 700;
    background-color: ${colors.kleenway.green};
    border-radius: 0;
    color: ${colors.white.default};
  }
`;

const MenuWrapper = styled(Col)`
  padding: 0;
  max-width: 240px;
`;

const ChildrenWrapper = styled(Col)`
  width: calc(100% - 240px);
  padding-left: 35px;
`;

const TabContent = styled(Tab.Pane)`
  padding-bottom: 22px;
  border-bottom: 1px solid ${colors.grey.light13};
`;

export const SaveWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding-top: 20px;
  width: max-content;
  float: right;
  & > .spinner-border {
    margin-right: 7px;
  }
`;
