import React, { FC } from "react";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { CommentRegEx } from ".";
import { DescriptionContainer } from "..";
import TimeLabel from "../../../components/TimeLabel";
import { Worklog } from "../../../types";
import { stripMaximoTimeZone } from "../../../utils";

type TechnicianNotesProps = {
  comments: Worklog[];
  timezone: string;
  maximoTimezone: string;
  $isMobile: boolean;
};

const TechnicianNotes: FC<TechnicianNotesProps> = ({ comments, timezone, maximoTimezone, $isMobile }) => {
  return (
    <CommentsContainer>
      {comments?.length > 0 ? (
        <>
          <label>Technician Notes</label>
          {comments.map((worklog: Worklog) => {
            const noteText = (worklog?.description_longdescription || worklog?.description || "").replace(
              CommentRegEx,
              "",
            );
            return noteText ? (
              <DescriptionContainer $isComments={true} key={worklog._rowstamp} $isMobile={$isMobile}>
                <TimeLabel
                  date={stripMaximoTimeZone(worklog.createdate.toString(), maximoTimezone, timezone)}
                  maximoTimezone={maximoTimezone}
                  timezone={timezone}
                  valueComponent="p"
                />
                <ReactQuill readOnly theme="snow" value={noteText} />
              </DescriptionContainer>
            ) : null;
          })}
        </>
      ) : null}
    </CommentsContainer>
  );
};

const CommentsContainer = styled.div`
  label {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #555555;
  }

  p {
    font-size: 14px;
  }
`;

export default TechnicianNotes;
