import { FieldError } from "react-hook-form";

export const enum DATEPICKER_VARIANT {
  DATE = "date",
  TIME = "time",
  DATE_TIME = "datetime",
}

export interface DatePickerProps {
  name?: string;
  value?: string;
  variant?: DATEPICKER_VARIANT;
  error?: FieldError;
  maxDate?: Date;
  onChange?: (value: string) => void;
  width?: string;
}

export interface CustomTimeInputProps {
  value: string | undefined;
  onChange: (value: string) => void;
  name?: string;
}
