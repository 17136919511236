import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { Label } from "../styles";
import { BillableAmountProps } from "../types";

export const BillableAmount = ({ required }: BillableAmountProps) => {
  const { control } = useFormContext();

  const fieldName = "approvedamount";

  return (
    <Stack direction="horizontal" gap={2}>
      <Label htmlFor={fieldName} required={required}>
        Billable Amount:
      </Label>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required }}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Input
            type={"number"}
            controlId={fieldName}
            name={name}
            value={value}
            placeholder={`Enter amount`}
            onChange={onChange}
            ref={ref}
            error={error}
            step="0.01"
          />
        )}
      />
    </Stack>
  );
};
