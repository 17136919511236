import "react-datetime-picker/dist/DateTimePicker.css";

import React from "react";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { SelectBox, SelectBoxContainer, SelectedItem } from "src/components/Dropdown/styles";
import { colors } from "src/styles";

import { CreateForm } from "./CreateForm";
import { ViewForm } from "./ViewForm";
import { MobileProps, TicketFormProps } from "./types";
import { useSelector } from "react-redux";
import { getUserData } from "src/redux/selectors/user";

export const TicketForm = ({ setStep, noAuthMode, noAuthData, setSuccessMessage }: TicketFormProps) => {
  const { id } = useParams();

  const isViewMode = !noAuthMode && !!id;
  const { isManager, clientId } = useSelector(getUserData);
  return isViewMode ? (
    <ViewForm noAuthData={noAuthData} noAuthMode={noAuthMode} />
  ) : (
    <CreateForm
      noAuthData={noAuthData}
      isNoAuthMode={noAuthMode}
      setStep={setStep}
      isManager={isManager}
      isClient={!!clientId}
      setSuccessMessage={setSuccessMessage}
    />
  );
};

export const PhotoInput = styled.div<MobileProps>`
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid ${colors.grey.light12};
  border-top: 1px solid ${colors.grey.light12};
  padding: 30px 0;

  label {
    font-weight: 600;
    font-size: 14px;
    flex: 0 0 195px;

    color: ${colors.black.light2};
    white-space: nowrap;
    margin-right: 20px;
    display: flex;
    align-items: center;

    span {
      display: block;
      margin-top: 5px;
      font-size: 14px;
      font-weight: 400;
      padding-left: 5px;
    }

    ${(props) => props.$isMobile && `width: 100px;`}
  }
`;

export const StyledForm = styled(Form)`
  padding-top: 20px;
`;

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  margin: 30px;
  background-color: ${colors.white.default};
`;

interface InputRowProps extends MobileProps {
  $marginBottom?: string;
  $isSelectSearch?: boolean;
  ismanager?: number;
}

export const InputRow = styled.div<InputRowProps>`
  display: flex;
  align-items: baseline;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom === undefined ? "40" : $marginBottom}px`};
  max-width: ${({ ismanager }) => (ismanager ? "unset" : "600px")};
  width: 100%;
  position: relative;
  margin-right: 20px;

  & div {
    width: ${({ ismanager }) => ismanager && "100%"};
  }

  ${(props) =>
    props.$topLabel &&
    css`
      align-items: flex-start;
    `}

  div {
    font-size: 14px;
    p {
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;

    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 14px;
        width: 100px;
      `}
  }

  input {
    width: ${({ ismanager }) => (ismanager ? "100%" : "360px")};
    ${(props) =>
      !props.$isSelectSearch &&
      css`
        padding: 0px 7px;
      `}

    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `}
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

export const InputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tooltip {
    max-width: 70%;
  }
`;

export const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectedItem} {
        font-size: 12px;
      }
    `}
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBox} {
        width: 200px;
      }
    `}
  ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
        width: 100px;
      }

      input {
        font-size: 12px;
        width: 100%;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `}
`;

export const DescriptionContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: ${({ $isComments }) => ($isComments ? "column" : "row")};
  margin-bottom: 20px;
  width: 100%;

  .ql-toolbar.ql-snow {
    display: none;
    border: none;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    border: 1px solid ${colors.grey.light13};
    padding: 10px;
  }

  label {
    font-size: 14px;
    font-weight: 600;
    flex: 0 0 200px;
    margin-right: 20px;
    color: ${colors.black.light2};
    margin-bottom: ${({ $isComments }) => ($isComments ? "10px" : "0px")};

    ${(props) =>
      props.$isMobile &&
      css`
        label {
          font-size: 12px;
        }
      `};
  }

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
      }
    `}
  textarea {
    border: 1px solid ${colors.grey.light13};
    padding: 10px;
    width: 100%;

    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
      `}
    &:disabled {
      background: none;
    }
  }
`;

export const AttachmentRow = styled.div`
  ${InputRow} {
    align-items: flex-start;
  }
`;
