import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "src/components/Input";
import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled, { css } from "styled-components";

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: baseline;
  margin-bottom: 32px;
  & [id="filters-dropdown"] {
    height: 35px;
  }
`;

export const Content = styled.div`
  padding: 15px 30px;
`;
export const Dropdowns = styled.div<MobileProps>`
  display: flex;
  align-items: stretch;
  gap: 30px;

  @media (max-width: 921px) {
    & > div {
      width: 100% !important;
    }
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      flex-direction: column;
      gap: 10px;
      font-size: 13px;
    `}
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 330px;
  box-sizing: content-box;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const StyledInput = styled(Input)`
  border-radius: 4px 0 0 4px;
  border-right: 0;
  height: unset;
  padding: 7px 0px 7px 12px;

  &:focus {
    outline: none;
    border: 1px solid ${colors.kleenway.green};
    border-right: 0;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 12px;
  width: 12px;
  height: 12px;
  color: ${colors.grey.dark4};
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  & button {
    border-radius: 0px 5px 5px 0px;
    padding: 8px 16px;
    &:disabled {
      color: ${colors.white.default};
    }
  }
`;

export const TabsWrapper = styled(Content)`
  padding: 0;
`;

export const TicketContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  width: 100%;
`;

export const TicketHeading = styled.h4`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.grey.dark};
`;

export const TicketSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketInfo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketStatus = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const TicketDot = styled.div`
  height: 2px;
  width: 2px;
  background-color: ${colors.grey.dark};
  border-radius: 50%;
  display: inline-block;
`;
export const TicketButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p {
    text-decoration: underline;
    cursor: pointer !important;
  }
`;
