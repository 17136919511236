import React from "react";
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { Required } from "src/components/atoms/Required";
import { InputRow } from "../styles";
import { ControlledInputProps } from "../types";

export const ControlledInput = ({ name, label, value, rules }: ControlledInputProps) => {
  const { control } = useFormContext();
  const renderInput = ({
    field: { onChange, value: data, ref },
    fieldState: { error },
  }: {
    field: ControllerRenderProps;
    fieldState: ControllerFieldState;
  }) => <Input name={name} value={data} placeholder={`Enter ${label}`} onChange={onChange} ref={ref} error={error} />;
  return (
    <InputRow>
      <label>
        {label}:{rules?.required && <Required />}
      </label>
      <div>
        <Controller control={control} name={name} defaultValue={value} rules={rules} render={renderInput} />
      </div>
    </InputRow>
  );
};
