import { FieldValues, UseFieldArrayAppend } from "react-hook-form";
import { DynamicRowsProps } from "./types";

/**
 * Takes care of the initial load, automaticaly adds a new empty form element at the end in case the form's default
 * values do not provide one.
 *
 * @param values - current form values
 * @param append - method to append a new form element
 * @param getNewItem - method to generate a new form element value
 */
export const ensureEmptyElement = <ItemType>(
  values: string[],
  append: UseFieldArrayAppend<FieldValues>,
  getNewItem: DynamicRowsProps<ItemType>["getNewItem"],
) => {
  let addNew = true;

  for (const i in values) {
    // if there is an empty value already present in the list - no need to add a new one
    if (!values[i]) {
      addNew = false;
      break;
    }
  }

  if (addNew) {
    append(getNewItem(), { shouldFocus: false });
  }
};
