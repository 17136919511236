import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import Button from "src/components/Button";
import { Container, NoTicketsMessage, PaginationContainer } from "src/components/ListScreen";
import { Pagination } from "src/components/Pagination";
import { setPreviousURLTeamMembers } from "src/redux/actions/openedPages";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ContentFrame, TopBar } from "src/styles";
import { Client, DBLocation, ID, TeamMember } from "src/types";
import styled from "styled-components";
import { BooleanParam, NumberParam, StringParam, useQueryParam, useQueryParams } from "use-query-params";
import TeamMembersFilter, { FiltersArguments, RoleFilter } from "./TeamMembersFilter";
import TeamMembersTable from "./TeamMembersTable";
import { MANAGER_TEAM_MEMBERS_BREADCRUMBS, teamMemberstableHeaders } from "./constants";

const TeamMembers = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [openedPage, setOpenedPage] = useQueryParam("openedPage", NumberParam);
  const { id: managerId } = useSelector(getUserData);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const [error, setError] = useState("");

  const [query, setQuery] = useQueryParams({
    openedPage: NumberParam,
    onSite: BooleanParam,
    name: StringParam,
    employeeId: StringParam,
    client: StringParam,
    location: StringParam,
    role: StringParam,
  });

  const isFiltersQuery = Object.entries(query).filter(([key, value]) => {
    if (key === "openedPage") {
      return false;
    }
    return !!value;
  }).length;

  const [isFilterBoxOpen, setIsFilterBoxOpen] = useState(false);
  const [areFiltersApplied, setAreFiltersApplied] = useState(!!isFiltersQuery);

  const [pageInfo, setPageInfo] = useState({ total: 0, perPage: 10 });

  const [locationFiltersData, setLocationFiltersData] = useState<DBLocation[]>();
  const [clientFiltersData, setClientFiltersData] = useState<Client[]>();
  const [roleFilterData, setRoleFilterData] = useState<RoleFilter>();
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  const onRowClick = (id: ID) => {
    dispatch(setPreviousURLTeamMembers(search));
    navigate(`/team-members/${id}`);
  };

  const onPageChangeClick = (pageNumber: number) => {
    setOpenedPage(pageNumber);
    setQuery({
      ...query,
      openedPage: pageNumber,
    });
  };

  const onCloseFilters = () => setIsFilterBoxOpen(false);

  const getFiltersData = async () => {
    setIsLoadingFilters(true);
    setError("");

    try {
      const [clientsData, rolesFilter, locationsData] = await Promise.allSettled([
        FirebaseDataService.getClientsByManagerId({ managerId }),
        FirebaseDataService.getRolesFilter(),
        FirebaseDataService.getLocationsByManagerId({ managerId }),
      ]);

      setClientFiltersData(clientsData.status === "fulfilled" ? clientsData.value.data : []);
      setRoleFilterData(rolesFilter.status === "fulfilled" ? rolesFilter.value.data : null);
      setLocationFiltersData(locationsData.status === "fulfilled" ? locationsData.value.data : null);
    } catch (err) {
      setLocationFiltersData([]);
      setError(err.message);
    } finally {
      setIsLoadingFilters(false);
    }
  };

  const getTeamMembers = async () => {
    setIsLoading(true);
    setError("");

    try {
      const filtersForDb = {
        ...(query.client && { client: query.client }),
        ...(query.employeeId && { employeeId: query.employeeId }),
        ...(query.onSite && { onSite: query.onSite }),
        ...(query.location && { location: query.location }),
        ...(query.name && { name: query.name }),
        ...(query.role && { role: query.role }),
      };
      const { data, total, perPage } = await FirebaseDataService.getTeamMembers({
        managerId,
        currentTeamMembersPage: openedPage || 1,
        filters: filtersForDb,
      });

      setTeamMembers(data);
      setPageInfo({ total, perPage });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onAppliedHandler = async ({
    clientFilter,
    employeeIdFilter,
    isOnSite,
    locationFilter,
    nameFilter,
    roleFilter,
  }: FiltersArguments) => {
    setAreFiltersApplied(true);
    setIsLoading(true);
    setError("");

    try {
      const URLQuery = {
        ...query,
        openedPage: "1",
        client: clientFilter || undefined,
        employeeId: employeeIdFilter || undefined,
        onSite: isOnSite || undefined,
        location: locationFilter || undefined,
        name: nameFilter || undefined,
        role: roleFilter || undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const urlQueryWithoutUndefined = Object.fromEntries(Object.entries(URLQuery).filter(([_, value]) => value));

      const filtersForDb = {
        ...(clientFilter && { client: clientFilter }),
        ...(employeeIdFilter && { employeeId: employeeIdFilter }),
        ...(isOnSite && { onSite: isOnSite }),
        ...(locationFilter && { location: locationFilter }),
        ...(nameFilter && { name: nameFilter }),
        ...(roleFilter && { role: roleFilter }),
      };

      const { data, total, perPage } = await FirebaseDataService.getTeamMembers({
        managerId,
        currentTeamMembersPage: 1,
        filters: filtersForDb,
      });
      setTeamMembers(data);
      setPageInfo({ total, perPage });
      setQuery(urlQueryWithoutUndefined);

      onCloseFilters();
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const clearAllURLFilterParams = () => {
    setQuery({
      openedPage: undefined,
      client: undefined,
      employeeId: undefined,
      onSite: undefined,
      location: undefined,
      name: undefined,
      role: undefined,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { openedPage, ...rest } = query;
    if (!Object.values(rest).filter((item) => item).length) {
      setAreFiltersApplied(false);
    }
  }, [query]);

  useEffect(() => {
    getFiltersData();
  }, []);

  useEffect(() => {
    getTeamMembers();
  }, [query]);

  return (
    <Container>
      <TopBar>
        <BreadCrumbs folderStack={MANAGER_TEAM_MEMBERS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />
      </TopBar>
      {!isLoadingFilters && !isLoading && error && <Error>{error}</Error>}
      {isLoadingFilters || isLoading ? (
        <SpinnerContainer>
          <Spinner size="sm" animation="border" />
        </SpinnerContainer>
      ) : (
        <Content>
          <ContentFrame>
            {!areFiltersApplied && !teamMembers.length ? (
              <NoTicketsMessage>No Team Members assigned</NoTicketsMessage>
            ) : (
              <>
                <Button
                  additionalStyles={{
                    float: "unset",
                    borderRadius: "10px",
                    padding: "9px 20px",
                    margin: "0 0 25px auto",
                  }}
                  disabled={isFilterBoxOpen}
                  icon={faFilter as IconDefinition}
                  text="Filter"
                  onClick={() => setIsFilterBoxOpen(true)}
                />

                {(isFilterBoxOpen || areFiltersApplied) && (
                  <TeamMembersFilter
                    onAppliedHandler={onAppliedHandler}
                    setAreFiltersApplied={setAreFiltersApplied}
                    areFiltersApplied={areFiltersApplied}
                    isFilterBoxOpen={isFilterBoxOpen}
                    onCloseFilters={onCloseFilters}
                    clientFiltersData={clientFiltersData || []}
                    roleFilterData={roleFilterData}
                    initialLocationFiltersData={locationFiltersData || []}
                    clearAllURLFilterParams={clearAllURLFilterParams}
                  />
                )}

                {!teamMembers.length && areFiltersApplied && (
                  <NoTicketsMessage>
                    We couldn&apos;t find anything matching your filters. Please, try again with different filter
                    criteria.
                  </NoTicketsMessage>
                )}

                {!!teamMembers.length && (
                  <TeamMembersTable
                    headers={teamMemberstableHeaders}
                    onRowClick={onRowClick}
                    data={teamMembers}
                    roleFilterData={roleFilterData}
                  />
                )}

                {!!teamMembers.length && (
                  <PaginationContainer>
                    <Pagination
                      totalCount={pageInfo.total}
                      pageSize={pageInfo.perPage}
                      onPageChange={onPageChangeClick}
                      currentPage={openedPage || 1}
                    />
                  </PaginationContainer>
                )}
              </>
            )}
          </ContentFrame>
        </Content>
      )}
    </Container>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

const Content = styled.div`
  padding: 30px;
  & > button {
    &:focus {
      border: 0;
    }
  }
`;

const Error = styled.p`
  padding: 30px 0 0 30px;
`;

export default TeamMembers;
