import React from "react";
import { useSelector } from "react-redux";
import DataTable from "src/components/Table";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Ticket } from "src/types";
import { tableHeaders } from "../constant";
import ClientListItem from "./ClientListItem";
import ClientTableRow from "./ClientTableRow";
import { getIsTicketsLoading, getTickets } from "src/redux/selectors/resources";

const ClientTicketsWrapper = () => {
  const isTicketsLoading = useSelector(getIsTicketsLoading);
  const tickets = useSelector(getTickets);

  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <>
        {tickets.map((ticket: Ticket) => (
          <ClientListItem ticket={ticket} key={ticket.wonum} />
        ))}
      </>
    );
  }
  return (
    <DataTable isLoading={isTicketsLoading} headers={tableHeaders}>
      {tickets?.map((ticket: Ticket) => <ClientTableRow key={ticket.wonum} ticket={ticket} />)}
    </DataTable>
  );
};

export default ClientTicketsWrapper;
