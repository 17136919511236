import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPlus, faRedo, faTimes, faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { colors } from "src/styles";

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import UploadDropZone from "@rpldy/upload-drop-zone";
import { UPLOADER_EVENTS, useUploady } from "@rpldy/uploady";
import axios, { AxiosResponse } from "axios";
import { Spinner } from "react-bootstrap";
import { FirebaseDataService } from "src/services/Firebase/data";
import { ID } from "src/types";
import { extractFileNameParts } from "src/utils/helpers";
import { debugLog } from "src/utils/log";
import { v4 as uuidv4 } from "uuid";
import Button from "../Button";

export interface ModalUploadProps {
  isShowing: boolean;
  title: string;
  onCancelClick: () => void;
  approveButtonText?: string;
  cancelButtonText?: string;
  approveButtonStyle?:
    | "primary"
    | "secondary"
    | "text"
    | "decline"
    | "small-primary"
    | "small-secondary"
    | "dotted-border";
  cancelButtonStyle?:
    | "primary"
    | "secondary"
    | "text"
    | "decline"
    | "small-primary"
    | "small-secondary"
    | "dotted-border";
  buttonsFlexDirection?: "column" | "row";
  revertButtons?: boolean;
  maxWidth?: string;
  rootFolderId: ID;
  setClickedAction: React.Dispatch<React.SetStateAction<string>>;
  getData: () => Promise<void>;
}

type FileStatus = "new" | "loading" | "completed" | "error";

interface UploadedFiles {
  id: string;
  file: File;
  error: string;
  status: FileStatus;
}

type FoldersCache = {
  [folderName: string]: {
    id: ID;
    children: FoldersCache;
  };
};

type FileMetada = {
  created: string;
  deleted: string | null;
  id: ID;
  is_deleted: boolean;
  is_locked: boolean;
  locks: unknown[];
  modified: string;
  name: string;
  path: string;
  revision_id: ID;
  root_id: ID;
  size: number;
  size_formatted: string;
  type: string;
};

const ModalUpload = ({
  isShowing,
  title,
  onCancelClick,
  approveButtonText,
  approveButtonStyle,
  cancelButtonText,
  cancelButtonStyle,
  buttonsFlexDirection = "row",
  revertButtons = false,
  maxWidth = "800px",
  rootFolderId,
  setClickedAction,
  getData,
}: ModalUploadProps) => {
  const uplState = useUploady();
  const [uploadFilesList, setUploadFilesList] = useState<UploadedFiles[]>([]);
  const createdFolders: FoldersCache = {};

  const [disableApproveButton, setDisableApproveButton] = useState(true);
  const [disableAddButtons, setDisableAddButtons] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);

  useEffect(() => {
    if (uploadFilesList.length) {
      setDisableApproveButton(false);
    } else {
      setDisableApproveButton(true);
    }

    uplState.on(UPLOADER_EVENTS.BATCH_ADD, batchAddHandler);

    return () => {
      uplState.off(UPLOADER_EVENTS.BATCH_ADD, batchAddHandler);
    };
  }, [uploadFilesList, uplState]);

  /**
   * Uploads a file to Omega folder with the given ID.
   *
   * @param file - Original file data
   * @param fileName - New file name to use
   * @param folderId - Omega folder id to upload the file to
   * @returns - Response from the backend
   */
  const uploadFile = async (file: File, fileName: string, folderId: ID): Promise<AxiosResponse<FileMetada, any>> => {
    const uploadURL = `${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-uploadFilesToOmega`;

    const formData = new FormData();

    formData.append("file", new File([file], file.name, { type: file.type }));

    return axios.post(uploadURL, formData, {
      params: {
        folder_id: +folderId,
        file_name: fileName,
      },
    });
  };

  /**
   * Recursively creates folders in Omega and returns the ID of the deepest folder.
   *
   * @param folders - Array of folders to create
   * @param parentFolderId - Id of the folder to create the folders in
   * @param parentFolderCache - the list of know folders IDs to prevent unnessary attempts to create the same folder
   *  more than once.
   * @returns The ID of the deepest folder
   */
  const ensureFolders = async (
    folders: string[],
    parentFolderId: ID = rootFolderId,
    parentFolderCache: FoldersCache = createdFolders,
  ): Promise<ID> => {
    const folderName = folders.shift();

    if (!folderName) {
      return parentFolderId;
    }

    if (!parentFolderCache?.[folderName]?.id) {
      const { data: folderId } = await FirebaseDataService.createFoldersOmega(folderName, +parentFolderId);

      parentFolderCache[folderName] = {
        id: folderId,
        children: {},
      };
    }

    return ensureFolders(folders, parentFolderCache[folderName].id, parentFolderCache[folderName].children);
  };

  const handleUpload = async (newFiles: UploadedFiles[]) => {
    let filesToUpload = newFiles;

    for await (const uploadedFile of newFiles) {
      if (uploadedFile.status === "completed" || uploadedFile.status === "error") {
        continue;
      }

      const { fileName, folders } = extractFileNameParts(uploadedFile.file.name);
      let errorMessage = "";

      try {
        const deepestFolderId = await ensureFolders(folders);

        await uploadFile(uploadedFile.file, fileName, deepestFolderId);
      } catch (error) {
        debugLog(error);

        const message = error.response
          ? `${error.response?.message}: ${error.response?.data?.error}`
          : error.toString();
        errorMessage = error.response?.data ? message : "Failed to upload data. Please try again.";
      }

      filesToUpload = filesToUpload.map((oldItem) => {
        return oldItem.id === uploadedFile.id
          ? {
              ...oldItem,
              status: errorMessage ? "error" : "completed",
              error: errorMessage,
            }
          : oldItem;
      });

      setUploadFilesList(filesToUpload);
    }
    setDisableApproveButton(false);
    setDisableAddButtons(false);
  };

  const retryUpload = async (fileId: string) => {
    if (isRetrying) {
      return;
    }

    setIsRetrying(true);

    const retryFile = uploadFilesList.find((item) => item.id === fileId && item.error);
    if (!retryFile) {
      return;
    }
    const newFiles: UploadedFiles[] = uploadFilesList.map((file) => {
      return file.id === retryFile.id
        ? {
            ...file,
            status: "loading",
            error: "",
          }
        : file;
    });

    await Promise.resolve()
      .then(() => {
        setUploadFilesList(newFiles);
      })
      .then(() => handleUpload(newFiles))
      .then(() => setIsRetrying(false));
  };

  const onClickStartUpload = async () => {
    setDisableApproveButton(true);
    setDisableAddButtons(true);

    const loadingFiles: UploadedFiles[] = uploadFilesList.map((item) => {
      if (item.status === "completed" || item.error) {
        return item;
      }

      return { ...item, status: "loading", error: "" };
    });

    await Promise.resolve()
      .then(() => {
        setUploadFilesList(loadingFiles);
      })
      .then(() => handleUpload(loadingFiles));
  };

  const onCancelHandler = () => {
    if (disableAddButtons) {
      return;
    }
    setUploadFilesList([]);
    getData();
    onCancelClick();
  };

  const removeFileFromList = (name: string) => {
    const index = uploadFilesList.findIndex((item) => item.file.name == name);
    setUploadFilesList([...uploadFilesList.slice(0, index), ...uploadFilesList.slice(index + 1)]);
  };

  /**
   * Handler for adding files via buttons.
   * batch - array of uploaded files
   */
  const batchAddHandler = (batch: any) => {
    const files = batch.items.map((item: any) => {
      if (item.file.webkitRelativePath) {
        return new File([item.file], item.file.webkitRelativePath, { type: item.file.type });
      }
      return item.file;
    });

    const newFiles = files.map((item: File) => {
      return {
        id: uuidv4(),
        file: item,
        status: "new",
        error: "",
      };
    });

    setUploadFilesList((prev) => [...prev, ...newFiles]);
  };

  //before trigger add handler, clickedAction should be set, only after handler should be triggered
  const onAddFiles = async () => {
    await Promise.resolve()
      .then(() => {
        setClickedAction("files");
      })
      .then(() => uplState.showFileUpload());
  };

  const onAddFolders = async () => {
    await Promise.resolve()
      .then(() => {
        setClickedAction("folders");
      })
      .then(() => uplState.showFileUpload());
  };

  return isShowing ? (
    <React.Fragment>
      <ModalWrapper>
        <ModalWindow style={{ maxWidth }}>
          <ModalHeader>
            <div>
              <h3>{title}</h3>
              <Icon
                disabled={disableAddButtons ? 1 : 0}
                onClick={onCancelHandler}
                icon={faTimes as any}
                aria-disabled={disableAddButtons}
              />
            </div>
          </ModalHeader>

          <UploadDropZone
            onDragOverClassName="drag-over"
            grouped
            maxGroupSize={3}
            htmlDirContentParams={{ recursive: true, withFullPath: true }}
          >
            <ModalContent>
              <ModalUploadArea>
                {uploadFilesList.length ? (
                  uploadFilesList.map((item) => {
                    return (
                      <UploadFileWrapper key={`${item.id}`}>
                        <UploadFile iscompleted={item.status === "completed" ? 1 : 0}>
                          {item.file.name}
                          <IconWrap>
                            {item.status === "new" && (
                              <Icon icon={faTimes as any} onClick={() => removeFileFromList(item.file.name)} />
                            )}
                            {item.status === "loading" && (
                              <SpinnerWrapper>
                                <Spinner size="sm" animation="border" />
                              </SpinnerWrapper>
                            )}
                            {item.status === "completed" && <TickIcon icon={faCheck as any} />}
                            {item.status === "error" && item.error && (
                              <RetryIcon
                                icon={faRedo as any}
                                onClick={() => retryUpload(item.id)}
                                $disabled={isRetrying}
                              />
                            )}
                          </IconWrap>
                        </UploadFile>
                        {item.status === "error" && item.error && <ErrorMessage>{item.error}</ErrorMessage>}
                      </UploadFileWrapper>
                    );
                  })
                ) : (
                  <ModalUploadAreaTextEmpty>
                    Drag Files here or click Add Files, Add Folder buttons{" "}
                  </ModalUploadAreaTextEmpty>
                )}
              </ModalUploadArea>
            </ModalContent>
          </UploadDropZone>

          <ModalActions $flexDirection={revertButtons ? buttonsFlexDirection + "-reverse" : buttonsFlexDirection}>
            <Button
              onClick={onAddFiles}
              text={approveButtonText || "Add Files"}
              variant={approveButtonStyle || "primary"}
              icon={faPlus as IconDefinition}
              disabled={disableAddButtons}
              iconPosition={"left"}
            />
            <Button
              onClick={onAddFolders}
              text={approveButtonText || "Add Folder"}
              variant={approveButtonStyle || "primary"}
              icon={faPlus as IconDefinition}
              disabled={disableAddButtons}
              iconPosition={"left"}
            />
            <Button
              onClick={onClickStartUpload}
              text={approveButtonText || "Start Upload "}
              variant={approveButtonStyle || "primary"}
              disabled={disableApproveButton || disableAddButtons}
              icon={faUpload as IconDefinition}
              iconPosition={"left"}
            />
            <div style={{ marginLeft: "auto" }}>
              <Button
                onClick={onCancelHandler}
                text={cancelButtonText || "Close"}
                variant={cancelButtonStyle || "secondary"}
                disabled={disableAddButtons}
              />
            </div>
          </ModalActions>
        </ModalWindow>
      </ModalWrapper>
    </React.Fragment>
  ) : null;
};

const ModalWrapper = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
`;

const ModalWindow = styled.div`
  background-color: ${colors.white.default};
  z-index: 100;
  max-width: 800px;
  width: 100%;
  margin: 0 20px;
  border-radius: 5px;
`;

const ModalContent = styled.div`
  padding: 15px;

  p {
    margin: 0;
  }
`;

interface ModalActionsProps {
  $flexDirection: string;
}

const ModalActions = styled.div<ModalActionsProps>`
  padding: 15px;
  display: flex;
  flex-direction: row;
  flex-direction: ${(props) => props.$flexDirection};
  justify-content: flex-start;
  margin-bottom: 10px;

  button {
    margin: 0 15px;
  }
`;
const IconWrap = styled.div`
  display: block;
  margin-left: auto;
`;

const Icon = styled(FontAwesomeIcon)<{ disabled?: number }>`
  color: ${colors.grey.dark};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

const ModalHeader = styled.div`
  color: ${colors.grey.dark};
  border-radius: 5px 5px 0 0;

  p {
    margin: 0;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  padding: 15px;
  background-color: ${colors.grey.light1};
`;

const ModalUploadArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  gap: 0;
  height: 284px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
`;
const ModalUploadAreaTextEmpty = styled.div`
  display: block;
  font-size: 14px;
  margin: auto;
`;

const UploadFileWrapper = styled.div`
  border-bottom: 1px solid ${colors.grey.light4};
`;

const UploadFile = styled.div<{ iscompleted: number }>`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;

  align-items: center;
  color: ${({ iscompleted }) => (iscompleted ? colors.grey.dark4 : colors.grey.dark)};
`;

const SpinnerWrapper = styled.div`
  & div {
    border-color: ${colors.kleenway.greenLight};
    border-right-color: transparent;
  }
`;

const TickIcon = styled(FontAwesomeIcon)`
  color: ${colors.kleenway.greenLight};
`;

const RetryIcon = styled(FontAwesomeIcon)<{ $disabled?: boolean }>`
  color: ${(props) => (props.$disabled ? colors.grey.dark4 : colors.red.dark)};
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  border: 1px solid ${colors.red.dark};
  border-radius: 10px;
  width: 90%;
  margin: 0 auto;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${colors.red.light1};
`;

export default ModalUpload;
