/* eslint-disable @typescript-eslint/no-empty-function */
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { Button as BootstrapButton, Spinner } from "react-bootstrap";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

export interface ButtonProps {
  loading?: boolean;
  text?: string;
  disabled?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "middle-text"
    | "text"
    | "decline"
    | "middle-primary"
    | "small-primary"
    | "small-secondary"
    | "dotted-border"
    | "btn-icon-grey"
    | "btn-icon-red"
    | "dotted-border-light"
    | "white";
  additionalStyles?: CSSProperties;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: IconDefinition;
  iconPosition?: "left" | "right" | "center";
  id?: string;
  name?: string;
}

const Button: React.FC<ButtonProps> = ({
  loading = false,
  text = "",
  disabled = false,
  variant = "primary",
  additionalStyles,
  type = "",
  onClick = () => {},
  icon,
  iconPosition = "left",
  id = "",
  name,
}: ButtonProps) => {
  const { isMobile } = useIsMobile();
  const buttonStyle = getButtonStyles(variant);

  return (
    <StyledButton
      $isMobile={isMobile ? 1 : 0}
      style={{ ...buttonStyle, ...additionalStyles }}
      disabled={disabled}
      onClick={onClick}
      variant="light"
      type={type}
      id={id}
      name={name}
    >
      {icon && iconPosition === "left" && <LeftIcon icon={icon} />}
      {loading ? <Spinner animation="border" size="sm" /> : text}
      {icon && iconPosition === "right" && <RightIcon icon={icon} />}
      {icon && iconPosition === "center" && <FontAwesomeIcon icon={icon} /> && <CenterIcon icon={icon} />}
    </StyledButton>
  );
};

interface StyledButtonProps {
  disabled: boolean;
  $isMobile: number;
}

const StyledButton = styled(BootstrapButton)<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  font-size: 14px;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 0px transparent;
    border: 0.5px solid ${colors.grey.dark};
  }

  ${(props) => props.disabled && `pointer-events: none,  background-color: ${colors.grey.dark}`}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 36px;
    `}
`;

const LeftIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const RightIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const CenterIcon = styled(FontAwesomeIcon)`
  margin-left: 0px;
`;

const getButtonStyles = (style: string) => {
  switch (style) {
    case "primary":
      return {
        backgroundColor: colors.kleenway.green,
        textDecoration: "none",
        float: "left",
        color: colors.white.default,
        padding: "12px 24px",
        fontWeight: 700,
      };
    case "decline":
      return {
        backgroundColor: colors.red.light,
        textDecoration: "none",
        float: "left",
      };
    case "text":
      return {
        backgroundColor: "transparent",
        textDecoration: "underline",
        float: "inherit",
        borderWidth: 0,
      };
    case "middle-text":
      return {
        backgroundColor: "transparent",
        textDecoration: "underline",
        float: "inherit",
        borderWidth: 0,
      };
    case "secondary":
      return {
        backgroundColor: colors.grey.light1,
        color: colors.black.light1,
        textDecoration: "none",
        float: "inherit",
        padding: "12px 24px",
      };

    case "small-primary":
      return {
        backgroundColor: colors.kleenway.green,
        textDecoration: "none",
        float: "left",
        color: colors.white.default,
      };
    case "middle-primary":
      return {
        backgroundColor: colors.kleenway.green,
        textDecoration: "none",
        float: "left",
        color: colors.white.default,
      };
    case "small-secondary":
      return {
        backgroundColor: "inherit",
        textDecoration: "underline",
        float: "inherit",
      };
    case "dotted-border":
      return {
        color: colors.grey.dark,
        backgroundColor: "transparent",
        float: "inherit",
        borderStyle: "dotted",
        borderWidth: "2px",
        borderColor: colors.grey.light4,
      };
    case "btn-icon-grey":
      return {
        backgroundColor: colors.grey.light1,
        borderRadius: 0,
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: colors.grey.light4,
        width: "40px",
        height: "40px",
      };
    case "btn-icon-red":
      return {
        backgroundColor: colors.red.light1,
        borderRadius: 0,
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: colors.red.dark,
        width: "40px",
        height: "40px",
      };
    case "dotted-border-light":
      return {
        color: colors.grey.dark,
        backgroundColor: "transparent",
        float: "inherit",
        borderStyle: "dotted",
        borderWidth: "1px",
        borderColor: colors.grey.light4,
        height: "40px",
      };
    case "white":
      return {
        backgroundColor: colors.white.default,
        color: colors.black,
        float: "inherit",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.green.default,
      };

    default:
      break;
  }
};

export default Button;
