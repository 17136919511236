import React from "react";
import SelectSearch from "src/components/SelectSearch";
import { SelectFieldProps } from "../types";

const SelectField = ({ value, options, onSelectChange }: SelectFieldProps) => (
  <SelectSearch
    defaultValue={typeof value === "string" ? value.toLowerCase() : value || ""}
    options={options || []}
    onChange={onSelectChange}
    bottomResultsGap={45}
    buttonStyle="top: 7px;"
  />
);

export default SelectField;
