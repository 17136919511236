import React from "react";
import {
  TicketContainer,
  TicketDot,
  TicketHeading,
  TicketInfo,
  TicketStatus,
  TicketSubtitle,
  TicketWrapper,
} from "../styles";
import { Ticket } from "src/types";
import { useNavigate } from "react-router-dom";

const ClientListItem = ({ ticket, isRedirectAllowed }: { ticket: Ticket; isRedirectAllowed?: boolean }) => {
  const navigate = useNavigate();

  const onRowClick = (wonum: string) => {
    if (isRedirectAllowed) {
      navigate(`/workrequests/${wonum}`, { state: { ticketNumber: wonum } });
    }
  };
  return (
    <TicketWrapper
      onClick={() => {
        onRowClick(ticket.wonum);
      }}
      key={ticket.wonum}
    >
      <TicketContainer>
        <TicketHeading>
          {ticket.wonum}: {ticket.description}
        </TicketHeading>
        <TicketSubtitle>
          <p>{ticket.dateCreated}</p>
          <TicketDot />
          <p>{ticket.location}</p>
        </TicketSubtitle>

        <TicketInfo>
          {<p>{ticket.classification}</p>}
          <TicketDot />
          <p>{ticket.serviceType}</p>
        </TicketInfo>
        <TicketStatus>
          <p>{ticket.priority}</p>
          <TicketDot />
          {<p>{ticket.status}</p>}
        </TicketStatus>
      </TicketContainer>
    </TicketWrapper>
  );
};

export default ClientListItem;
