export const appName = "kleenway-client-portal";

export const DAYS_AFTER_SESSION_EXPIRES = 7;

export const MAX_MOBILE_RESOLUTION = 768;

export const MAXIMO_TIME_ZONE = "America/New_York";

export const MAX_PHOTO_SIZE = 7340032; // 7 MB

export const HEIC_TYPE = "image/heic"; // 7 MB

export const HEIF_TYPE = "image/heif"; // 7 MB

export const PNG_TYPE = "image/png";

export const JPEG_TYPE = "image/jpeg";

export const DEFAULT_SESSION_TIME = 1800; // 30 min
