import moment from "moment";
import React, { useContext } from "react";
import SelectSearch, { OptionItem } from "src/components/SelectSearch";
import ClientDetailsContext from "src/pages/ClientDetails/context";
import { roundTo2Decimals } from "src/utils/helpers";
import CustomInput from "../../ApprovedHoursInput";
import { formatTimeReportPairDate } from "../../utils";
import { EmployeeTableRow, PayRollTableCell } from "../styles";
import { EmployeeHourlyPayPeriodDayRowProps } from "../types";

export const EmployeeHourlyPayPeriodDayRow = ({
  date,
  employeeId,
  formattedPayPeriodDay,
  isStat,
  isToday,
  isWeekend,
  payPeriodDayDate,
  payPeriodDayId,
  rawHours,
  scheduledHours,
  clockInDate,
  clockOutDate,
  isFirstLocationRow,
  isFirstRow,
  locationDisplayName,
  showBottomBorder,
  showTopBorder,
  timezoneLocation,
}: EmployeeHourlyPayPeriodDayRowProps) => {
  const now = moment();
  const { employeesApprovedHoursForm, dayClassifications, setEmployeesApprovedHoursForm } =
    useContext(ClientDetailsContext);

  const onDayClassificationChange = (value: OptionItem) => {
    setEmployeesApprovedHoursForm((prevState) => ({
      ...prevState,
      [employeeId]: {
        ...prevState[employeeId],
        [payPeriodDayId]: {
          ...prevState[employeeId][payPeriodDayId],
          classification: value.id.toString(),
          updated: true,
        },
      },
    }));
  };

  const onInputChangeHandler = (value: number) => {
    setEmployeesApprovedHoursForm((prevState) => {
      const employee = prevState[employeeId] || {};
      const payPeriodDay = employee[payPeriodDayId] || {};

      return {
        ...prevState,
        [employeeId]: {
          ...employee,
          [payPeriodDayId]: {
            ...payPeriodDay,
            approvedHours: value,
            updated: true,
          },
        },
      };
    });
  };

  return (
    <EmployeeTableRow
      $isToday={isToday}
      $hideBottomBorder={!showBottomBorder}
      $hideTopBorder={!showTopBorder}
      key={date}
    >
      {/* Date */}
      <PayRollTableCell
        $isWeekend={isWeekend}
        $isStat={Boolean(isStat)}
        $hideBottomBorder={!showBottomBorder || isToday}
        $hideTopBorder={!showTopBorder || isToday}
        data-tooltip-id="stat-tooltip"
        data-tip={isStat ? isStat.name : ""}
        minwidth={75}
      >
        {formattedPayPeriodDay}
      </PayRollTableCell>
      {/* Scheduled */}
      <PayRollTableCell
        minwidth={75}
        $hideBottomBorder={!showBottomBorder || isToday}
        $hideTopBorder={!showTopBorder || isToday}
      >
        {scheduledHours}
      </PayRollTableCell>
      {/* Location */}
      <PayRollTableCell minwidth={75} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {isFirstLocationRow ? locationDisplayName : ""}
      </PayRollTableCell>
      {/* Clock-in date */}
      <PayRollTableCell minwidth={75} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {clockInDate ? formatTimeReportPairDate(clockInDate, timezoneLocation) : "—"}
      </PayRollTableCell>
      {/* Clock-out date */}
      <PayRollTableCell minwidth={75} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {clockOutDate ? formatTimeReportPairDate(clockOutDate, timezoneLocation) : "—"}
      </PayRollTableCell>
      {/* Raw */}
      <PayRollTableCell minwidth={75} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {rawHours && roundTo2Decimals(rawHours)}
      </PayRollTableCell>
      {/* Approved */}
      <PayRollTableCell minwidth={75} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {now.isSameOrAfter(payPeriodDayDate) && isFirstRow && (
          <CustomInput
            initialValue={employeesApprovedHoursForm[employeeId][payPeriodDayId].approvedHours}
            onChange={onInputChangeHandler}
          />
        )}
      </PayRollTableCell>
      {/* Classification*/}
      <PayRollTableCell minwidth={10} $hideBottomBorder={!showBottomBorder} $hideTopBorder={!showTopBorder}>
        {now.isSameOrAfter(payPeriodDayDate) && isFirstRow && (
          <SelectSearch
            defaultValue={employeesApprovedHoursForm[employeeId][payPeriodDayId].classification || "REG"}
            options={dayClassifications}
            onChange={(value) => onDayClassificationChange(value)}
          />
        )}
      </PayRollTableCell>
    </EmployeeTableRow>
  );
};
