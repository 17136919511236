import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { CloseWorkOrderForm } from "src/components/molecules/CloseWorkOrderForm";
import { Icon, ModalContent, ModalHeader, ModalWindow, ModalWrapper } from "./styles";
import { CloseWorkOrderFormModalProps } from "./types";

const CloseWorkOrderModal = ({ closeModal, onSubmit, workorder }: CloseWorkOrderFormModalProps) => {
  return (
    <ModalWrapper>
      <ModalWindow style={{ maxWidth: "480px" }}>
        <ModalHeader>
          <div>
            <h3>Close Work Order</h3>
            <Icon onClick={closeModal} icon={faTimes as IconProp} />
          </div>
        </ModalHeader>
        <ModalContent>
          <CloseWorkOrderForm workorder={workorder} onSubmit={onSubmit} onCancel={closeModal} />
        </ModalContent>
      </ModalWindow>
    </ModalWrapper>
  );
};

export default CloseWorkOrderModal;
