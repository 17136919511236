import React from "react";
import DataTable from "src/components/Table";
import TableRow from "src/components/Table/TableRow";
import { DBVendor } from "src/types";
import VendorLogo from "../Logo";
import { StyledCell } from "../styles";
import { VendorsTableProps } from "../types";

export const VendorsTable = ({ isLoading, headers, data, onRowClick }: VendorsTableProps) => {
  return (
    <DataTable isLoading={isLoading} headers={headers}>
      <>
        {data?.map(({ id, logo, address, name }: DBVendor) => {
          return (
            <TableRow onClick={() => onRowClick(id)} $clickable key={id}>
              <StyledCell>{id}</StyledCell>
              <StyledCell>
                <VendorLogo logoFileName={logo} />
              </StyledCell>
              <StyledCell>{`${name}`}</StyledCell>
              <StyledCell>{address}</StyledCell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};
