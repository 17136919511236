import React from "react";
import styled, { css } from "styled-components";

import { colors } from "src/styles";

export interface CheckboxProps {
  checked: boolean;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  containerStyle?: string;
  checkboxStyle?: string;
}

const Checkbox = ({ checked, label, containerStyle, checkboxStyle, onChange, ...props }: CheckboxProps) => (
  <StyledLabel containerStyle={containerStyle}>
    <CheckboxContainer>
      <HiddenCheckbox onChange={onChange} checked={checked} {...props} />
      <StyledCheckbox checked={checked} checkboxStyle={checkboxStyle}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
    <span style={{ marginLeft: 8 }}>{label}</span>
  </StyledLabel>
);

interface CheckboxContainerProps {
  containerStyle?: string;
}

const StyledLabel = styled.label<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 14px;

  ${({ containerStyle }) =>
    containerStyle &&
    css`
      ${containerStyle}
    `}
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.grey.dark};
  stroke-width: 2px;
  margin-bottom: 15px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledCheckboxProps {
  checked: boolean;
  checkboxStyle?: string;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 32px;
  height: 32px;
  background: ${(props) => (props.checked ? colors.kleenway.green : colors.white.default)};
  transition: all 150ms;
  border: 1px solid ${colors.grey.light1};
  border-radius: 4px;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${colors.grey.light1};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }

  ${(props) => props.checkboxStyle && props.checkboxStyle}
`;

export default Checkbox;
