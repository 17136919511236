import { ButtonCheckboxGroupOption } from "../ButtonCheckboxGroup/types";

export const WEEK_DAYS: ButtonCheckboxGroupOption[] = [
  {
    label: "SU",
    value: "sun",
  },
  {
    label: "MO",
    value: "mon",
  },
  {
    label: "TU",
    value: "tue",
  },
  {
    label: "WE",
    value: "wed",
  },
  {
    label: "TH",
    value: "thu",
  },
  {
    label: "FR",
    value: "fri",
  },
  {
    label: "SA",
    value: "sat",
  },
];
