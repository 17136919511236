import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

interface ToggleArrowProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ToggleArrow: React.FC<ToggleArrowProps> = ({ isVisible, setIsVisible }) => {
  return (
    <>
      <Container style={{ left: !isVisible ? "85px" : "235px" }}>
        <Item onClick={() => setIsVisible(isVisible ? false : true)}>
          {isVisible ? <Icon icon={faChevronLeft as IconProp} /> : <Icon icon={faChevronRight as IconProp} />}
        </Item>
      </Container>
    </>
  );
};

const Container = styled.div`
  height: 1px;
  text-align: right;
  position: fixed;
  top: 40px;
  z-index: 101;
`;

const Icon = styled(FontAwesomeIcon)`
  height: 50%;
  color: ${colors.kleenway.green};
  vertical-align: -webkit-baseline-middle;
`;

const Item = styled.div`
  cursor: pointer;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.kleenway.green};
  border-radius: 20px;
  background: ${colors.white.default};
  z-index: 110;
`;
