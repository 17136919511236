import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { ServiceTypesTiles } from "./ServiceTypeTiles";
import { CreateButtonWrapper, TabsWrapper } from "../styles";
import { HeaderProps } from "../types";
import WorkRequestBreadCrumbs from "./WorkRequestBreadCrumbs";
import { TilesContainer } from "src/styles";

const Header = ({
  isBreadCumVisible,
  isCreateNewWorkRequestVisible,
  isServiceTypeVisible,
  countOfServiceType,
  serviceTypes,
  inited,
}: HeaderProps) => {
  const navigate = useNavigate();
  const onAddNewRequestClick = () => {
    navigate("/workrequests/create");
  };
  return (
    <>
      {isBreadCumVisible && <WorkRequestBreadCrumbs />}
      {isServiceTypeVisible && (
        <TilesContainer>
          <ServiceTypesTiles
            serviceTypes={serviceTypes}
            numberOfTilestoDisplay={countOfServiceType}
            isLoading={!inited}
          />
        </TilesContainer>
      )}
      <TabsWrapper>
        {isCreateNewWorkRequestVisible && (
          <CreateButtonWrapper>
            <Button
              text="New Work Request"
              variant="white"
              additionalStyles={{ display: "flex" }}
              icon={faPlus}
              onClick={() => onAddNewRequestClick()}
            />
          </CreateButtonWrapper>
        )}
      </TabsWrapper>
    </>
  );
};

export default Header;
