import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Input from "src/components/Input";
import { EmptyContainer, NoTicketsMessage } from "src/components/ListScreen";
import { Cell } from "src/components/Table/Cell";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { QuickSearchEntity } from "src/types";
import styled from "styled-components";
import {
  CLIENTS,
  DEBOUNCE_DELAY,
  LOCATIONS,
  MANAGER_EMPLOYEES,
  MIN_SEARCH_LENGTH,
  WORK_ORDER_INDEX,
} from "../constant";

interface QuickSearchProps {
  managerId: string;
}

const SectionNames = {
  [MANAGER_EMPLOYEES]: "Team Members",
  [LOCATIONS]: "Locations",
  [CLIENTS]: "Clients",
  [WORK_ORDER_INDEX]: "Work Requests",
};

const SectionRoutes = {
  [MANAGER_EMPLOYEES]: "/team-members",
  [LOCATIONS]: "/clients/locations",
  [CLIENTS]: "/clients",
  [WORK_ORDER_INDEX]: "/workrequests",
};

const generateDisplayValue = (obj: Record<string, any>, entity: string) => {
  let displayValue = "";
  switch (entity) {
    case WORK_ORDER_INDEX:
      displayValue = `${obj.id}: ${obj.workRequestName}`;
      break;
    case LOCATIONS:
      if (obj.data.label && !obj.data.address) {
        displayValue = obj.data.label;
      }
      if (!obj.data.label && obj.data.address) {
        displayValue = obj.data.address;
      }
      break;
    case MANAGER_EMPLOYEES:
      displayValue = `${obj.data.employeeId}: ${obj.data.employeeDisplayName}`;
      break;
    case CLIENTS:
      displayValue = obj.data.name;
      break;
    default:
      break;
  }
  return displayValue;
};

const QuickSearch = ({ managerId }: QuickSearchProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");
  const [showBoxResults, setShowBoxResults] = useState(false);

  const boxResultsRef = useRef<HTMLDivElement>(null);

  const [searchData, setSearchData] = useState<QuickSearchEntity[]>([]);

  const getSearchResults = async (search: string) => {
    setIsLoading(true);
    setError("");
    try {
      const { data } = await FirebaseDataService.getQuickSearchResults(search, managerId);
      setSearchData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const debounceSearch = useCallback(
    debounce((value: string) => {
      getSearchResults(value);
    }, DEBOUNCE_DELAY),
    [],
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const onFocusHandler = () => {
    if (searchValue.length < MIN_SEARCH_LENGTH) {
      return;
    }
    setShowBoxResults(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (boxResultsRef.current && !boxResultsRef.current.contains(event.target as Node)) {
        setShowBoxResults(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showBoxResults]);

  useEffect(() => {
    if (searchValue.length <= MIN_SEARCH_LENGTH) {
      setShowBoxResults(false);
    }
    if (searchValue.length >= MIN_SEARCH_LENGTH) {
      setIsLoading(true);
      debounceSearch(searchValue);
      setShowBoxResults(true);
    }
  }, [searchValue]);
  return (
    <InputWrapper ref={boxResultsRef}>
      <Icon icon={faSearch} />
      <StyledInput onFocus={onFocusHandler} placeholder="Search" value={searchValue} onChange={onChange} />
      {showBoxResults && (
        <ResultsWrapper>
          {error && <p>{error}</p>}
          {isLoading ? (
            <SpinnerContainer>
              <Spinner animation="border" size="sm" />
              <p>Searching</p>
            </SpinnerContainer>
          ) : (
            <>
              {!searchData.some((item) => item.data.length) && (
                <NoResults>
                  We couldn&apos;t find anything matching your search. Please, try again with different search criteria.
                </NoResults>
              )}
              {!!searchData.length &&
                searchData.map((item) => {
                  if (!item.data.length) {
                    return <></>;
                  }
                  return (
                    <SectionValueWrapper key={item.entity}>
                      <SectionName>{SectionNames[item.entity as keyof typeof SectionNames]}</SectionName>
                      {item.data.map((findValue) => {
                        const redirectHandler = () => {
                          const redirecId =
                            item.entity === "manager-employees" ? findValue.id.split("_")[1] : findValue.id;
                          const isLocation = item.entity === "locations";

                          //pass clientName to the state to create correct breadcrumbs on the location page
                          navigate(`${SectionRoutes[item.entity as keyof typeof SectionRoutes]}/${redirecId}`, {
                            state: { ...(isLocation && { clientName: findValue.data.clientName || "" }) },
                          });
                          setShowBoxResults(false);
                          setSearchValue("");
                        };
                        //format search value before passing it to the mark component to avoid special characters
                        const formattedSearchValue = searchValue.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");

                        const fullLocationInfo =
                          item.entity === "locations" && findValue.data.label && findValue.data.address;

                        const displayValue = fullLocationInfo
                          ? findValue.data.label
                          : generateDisplayValue(findValue, item.entity);

                        return (
                          <SectionValue onClick={redirectHandler} key={displayValue}>
                            <Cell showMark highlight={formattedSearchValue}>
                              {displayValue}
                            </Cell>
                            {fullLocationInfo && (
                              <Cell showMark highlight={formattedSearchValue}>
                                {findValue.data.address}
                              </Cell>
                            )}
                          </SectionValue>
                        );
                      })}
                    </SectionValueWrapper>
                  );
                })}
            </>
          )}
        </ResultsWrapper>
      )}
    </InputWrapper>
  );
};

export default QuickSearch;

const SectionValueWrapper = styled.div`
  & > p:not(:nth-child(-n + 4)) {
    display: none;
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 450px;
  margin-right: 25px;
  padding-left: 40px;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  left: 15px;
  top: 12px;
  width: 15px;
  height: 15px;
  position: absolute;
`;

const ResultsWrapper = styled.div`
  width: 460px;
  max-height: 245px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  background-color: ${colors.white.default};
  border: 1px solid ${colors.grey.light1};
  border-radius: 5px;
  padding: 7px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.grey.light1};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.grey.default};
  }
`;

const SpinnerContainer = styled(EmptyContainer)`
  height: 100px;
  min-height: unset;
  & > p {
    padding-left: 12px;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.grey.light5};
  }
`;

const NoResults = styled(NoTicketsMessage)`
  margin-top: 0;
`;

const SectionName = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${colors.grey.dark5};
`;

const SectionValue = styled.p`
  padding-left: 20px;
  padding-top: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:hover {
    background-color: ${colors.grey.light1};
  }

  & > td {
    padding: 0;
    padding-bottom: 4px;
    border-bottom: 0px;
    & > span > mark {
      color: ${colors.grey.dark};
      font-weight: 700;
      font-style: unset;
    }
    & + td {
      color: ${colors.grey.light5};
      font-style: italic;
      & > span > mark {
        color: ${colors.grey.light5};
        font-weight: 700;
        font-style: italic;
      }
    }
  }
`;
