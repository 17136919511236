import React, { useEffect, useState } from "react";
import Button from "src/components/Button";
import SelectSearch, { OptionItem } from "src/components/SelectSearch";
import { shiftFactory } from "src/components/molecules/ShiftForm/utils";
import { ShiftListForm } from "src/components/molecules/ShiftListForm";
import { NO_ASSIGNED_CLIENTS_SELECT, NO_ASSIGNED_LOCATIONS_SELECT } from "src/pages/TeamMembers/constants";
import { colors } from "src/styles";
import { Assignment, Client, DBLocation } from "src/types";
import styled from "styled-components";
import ScheduleTypeInfo from "../ScheduleTypeInfo";

interface AssignmentFormProps {
  index: number;
  deleteHandler: () => void;
  assignment: Assignment;
  scheduleTemplates: any[];
  scheduleTypes: Record<string, number>;
  clients: Client[];
  locations: DBLocation[];
  assignmentFieldsHandler: (id: string, value: string, fieldName: string) => void;
  scheduleHandler: (assignmentId: string, newSchedule: Assignment["schedule"]) => void;
}

const AssignmentForm = ({
  scheduleTypes,
  scheduleTemplates,
  index,
  deleteHandler,
  assignment,
  clients,
  locations,
  assignmentFieldsHandler,
  scheduleHandler,
}: AssignmentFormProps) => {
  const scheduleTemplatesSelect = scheduleTemplates.map((type) => {
    return {
      id: type.id,
      value: type.label.toString(),
      ...(type.label.toString().includes("Custom") && {
        priority: 1,
      }),
    };
  });

  const scheduleTypesSelect = Object.entries(scheduleTypes).map(([key, value]) => {
    const uppercaseFirstLetter = key.charAt(0).toUpperCase() + key.slice(1);
    return {
      id: key,
      value: `${uppercaseFirstLetter} (${value}h grace period)`,
    };
  });

  const clientsSelect = Object.entries(clients).map(([key, value]) => {
    return {
      id: key,
      value: value.name,
    };
  });

  const locationsSelect = Object.entries(locations).map(([key, value]) => {
    return {
      id: key,
      value: value.label,
    };
  });

  const [locationsSelectData, setLocationSelectData] = useState<OptionItem[]>(locationsSelect);

  const clientSelectHandler = (option: OptionItem) => {
    assignmentFieldsHandler(assignment.id, option.id.toString(), "clientId");
  };

  const locationSelectHandler = (option: OptionItem) => {
    assignmentFieldsHandler(assignment.id, option.id.toString(), "locationId");
  };

  const scheduleTemplateHandler = (option: OptionItem) => {
    const newtemplate = option.id.toString();

    const newSchedule = {
      ...assignment.schedule,
      template: newtemplate,
      ...(newtemplate.includes("Custom") && {
        shifts: [shiftFactory.createShift()],
      }),
    };

    scheduleHandler(assignment.id, newSchedule);
  };

  const scheduleTypeHandler = (option: OptionItem) => {
    const newSchedule = {
      ...assignment.schedule,
      type: option.id.toString(),
    };
    scheduleHandler(assignment.id, newSchedule);
  };

  useEffect(() => {
    const newLocationsData = Object.entries(locations)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value.clientId === assignment.clientId)
      .map(([key, value]) => {
        return {
          id: key,
          value: value.label,
        };
      });
    const isExistsLocation = newLocationsData.find((item) => item.id === assignment.locationId);
    assignmentFieldsHandler(assignment.id, isExistsLocation ? assignment.locationId : "", "locationId");
    setLocationSelectData(newLocationsData);
  }, [locations, assignment.clientId]);

  return (
    <div>
      <Header>
        <Title>{`Assignment ${index + 1}:`} </Title>
        <Button variant="text" text="Delete Assignment" onClick={deleteHandler} />
      </Header>
      <RowValue>
        <Label>Client: </Label>
        <SelectSearch
          buttonStyle="top: 12px;"
          options={clientsSelect.length ? clientsSelect : [NO_ASSIGNED_CLIENTS_SELECT]}
          onChange={clientSelectHandler}
          defaultValue={assignment.clientId || ""}
          placeholder="Select Client"
        />
      </RowValue>
      <RowValue>
        <Label>Location: </Label>
        <SelectSearch
          buttonStyle="top: 12px;"
          options={locationsSelectData?.length ? locationsSelectData : [NO_ASSIGNED_LOCATIONS_SELECT]}
          disabled={!assignment.clientId}
          defaultValue={assignment.locationId || ""}
          onChange={locationSelectHandler}
          placeholder="Select Location"
        />
      </RowValue>
      <RowValue>
        <Label>
          Schedule: <span>(optional)</span>
        </Label>
        <SelectSearch
          buttonStyle="top: 12px;"
          options={scheduleTemplatesSelect}
          onChange={scheduleTemplateHandler}
          defaultValue={assignment.schedule?.template || ""}
          placeholder="Select Schedule Template or Custom Schedule"
        />
      </RowValue>

      <RowValue>
        <Label>Schedule Type:</Label>
        <SelectSearch
          buttonStyle="top: 12px;"
          placeholder="Select Schedule Type"
          options={scheduleTypesSelect}
          onChange={scheduleTypeHandler}
          defaultValue={assignment.schedule?.type || ""}
        />
      </RowValue>

      {assignment.schedule?.template &&
        (assignment.schedule?.template.includes("Custom") ? (
          <ScheduleBoxWrapper>
            <ShiftListForm name={`assignments.${index}.schedule.shifts`} />
          </ScheduleBoxWrapper>
        ) : (
          <ScheduleTypeInfo
            scheduleTemplate={scheduleTemplates.find((template) => template.id === assignment.schedule?.template)}
          />
        ))}
    </div>
  );
};
const ScheduleBoxWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${colors.grey.light4};
  margin-bottom: 30px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark};
`;
const RowValue = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  &:last-child {
    padding-bottom: 40px;
  }
  & > div {
    width: 100%;
  }
`;

const Label = styled.label`
  width: 183px;
  font-weight: 600;
  font-size: 14px;
  color: ${colors.grey.dark};
  & span {
    font-weight: 500;
    font-style: italic;
  }
`;

export default AssignmentForm;
