import React from "react";
import DataTable from "src/components/Table";
import { StyledTableCell } from "src/components/Table/TableCell";
import TableRow from "src/components/Table/TableRow";
import { Client, ID } from "src/types";
import { formatTimezoneToShort } from "src/utils/time";
import styled from "styled-components";
import ClientLogo from "../Logo";

export interface ClientsTableProps {
  isLoading: boolean;
  headers: string[];
  data: Client[];
  onRowClick: (id: ID) => void;
}

export const ClientTable = ({ isLoading, headers, data, onRowClick }: ClientsTableProps) => {
  return (
    <DataTable isLoading={isLoading} headers={headers}>
      <>
        {data?.map(({ id, logo, name, settings }: Client) => {
          return (
            <TableRow onClick={() => onRowClick(id)} $clickable key={id}>
              <StyledCell>
                <ClientLogo logoFileName={logo} />
              </StyledCell>
              <StyledCell>{name}</StyledCell>
              <StyledCell>{formatTimezoneToShort(settings?.timezone)}</StyledCell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};

const StyledCell = styled(StyledTableCell)`
  height: 50px;
  box-sizing: content-box;
  padding: 10px;
`;
