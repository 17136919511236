import React, { ReactNode } from "react";
import { Spinner } from "react-bootstrap";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

export interface IDataTable {
  headers?: string[];

  disableHeader?: boolean;
  onRowClick?: (id: string) => void;
  clickable?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}

const DataTable = ({ headers, disableHeader, isLoading, children }: IDataTable) => {
  const { isMobile } = useIsMobile();

  const renderTableHeaders = () => {
    return headers?.map((header) => {
      return (
        <TableHeaderCell $isMobile={isMobile} disableHeader={disableHeader} key={header}>
          <span style={header === "Resource" ? { paddingLeft: 15 } : {}}>{header}</span>
        </TableHeaderCell>
      );
    });
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Spinner animation="border" size="sm" />
        </Container>
      ) : (
        <Table disableHeader={disableHeader}>
          <TBody>
            <TableHeader disableHeader={disableHeader}>{renderTableHeaders()}</TableHeader>
            {children}
          </TBody>
        </Table>
      )}
    </>
  );
};

export default DataTable;

export interface TableRowProps {
  clickable?: boolean;
}

export interface TableProps {
  disableHeader?: boolean;
}

export interface TableHeaderProps {
  disableHeader?: boolean;
  $isMobile?: boolean;
}

const Table = styled.table<TableProps>`
  width: 100%;
  border-collapse: separate;

  ${(props) =>
    props.disableHeader &&
    `margin-top: -60px; @media (max-width: 1560px) {
    margin-top: -60px;
  }`}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  min-height: 50vh;
`;

const TableHeaderCell = styled.th<TableHeaderProps>`
  padding: 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${colors.white.default};
  border-bottom: 1px solid ${colors.grey.light15};

  ${(props) =>
    props.disableHeader &&
    `margin: 0;
  opacity: 0;
  height: 0;
  position: relative;
  z-index: -100;`}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      padding: 8px;
    `}
`;

const TableHeader = styled.tr<TableHeaderProps>`
  background-color: ${colors.grey.dark};
  width: 100%;

  ${(props) => props.disableHeader && `background: none`}
`;

const TBody = styled.tbody`
  width: 100%;
`;
