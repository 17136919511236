import { colors } from "src/styles";
import styled from "styled-components";

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.div`
  visibility: hidden;
  width: 100%;
  background-color: ${colors.grey.light1};
  color: ${colors.grey.dark};
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjust as needed */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  white-space: nowrap;
`;

export const Container = styled.div<{ text: string }>`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;
