import React from "react";

import { Client as ClientType } from "src/types";

import { ClientContainer, ClientHeading, ClientSubtitle } from "./styles";
import ClientLogo from "../Logo";

export interface ClientProps {
  client: ClientType;
}

export const Client: React.FC<ClientProps> = ({ client }) => {
  return (
    <ClientContainer>
      {client.logo && (
        <div style={{ flex: 0.15, display: "flex", alignItems: "center" }}>
          <ClientLogo logoFileName={client.logo} />
        </div>
      )}

      <div style={{ flex: client.logo ? 1 : 0.85, display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <ClientHeading>{client.name}</ClientHeading>
        <ClientSubtitle>
          <p>{client.settings?.timezone || "EST"}</p>
        </ClientSubtitle>
      </div>
    </ClientContainer>
  );
};
