import React from "react";
import Input from "src/components/Input";
import { DynamicRows } from "src/components/molecules/DynamicRows";
import { EMAIL_VALIDATION_PATTERN } from "src/utils/validator";
import { v4 as uuidv4 } from "uuid";
import { EmailToProps } from "../../../pages/TicketForm/CreateForm/types";
import { FormLabel } from "../FormLabel";

/**
 * A helper component to render the Email to form fields
 */
export const EmailTo = ({ disabled, isRequired }: EmailToProps) => {
  return (
    <>
      <FormLabel required={isRequired}>Email To:</FormLabel>
      <DynamicRows
        name="emailTo"
        getNewItem={() => ({ id: uuidv4(), value: "" })}
        rules={{ pattern: EMAIL_VALIDATION_PATTERN }}
        renderItem={(value, onChange, error, ref) => (
          <Input
            value={value}
            onChange={onChange}
            placeholder="Enter email address"
            ref={ref}
            error={error}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};
