import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";

import Alert, { AlertVariants } from "src/components/Alert";
import Button from "src/components/Button";
import Input, { InputTypes } from "src/components/Input";
import Logo from "src/components/Logo";
import { VALIDATION_MESSAGES } from "src/constants/validation";
import { getErrorMsg } from "src/helpers/getErrorMsg";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { FirebaseAuthService } from "src/services/Firebase/auth";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import ValidateData from "src/utils/validator";

const getUserDataByEmail = async (email: string) => {
  try {
    const { data: clientUserData } = await FirebaseDataService.getClientUserByEmail(email);
    return clientUserData;
  } catch (e) {
    try {
      const { data: employeeUserData } = await FirebaseDataService.getEmployeeUserByEmail(email);
      return employeeUserData;
    } catch (e) {
      try {
        const { data: vendorUserData } = await FirebaseDataService.getVendorUserByEmail(email);
        return vendorUserData;
      } catch (err) {
        return err;
      }
    }
  }
};

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
  });

  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode");

  useEffect(() => {
    if (!oobCode) {
      navigate("/", { replace: true });
    }
  }, [oobCode]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const onSubmit = handleSubmit(({ newPassword, repPassword }) => confirmResetPassword(newPassword, repPassword));

  const confirmResetPassword = async (newPassword: string, repPassword: string) => {
    setLoading(true);
    setSuccess(false);
    setError("");

    try {
      if (newPassword !== repPassword) {
        throw new Error("The confirmation password does not match.");
      }

      const {
        data: { email },
      } = await FirebaseAuthService.checkActionCode(oobCode || "");
      if (!email) {
        throw new Error("Unknown error");
      }

      const userData = await getUserDataByEmail(email);
      const { firstName, lastName }: any = Object.values(userData)[0];
      if (!ValidateData.password(newPassword, firstName, lastName)) {
        throw new Error(
          "New password should meet the requirements: at least 8 characters, at least one uppercase letter, one lowercase letter and one number, not too obvious (like your name).",
        );
      }

      await FirebaseAuthService.confirmPasswordReset(oobCode || "", newPassword);
      setSuccess(true);

      setTimeout(() => {
        navigate("/", { replace: true });
      }, 1500);
    } catch (error) {
      setError(getErrorMsg(error));
    }

    setLoading(false);
  };

  return (
    <Container $isMobile={isMobile}>
      <Logo />

      <Title $isMobile={isMobile}>Reset Password</Title>

      <Description>To make your account secure, make sure your new password:</Description>

      <List>
        <li>is at least 8 characters long</li>
        <li>has at least one uppercase letter, one lowercase letter and one number</li>
        <li>is not too obvious, like your name</li>
      </List>

      <StyledForm $isMobile={isMobile} onSubmit={onSubmit}>
        <Input
          controlId="reset-new-password"
          type={InputTypes.password}
          label="New Password"
          validation={register("newPassword", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
          })}
        />

        <Input
          controlId="reset-rep-password"
          type={InputTypes.password}
          label="Confirm New Password"
          validation={register("repPassword", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
          })}
        />

        {!!error && <Alert text={error} variant={AlertVariants.danger} />}
        {!!success && <Alert text="Password successfully changed!" variant={AlertVariants.success} />}

        <ButtonContainer>
          <Button text="Reset Password" type="submit" disabled={!isValid} loading={loading} />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

//#region styles
const Container = styled.div<MobileProps>`
  margin: 0 25%;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 0;
    `}
`;

const Title = styled.label<MobileProps>`
  margin: 25px 0;
  font-weight: 600;
  font-size: 16px;
  color: ${colors.grey.dark};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 12px;
    `}
`;

const Description = styled.label`
  margin-bottom: 15px;
  margin-left: 55px;
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.default};
`;

const List = styled.ul`
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.default};
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

const StyledForm = styled(Form)<MobileProps>`
  width: 50%;
  margin-top: 30px;
  & > div {
    margin-bottom: 25px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 80%;
      margin-top: 20px;
    `}
`;
//#endregion

export default ResetPasswordPage;
