import moment from "moment";
import React from "react";
import { TimeReportPair } from "src/types";
import { isEmpty } from "src/utils";
import { formatPayPeriodDayDate, formatToISO, generatePayPeriodDayId } from "../../utils";
import { PayRollTableHeader, StyledTable } from "../styles";
import { EmployeeHourlyPayPeriodTableProps } from "../types";
import { EmployeeHourlyPayPeriodDayRow } from "./EmployeeHourlyPayPeriodDayRow";

const EmployeeHourlyPayPeriodTable = ({ selectedEmployeePayroll }: EmployeeHourlyPayPeriodTableProps) => {
  const calculateRowProperties = (
    locationIndex: number,
    timeReportPairIndex: number,
    locationsArray: TimeReportPair[][],
    locationTimeReportPairs: TimeReportPair[],
  ) => {
    const isFirstRow = !locationIndex && !timeReportPairIndex;
    const isFirstLocationRow = !timeReportPairIndex;

    const isLastRow =
      locationIndex === locationsArray.length - 1 && timeReportPairIndex === locationTimeReportPairs.length - 1;

    const isOneRow = locationsArray.length === 1 && locationTimeReportPairs.length === 1;

    const showTopBorder = isOneRow || isFirstRow;
    const showBottomBorder = isOneRow || isLastRow;

    return {
      isFirstRow,
      isFirstLocationRow,
      isLastRow,
      isOneRow,
      showTopBorder,
      showBottomBorder,
    };
  };
  const isToday = (date: string) => moment().format("YYYYMMDD") === moment(date).utc().format("YYYYMMDD");

  return (
    <StyledTable responsive>
      <thead>
        <tr>
          <PayRollTableHeader>Date</PayRollTableHeader>
          <PayRollTableHeader>Scheduled</PayRollTableHeader>
          <PayRollTableHeader>Location</PayRollTableHeader>
          <PayRollTableHeader>Clock-in date</PayRollTableHeader>
          <PayRollTableHeader>Clock-out date</PayRollTableHeader>
          <PayRollTableHeader>Raw</PayRollTableHeader>
          <PayRollTableHeader>Approved</PayRollTableHeader>
          <PayRollTableHeader>Classification</PayRollTableHeader>
        </tr>
      </thead>
      <tbody>
        {selectedEmployeePayroll.payPeriodDays.map(
          ({ date, scheduledHours, rawHours, locations, isWeekend, isStat }) => {
            const payPeriodDayDate = moment(formatToISO(date)).utc();
            const payPeriodDayId = generatePayPeriodDayId(date);
            const formattedPayPeriodDay = formatPayPeriodDayDate(date);

            if (isEmpty(locations)) {
              return (
                <EmployeeHourlyPayPeriodDayRow
                  key={date}
                  date={date}
                  employeeId={selectedEmployeePayroll.employeeId}
                  formattedPayPeriodDay={formattedPayPeriodDay}
                  isStat={isStat}
                  isToday={isToday(date)}
                  isWeekend={isWeekend}
                  payPeriodDayDate={payPeriodDayDate}
                  payPeriodDayId={payPeriodDayId}
                  rawHours={rawHours}
                  scheduledHours={scheduledHours}
                  showTopBorder={true}
                  showBottomBorder={true}
                  isFirstRow={true}
                  isFirstLocationRow={false}
                />
              );
            }
            return (
              <>
                {Object.values(locations).map((locationTimeReportPairs, locationIndex, locationsArray) => (
                  <>
                    {locationTimeReportPairs.map(
                      ({ locationDisplayName, clockInDate, clockOutDate, timezoneLocation }, timeReportPairIndex) => {
                        const { isFirstRow, isFirstLocationRow, showTopBorder, showBottomBorder } =
                          calculateRowProperties(
                            locationIndex,
                            timeReportPairIndex,
                            locationsArray,
                            locationTimeReportPairs,
                          );
                        return (
                          <EmployeeHourlyPayPeriodDayRow
                            key={clockInDate}
                            date={`${date}-${locationIndex}-${timeReportPairIndex}`}
                            employeeId={selectedEmployeePayroll.employeeId}
                            formattedPayPeriodDay={isFirstRow ? formattedPayPeriodDay : ""}
                            isStat={isStat}
                            isToday={isToday(date)}
                            isWeekend={isWeekend}
                            payPeriodDayDate={payPeriodDayDate}
                            payPeriodDayId={payPeriodDayId}
                            rawHours={isFirstRow ? rawHours : undefined}
                            scheduledHours={isFirstRow ? scheduledHours : undefined}
                            showTopBorder={showTopBorder}
                            showBottomBorder={showBottomBorder}
                            isFirstRow={isFirstRow}
                            isFirstLocationRow={isFirstLocationRow}
                            clockInDate={clockInDate}
                            clockOutDate={clockOutDate}
                            locationDisplayName={locationDisplayName}
                            timezoneLocation={timezoneLocation}
                          />
                        );
                      },
                    )}
                  </>
                ))}
              </>
            );
          },
        )}
      </tbody>
    </StyledTable>
  );
};

export default EmployeeHourlyPayPeriodTable;
