import React, { ChangeEvent, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";
import Button from "src/components/Button";
import DeleteModal from "src/components/DeleteModal";
import { HEIC_TYPE, HEIF_TYPE } from "src/config/сonstants";
import { ALL_FILE_TYPE } from "src/constants/validation";
import convertHeicToJpg from "src/helpers/convertHeicToJPG";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { InputRow, InputsRow } from "src/pages/TicketForm";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { MaximoDocument } from "src/types";
import { getBase64File, getComressedBase64File } from "src/utils/convertToBase64";
import { filterFiles } from "src/utils/helpers";
import ManagerAttachment from "./ManagerAttachment";

interface AttachmentsProps {
  documents?: MaximoDocument[];
  workOrderId?: number;
  workOrderHref?: string;
}

export const Attachments = ({ documents, workOrderId, workOrderHref }: AttachmentsProps) => {
  const { isMobile } = useIsMobile();
  const { isManager, loggedIn } = useSelector(getUserData);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [managerAttachments, setManagerAttachments] = useState<MaximoDocument[]>(documents || []);
  const [error, setError] = useState("");
  const [attachmentToDelete, setAttachmentToDelete] = useState("");
  const [showShowDeletePopup, setShowDeletePopup] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const onAttachmentClick = async (maximoDocument: MaximoDocument) => {
    const query = [
      `workorderid=${String(workOrderId)}`,
      `attachmentId=${maximoDocument.attachmentId}`,
      `fileName=${encodeURI(maximoDocument.fileName)}`,
    ].join("&");
    window.open(`${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-getMaximoAttachment?${query}`);
  };

  const onClickUpload = () => {
    hiddenFileInput.current?.click();
  };

  const uploadAttachments = async (files: MaximoDocument[]) => {
    if (!workOrderId) {
      return;
    }

    for await (const item of files) {
      let file = item.file;
      if (item.state === "completed" || item.state === "error" || !item.state) {
        continue;
      }

      if (!file) {
        return;
      }

      let base64File;
      if (file.type.includes("image")) {
        if (file.type === HEIC_TYPE || file.type === HEIF_TYPE) {
          const convertedImage = await convertHeicToJpg(file);
          if (convertedImage) {
            file = convertedImage;
          }
        }
        base64File = (await getComressedBase64File(file)) as string;
      }
      const convertedFile = await getBase64File(file);
      base64File = convertedFile;
      if (base64File && typeof base64File === "string") {
        const fileToUpload = base64File?.slice(base64File.indexOf(",") + 1);
        let errorMessage = "";
        let response: Record<string, string> = {};

        try {
          const resp = await FirebaseDataService.addMaximoWorkOrderAttachment(
            fileToUpload,
            workOrderId.toString(),
            file.name,
            file.type,
            "Attachments",
          );

          if (resp.status === "error") {
            errorMessage = resp.message;
          }

          response = resp.data;
        } catch (err) {
          errorMessage = err.response ? `${err.response?.message}: ${err.response?.data?.error}` : err.toString();
        }

        setManagerAttachments((prev) => {
          return prev.map((item) => {
            return item.fileName === file?.name
              ? {
                  ...item,
                  attachmentId: response?.id,
                  state: !errorMessage ? "completed" : "error",
                  error: errorMessage,
                }
              : item;
          });
        });
      }
    }
  };

  const retryUpload = async (fileName: string) => {
    const failedFile = managerAttachments.find((item) => item.file?.name === fileName);
    if (!failedFile) {
      return;
    }

    const newFiles = managerAttachments.map((file) => {
      return file.file?.name === fileName
        ? {
            ...file,
            state: "loading",
            error: "",
          }
        : file;
    });

    Promise.resolve()
      .then(() => {
        setManagerAttachments(newFiles);
      })
      .then(() => uploadAttachments(newFiles));
  };

  const getFileName = (name: string) => {
    if (name.includes("heic")) {
      return name.replace("heic", "jpeg");
    } else if (name.includes("heif")) {
      return name.replace("heif", "jpeg");
    } else {
      return name;
    }
  };

  const onChangeUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    const { supportedFiles, unsupportedFiles } = filterFiles(event.target.files, ALL_FILE_TYPE);
    setIsError(!!unsupportedFiles.length);

    const filesUploaded: MaximoDocument[] = Object.values(supportedFiles).map((item) => {
      return {
        file: item,
        state: "loading",
        error: "",
        attachmentId: "",
        description: "",
        fileName: getFileName(item.name),
        href: "",
      };
    });

    Promise.resolve()
      .then(() => {
        setManagerAttachments((prev) => [...prev, ...filesUploaded]);
      })
      .then(() => uploadAttachments(filesUploaded));
  };

  const deleteAttachment = async (attachmentId: string, e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    const workOrderKey = workOrderHref?.split("/").pop();
    setIsDeleting(true);
    try {
      await FirebaseDataService.deleteMaximoAttachment(attachmentId, workOrderKey || "");
    } catch (err) {
      setError(err.message);
    } finally {
      const newAttachments = managerAttachments.filter((item) => item.attachmentId !== attachmentId);
      setManagerAttachments(newAttachments);
      setShowDeletePopup(false);
      setIsDeleting(false);
    }
  };

  if (!workOrderId) {
    return null;
  }

  return (
    <>
      <DeleteModal
        isLoadingApproveButton={isDeleting}
        disableApproveButton={isDeleting}
        disableCancelButton={isDeleting}
        onApproveClick={(e) => deleteAttachment(attachmentToDelete, e)}
        onCancelClick={() => setShowDeletePopup(false)}
        isShowing={showShowDeletePopup}
        hide={() => setShowDeletePopup(false)}
        title="Attachment"
      />
      <InputsRow>
        {error && <div>{error}</div>}
        <InputRow
          style={{
            marginBottom: 0,
            ...(isManager && { position: "relative", maxWidth: "unset" }),
          }}
        >
          <Container $isMobile={isMobile}>
            <Label>Attachments: </Label>

            <AttachmentsContainer>
              {managerAttachments.map((item) => (
                <ManagerAttachment
                  isManager={isManager}
                  key={item.file?.name || item.fileName}
                  onRetry={retryUpload}
                  item={item}
                  setAttachmentToDelete={setAttachmentToDelete}
                  setShowDeletePopup={setShowDeletePopup}
                  onAttachmentClick={onAttachmentClick}
                />
              ))}
              {isError && <ErrorMessage>{`Please upload the supported files only ${ALL_FILE_TYPE}`}</ErrorMessage>}
              {loggedIn && (
                <Wrapper>
                  <label htmlFor="contained-button-file">
                    <Button
                      type="button"
                      text="Add Files"
                      variant="dotted-border"
                      onClick={onClickUpload}
                      icon={faPlus}
                    />
                  </label>
                  <input
                    ref={hiddenFileInput}
                    id="contained-button-file"
                    multiple
                    accept={ALL_FILE_TYPE}
                    type="file"
                    onChange={onChangeUpload}
                    style={{ display: "none" }}
                  />
                </Wrapper>
              )}
            </AttachmentsContainer>
          </Container>
        </InputRow>
      </InputsRow>
    </>
  );
};

export const Container = styled.div<MobileProps>`
  display: flex;
  flex-direction: row;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      flex-direction: column;
    `}
`;

export const Label = styled.label<MobileProps>`
  align-self: flex-start;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-bottom: 10px;
    `}
`;

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    width: unset;
  }
`;

export const Wrapper = styled.div`
  & label {
    width: unset;
  }
`;

export const ErrorMessage = styled.span`
  color: ${colors.red.default};
  font-size: 12px;
`;
