import React, { useMemo } from "react";
import { DropdownItem } from "src/components/Dropdown/types";
import SelectSearch from "src/components/SelectSearch";
import { InputRow } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { WorkRequestSelectLocationProps } from "../types";

const WorkRequestSelectLocation = ({
  clientId,
  isVerifyMode,
  locations,
  setFormData,
  setTimezone,
}: WorkRequestSelectLocationProps) => {
  const { isMobile } = useIsMobile();
  const dropdownLocations = useMemo(() => {
    const newLocations = locations.map((location) => {
      return {
        id: location.id,
        value: location.label || location.address,
        timezone: location?.timezone,
        clientId: location.clientId,
      };
    });

    return newLocations;
  }, [locations, clientId]);
  return (
    <InputRow $isMobile={isMobile} $isSelectSearch>
      <label>Location: </label>
      <SelectSearch
        defaultValue={""}
        placeholder={"Select Location"}
        disabled={isVerifyMode || !clientId}
        $isMobile={isMobile}
        options={dropdownLocations as DropdownItem[]}
        onChange={(item: DropdownItem) => {
          setFormData((data) => ({ ...data, location: String(item.id) }));
          setTimezone(item.timezone);
        }}
        bottomResultsGap={43}
      />
    </InputRow>
  );
};

export default WorkRequestSelectLocation;
