import { StyledTableCell } from "src/components/Table/TableCell";
import { colors } from "src/styles";
import styled from "styled-components";
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;
export const Content = styled.div`
  padding: 15px 30px;
`;
export const ContentFrame = styled.div`
  padding: 30px;
  background-color: ${colors.white.default};
  filter: drop-shadow(0px 4px 120px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
`;
export const EmptyContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  margin-bottom: 10px;
`;
export const StyledCell = styled(StyledTableCell)`
  height: 50px;
  box-sizing: content-box;
  padding: 10px;
`;
