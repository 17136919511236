import React, { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import Chip from "src/components/Chip";
import FilterPanel from "src/components/FilterPanel";
import Input from "src/components/Input";
import SelectSearch, {
  OptionItem,
  SELECT_CLIENT_FIRST_PLACEHOLDER,
  SELECT_CLIENT_PLACEHOLDER,
  SELECT_LOCATION_PLACEHOLDER,
  SELECT_ROLE_PLACEHOLDER,
} from "src/components/SelectSearch";
import { colors } from "src/styles";
import { Client, DBLocation } from "src/types";
import styled from "styled-components";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import { NO_ASSIGNED_CLIENTS_SELECT, NO_ASSIGNED_LOCATIONS_SELECT } from "../constants";

const emptyValueSelect = {
  id: 0,
  value: "",
};

type FilterType = "client" | "name" | "location" | "role" | "employeeId" | "onSiteOnly";

export interface FiltersArguments {
  roleFilter: string;
  nameFilter: string;
  employeeIdFilter: string;
  clientFilter: string;
  locationFilter: string;
  isOnSite: boolean;
}

export type RoleFilter = Record<string, Record<string, string>>;

interface TeamMembersFilterProps {
  onCloseFilters: () => void;
  isFilterBoxOpen: boolean;
  areFiltersApplied: boolean;
  setAreFiltersApplied: Dispatch<React.SetStateAction<boolean>>;
  onAppliedHandler: (filters: FiltersArguments) => Promise<void>;
  clientFiltersData: Client[];
  initialLocationFiltersData: DBLocation[];
  clearAllURLFilterParams: () => void;
  roleFilterData?: RoleFilter;
}

const TeamMembersFilter = ({
  onCloseFilters,
  isFilterBoxOpen,
  areFiltersApplied,
  setAreFiltersApplied,
  onAppliedHandler,
  clientFiltersData,
  clearAllURLFilterParams,
  initialLocationFiltersData,
  roleFilterData,
}: TeamMembersFilterProps) => {
  const [error, setError] = useState("");

  const [onSiteParam, setOnSiteQueryParam] = useQueryParam("onSite", BooleanParam);
  const [isOnSite, setIsOnSite] = useState(!!onSiteParam);

  const [nameFilterParam, setNameFilterParam] = useQueryParam("name", StringParam);
  const [nameFilter, setNameFilter] = useState(nameFilterParam || "");

  const [employeeIdFilterParam, setEmployeeIdFilterParam] = useQueryParam("employeeId", StringParam);
  const [employeeIdFilter, setEmployeeIdFilter] = useState(employeeIdFilterParam || "");

  const formattedLocations = Object.entries(initialLocationFiltersData ?? []).map(([key, value]) => {
    return {
      id: key,
      value: value.label,
    };
  });

  const formattedClientsFiltersData = Object.entries(clientFiltersData).map(([key, value]) => {
    return {
      id: key,
      value: value.name,
    };
  });

  const formattedRoles = roleFilterData
    ? Object.entries(roleFilterData).map(([key, value]) => {
        return {
          id: key,
          value: value.label,
        };
      })
    : [];

  //if there is a query param, find it in the array of clients and set as initial value
  const [clientFilterParam, setClientFilterParam] = useQueryParam("client", StringParam);
  const currentClientFilter =
    formattedClientsFiltersData.find((item) => item.id === clientFilterParam) || emptyValueSelect;
  const [clientFilterDropDown, setClientFilterDropDown] = useState<OptionItem>(currentClientFilter);

  //if there is a query param, find it in the array of locations and set as initial value
  const [locationFilterParam, setLocationFilterParam] = useQueryParam("location", StringParam);
  const currentLocationFilter = formattedLocations.find((item) => item.id === locationFilterParam) || emptyValueSelect;
  const [locationFilterDropDown, setLocationFilterDropDown] = useState<OptionItem>(currentLocationFilter);
  const [locationsFiltersData, setLocationsFiltersData] = useState(formattedLocations);

  //if there is a query param, find it in the array of roles and set as initial value
  const [roleFilterParam, setRoleFilterParam] = useQueryParam("role", StringParam);
  const currentRole = formattedRoles.find((role) => role.id === roleFilterParam) || emptyValueSelect;
  const [roleFilterDropDown, setRoleFilterDropDown] = useState<OptionItem>(currentRole);

  const clearAppliedFilter = (filterType: FilterType) => {
    switch (filterType) {
      case "client":
        setClientFilterParam(undefined);
        setClientFilterDropDown(emptyValueSelect);
        break;
      case "name":
        setNameFilterParam(undefined);
        setNameFilter("");
        break;
      case "location":
        setLocationFilterParam(undefined);
        setLocationFilterDropDown(emptyValueSelect);
        break;
      case "role":
        setRoleFilterParam(undefined);
        setRoleFilterDropDown(emptyValueSelect);
        break;
      case "employeeId":
        setEmployeeIdFilterParam(undefined);
        setEmployeeIdFilter("");
        break;
      case "onSiteOnly":
        setOnSiteQueryParam(undefined);
        setIsOnSite(false);
        break;
      default:
        break;
    }
  };

  const onDropDownClientHandler = (option: OptionItem) => setClientFilterDropDown(option);
  const onDropDownLocationHandler = (option: OptionItem) => setLocationFilterDropDown(option);
  const onDropDownRoleHandler = (option: OptionItem) => setRoleFilterDropDown(option);
  const nameFilterHandler = (e: ChangeEvent<HTMLInputElement>) => setNameFilter(e.target.value);
  const employeeIdFilterHandler = (e: ChangeEvent<HTMLInputElement>) => setEmployeeIdFilter(e.target.value);

  const onSiteHandler = () => {
    setIsOnSite((prev) => !prev);
  };

  const clearAllHandler = () => {
    clearAllURLFilterParams();
    setClientFilterDropDown(emptyValueSelect);

    setNameFilter("");

    setLocationFilterDropDown(emptyValueSelect);

    setRoleFilterDropDown(emptyValueSelect);

    setEmployeeIdFilter("");

    setIsOnSite(false);
  };

  const removeAllHandler = () => {
    clearAllURLFilterParams();
    setAreFiltersApplied(false);
  };

  const onCancelHandler = () => {
    setAreFiltersApplied(false);
    clearAllURLFilterParams();
    onCloseFilters();
  };

  const applyHandler = async () => {
    try {
      await onAppliedHandler({
        employeeIdFilter,
        isOnSite,
        nameFilter,
        clientFilter: clientFilterDropDown.id ? clientFilterDropDown.id.toString() : "",
        locationFilter: locationFilterDropDown.id ? locationFilterDropDown.id.toString() : "",
        roleFilter: roleFilterDropDown.id ? roleFilterDropDown.id.toString() : "",
      });
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    if (!initialLocationFiltersData) {
      return;
    }
    const newLocationsData = Object.entries(initialLocationFiltersData)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value.clientId === clientFilterDropDown.id)
      .map(([key, value]) => {
        return {
          id: key,
          value: value.label,
        };
      });
    const existsLocation = newLocationsData.find((item) => item.id === locationFilterDropDown.id);
    const currentLocation = !isFilterBoxOpen ? locationFilterDropDown : existsLocation;
    setLocationFilterDropDown(currentLocation || emptyValueSelect);

    setLocationsFiltersData(newLocationsData);
  }, [initialLocationFiltersData, clientFilterDropDown.id, isFilterBoxOpen]);

  const isApplyButtonDisabled = !(
    nameFilter ||
    employeeIdFilter ||
    clientFilterDropDown.value ||
    locationFilterDropDown.value ||
    roleFilterDropDown?.value ||
    isOnSite
  );

  return (
    <>
      {error && <Error>{error}</Error>}
      <FilterPanel
        isFilterBoxOpen={isFilterBoxOpen}
        clearAllHandler={clearAllHandler}
        areFiltersApplied={areFiltersApplied}
        onCancelHandler={onCancelHandler}
        isApplyButtonDisabled={isApplyButtonDisabled}
        removeAllHandler={removeAllHandler}
        applyHandler={applyHandler}
        filterItems={
          <GridWrapper>
            <div>
              <label>Client: </label>
              <SelectSearch
                defaultValue={clientFilterDropDown?.id || ""}
                placeholder={SELECT_CLIENT_PLACEHOLDER}
                options={
                  formattedClientsFiltersData.length ? formattedClientsFiltersData : [NO_ASSIGNED_CLIENTS_SELECT]
                }
                onChange={onDropDownClientHandler}
                inputStyle="padding: 6px 8px;"
                buttonStyle="top: 3px;"
                bottomResultsGap={30}
              />
            </div>
            <div>
              <FilterInput onChange={nameFilterHandler} value={nameFilter} label="Name:" placeholder="Enter Name" />
            </div>
            <div>
              <label>Role: </label>
              <SelectSearch
                defaultValue={roleFilterDropDown?.id || ""}
                placeholder={SELECT_ROLE_PLACEHOLDER}
                options={formattedRoles}
                onChange={onDropDownRoleHandler}
                inputStyle="padding: 6px 8px;"
                buttonStyle="top: 3px;"
                bottomResultsGap={30}
              />
            </div>
            <div>
              <label>Location: </label>

              <SelectSearch
                disabled={!clientFilterDropDown.value}
                defaultValue={locationFilterDropDown?.id || ""}
                placeholder={clientFilterDropDown.value ? SELECT_LOCATION_PLACEHOLDER : SELECT_CLIENT_FIRST_PLACEHOLDER}
                options={locationsFiltersData?.length ? locationsFiltersData : [NO_ASSIGNED_LOCATIONS_SELECT]}
                onChange={onDropDownLocationHandler}
                inputStyle="padding: 6px 8px;"
                buttonStyle="top: 3px;"
                bottomResultsGap={30}
              />
            </div>
            <div>
              <FilterInput
                onChange={employeeIdFilterHandler}
                value={employeeIdFilter}
                label="Employee ID:"
                placeholder="Enter Employee ID"
              />
            </div>
            <SwitchWrapper>
              <label>On Site Only:</label>
              <FormCheck checked={isOnSite} onChange={onSiteHandler} type="switch" />
            </SwitchWrapper>
          </GridWrapper>
        }
        appliedFiltersItems={
          <BadgesWrapper>
            {clientFilterDropDown?.value && (
              <Chip
                variant="text"
                actionHandler={() => clearAppliedFilter("client")}
                withAction
                badgeText={clientFilterDropDown.value}
              />
            )}
            {locationFilterDropDown?.value && (
              <Chip
                variant="text"
                actionHandler={() => clearAppliedFilter("location")}
                withAction
                badgeText={locationFilterDropDown.value}
              />
            )}
            {nameFilter && (
              <Chip variant="text" actionHandler={() => clearAppliedFilter("name")} withAction badgeText={nameFilter} />
            )}
            {employeeIdFilter && (
              <Chip
                variant="text"
                actionHandler={() => clearAppliedFilter("employeeId")}
                withAction
                badgeText={employeeIdFilter}
              />
            )}
            {roleFilterDropDown?.value && (
              <Chip
                variant="text"
                actionHandler={() => clearAppliedFilter("role")}
                withAction
                badgeText={roleFilterDropDown.value}
              />
            )}

            {isOnSite && (
              <Chip
                actionHandler={() => clearAppliedFilter("onSiteOnly")}
                withAction
                variant="text"
                badgeText={"On Site"}
              />
            )}
          </BadgesWrapper>
        }
      />
    </>
  );
};

export const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid ${colors.grey.light4};
`;

export const SelectedFiltersBox = styled.div<{ areFiltersApplied?: boolean }>`
  background-color: ${colors.grey.light1};
  padding: 10px 20px;
  border-bottom: ${({ areFiltersApplied }) => areFiltersApplied && `1px solid ${colors.grey.light4}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 40px;

  & label {
    font-size: 14px;
    font-weight: 600;
    margin-left: 0;
    margin-bottom: 3px;
  }
  & div {
    margin-bottom: 0;
  }
`;

const FilterInput = styled(Input)`
  padding: 6px 8px;
  height: unset;
`;

const BadgesWrapper = styled.div`
  display: flex;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 17px;

  .form-switch > .form-check-input {
    cursor: pointer;
  }

  & label {
    padding-right: 8px;
    margin-bottom: 0;
  }
  .form-switch > .form-check-input:checked {
    background-color: ${colors.kleenway.green};
    border: 0;
  }
  .form-switch .form-check-input:focus {
    border-color: ${colors.grey.light1};
    box-shadow: unset;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
  }
`;

const Error = styled.p`
  padding-bottom: 15px;
`;

export default TeamMembersFilter;
