import React from "react";
import { OptionItem } from "src/components/SelectSearch";
import { AdjustmentFormValues } from "src/components/molecules/AdjustmentForm/types";
import AdjustmentFormModal from "src/components/organisms/AdjustmentFormModal";

import { FirebaseDataService } from "src/services/Firebase/data";
import { EmployeeAdjustment } from "src/types";
import { debugLog } from "src/utils/log";
import { EmployeeAdjustmentManagerProps } from "../types";

const EmployeeAdjustmentManager = ({
  reasons,
  payPeriod,
  employeeAdjustment,
  employeesPayrollData,
  closeModal,
  setEmployeesPayrollData,
}: EmployeeAdjustmentManagerProps) => {
  const findAdjustmentReason = (items: OptionItem[], adjustmentReasonCode: string) => {
    return items.find((item) => item.id === adjustmentReasonCode);
  };

  const getEmployeesPayrollData = (
    adjustmentData: EmployeeAdjustment,
    formValues: AdjustmentFormValues,
    adjustmentReason: OptionItem,
  ) => {
    return employeesPayrollData.map((employeePayrollData) =>
      employeePayrollData.employeeId === adjustmentData.employeeId
        ? { ...employeePayrollData, adjustment: createEmployeeAdjustment(formValues, adjustmentReason) }
        : employeePayrollData,
    );
  };

  const createEmployeeAdjustment = (formValues: AdjustmentFormValues, adjustmentReason: OptionItem) => ({
    ...formValues,
    adjustmentReason: {
      code: adjustmentReason.id.toString(),
      label: adjustmentReason.value,
      priority: adjustmentReason.priority,
    },
    id: employeeAdjustment.employeeId.toString(),
  });

  const updateEmployeesPayroll = (formValues: AdjustmentFormValues) => {
    const adjustmentReason = findAdjustmentReason(reasons, formValues.adjustmentReasonCode) ?? { id: "", value: "" };

    setEmployeesPayrollData(getEmployeesPayrollData(employeeAdjustment, formValues, adjustmentReason));
  };

  const onSubmitHandler = async (adjustment: AdjustmentFormValues) => {
    const { employeeId } = employeeAdjustment;

    try {
      const { status } = await FirebaseDataService.updateEmployeePayPeriodAdjustment(
        { ...adjustment, adjustmentAmount: parseFloat(adjustment.adjustmentAmount.toString()) },
        payPeriod,
        employeeId,
      );
      if (status === "success") {
        updateEmployeesPayroll(adjustment);
      }
      closeModal();
    } catch (error) {
      debugLog(`Error in Update Adjustment Form EmployeeId ${employeeId} and Pay PeriodId ${payPeriod}: ${error}`);
    }
  };

  return (
    <AdjustmentFormModal
      closeModal={closeModal}
      defaultValues={employeeAdjustment.adjustment}
      onSubmit={onSubmitHandler}
      reasons={reasons}
    />
  );
};
export default EmployeeAdjustmentManager;
