import React, { useEffect, useState } from "react";

import { Outlet, useParams } from "react-router-dom";
import { OptionItem } from "src/components/SelectSearch";
import { DropdownItem } from "../../components/Dropdown/types";
import { DayClassification, FirebaseDataService } from "../../services/Firebase/data";
import {
  Client,
  DBClientContact,
  DBClientPortalTile,
  DBDropdownListSizes,
  DBKleenwayContact,
  DBLocation,
  DBMessage,
  DBPayPeriod,
  FileType,
  ID,
  IOmegaItem,
  PayPeriodType,
} from "../../types";
import { ModalTypeFile } from "./FileSharing/Modal/FileModal";
import { DEFAULT_TIMEZONE_DROPDOWN_OPTION, EMPTY_DROPDOWN_OPTION } from "./constants";
import ClientDetailsContext from "./context";
import { checkFormsValidity, checkTilesFormsValidity } from "./utils";
import { Spinner } from "react-bootstrap";
import { EmptyContainer } from "src/components/ListScreen";

export interface ClientContactFormProps extends DBClientContact {
  id: string;
  isValid: boolean;
}

export interface ClientPortalUserFormProps {
  id: string;
  isValid: boolean;
  isSaved: boolean;
  isDeleted: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  photo?: File | string | null;
  initialPhoto: string | null;
  error: string;
}

export interface KleenwayContactFormProps extends DBKleenwayContact {
  formId: string;
  isValid: boolean;
}

export interface InfoMessagesFormProps {
  firstText: DropdownItem;
  secondText: DropdownItem;
  welcomeText: DropdownItem;
}

export interface clientPortalProps {
  tiles?: any[];
  targetTime?: {
    targetStartDayDelay: number;
    targetStartTime: string;
  };
}

export interface GeneralInfoFormStateProps {
  logo?: File | null | string;
  timezone: DropdownItem;
  clientContacts: ClientContactFormProps[];
  kleenwayContacts: KleenwayContactFormProps[];
  infoMessages: InfoMessagesFormProps;
  clientPortalUsers: ClientPortalUserFormProps[];
  clientPortal: clientPortalProps;
}

export interface EmployeesApprovedHoursFormProps {
  [key: string]: {
    [key: string]: {
      approvedHours: number;
      rawHours: number;
      classification: string;
      updated?: boolean;
    };
  };
}

export interface StructuredPayPeriods {
  [key: string]: {
    [key: string]: DBPayPeriod[];
  };
}

export interface RenameModalProps {
  isVisible?: boolean;
  id?: ID;
  name?: string;
  type?: FileType;
  modalType?: ModalTypeFile;
  error?: string;
}

function ClientDetailsContextProvider() {
  const { id: clientId } = useParams();
  const [client, setClient] = useState<Client>({} as Client);
  const [isGeneralInfoFormSaving, setIsGeneralInfoFormSaving] = useState(false);
  const [generalInfoFormState, setGeneralInfoFormState] = useState<GeneralInfoFormStateProps>(
    {} as GeneralInfoFormStateProps,
  );
  const [kleenwayContacts, setKleenwayContacts] = useState<DBKleenwayContact[]>([]);
  const [timezones, setTimezones] = useState<DropdownItem[]>([]);
  const [messages, setMessages] = useState<DropdownItem[]>([]);
  const [locations, setLocations] = useState<DBLocation[]>([]);
  const [payPeriods, setPayPeriods] = useState<StructuredPayPeriods>({} as StructuredPayPeriods);
  const [payPeriodsTypes, setPayPeriodsTypes] = useState<PayPeriodType[]>([]);
  const [isContextLoaded, setIsContextLoaded] = useState<boolean>(false);
  const [isGeneralInfoFormValid, setIsGeneralInfoFormValid] = useState(true);
  const [dayClassifications, setDayClassifications] = useState<OptionItem[]>([]);

  const [isTilesFormValid, setIsTilesFormValid] = useState(true);
  const [dropdownListSizes, setDropdownListSizes] = useState({} as DBDropdownListSizes);
  const [modalRename, setModalRename] = useState<RenameModalProps>({});
  const [folderContent, setFolderContent] = useState<IOmegaItem[]>([]);
  const [clientPortalTiles, setClientPortalTiles] = useState<DBClientPortalTile[]>([]);

  const [employeesApprovedHoursForm, setEmployeesApprovedHoursForm] = useState<EmployeesApprovedHoursFormProps>(
    {} as EmployeesApprovedHoursFormProps,
  );

  const fetchClientContext = async () => {
    if (!clientId) return;
    const { data } = await FirebaseDataService.getClientContext(clientId);
    setIsContextLoaded(false);
    setPayPeriodsTypes(data.payPeriodTypes || []);
    setKleenwayContacts(data.globalContacts || []);
    setTimezones(ensureDefaultTimezone(data.supportedTimezones || []));
    setMessages(formatInfoMessages(data.infoMessages || []));
    setDropdownListSizes(data.dropdownListSizes || []);
    setDayClassifications(formatDayClassifications(data.dayClassifications || []));

    setIsContextLoaded(true);
  };

  useEffect(() => {
    fetchClientContext();
  }, []);

  useEffect(() => {
    setIsGeneralInfoFormValid(
      checkFormsValidity(generalInfoFormState.clientContacts) &&
        checkFormsValidity(generalInfoFormState.kleenwayContacts) &&
        checkFormsValidity(generalInfoFormState.clientPortalUsers?.filter((item) => !item?.isDeleted)),
    );
  }, [generalInfoFormState]);

  useEffect(() => {
    setIsTilesFormValid(checkTilesFormsValidity(generalInfoFormState.clientPortal?.tiles || []));
  }, [generalInfoFormState]);
  if (!isContextLoaded) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }
  return (
    <ClientDetailsContext.Provider
      value={{
        isGeneralInfoFormSaving,
        setIsGeneralInfoFormSaving,
        generalInfoFormState,
        setGeneralInfoFormState,
        kleenwayContacts,
        timezones,
        setTimezones,
        messages,
        payPeriods,
        setPayPeriods,
        locations,
        setLocations,
        payPeriodsTypes,
        setPayPeriodsTypes,
        isContextLoaded,
        isGeneralInfoFormValid,
        setIsGeneralInfoFormValid,
        isTilesFormValid,
        setIsTilesFormValid,
        client,
        setClient,
        dropdownListSizes,
        setDropdownListSizes,
        employeesApprovedHoursForm,
        setEmployeesApprovedHoursForm,
        modalRename,
        setModalRename,
        folderContent,
        setFolderContent,
        clientPortalTiles,
        setClientPortalTiles,
        dayClassifications,
      }}
    >
      <Outlet />
    </ClientDetailsContext.Provider>
  );
}

/**
 * A helper item to convert the list to OptionItem array
 */
const formatDayClassifications = (dayClassifications: DayClassification[]): OptionItem[] =>
  dayClassifications.map((classification) => ({
    ...classification,
    value: classification.label,
  }));

/**
 * A helper function to add an empty item to the list and convert it to DropdownItem[]
 */
const formatInfoMessages = (infoMessages: DBMessage[]): DropdownItem[] => [
  EMPTY_DROPDOWN_OPTION,
  ...infoMessages.map((message) => ({
    ...message,
    value: message.label,
  })),
];

/**
 * A helper function to ensure there is a default timezone in the list and convert the simple list of strings
 * to DropdownItem[]
 */
const ensureDefaultTimezone = (timezones: string[]): DropdownItem[] => {
  const isDefaultTimezoneOutOfList = !timezones.find((timezone) => timezone === DEFAULT_TIMEZONE_DROPDOWN_OPTION.value);

  const timezoneOptions = timezones.map((tz: string, index: number) => ({
    value: tz,
    id: index,
  }));

  return isDefaultTimezoneOutOfList ? [DEFAULT_TIMEZONE_DROPDOWN_OPTION, ...timezoneOptions] : timezoneOptions;
};

export default ClientDetailsContextProvider;
