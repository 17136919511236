import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import { DynamicFormConfig } from "src/components/DynamicFormContainer/types";
import { NoTicketsMessage } from "src/components/ListScreen";
import { useIsMobile } from "src/hooks/useIsMobile";
import { FirebaseDataService } from "src/services/Firebase/data";
import { TopBar } from "src/styles";
import { ID } from "src/types";
import { Content } from "../TicketForm";
import { FormTable } from "./FormTable";
import { FormsList } from "./FormsList";
import { FORMS_BREADCRUMBS, tableHeaders } from "./constant";
import { ContentFrame } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setPageNameHeader } from "src/redux/actions/header";
import { getSubmittedPageName } from "src/redux/selectors/dynamicForm";
import { setSubmittedPageName } from "src/redux/actions/dynamicForms";

const DynamicFormList = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [formList, setFormList] = useState<DynamicFormConfig[]>([]);
  const dispatch = useDispatch();
  const submittedPageName = useSelector(getSubmittedPageName);
  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);
  const onRowClick = (id: ID) => navigate(`/dynamic-forms/${id}`);
  useEffect(() => {
    dispatch(setPageNameHeader(""));
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const res = await FirebaseDataService.getDynamicFormList();
    if (res.data) {
      setFormList(res.data.dynamicFormList ?? []);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (submittedPageName !== "") {
      setTimeout(() => {
        dispatch(setSubmittedPageName(""));
      }, 3000);
    }
  }, [submittedPageName]);

  return (
    <Container>
      <TopBar>
        <BreadCrumbs folderStack={FORMS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />
      </TopBar>
      {submittedPageName && (
        <Alert variant={"success"} onClose={() => dispatch(setSubmittedPageName(""))} dismissible>
          <Alert.Heading>{`${submittedPageName} is submitted successfully`}</Alert.Heading>
        </Alert>
      )}

      <Content>
        <ContentFrame>
          {isMobile ? (
            <FormsList onClick={onRowClick} list={formList} />
          ) : (
            <FormTable onRowClick={onRowClick} isLoading={isLoading} headers={tableHeaders} data={formList} />
          )}
          {formList.length === 0 && !isLoading && (
            <NoTicketsMessage $isMobile={isMobile}>No Form available</NoTicketsMessage>
          )}
        </ContentFrame>
      </Content>
      <Outlet />
    </Container>
  );
};

export default DynamicFormList;
