import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBars, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

interface NavbarToggleProps {
  $isMobile: boolean;
  $isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NavbarToggle: React.FC<NavbarToggleProps> = ({ $isMobile, $isVisible, setIsVisible }) => {
  const onClickIcon = (value: boolean) => {
    setIsVisible(value);
  };
  return (
    <>
      <Container
        style={{
          display: $isMobile ? "flex" : "none",
          marginLeft: $isVisible ? "-20px" : "0",
        }}
      >
        {$isMobile && $isVisible ? (
          <Icon icon={faTimes as IconProp} onClick={() => onClickIcon(false)} />
        ) : (
          <Icon icon={faBars as IconProp} onClick={() => onClickIcon(true)} />
        )}
      </Container>
    </>
  );
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100px;
  z-index: 99;
`;

const Icon = styled(FontAwesomeIcon)`
  height: 40px;
  color: ${colors.grey.dark};
`;
