import React, { useMemo } from "react";
import DropdownRow from "../../DropdownRow";
import { DropdownItem } from "src/components/Dropdown/types";
import { WorkRequestShowPriorityLevelProps } from "../types";
import { PriorityLevel } from "src/types";
import { colors } from "src/styles";

const WorkRequestShowPriorityLevel = ({
  clientId,
  isNoAuth,
  isVerifyMode,
  priorityName,
  setFormData,
  priorityLevels,
}: WorkRequestShowPriorityLevelProps) => {
  const dropdownPriorityLevels = useMemo(() => {
    const dataSource = isNoAuth ? priorityLevels : priorityLevels?.[clientId];

    return (dataSource as PriorityLevel[])
      ?.map((priorityLevel: PriorityLevel) => {
        const pickedPriorityLevel = priorityLevels?.[clientId]?.find(
          ({ priority }) => priority === priorityLevel.priority,
        );
        return {
          id: priorityLevel.name,
          value: priorityLevel.name,
          priority: priorityLevel.priority,
          color: pickedPriorityLevel ? pickedPriorityLevel.color : colors.grey.default,
          hoursDelay: priorityLevel.hoursDelay,
        };
      })
      .sort((a, b) => a.priority - b.priority);
  }, [priorityLevels, clientId]);

  const onPriorityLevelChange = (item: DropdownItem) => {
    setFormData((data) => ({
      ...data,
      priorityName: item.value,
      priority: item.priority,
      hoursDelay: item.hoursDelay,
    }));
  };
  return (
    <DropdownRow
      disabled={isVerifyMode || !clientId}
      label="Priority Level:"
      defaultValue={priorityName}
      dropdownItems={dropdownPriorityLevels || []}
      placeholder={"Select Priority Level"}
      onClick={(item) => onPriorityLevelChange(item)}
    />
  );
};

export default WorkRequestShowPriorityLevel;
