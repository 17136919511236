import React from "react";
import { FormCheck } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { InputRow, InputsContainer } from "../styles";
import { CheckBoxToFormProps } from "../types";

/**
 * A helper component to render the Toogle Button for workorder show or not to client
 */
export const IsClientVisbleCheckBoxForm = ({ form, $isMobile }: CheckBoxToFormProps) => {
  return (
    <Controller
      control={form.control}
      name={"isvisibletoclient"}
      defaultValue={false}
      render={({ field: { onChange, value, name, ref } }) => (
        <InputsContainer>
          <InputRow $isMobile={$isMobile}>
            <label>Visible to Client?: </label>
            <label>
              <FormCheck onChange={onChange} value={value} name={name} ref={ref} type="switch" />
            </label>
          </InputRow>
        </InputsContainer>
      )}
    />
  );
};
