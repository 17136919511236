import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { PAY_TYPE } from "src/constants/validation";
import { mapRoleWithEmployeeDisplayName } from "../../../utils";
import { EmployeesTableCell, StarIcon } from "../styles";
import { EmployeeSummaryRowProps } from "../types";
import EmployeeAdjustementColumn from "./EmployeeAdjustementColumn";

const EmployeeSummaryRow = ({ employeePayrollData, onAdjustmentClick }: EmployeeSummaryRowProps) => {
  return (
    <>
      <EmployeesTableCell>{employeePayrollData.employeeId}</EmployeesTableCell>
      <EmployeesTableCell>
        {mapRoleWithEmployeeDisplayName(employeePayrollData)}
        {employeePayrollData.hasSeveralClients && (
          <>
            <StarIcon
              icon={faStar as IconProp}
              data-tooltip-id="star-tooltip"
              data-tip="This Employee may have additional hours approved by another Manager."
            />
            <ReactTooltip id="star-tooltip" place="bottom" variant="light" border="solid" />
          </>
        )}
      </EmployeesTableCell>

      <EmployeesTableCell>
        {employeePayrollData.payType === PAY_TYPE.salary ? "-" : employeePayrollData.allClientsScheduledHours}
      </EmployeesTableCell>
      <EmployeesTableCell>
        {employeePayrollData.payType === PAY_TYPE.salary
          ? `$${employeePayrollData.salaryAmount}`
          : employeePayrollData.allClientsTotalRegularHours}
      </EmployeesTableCell>
      <EmployeesTableCell>
        {employeePayrollData.payType === PAY_TYPE.salary ? "-" : employeePayrollData.allClientsTotalOvertimeHours}
      </EmployeesTableCell>
      <EmployeesTableCell>
        {employeePayrollData.payType === PAY_TYPE.salary ? "-" : employeePayrollData.stat}
      </EmployeesTableCell>
      <EmployeesTableCell>
        {employeePayrollData.payType === PAY_TYPE.salary ? "-" : employeePayrollData.statWorked}
      </EmployeesTableCell>
      <EmployeeAdjustementColumn employeePayrollData={employeePayrollData} onClick={onAdjustmentClick} />
    </>
  );
};

export default EmployeeSummaryRow;
