import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "src/components/atoms/DatePicker";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { Label } from "../styles";

export const CloseWorkOrderDatePicker = ({ fieldName, label }: CloseWorkOrderDatePickerProps) => {
  const { control } = useFormContext();

  return (
    <Stack direction="horizontal" gap={2}>
      <Label htmlFor={fieldName} required={true}>
        {label}
      </Label>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: true }}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
          <DatePicker
            maxDate={new Date()}
            onChange={onChange}
            value={value}
            variant={DATEPICKER_VARIANT.DATE_TIME}
            ref={ref}
            error={error}
          />
        )}
      />
    </Stack>
  );
};
interface CloseWorkOrderDatePickerProps {
  fieldName: string;
  label: string;
}
