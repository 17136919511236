import React from "react";
import { Required } from "../Required";
import { FormLabelProps } from "./types";

export const FormLabel = ({ required, children, ...labelProps }: FormLabelProps) => {
  return (
    <label {...labelProps}>
      {children} {required && <Required />}
    </label>
  );
};
