import React from "react";
import Textarea from "src/components/Textarea";
import { DescriptionContainer } from "../styles";
import { useIsMobile } from "src/hooks/useIsMobile";
import { WorkRequestDescriptionInputProps } from "../types";

const WorkRequestDescriptionInput = ({ description, setFormData, isVerifyMode }: WorkRequestDescriptionInputProps) => {
  const { isMobile } = useIsMobile();
  return (
    <DescriptionContainer $isMobile={isMobile}>
      <label>Description: </label>

      <Textarea
        countSymbols
        width="360px"
        value={description}
        disabled={isVerifyMode}
        placeholder="Enter Description"
        onChange={(e) => setFormData((data) => ({ ...data, description: e.target.value }))}
      ></Textarea>
    </DescriptionContainer>
  );
};

export default WorkRequestDescriptionInput;
