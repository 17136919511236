import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomTimeInput from "./components/CustomTimeInput";
import { onChangeHandler } from "./handlers";
import { ErrorLabel, Icon, StyledInput, Wrapper } from "./styles";
import { DATEPICKER_VARIANT, DatePickerProps } from "./types";
import { getVariantProps, noop, validateValue } from "./utils";

/**
 * Wrapper around https://reactdatepicker.com/ to provide it with the project-specific configuration and ensure props
 * consistency throughout the system.
 *
 * It also makes it so the component value is a string and no longer a Date object to streamline the usage of values
 * from the database (which are strings).
 */
export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(function DatePicker(
  { variant = DATEPICKER_VARIANT.DATE_TIME, name, value, error, maxDate, onChange }: DatePickerProps,
  ref,
) {
  const { dateFormat, placeholder } = getVariantProps(variant);

  validateValue(value, dateFormat);

  return (
    <Wrapper error={error && true}>
      <ReactDatePicker
        selected={value ? moment(value, dateFormat).toDate() : null}
        showTimeInput={variant === DATEPICKER_VARIANT.DATE_TIME || variant === DATEPICKER_VARIANT.TIME}
        showTimeSelectOnly={variant === DATEPICKER_VARIANT.TIME}
        inline={variant === DATEPICKER_VARIANT.TIME}
        timeIntervals={1}
        disabledKeyboardNavigation
        onChange={(date) => onChangeHandler(date, dateFormat, onChange)}
        shouldCloseOnSelect={variant !== DATEPICKER_VARIANT.DATE_TIME}
        maxDate={maxDate}
        customInput={
          <div>
            {/* The empty onChange handler is required to remove react-hook-form component tree validation error */}
            <StyledInput
              name={name}
              variant={variant}
              onChange={noop}
              placeholder={placeholder}
              value={value}
              error={error}
              ref={ref}
            />
            <span>
              <Icon icon={faCalendar as IconProp} />
            </span>
          </div>
        }
        customTimeInput={
          <CustomTimeInput
            value={value}
            onChange={(newValue) => onChangeHandler(moment(newValue, "HH:mm").toDate(), dateFormat, onChange)}
            name={name}
          />
        }
      />
      {error && <ErrorLabel>{error.message}</ErrorLabel>}
    </Wrapper>
  );
});
