import React, { useState } from "react";

import { useIsMobile } from "src/hooks/useIsMobile";
import { usePagination } from "src/hooks/usePagination";
import { PaginationWrapper, PaginationContainer } from "./styles";

import { IPagination } from "./types";
import { GoToPageInput } from "./components/GoToPageInput";
import { PaginationItems } from "./components/PaginationItems";
import { PaginationNavigation } from "./components/PaginationNavigation";

export const Pagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
  onPageChange,
  isTotalCountFinal = true,
}: IPagination) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { isMobile } = useIsMobile();
  const lastPage = paginationRange[paginationRange.length - 1];

  const [pageNum, setPageNum] = useState<string | number>(0);

  const maxPaginationLength = 2;
  if (currentPage === 0 || paginationRange.length < maxPaginationLength) {
    return null;
  }

  const goToPage = (number: number) => {
    if (isNaN(number)) {
      return;
    }
    if (isTotalCountFinal && number > +lastPage) {
      return;
    }
    number ? onPageChange(number) : onPageChange(1);
  };

  return (
    <PaginationWrapper>
      <PaginationContainer $isMobile={isMobile}>
        <PaginationNavigation
          isMobile={isMobile}
          currentPage={currentPage}
          lastPage={+lastPage}
          onPageChange={onPageChange}
        />
        <PaginationItems
          paginationRange={paginationRange}
          currentPage={currentPage}
          onPageChange={onPageChange}
          isMobile={isMobile}
        />
        <PaginationNavigation
          isMobile={isMobile}
          currentPage={currentPage}
          lastPage={+lastPage}
          onPageChange={onPageChange}
          isNext
        />
      </PaginationContainer>
      <GoToPageInput goToPage={goToPage} pageNum={pageNum} setPageNum={setPageNum} />
    </PaginationWrapper>
  );
};
