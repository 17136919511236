import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { NumberParam, useQueryParam } from "use-query-params";

import { Pagination } from "src/components/Pagination";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import { Client, ID } from "src/types";

import { Content, ContentFrame, TopBar } from "src/styles";
import BreadCrumbs from "../../components/BreadCrumbs";
import { Container, EmptyContainer, NoTicketsMessage, PaginationContainer } from "../../components/ListScreen";
import { compareBy } from "../../utils";
import { setOpenedClientsPage as setPageStore } from "./../../redux/actions/openedPages";
import { ClientTable } from "./ClientTable";
import { ClientsList } from "./ClientsList";
import { DEFAULT_PAGE_SIZE, INITIAL_LAST_PAGE, MANAGER_CLIENTS_BREADCRUMBS, tableHeaders } from "./constants";

export const Clients = () => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [clients, setClients] = useState<Client[]>([]);
  const [currentClientsPage, setCurrentClientsPage] = useState<Client[]>([]);
  const { id: employeeId } = useSelector(getUserData);

  const [isLoading, setIsLoading] = useState(false);

  const [openedClientsPage, setOpenedClientsPage] = useQueryParam("openedClientsPage", NumberParam);
  const [lastPage, setLastPage] = useState<number>(INITIAL_LAST_PAGE);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const pageNum = openedClientsPage || 1;

  const convertClientsObjectToArray = (clientsObject: any) => {
    return Object.keys(clientsObject).reduce((acc: Client[], key, index) => {
      if (clients[clients.length - 1]?.id && !index) return acc;

      return [
        ...acc,
        {
          id: key,
          ...clientsObject[key],
        },
      ];
    }, []);
  };

  const getPageSize = async () => {
    try {
      const { data } = await FirebaseDataService.getEmployeeClientsPageSize();
      setPageSize(data || DEFAULT_PAGE_SIZE);
    } catch (e) {
      setPageSize(DEFAULT_PAGE_SIZE);
    }
  };

  const getClients = async () => {
    setIsLoading(true);

    if (!lastPage || (lastPage && pageNum <= lastPage)) {
      try {
        const { data: clientsObject } = await FirebaseDataService.getEmployeeClients({
          employeeId: employeeId.toString(),
        });

        const allClients: Client[] = convertClientsObjectToArray(clientsObject).sort((client1, client2) =>
          compareBy("name", client1, client2),
        );
        const resultClientsPageArray = allClients.slice((pageNum - 1) * pageSize, pageNum * pageSize);

        setCurrentClientsPage(resultClientsPageArray);
        setClients(allClients);
        setLastPage(Math.ceil(allClients.length / pageSize));
      } catch (e) {
        console.log(e.message);
      }
    }

    setIsLoading(false);
  };

  const onPageChangeClick = (pageNumber: number) => {
    setOpenedClientsPage(pageNumber);
    dispatch(setPageStore(pageNumber));
    setCurrentClientsPage(clients.slice((pageNumber - 1) * pageSize, pageNumber * pageSize));
  };

  const onRowClick = (id: ID) => navigate(`/clients/${id}`);

  useEffect(() => {
    setIsLoading(true);
    getPageSize().finally(() => getClients());
  }, [pageSize]);

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  return (
    <Container>
      <TopBar>
        <BreadCrumbs folderStack={MANAGER_CLIENTS_BREADCRUMBS} onClickItem={onClickBreadcrumb} />
      </TopBar>

      <Content>
        <ContentFrame>
          {isLoading ? (
            <EmptyContainer>
              <Spinner animation="border" size="sm" />
            </EmptyContainer>
          ) : (
            <>
              {currentClientsPage?.length ? (
                <>
                  {isMobile ? (
                    <ClientsList onClick={onRowClick} data={currentClientsPage} />
                  ) : (
                    <ClientTable
                      onRowClick={onRowClick}
                      isLoading={isLoading}
                      headers={tableHeaders}
                      data={currentClientsPage}
                    />
                  )}
                </>
              ) : (
                <NoTicketsMessage $isMobile={isMobile}>No Clients assigned</NoTicketsMessage>
              )}
              <PaginationContainer>
                <Pagination
                  totalCount={clients.length}
                  pageSize={pageSize}
                  onPageChange={onPageChangeClick}
                  currentPage={pageNum}
                />
              </PaginationContainer>
            </>
          )}
        </ContentFrame>
      </Content>
      <Outlet />
    </Container>
  );
};
