import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Textarea from "src/components/Textarea";
import { Label } from "../styles";

export const CloseWorkOrderNotes = () => {
  const { control } = useFormContext();

  const fieldName = "notes";

  return (
    <Stack direction="horizontal" gap={2}>
      <Label htmlFor={fieldName}>Notes:</Label>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Textarea
            placeholder="Notes"
            onChange={onChange}
            value={value}
            width="100%"
            name={name}
            ref={ref}
            error={error}
          />
        )}
      />
    </Stack>
  );
};
