import React, { useEffect, useState } from "react";
import SearchInput from "src/components/atoms/SearchInput";
import VirtualizedList from "src/components/molecules/VirtualizedList";
import { PAY_TYPE } from "src/constants/validation";
import { EmployeePayrollData } from "src/types";
import EmployeeHourlyPayPeriodTable from "./components/EmployeeHourlyPayPeriodTable";
import EmployeeListItem from "./components/EmployeeListItem";
import { EmployeeSalariedPayPeriodTable } from "./components/EmployeeSalariedPayPeriodTable";
import { Container, ContentPanel, SidePanel } from "./styles";
import { PayPeriodRawTabProps } from "./types";

const PayPeriodRawTab = ({ employeesPayrollData }: PayPeriodRawTabProps) => {
  const [selectedEmployeePayroll, setSelectedEmployeePayroll] = useState<EmployeePayrollData>();
  const [filteredItems, setFilteredItems] = useState<EmployeePayrollData[]>(employeesPayrollData);

  useEffect(() => {
    setSelectedEmployeePayroll(employeesPayrollData[0]);
  }, []);

  const onChangeHandler = (value: string) => {
    setFilteredItems(
      employeesPayrollData.filter(
        (item) =>
          item.employeeId.toString().toLowerCase().includes(value.toLowerCase()) ||
          item.employeeDisplayName.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };
  return (
    <Container direction="horizontal">
      <SidePanel>
        <SearchInput onChange={onChangeHandler} placeholder={"Employee ID or Name"} />
        <VirtualizedList
          rowHeight={75}
          rowCount={filteredItems.length}
          rowRenderer={(props) => (
            <EmployeeListItem
              onSelectItem={(id) => {
                setSelectedEmployeePayroll(employeesPayrollData.find((payrollData) => payrollData.employeeId === id));
              }}
              selectedItem={selectedEmployeePayroll ? selectedEmployeePayroll.employeeId.toString() : ""}
              listRowProps={props}
              list={filteredItems}
            />
          )}
        />
      </SidePanel>
      <ContentPanel>
        {selectedEmployeePayroll && selectedEmployeePayroll.payType === PAY_TYPE.hourly && (
          <EmployeeHourlyPayPeriodTable selectedEmployeePayroll={selectedEmployeePayroll} />
        )}
        {selectedEmployeePayroll && selectedEmployeePayroll.payType === PAY_TYPE.salary && (
          <EmployeeSalariedPayPeriodTable selectedEmployeePayroll={selectedEmployeePayroll} />
        )}
      </ContentPanel>
    </Container>
  );
};

export default PayPeriodRawTab;
