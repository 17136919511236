import moment from "moment-timezone";

import { DBEmployeeUser, DBVendorUser } from "src/types";

export const renderResponseResult = (success: boolean, data: any) => {
  if (!success) {
    throw new Error(data);
  }

  return {
    success,
    data,
  };
};

export const getDatesDifference = (date: string | null) => {
  if (!date) {
    return 0;
  }
  const date1: any = new Date(date);
  const date2: any = new Date();
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const isTimezoneOffsetsEqual = (firstTimezone: string, secondTimezone: string) => {
  return moment(new Date()).tz(firstTimezone).utcOffset() === moment(new Date()).tz(secondTimezone).utcOffset();
};

export const stripMaximoTimeZone = (
  date: string | undefined,
  maximoTimezone: string,
  locationTimezone: string | undefined,
) => {
  const localTimeZone = moment.tz.guess();
  const targetDate = moment(date || new Date());
  let result = targetDate.tz(maximoTimezone).format("YYYY-MM-DD HH:mm:ss");

  if (locationTimezone && !isTimezoneOffsetsEqual(localTimeZone, locationTimezone)) {
    result = moment.tz(result, locationTimezone).format();
  }

  return result;
};

export const isManager = (employee?: DBEmployeeUser) => {
  return Boolean(employee?.roles?.manager);
};

export const isClient = (client?: any) => {
  return Boolean(client?.clientId);
};

export const isVendor = (vendor?: DBVendorUser) => {
  return Boolean(vendor?.vendorId);
};

export const getPageFromArray = (pageSize: number, pageNum: number, array: any[]) => {
  const page: any[] = [];
  let i = (pageNum - 1) * pageSize;

  while (array[i] && i <= pageNum * pageSize - 1) {
    page.push(array[i]);
    i++;
  }

  return page;
};

export const compareBy = (field: string, value1: { [key: string]: any }, value2: { [key: string]: any }) => {
  if (value1[field]?.toLowerCase() < value2[field]?.toLowerCase()) return -1;
  if (value1[field]?.toLowerCase() > value2[field]?.toLowerCase()) return 1;
  return 0;
};

export const groupArray = (items: any[], key: string) =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item[key]]: [...(acc[item[key]] || []), item],
    }),
    {},
  );

export const sortBy = (array: { [key: string]: any }[], key: string, isAscOrder = true) =>
  array.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
    const keyA = a[key];
    const keyB = b[key];
    if (keyA < keyB) {
      return isAscOrder ? -1 : 1;
    }
    if (keyA > keyB) {
      return isAscOrder ? 1 : -1;
    }

    return 0;
  });

export const arrayToObj = (array: { [key: string]: any }[], keyField: string) =>
  array.reduce(
    (acc, { [keyField]: key, ...value }) => ({
      ...acc,
      [key]: value,
    }),
    {},
  );

export const bulkPropDelete = (array: { [key: string]: any }[], keyToDelete: string) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  array.map(({ [keyToDelete]: valueToDelete, ...item }) => item);

export const isEmpty = (obj: Record<string, any>) => !Object.keys(obj).length;

export function removeQuotesFromRegex(regexString: string): RegExp {
  // Remove leading and trailing quotes
  if (
    (regexString.startsWith('"') && regexString.endsWith('"')) ||
    (regexString.startsWith("'") && regexString.endsWith("'"))
  ) {
    regexString = regexString.slice(1, -1);
  }

  return new RegExp(regexString);
}
