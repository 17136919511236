import React from "react";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { Icon, PaginationItem } from "../styles";

interface PaginationNavigationProps {
  isMobile: boolean;
  currentPage: number;
  lastPage: number;
  onPageChange: (page: number) => void;
  isNext?: boolean;
}

export const PaginationNavigation = ({
  isMobile,
  currentPage,
  lastPage,
  onPageChange,
  isNext = false,
}: PaginationNavigationProps) => {
  const handleClick = () => {
    onPageChange(isNext ? currentPage + 1 : currentPage - 1);
  };

  return (
    <PaginationItem
      $isMobile={isMobile}
      disabled={isNext ? currentPage === lastPage : currentPage === 1}
      onClick={handleClick}
    >
      <Icon
        disabled={isNext ? currentPage === lastPage : currentPage === 1}
        icon={isNext ? faChevronRight : faChevronLeft}
      />
    </PaginationItem>
  );
};
