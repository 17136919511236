import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageNameHeader } from "src/redux/actions/header";
import { getUserData } from "src/redux/selectors/user";
import ClientWorkRequests from "./ClientWorkRequests";
import ManagerWorkRequests from "./ManagerWorkRequests";
import VendorWorkRequests from "./VendorWorkRequests";
import TicketsContainer from "src/components/molecules/TicketsContainer";

const WorkRequests = () => {
  const { isManager, isVendor } = useSelector(getUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageNameHeader("Work Requests"));
  }, []);
  if (isManager) {
    return <ManagerWorkRequests renderTable={<TicketsContainer isManager={true} />} />;
  } else if (isVendor) {
    return <VendorWorkRequests renderTable={<TicketsContainer isVendor={true} />} />;
  } else {
    return <ClientWorkRequests renderTable={<TicketsContainer />} />;
  }
};

export default WorkRequests;
