import React from "react";
import { AutoSizer, List } from "react-virtualized";
import { Container } from "./styles";
import { VirtualizedListProps } from "./types";

const VirtualizedList = ({ rowHeight, rowCount, rowRenderer }: VirtualizedListProps) => {
  return (
    <Container>
      <AutoSizer>
        {({ height, width }) => (
          <List height={height} rowCount={rowCount} rowHeight={rowHeight} rowRenderer={rowRenderer} width={width} />
        )}
      </AutoSizer>
    </Container>
  );
};

export default VirtualizedList;
