import { Location } from "react-router-dom";
import { DropdownItem } from "../../../components/Dropdown/types";
import { DBLocation } from "../../../types";
import { arrayToObj } from "../../../utils";
import { EMPTY_SUPERVISORS_DROPDOWN_OPTION } from "../constants";
import { GeneralInfoFormStateProps, LocationContactFormProps, SupervisorOption } from "./LocationDetailsProvider";

type ExtendedLocation = Location & {
  state: {
    clientName: string | null;
  };
};

export function isLocationWithState(location: Location): location is ExtendedLocation {
  return typeof location.state === "object" && location.state !== null && "clientName" in location.state;
}

export const generateUpdateLocationRequest = ({
  location,
  formState,
  locationId,
}: {
  location: DBLocation;
  formState: GeneralInfoFormStateProps;
  locationId: string;
}): DBLocation => {
  const fileEvacuationPlan = formState.evacuationPlan as File;
  const stringEvacuationPlan = formState.evacuationPlan as string;
  const fileName = fileEvacuationPlan?.name || stringEvacuationPlan;

  //check if previous image name includes location id, save as it is.Otherwise - add location id to the name
  const evacuationPlanImageName =
    fileName && fileName.includes(locationId)
      ? fileName
      : `${locationId}_${fileEvacuationPlan?.name || stringEvacuationPlan}`;

  return {
    ...location,
    evacuationPlan: fileName ? evacuationPlanImageName : fileName,
    assignedSupervisorId: +formState.supervisor?.id < 0 ? null : formState.supervisor?.id,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contacts: arrayToObj(formState.locationContacts?.map(({ isValid, ...contact }) => contact), "id"),
    ...(formState.latitude &&
      formState.longitude && {
        position: {
          lat: formState.latitude,
          long: formState.longitude,
        },
      }),
    infoMessages: formState.infoMessages
      ? Object.entries(formState.infoMessages).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value?.id,
          }),
          {},
        )
      : null,
  };
};

export const generateLocationDetailsFormState = (
  locationObject: DBLocation,
  supervisors: SupervisorOption[],
  messages: DropdownItem[],
) => ({
  evacuationPlan: locationObject?.evacuationPlan,
  supervisor:
    supervisors.find(({ id }) => id === locationObject.assignedSupervisorId) || EMPTY_SUPERVISORS_DROPDOWN_OPTION,
  latitude: locationObject.position?.lat.toString(),
  longitude: locationObject.position?.long.toString(),
  locationContacts: locationObject.contacts
    ? Object.entries(locationObject.contacts).reduce(
        (acc, [key, contact]) => [...acc, { id: key, isValid: true, ...contact }],
        [] as LocationContactFormProps[],
      )
    : ([] as LocationContactFormProps[]),
  infoMessages: locationObject.infoMessages && {
    welcomeText: messages.find(({ id }) => id === locationObject.infoMessages?.welcomeText) || null,
    firstText: messages.find(({ id }) => id === locationObject.infoMessages?.firstText) || null,
    secondText: messages.find(({ id }) => id === locationObject.infoMessages?.secondText) || null,
  },
});
