import React, { useEffect, useState } from "react";
import styled from "styled-components";

import parse from "html-react-parser";
import Button from "src/components/Button";
import { useIsMobile } from "src/hooks/useIsMobile";
import { Attachments } from "src/pages/TicketForm/Attachments";
import { ButtonsApproveClientBlock, iNavigate } from "src/pages/TicketForm/ClientApproval";
import { FirebaseDataService } from "src/services/Firebase/data";
import { MaximoDocument } from "src/types";

interface MainStepProps {
  navigate: iNavigate;
  documents?: MaximoDocument[];
  workOrderId: number;
}

const MainStep = ({ navigate, documents, workOrderId }: MainStepProps) => {
  const [approvalDetailsMessage, setApprovalDetailsMessage] = useState("");
  const { isMobile } = useIsMobile();

  useEffect(() => {
    const getApprovalDetailsMessage = async () => {
      try {
        const { success, data } = await FirebaseDataService.getApprovalDetailsMessage();
        if (success) setApprovalDetailsMessage(data);
      } catch (error) {
        // this error is ignored because Work Requests should work fine even when approve details message is absent at the DB
        setApprovalDetailsMessage("");
      }
    };
    getApprovalDetailsMessage();
  }, []);

  return (
    <>
      <ApprovalDetailsMessage>{parse(approvalDetailsMessage)}</ApprovalDetailsMessage>
      <Attachments documents={documents} workOrderId={workOrderId} />
      <ButtonsApproveClientBlock $isMobile={isMobile}>
        <Button text="Reject" variant="decline" onClick={navigate.reject} />
        <Button text="Approve" variant="primary" onClick={navigate.approve} />
      </ButtonsApproveClientBlock>
    </>
  );
};

const ApprovalDetailsMessage = styled.p`
  margin-bottom: 10px;
`;

export default MainStep;
