import React, { ReactNode, useEffect, useState } from "react";

import { DropdownItem } from "../../../components/Dropdown/types";
import { FirebaseDataService } from "../../../services/Firebase/data";
import { Client, DBClientContact, DBDropdownListSizes, DBEmployeeUser, DBLocation } from "../../../types";
import { sortBy } from "../../../utils";
import { EMPTY_DROPDOWN_MESSAGES_OPTION, EMPTY_SUPERVISORS_DROPDOWN_OPTION } from "../constants";
import { checkFormsValidity } from "../utils";
import LocationDetailsContext from "./context";

export interface LocationContactFormProps extends DBClientContact {
  id: string;
  isValid: boolean;
}

export interface SupervisorOption extends DBEmployeeUser {
  id: string;
  value: string;
}

export interface InfoMessagesFormProps {
  firstText?: DropdownItem | null;
  secondText?: DropdownItem | null;
  welcomeText?: DropdownItem | null;
}

export interface GeneralInfoFormStateProps {
  logo?: File | null;
  latitude?: string;
  longitude?: string;
  supervisor: DropdownItem;
  locationContacts: LocationContactFormProps[];
  infoMessages?: InfoMessagesFormProps | null;
  evacuationPlan?: File | string | null;
  isValid?: boolean;
}

function LocationDetailsContextProvider({ children }: { children: ReactNode }) {
  const [location, setLocation] = useState<DBLocation>({} as DBLocation);
  const [client, setClient] = useState<Client | null>(null);
  const [isGeneralInfoFormSaving, setIsGeneralInfoFormSaving] = useState(false);
  const [generalInfoFormState, setGeneralInfoFormState] = useState<GeneralInfoFormStateProps>(
    {} as GeneralInfoFormStateProps,
  );
  const [supervisors, setSupervisors] = useState<SupervisorOption[]>([]);
  const [messages, setMessages] = useState<DropdownItem[]>([]);
  const [isSupervisorsLoaded, setIsSupervisorsLoaded] = useState(false);
  const [isMessagesLoaded, setIsMessagesLoaded] = useState(false);
  const [dropdownListSizes, setDropdownListSizes] = useState({} as DBDropdownListSizes);

  const getDropdownListSizes = async () => {
    try {
      const { data } = await FirebaseDataService.getDropdownListSizes("locationDetails");
      if (data) setDropdownListSizes(data);
      // If there is no value from DB, it will be handled in SelectSearch component with default value for listSize
    } catch {}
  };
  const [isGeneralInfoFormValid, setIsGeneralInfoFormValid] = useState(true);

  const getSupervisors = async () => {
    try {
      const { data } = await FirebaseDataService.getSupervisors();
      if (data)
        setSupervisors([
          EMPTY_SUPERVISORS_DROPDOWN_OPTION,
          ...sortBy(
            data.map(({ employeeId, firstName, lastName, ...supervisor }) => ({
              ...supervisor,
              id: employeeId,
              value: `${firstName} ${lastName}`,
            })),
            "value",
          ),
        ] as SupervisorOption[]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSupervisorsLoaded(true);
    }
  };

  const getInfoMessages = async () => {
    try {
      const {
        data: { globalMessages },
      } = await FirebaseDataService.getInfoMessages();

      setMessages([
        EMPTY_DROPDOWN_MESSAGES_OPTION,
        ...(globalMessages || []).map((message) => ({
          ...message,
          value: message.label,
        })),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsMessagesLoaded(true);
    }
  };

  useEffect(() => {
    getSupervisors();
    getInfoMessages();
    getDropdownListSizes();
  }, []);

  useEffect(() => {
    setIsGeneralInfoFormValid(
      !!generalInfoFormState.isValid && checkFormsValidity(generalInfoFormState.locationContacts),
    );
  }, [generalInfoFormState]);

  return (
    <LocationDetailsContext.Provider
      value={{
        location,
        setLocation,
        client,
        setClient,
        isGeneralInfoFormSaving,
        setIsGeneralInfoFormSaving,
        generalInfoFormState,
        setGeneralInfoFormState,
        dropdownListSizes,
        setDropdownListSizes,
        supervisors,
        messages,
        isSupervisorsLoaded,
        isMessagesLoaded,
        isGeneralInfoFormValid,
        setIsGeneralInfoFormValid,
      }}
    >
      {children}
    </LocationDetailsContext.Provider>
  );
}

export default LocationDetailsContextProvider;
