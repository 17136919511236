import React from "react";
import Button from "src/components/Button";
import { Header, Title } from "../styles";
import { FullItemHeaderProps } from "../types";

export const FullItemHeader = ({ name, caption, index, onDelete }: FullItemHeaderProps) => {
  return (
    <Header>
      <Title>{`${caption ?? name} ${index + 1}:`}</Title>

      <Button text={`Delete ${name}`} variant="text" onClick={onDelete} />
    </Header>
  );
};
