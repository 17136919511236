import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COUNT_OF_SERVICE_TYPE } from "src/pages/WorkRequests/constants";
import { getUserData } from "src/redux/selectors/user";
import { ServiceTypeList } from "src/types";
import Header from "./components/Header";
import WorkRequestTabs from "./components/WorkRequestTabs";
import { fetchDataHandler } from "./handler";
import { Container } from "./styles";
import { WorkRequestContainerProps } from "./types";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const WorkRequestContainer = ({
  isBreadCumVisible,
  isCreateNewWorkRequestVisible,
  isServiceTypeVisible,
  tabs,
  isRedirectToDetailPageAllowed,
  renderTable,
}: WorkRequestContainerProps) => {
  const { id: loggedInUserId, isManager, isVendor, clientId } = useSelector(getUserData);
  const { setActiveTab, activeTab } = useContext(WorkRequestListContext);
  const [siblingCount, setSiblingCount] = useState(0);
  const [serviceTypes, setServiceTypes] = useState<ServiceTypeList>();
  const [countOfServiceType, setCountOfServiceType] = useState<number>(COUNT_OF_SERVICE_TYPE);
  const [inited, setInited] = useState(false);
  const [pageSize, setPageSize] = useState<number>(0);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchDataHandler({
      loggedInUserId,
      isManager,
      isVendor,
      clientId,
      setSiblingCount,
      setServiceTypes,
      setCountOfServiceType,
      setPageSize,
      setInited,
      dispatch,
    });
  }, []);
  useEffect(() => {
    if (!activeTab) {
      setActiveTab(activeTab ?? tabs[0].eventKey);
    }
  }, [activeTab]);

  return (
    <Container>
      <Header
        isBreadCumVisible={isBreadCumVisible}
        isCreateNewWorkRequestVisible={isCreateNewWorkRequestVisible}
        isServiceTypeVisible={isServiceTypeVisible}
        countOfServiceType={countOfServiceType}
        serviceTypes={serviceTypes}
        inited={inited}
      />
      {inited && (
        <WorkRequestTabs
          siblingCount={siblingCount}
          tabs={tabs}
          activeTab={activeTab ?? tabs[0].eventKey}
          setActiveTab={setActiveTab}
          pageSize={pageSize}
          isRedirectToDetailPageAllowed={isRedirectToDetailPageAllowed}
          renderTable={renderTable}
        />
      )}
    </Container>
  );
};

export default WorkRequestContainer;
