import React from "react";
import DataTable from "src/components/Table";
import TableRow from "src/components/Table/TableRow";
import { StyledCell } from "../styles";
import { ClientsTableProps } from "../types";

export const FormTable = ({ isLoading, headers, data, onRowClick }: ClientsTableProps) => {
  return (
    <DataTable isLoading={isLoading} headers={headers}>
      <>
        {data?.map((item, index) => {
          return (
            <TableRow onClick={() => onRowClick(item.id)} $clickable key={item.id}>
              <StyledCell>{index + 1}</StyledCell>
              <StyledCell>{item.name}</StyledCell>
            </TableRow>
          );
        })}
      </>
    </DataTable>
  );
};
