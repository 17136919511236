import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { IMAGES } from "src/assets";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { getUserData } from "src/redux/selectors/user";
import { colors } from "src/styles";
import FlyDown from "../FlyDown";

import { faCog, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLoadProfilePicture } from "src/hooks/useLoadProfilePicture";
import { logOutUser } from "src/redux/actions/user";
import { getOnSiteHeader, getPageNameHeader } from "src/redux/selectors/header";
import { FirebaseAuthService } from "src/services/Firebase/auth";
import { debugError } from "src/utils/log";
import QuickSearch from "./QuickSearch";

interface HeaderProps {
  name: string;
  logo: string;
  noLink?: boolean;
  noUserLoginPage?: boolean;
}

const Header: React.FC<HeaderProps> = ({ name, logo, noLink, noUserLoginPage }) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [profileLogo, setProfileLogo] = useState("");

  const { firstName, lastName, photo, profilePicture, isManager, id } = useSelector(getUserData);
  const pageName = useSelector(getPageNameHeader);
  const isOnSite = useSelector(getOnSiteHeader);

  /**
   * profilePicture - if logged in user is manager
   * photo - if logged in user is client
   */
  const { profileImageData } = useLoadProfilePicture(profilePicture || photo);

  useEffect(() => {
    setProfileLogo(profileImageData);
  }, [profilePicture, photo, profileImageData]);

  const isTeamMemberPage = pathname.includes("team-members/");

  const handleLogout = async () => {
    try {
      await FirebaseAuthService.signOut();
      dispatch(logOutUser());
      navigate("/");
    } catch (error) {
      debugError(error);
    }
  };
  const redirectLogoHandler = () => {
    if (!noLink) {
      navigate("/", {
        state: {
          folderId: null,
          folderStack: [],
          parentTile: "",
        },
      });
    }
  };

  return (
    <Container $isMobile={isMobile}>
      <div>
        {noUserLoginPage ? (
          <img src={IMAGES.EXPANDED_LOGO_WHITE} width={200} />
        ) : (
          <PageName>
            {pageName}
            {isTeamMemberPage && pageName.trim() && (
              <OnSiteStatus isonsite={isOnSite ? 1 : 0}>{isOnSite ? "• On Site " : "• Not On Site "}</OnSiteStatus>
            )}
          </PageName>
        )}
      </div>
      <RightContainer>
        {!noUserLoginPage && (
          <>
            {isManager && <QuickSearch managerId={id.toString()} />}
            <FlyDown userInfo={{ firstName, lastName, photo: profileLogo }}>
              {isManager && <FlyDownItemManager as={"div"}>Manager</FlyDownItemManager>}
              <>
                <FlyDownItem ismanager={isManager ? 1 : 0} onClick={() => navigate("/change-password")}>
                  <Icon icon={faCog as any} /> Change Password
                </FlyDownItem>
                <FlyDownItem onClick={handleLogout}>
                  <Icon icon={faSignOut as any} />
                  Sign Out
                </FlyDownItem>
              </>
            </FlyDown>
          </>
        )}
        {!noUserLoginPage && <Divider />}
        {logo ? (
          <UserLogo src={logo} onClick={redirectLogoHandler} />
        ) : (
          name && <Title onClick={redirectLogoHandler}>{name}</Title>
        )}
      </RightContainer>
    </Container>
  );
};

const Container = styled.header<MobileProps>`
  display: flex;
  height: 80px;
  position: fixed;
  top: 0;
  width: inherit;
  max-width: inherit;
  background: ${colors.white.default};
  justify-content: space-between;
  align-items: center;
  z-index: 40;
  padding: 0 30px;
  border-bottom: 1px solid ${colors.grey.light8};
  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-bottom: 1px solid ${colors.grey.light1};
    `};
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.span`
  width: 1px;
  height: 47px;
  background-color: ${colors.grey.light8};
  margin: 0 14px;
`;

const Title = styled.label`
  max-width: 400px;
  font-weight: 800;
  font-size: 16px;
  color: ${colors.black.dark1};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    margin-left: 15px;
    font-size: 22px;
  }
`;

const UserLogo = styled(Image)`
  max-height: 80px;
  height: 100%;
  padding: 10px 0px;
`;

const FlyDownItem = styled(Dropdown.Item)<{ ismanager?: number }>`
  padding: 23px 0 0;
  color: ${colors.black.dark1};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  &:last-child {
    padding-bottom: 10px;
  }
  &:first-child {
    padding-top: ${({ ismanager }) => !ismanager && "15px"};
  }
  &:active,
  &:hover,
  &:focus {
    background-color: inherit;
    color: ${colors.black.dark1};
  }
`;

const FlyDownItemManager = styled(FlyDownItem)`
  padding: 23px 0;
  color: ${colors.grey.dark2};
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid ${colors.grey.light7};
  &:hover {
    background-color: inherit;
    color: ${colors.grey.dark2};
  }
`;

const Icon = styled(FontAwesomeIcon)`
  padding-right: 15px;
  height: 20px;
  color: ${colors.grey.dark};
  cursor: pointer;
`;

const PageName = styled.div`
  font-size: 16px;
  font-weight: 800;
  color: ${colors.grey.dark3};
  display: flex;
  align-items: center;
`;

const OnSiteStatus = styled.p<{ isonsite: number }>`
  font-size: 16px;
  font-weight: 400;
  padding-left: 8px;
  color: ${({ isonsite }) => (isonsite ? `${colors.green.default}` : `${colors.grey.light5}`)};
`;

export default Header;
