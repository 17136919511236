import React from "react";
import { EmployeesSummaryTableRow, StyledSummaryListItem } from "../styles";
import { SummaryListItemProps } from "../types";
import EmployeeSummaryRow from "./EmployeeSummaryRow";

const SummaryListItem = ({ list, listRowProps, onAdjustmentClick }: SummaryListItemProps) => {
  return (
    <StyledSummaryListItem
      key={listRowProps.key}
      direction="horizontal"
      style={listRowProps.style}
      index={listRowProps.index}
    >
      <EmployeesSummaryTableRow key={list[listRowProps.index].employeeId}>
        <EmployeeSummaryRow employeePayrollData={list[listRowProps.index]} onAdjustmentClick={onAdjustmentClick} />
      </EmployeesSummaryTableRow>
    </StyledSummaryListItem>
  );
};

export default SummaryListItem;
