import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge, BadgeProps } from "react-bootstrap";
import { colors } from "src/styles";
import styled from "styled-components";

interface IChipProps extends BadgeProps {
  withAction?: boolean;
  actionHandler?: () => void;
  badgeText: string;
  variant?: "primary" | "text";
}

const Chip = ({ variant = "primary", withAction, actionHandler, badgeText, ...props }: IChipProps) => {
  return (
    <StyledBadge variant={variant} {...props}>
      {badgeText} {badgeText && withAction && <Icon onClick={actionHandler} icon={faTimes as IconProp} />}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 20px;
  &.badge.bg-primary.bg-primary {
    background-color: transparent !important;
    font-size: 14px;
    color: ${colors.grey.dark};
    font-weight: 400;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding-left: 8px;
`;

export default Chip;
