import React from "react";
import { NoTicketsMessage } from "src/components/ListScreen";
import { useIsMobile } from "src/hooks/useIsMobile";
import { DBContract } from "src/types";
import { contractsTableHeaders } from "../../constants";
import { ContractTable } from "../ContractTable";
import { ContractsList } from "../ContractsList";

const VendorContractInfo = ({ contractData }: { contractData: DBContract[] }) => {
  const { isMobile } = useIsMobile();
  if (contractData.length === 0) {
    return <NoTicketsMessage $isMobile={isMobile}>No Blanket PO available in maximo</NoTicketsMessage>;
  }
  return (
    <>
      {isMobile ? (
        <ContractsList data={Object.values(contractData ?? {})} />
      ) : (
        <ContractTable data={Object.values(contractData ?? {})} headers={contractsTableHeaders} />
      )}
    </>
  );
};

export default VendorContractInfo;
