import moment from "moment";
import { isValidDate } from "src/utils/helpers";
import { DatePickerProps } from "./types";

/**
 * Converts the Date value into it;s string representation according to the given format.
 *
 * @param date - Date object received from `react-datepicker`
 * @param dateFormat - date format to convert the date to
 * @param onChange - onChange component prop
 */
export const onChangeHandler = (date: Date | null, dateFormat: string, onChange: DatePickerProps["onChange"]) => {
  onChange?.(isValidDate(date) ? moment(date?.toISOString()).format(dateFormat) : "");
};
