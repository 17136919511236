import { SelectBox, SelectBoxContainer, SelectedItem } from "src/components/Dropdown/styles";
import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const SubmitContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin: 0 10px;
  }
  label {
    margin-left: 5px;
    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 80px;
      `}
  }
  ${ButtonContainer} {
    margin-top: 10px;
  }
`;
export const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectedItem} {
        font-size: 12px;
      }
    `}
  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBox} {
        width: 200px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      label {
        font-size: 12px;
        width: 100px;
      }
      input {
        font-size: 12px;
      }
    `}
    ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `};
`;
export const InputRow = styled.div<MobileProps>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  max-width: 580px;
  width: 100%;
  position: relative;
  & div {
    width: 100%;
  }
  div {
    font-size: 14px;
    p {
      font-style: italic;
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }
  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
    padding-top: 10px;
    ${(props) =>
      props.$isMobile &&
      css`
        font-size: 12px;
        width: 100px;
      `}
  }
  input {
    ${(props) =>
      props.$isMobile &&
      css`
        width: 200px;
      `}
  }
  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
`;
