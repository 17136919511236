export const sortingDirections = [
  {
    value: "Sorting Order: Descending",
    id: 1,
    filter: ["desc"],
  },
  {
    value: "Sorting Order: Ascending",
    id: 2,
    filter: ["asc"],
  },
];

export const sortingOptions = [
  {
    value: "Sort By: Date Created",
    id: 1,
    filter: [["dateCreated", "desc"]],
    onlyManager: false,
  },
  { value: "Sort By: No.", id: 2, filter: [["id", "desc"]], onlyManager: false },
  { value: "Sort By: Client", id: 3, filter: [["client.raw", "asc"]], onlyManager: true },
  { value: "Sort By: Work Request Name", id: 4, filter: [["description.raw", "asc"]], onlyManager: false },
  {
    value: "Sort By: Priority Level",
    id: 8,
    filter: [
      ["priorityId", "asc"],
      ["dateCreated", "desc"],
    ],
    onlyManager: false,
  },
];
