import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import Alert, { AlertVariants } from "src/components/Alert";
import Button from "src/components/Button";
import Input, { InputTypes } from "src/components/Input";
import Logo from "src/components/Logo";
import { VALIDATION_MESSAGES } from "src/constants/validation";
import { getErrorMsg } from "src/helpers/getErrorMsg";
import { MobileProps, useIsMobile } from "src/hooks/useIsMobile";
import { FirebaseAuthService } from "src/services/Firebase/auth";
import { colors } from "src/styles";
import { EMAIL_VALIDATION_PATTERN } from "src/utils/validator";

const ForgotPasswordPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: "all",
  });

  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const onPressBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate("/login", { replace: true });
  };

  const onSubmit = handleSubmit(({ email }) => sendPasswordResetEmail(email));

  const sendPasswordResetEmail = async (email: string) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      const { status, message } = await FirebaseAuthService.sendPasswordResetEmail(email.toLocaleLowerCase());
      if (status === "error") setError(message);
      else setSuccess(true);
    } catch (error) {
      setError(getErrorMsg(error));
    }
    setLoading(false);
  };

  return (
    <Container $isMobile={isMobile}>
      <Logo />

      <Title>Forgot Your Password?</Title>

      <Description>
        Please enter your Email Address and we will send you the instructions on how to reset your password.
      </Description>

      <StyledForm onSubmit={onSubmit}>
        <Input
          controlId="forgot-password-email"
          type={InputTypes.email}
          label="Email Address"
          validation={register("email", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
            pattern: {
              value: EMAIL_VALIDATION_PATTERN,
              message: "Please, enter correct email.",
            },
          })}
        />

        {!!(success || error) && (
          <Alert
            text="Password reset instructions successfully sent. Please, check
                your email."
            variant={AlertVariants.success}
          />
        )}

        <ButtonContainer>
          <Button text="Back to Sign In" variant="text" onClick={(e) => onPressBack(e)} />
          <br />
          <Button text="Send Instructions" type="submit" disabled={!isValid} loading={loading} />
        </ButtonContainer>
      </StyledForm>
    </Container>
  );
};

//#region styles
const Container = styled.div<MobileProps>`
  margin: 0 25%;
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin: 0;
    `}
`;

const Title = styled.label<MobileProps>`
  margin: 25px 0;
  font-weight: 500;
  font-size: 16px;
  color: ${colors.grey.dark};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 12px;
    `}
`;

const Description = styled.label<MobileProps>`
  width: 65%;
  font-weight: 400;
  font-size: 14px;
  color: ${colors.grey.dark};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      font-size: 12px;
      width: 80%;
    `}
`;

const ButtonContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      margin-top: 30px;
      flex-direction: column-reverse;
    `}
`;

const StyledForm = styled(Form)<MobileProps>`
  width: 50%;
  margin-top: 30px;

  & > div {
    margin-bottom: 25px;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      width: 80%;
      margin-top: 20px;
    `}
`;
//#endregion

export default ForgotPasswordPage;
