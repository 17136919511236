import isEqual from "lodash.isequal";
import { Client, DBClientPortalTile, DBKleenwayContact } from "src/types";
import { v4 as uuidv4 } from "uuid";
import { OptionItem } from "../../components/SelectSearch";
import { bulkPropDelete } from "../../utils";
import { ClientPortalUserFormProps, GeneralInfoFormStateProps } from "./ClientDetailsProvider";
import { DEFAULT_TIMEZONE_DROPDOWN_OPTION, EMPTY_DROPDOWN_OPTION } from "./constants";

export const removeIdFields = (tilesToProcessing: { [key: string]: DBClientPortalTile }) => {
  for (const key in tilesToProcessing) {
    delete tilesToProcessing[key].id;
  }
  return tilesToProcessing;
};

export const checkTilesFormsValidity = (forms: DBClientPortalTile[]) => {
  return !forms?.length || forms?.every(({ isValid }) => isValid);
};

export const checkFormsValidity = (forms: { isValid: boolean; [key: string]: any }[]) =>
  !forms?.length || forms.every(({ isValid }) => !!isValid);

export const generateUpdateClientRequest = (client: Client, formState: GeneralInfoFormStateProps): Client => {
  const fileLogo = formState.logo as File;
  const stringLogo = formState.logo as string;

  const checkChanges = (newProps: any, oldProps: any) => {
    return Object.entries(newProps).reduce((acc, [key, value]) => {
      return {
        ...acc,
        ...(key !== "changes" && value !== oldProps[key] && !(key === "enabled" && value === true)
          ? { [key]: value }
          : {}),
      };
    }, {});
  };

  const clientTilesForSend = formState.clientPortal.tiles
    ?.filter((item) => item.tileType === "Client")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ tileType, editMode, isNew, isValid, ...tile }) => tile)
    .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});

  const globalTilesForSend = formState.clientPortal.tiles
    ?.filter((item) => item.tileType === "Global")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ tileType, isNew, isValid, ...tile }) => tile)
    .reduce((acc, cur) => {
      const currentInfo = cur.editMode ? checkChanges(cur, cur.changes) : checkChanges(cur.changes, cur.changes);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { editMode, ...saveInfo } = currentInfo as Record<string, string | number | boolean>;
      return {
        ...acc,
        [cur.id]: { folderId: cur.folderId, ...saveInfo },
      };
    }, {});

  return {
    ...client,
    logo: fileLogo?.name || stringLogo,
    settings: {
      ...(client.settings || {}),
      timezone: isEqual(formState.timezone, EMPTY_DROPDOWN_OPTION)
        ? ""
        : formState.timezone?.labelDB || formState.timezone?.value,
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    contacts: formState.clientContacts?.map(({ isValid, id, ...contact }) => contact),
    kleenwayContacts: formState.kleenwayContacts?.map(({ id }) => id?.toString()),
    infoMessages: Object.entries(formState.infoMessages).reduce(
      (acc, [key, value]) =>
        isEqual(value, EMPTY_DROPDOWN_OPTION)
          ? acc
          : {
              ...acc,
              [key]: value?.id,
            },
      {},
    ),
    ...(formState?.clientPortal?.tiles?.length
      ? {
          clientPortal: {
            tiles: {
              ...removeIdFields(clientTilesForSend),
              ...globalTilesForSend,
            },
          },
        }
      : {}),
  };
};

export const generateGeneralInfoFormState = (
  clientObject: Client,
  timezones: OptionItem[],
  messages: OptionItem[],
  kleenwayContacts: DBKleenwayContact[],
  clientPortalTiles: DBClientPortalTile[],
  clientUsers: ClientPortalUserFormProps[],
  generalInfoFormState: GeneralInfoFormStateProps,
): GeneralInfoFormStateProps => {
  const prefilledTimeZone =
    timezones.find(
      ({ value, labelDB }) =>
        clientObject?.settings?.timezone &&
        (value === clientObject?.settings?.timezone || labelDB === clientObject?.settings?.timezone),
    ) || DEFAULT_TIMEZONE_DROPDOWN_OPTION;

  return {
    ...generalInfoFormState,
    logo: clientObject?.logo,
    timezone: prefilledTimeZone,
    clientContacts:
      clientObject?.contacts
        ?.filter((contact) => contact)
        .map((contact) => ({ id: uuidv4(), isValid: true, ...contact })) || [],
    kleenwayContacts:
      clientObject?.kleenwayContacts
        ?.filter((contact) => contact)
        .map((contact) => ({
          formId: uuidv4(),
          isValid: true,
          ...(kleenwayContacts.find(({ id }) => id === contact) || ({} as DBKleenwayContact)),
        })) || [],
    infoMessages: {
      welcomeText: messages.find(({ id }) => id === clientObject?.infoMessages?.welcomeText) || EMPTY_DROPDOWN_OPTION,
      firstText: messages.find(({ id }) => id === clientObject?.infoMessages?.firstText) || EMPTY_DROPDOWN_OPTION,
      secondText: messages.find(({ id }) => id === clientObject?.infoMessages?.secondText) || EMPTY_DROPDOWN_OPTION,
    },
    clientPortalUsers: clientUsers,
    clientPortal: { tiles: clientPortalTiles },
  };
};

export const removeUniqueFormsFields = (formState: GeneralInfoFormStateProps) => {
  const clientContactsWithoutFormIds = bulkPropDelete(formState.clientContacts, "id");

  const formStateWithoutClientContactsUniqueFields = {
    ...formState,
    clientContacts: bulkPropDelete(clientContactsWithoutFormIds, "isValid"),
  };

  const kleenwayContactsWithoutFormIds = bulkPropDelete(formState.kleenwayContacts, "formId");

  return {
    ...formStateWithoutClientContactsUniqueFields,
    kleenwayContacts: bulkPropDelete(kleenwayContactsWithoutFormIds, "isValid"),
  };
};
