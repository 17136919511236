import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import LoadingContainer from "src/components/LoadingContainer";
import { Tile } from "src/components/Tile";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getUserData } from "src/redux/selectors/user";

import { useNavigate } from "react-router-dom";
import { ServiceTypeItem } from "src/types";
import { ServiceTypeTile } from "./ServiceTypeTile";
import { ServiceTypesTilesProps } from "../types";
import { Subtitle, ServiceTypesRow } from "../styles";

/**
 * A helper component to display the list of service types tiles
 */
export const ServiceTypesTiles = ({ serviceTypes, numberOfTilestoDisplay, isLoading }: ServiceTypesTilesProps) => {
  const { clientId } = useSelector(getUserData);
  const { isMobile } = useIsMobile();
  const [numberOfTilesShown, setNumberOfTilesShown] = useState(numberOfTilestoDisplay);

  const clientServiceTypes = serviceTypes?.[clientId] ?? [];
  const numberOfServiceTypes = clientServiceTypes.length;
  const itemList = clientServiceTypes.slice(0, numberOfTilesShown ?? numberOfServiceTypes);
  const navigate = useNavigate();
  const onShowMoreClick = useCallback(() => {
    setNumberOfTilesShown(numberOfServiceTypes);
  }, [serviceTypes, clientId]);
  const onServiceTypeClick = (serviceType: ServiceTypeItem) => {
    navigate("/workrequests/create", { state: { serviceType } });
  };
  return (
    <>
      <Subtitle $isMobile={isMobile}>Create New Work Request: </Subtitle>

      <ServiceTypesRow>
        <LoadingContainer isLoading={isLoading}>
          {itemList.map((serviceType) => (
            <ServiceTypeTile key={serviceType.id} onClick={onServiceTypeClick} serviceType={serviceType} />
          ))}
          {numberOfTilesShown < numberOfServiceTypes && (
            <Tile key="1" title="Show All" icon={"faEllipsisH"} onClick={onShowMoreClick}></Tile>
          )}
        </LoadingContainer>
      </ServiceTypesRow>
    </>
  );
};
