import React from "react";
import { CustomTimeInputProps } from "../types";

/**
 * Returns a custom time input component that:
 * - receives props from `react-datepicker` in type-safe way
 * - attaches `react-hook-form` ref to the input, so it can be correcly focused on validation error
 */

const CustomTimeInput: React.FC<CustomTimeInputProps> = ({ value, onChange, name }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return <input name={name} type="time" value={value} onChange={handleChange} />;
};

export default CustomTimeInput;
