import React from "react";
import { Controller } from "react-hook-form";
import Input from "../../../../components/Input";
import { SalaryAmountInputProps } from "../types";

/**
 * A helper component to render the salary amount input box
 */
export const SalaryAmountInput = ({ form }: SalaryAmountInputProps) => {
  return (
    <Controller
      control={form.control}
      name={"salaryAmount"}
      rules={{
        pattern: { value: /^\d+(\.\d+)?$/, message: "Please enter decimal value." },
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
        <Input ref={ref} name={name} onChange={onChange} value={value ?? 0} error={error} />
      )}
    />
  );
};
