import { NoTicketsMessage } from "src/components/ListScreen";
import { colors } from "src/styles";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.grey.dark};
`;

export const WarningMessage = styled(NoTicketsMessage)`
  margin: 20px;
`;

export const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  padding-right: 40px;
  white-space: nowrap;
`;

export const TemplateContainer = styled.div`
  padding: 20px;
`;
