import React from "react";

import { ListContainer, ListHeading, ListSubtitle, Wrapper } from "../../styles";
import { ContactProps } from "../../types";

export const Contact = ({ contact }: ContactProps) => {
  const { email, name, phone, position } = contact;
  return (
    <ListContainer>
      <Wrapper>
        <ListHeading>{name}</ListHeading>
        <ListSubtitle>
          <p>{email}</p>
        </ListSubtitle>
        <ListSubtitle>
          <p>{position}</p>
        </ListSubtitle>
        <ListSubtitle>
          <p>{phone}</p>
        </ListSubtitle>
      </Wrapper>
    </ListContainer>
  );
};
