import { useEffect, useState } from "react";
import { MAX_MOBILE_RESOLUTION } from "src/config/сonstants";

export interface MobileProps {
  $isMobile?: boolean;
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleResolutionChange = () => {
    setIsMobile(window.innerWidth <= MAX_MOBILE_RESOLUTION);
  };

  useEffect(() => {
    handleResolutionChange();
    window.addEventListener("resize", handleResolutionChange);
    screen.orientation?.addEventListener && screen.orientation.addEventListener("change", handleResolutionChange);
    return () => {
      window.removeEventListener("resize", handleResolutionChange);
      screen.orientation?.removeEventListener &&
        screen.orientation.removeEventListener("change", handleResolutionChange);
    };
  }, []);
  return { isMobile };
};
