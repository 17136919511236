import React from "react";

import { Container, TooltipContainer, TooltipText } from "./styles";
import { TooltipProps } from "./types";

const TooltipComponent: React.FC<TooltipProps> = ({ text, renderChildren }) => (
  <TooltipContainer>
    <Container text={text}>
      {renderChildren}
      <TooltipText>{text}</TooltipText>
    </Container>
  </TooltipContainer>
);
export default TooltipComponent;
