import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "react-bootstrap";
import { colors } from "src/styles";
import styled, { css } from "styled-components";

const evenRowIndex = 2;

export const EmployeesTableRow = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
`;
export const EmployeesSummaryTableRow = styled(EmployeesTableRow)`
  grid-template-columns: repeat(8, 1fr);
  :last-child {
    margin-bottom: 0px;
  }
`;
export const StyledSummaryListItem = styled(Stack)<{ isselected: boolean }>`
  background: ${(props) => (props.index % evenRowIndex === 0 ? colors.grey.light6 : colors.grey.light2)};
  justify-content: center;
  align-items: center;

  ${({ isselected }) =>
    isselected &&
    css`
      border: 1px solid ${colors.kleenway.greenLight};
      background: ${colors.kleenway.greenLight};
    `}
`;

export const UserIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  padding: 5px;
  color: #6c6c6c;
`;

export const TextContainer = styled(Stack)`
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.grey.dark};
`;
export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EmployeesTableHeader = styled.div`
  position: sticky;
  top: 80px;
  z-index: 4;
  background-color: ${colors.grey.default};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
  margin-top: 20px;
`;
export const SummaryEmployeesTableHeader = styled(EmployeesTableHeader)`
  grid-template-columns: repeat(8, 1fr);
`;

export const EditCellStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;
export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .accordion:not(:last-of-type) {
    margin-bottom: 10px;
  }
  .accordion-button {
    background-color: unset;
    border-color: ${colors.grey.light4};
    box-shadow: 0 0 0 0.5px ${colors.grey.light4};
    color: ${colors.grey.dark};
    font-weight: 500;
  }

  .accordion-button:not(.collapsed) {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
  .accordion .accordion-item,
  .accordion .accordion-item .accordion-button,
  .accordion .accordion-item .accordion-button.collapsed {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .accordion .accordion-item .accordion-button {
    background-color: ${colors.grey.light2};
  }
  .accordion .accordion-button {
    background-color: ${colors.grey.light2};
  }
`;
export const EmployeesTableHeaderCell = styled.span`
  color: ${colors.grey.dark};
  font-weight: 500;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
`;
export const TableCell = styled.div`
  font-weight: 400;
  font-size: 14px;
`;
export const EmployeesTableCell = styled(TableCell)`
  color: ${colors.grey.dark};
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const StarIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  padding: 0 4px 1px 4px;
`;
