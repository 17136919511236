export default class ValidateData {
  static password(value = "", firstName = "", lastName = "") {
    const testNames = new RegExp(firstName + "|" + lastName, "gi");
    const firstCondition = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const secondCondition = /(.)\1{2,}/;

    return firstCondition.test(value) && !secondCondition.test(value) && !testNames.test(value);
  }
}

// a good validation pattern from https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
export const EMAIL_VALIDATION_PATTERN =
  /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/gi;
