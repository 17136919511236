import React from "react";

import { IMAGES } from "src/assets";

import styled from "styled-components";

const Logo: React.FC = () => {
  return (
    <>
      <Img src={IMAGES.APP_LOGO} alt="Kleenway" />
    </>
  );
};

const Img = styled.img`
  height: 160px;
`;

export default Logo;
