import { Dispatch } from "redux";
import { setTickets } from "src/redux/actions/workRequest";
import { FirebaseDataService, Response } from "src/services/Firebase/data";
import { Ticket } from "src/types";
import { AssignWOResourceFormValues } from "../AssignWOResource/types";

export const onAssignResourceHandler = async (
  workOrder: AssignWOResourceFormValues,
  setAssignResourceErrorOnSubmit: React.Dispatch<React.SetStateAction<string>>,
  tickets: Ticket[],
  dispatch: Dispatch,
  userId: string,
  workOrderId: string,
  setSelectedTicketForAssignResource: React.Dispatch<React.SetStateAction<Ticket | undefined>>,
) => {
  setAssignResourceErrorOnSubmit("");

  try {
    const res = await onSubmitAssignResourceHandler(workOrder, workOrderId, userId);

    if (res && res.status === "success") {
      const updatedTickets = tickets.map((ticket) => {
        if (ticket.wonum === workOrderId) {
          const updatedTicket = { ...ticket };

          updatedTicket["vendorId"] = workOrder.vendors.vendorId;
          updatedTicket["laborId"] = workOrder.employees.laborCode;
          updatedTicket["woacceptscharges"] = workOrder.vendors.woacceptscharges;
          updatedTicket["approvedamount"] = workOrder.vendors.approvedamount;
          return updatedTicket;
        }
        return ticket;
      });

      dispatch(setTickets(updatedTickets));
      setSelectedTicketForAssignResource(undefined);
    } else {
      setAssignResourceErrorOnSubmit(res?.message ?? "An error occurred");
    }
  } catch (error) {
    setAssignResourceErrorOnSubmit(`An error occurred while assigning resources: ${error}`);
  }
};

const onSubmitAssignResourceHandler = async (
  data: AssignWOResourceFormValues,
  workOrderId: string,
  managerId: string,
): Promise<Response | undefined> => {
  if (workOrderId) {
    return FirebaseDataService.assignResourceInWorkOrder({
      wonum: workOrderId,
      approvedamount: data.vendors.approvedamount,
      vendorId: data.vendors.vendorId,
      woacceptscharges: data.vendors.woacceptscharges,
      laborCode: data.employees.laborCode,
      managerId,
    });
  }
};
