import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faDownload, faPlus, faUpload } from "@fortawesome/pro-light-svg-icons";
import Uploady from "@rpldy/uploady";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import Button from "src/components/Button";
import ModalUpload from "src/components/ModalUpload";
import Paper from "src/components/Paper";
import { Tile } from "src/components/Tile";
import { MobileProps } from "src/hooks/useIsMobile";
import { FirebaseDataService } from "src/services/Firebase/data";
import { colors } from "src/styles";
import { DBClientPortalTile, IBreadCrumb, ID, IOmegaItem } from "src/types";
import { debugError, debugLog } from "src/utils/log";
import styled from "styled-components";
import { RenameModalProps } from "../ClientDetailsProvider";
import ClientDetailsContext from "../context";
import { Box, DropResult } from "./DndBox";
import { FileItem } from "./FileItem";
import { FileModal, ModalTypeFile } from "./Modal/FileModal";

export interface Item {
  id: number;
  text: string;
}
export interface ContainerState {
  cards: IOmegaItem[];
}

const emptyStyles = {
  width: "calc(100% - 30px)",
  height: "40px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  background: `${colors.yellow.light}`,
  border: `1px solid ${colors.yellow.default}`,
  borderRadius: "5px",
  padding: "10px 24px",
  marginLeft: "30px",
};

interface LocationState {
  folderId: ID;
  parentTile: string;
  folderStack: { id: number | null; name: string }[];
}

export const FileSharing = () => {
  const { modalRename, setModalRename, folderContent, setFolderContent } = useContext(ClientDetailsContext);
  const navigate = useNavigate();

  const location = useLocation();
  const locationState = location.state as LocationState;
  const currentFolder = locationState?.folderId;
  const folderStack = locationState?.folderStack;
  const parentTile = locationState?.parentTile;

  const { id: clientId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [tiles, setTiles] = useState<DBClientPortalTile[]>([]);
  const [modalUploadState, setModalUploadState] = useState(false);

  const [clickedAction, setClickedAction] = useState("");

  const sortFolders = (items: IOmegaItem[]) => {
    return items.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, ignorePunctuation: true }),
    );
  };

  //Get a list of folders that the user has
  const getData = async () => {
    setTiles([]);
    setFolderContent([]);
    setLoading(true);
    try {
      if (currentFolder) {
        const {
          data: { children },
        } = await FirebaseDataService.getFolderContent(currentFolder);

        return setFolderContent(children || []);
      }
      if (!clientId) {
        return;
      }
      const { data } = await FirebaseDataService.getClientFolders(clientId);
      setTiles(data || []);
      return;
    } catch (error) {
      debugError(error);
    } finally {
      setLoading(false);
    }
  };

  /*
    Redirect to folder after clicking on the tiles page, 
    if the folder does not have an ID, an error in the console 
  */
  const onClickTile = (tile: DBClientPortalTile) => {
    if (!tile.folderId) {
      debugLog("Folder tile without FolderId");
      return;
    }

    navigate(`/clients/${clientId}`, {
      state: {
        folderId: tile.folderId,
        folderStack: [
          { id: null, name: "Home" },
          { id: tile.folderId, name: tile.title || tile.folder },
        ],
        parentTile: tile.title,
      },
    });
  };

  /* 
    Donwload file
    el - download file ID
    action - download or view
  */
  const downloadFileClick = async (el: ID, action: string) => {
    setLoadingFile(true);
    try {
      const { data: url } = await FirebaseDataService.getFileUrl(el, action);
      window.open(url);
    } catch (e) {
      return debugLog(e);
    }
    return setLoadingFile(false);
  };

  /*
    Redirect to folder after clicking on the folder row, 
    if the folder does not have an ID, an error in the console 
  */
  const onFolderClick = async (el: IOmegaItem) => {
    if (!el.id) {
      debugLog("Folder tile without FolderId");
      return;
    }

    navigate(`/clients/${clientId}`, {
      state: {
        parentTile,
        folderId: el.id,
        folderStack: [...folderStack, { id: el.id, name: el.name }],
      },
    });
  };

  /* 
    Donwload folder
    el - download folder ID
  */
  const downloadFolderClick = async (el: ID) => {
    setLoadingFile(true);
    try {
      const { data: url } = await FirebaseDataService.downloadOmegaFolder(el);
      window.open(url);
    } catch (e) {
      return debugLog(e);
    }
    return setLoadingFile(false);
  };

  /* 
    Create new folder
    folderId - id of the folder where the creation is in progress
    name - the name of the folder to be created
  */
  const createFolderClick = async (folderId: ID, name: string) => {
    setLoadingFile(true);
    try {
      return await FirebaseDataService.createOmegaFolder(folderId, name);
    } catch (e) {
      return e;
    } finally {
      setLoadingFile(false);
    }
  };

  /* 
    Rename file or folder
    elemId - the id of the element to be rename
    name - new name of Element
    type - type of element to be renamed (file or folder) 
  */
  const renameElementClick = async (elemId: ID, name: string, type: string) => {
    setLoadingFile(true);
    try {
      return await FirebaseDataService.renameOmegaElement(elemId, name, type);
    } catch (e) {
      return e;
    } finally {
      setLoadingFile(false);
    }
  };

  /* 
    Delete file or folder
    elemId - the id of the element to be delete
    type - type of element to be removed (file or folder) 
  */
  const deleteElementClick = async (elemId: ID, type: string) => {
    setLoadingFile(true);
    try {
      await FirebaseDataService.deleteOmegaElement(elemId, type);
    } catch (e) {
      return debugLog(e);
    }
    return setLoadingFile(false);
  };

  /* 
    Move file or folder to another folder
    dragId - the id of the element to be dragged
    propId - id of the folder where the file or folder will be dropped
    type - type of dragged element (file or folder) 
  */
  const moveElementByDragAndDrop = async (dragId: ID, dropId: ID, type: string) => {
    setLoadingFile(true);
    try {
      await FirebaseDataService.moveOmegaElement(dragId, dropId, type);
    } catch (e) {
      return debugLog(e);
    }
    return setLoadingFile(false);
  };

  /* Update the displayed elements after performing actions on the elements */
  const updateFilesList = (newItem: IOmegaItem, pos: number) => {
    const updatedFilesList = [...folderContent.slice(0, pos), newItem, ...folderContent.slice(pos + 1)];
    setFolderContent(updatedFilesList);
  };

  /* 
    The main actions of the modal window (create, rename, delete) are passed here. 
    This function processes their clicking and performs actions depending on the type of modal window. 
  */
  const onApproveClick = async (modalType: ModalTypeFile) => {
    const { id, type, name } = modalRename;

    if (modalType === "rename" && id && name && type) {
      let errorMessage = "";
      const resp = await renameElementClick(id, name.trim(), type);

      if (resp.status === "error") {
        errorMessage = resp.message;
        setModalRename({ ...modalRename, error: errorMessage });
        return;
      }

      const index = folderContent.findIndex((item) => item.id == id);
      updateFilesList({ id, type, index, name: name.trim() }, index);
      setModalRename({});
    }

    if (modalType === "delete" && id && type) {
      deleteElementClick(id, type);
      const index = folderContent.findIndex((item) => item.id === id);
      setFolderContent([...folderContent.slice(0, index), ...folderContent.slice(index + 1)]);
      setModalRename({});
    }

    if (modalType === "create" && name) {
      let errorMessage = "";
      const resp = await createFolderClick(currentFolder, name);

      if (resp.status === "error") {
        errorMessage = resp.message;
        setModalRename({ ...modalRename, error: errorMessage });
        return;
      }

      const newFolderContent = sortFolders([
        ...folderContent,
        {
          name,
          type: "folder",
          id: resp.data.id,
          index: folderContent.length,
        },
      ]);

      setFolderContent(newFolderContent);
      setModalRename({});
    }
  };

  /* Navigating folders when clicking on breadcrumbs */
  const onClickBreadCrumb = (id: ID | null) => {
    if (currentFolder === id) {
      return;
    }

    !id && setFolderContent([]);

    const currentIndex = folderStack.findIndex((item: IBreadCrumb) => item.id === id);
    const newFolderStack = folderStack.slice(0, currentIndex ? currentIndex + 1 : 0);

    navigate(`/clients/${clientId}`, {
      state: {
        folderId: id,
        folderStack: newFolderStack,
        parentTile: currentIndex ? parentTile : "",
      },
    });
  };

  useEffect(() => {
    navigate(`/clients/${clientId}`, {});
  }, []);

  useEffect(() => {
    getData();
  }, [currentFolder]);

  /* 
    Displaying a modal window depending on its type 
    id - id of file or folder to which the action applies
    name - name of file of folder to which the action applies
    type - file or folder
    modalType - type of modal action (create, rename, remove)
  */
  const showModal = ({ id, name, type, modalType }: RenameModalProps) => {
    setModalRename(() => {
      return { isVisible: true, name, type, id, modalType };
    });
  };

  /* 
    idDrag - id of file or folder to drag
    typeDrag - type (file or folder) to drag
    idDrop - id of folder to drop file or folder
    typeDrop - already folder
  */
  const onDragEnd = ({ idDrag, typeDrag, idDrop }: DropResult) => {
    moveElementByDragAndDrop(idDrag, idDrop, typeDrag);
    const index = folderContent.findIndex((item) => item.id === idDrag);
    setFolderContent([...folderContent.slice(0, index), ...folderContent.slice(index + 1)]);
  };

  return (
    <Container>
      {!!folderStack?.length && <BreadCrumbs folderStack={folderStack} onClickItem={onClickBreadCrumb} />}

      {(loadingFile || loading) && (
        <EmptyContainer>
          <Spinner animation="border" />
        </EmptyContainer>
      )}

      {!!currentFolder && !folderContent.length && !loading && (
        <EmptyContainer>
          <Paper containerStyle={emptyStyles}>
            <Title>This folder is empty.</Title>
          </Paper>
        </EmptyContainer>
      )}

      <FileModal onApproveClick={(modalType) => onApproveClick(modalType)} />
      <Uploady
        destination={{
          url: `${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-uploadFilesToOmega`,
        }}
        autoUpload={false}
        sendWithFormData
        webkitdirectory={clickedAction === "folders" ? true : undefined}
      >
        <ModalUpload
          onCancelClick={() => setModalUploadState(false)}
          title="Upload Files"
          approveButtonStyle="small-primary"
          cancelButtonStyle="text"
          buttonsFlexDirection="row"
          isShowing={modalUploadState}
          rootFolderId={currentFolder}
          setClickedAction={setClickedAction}
          getData={getData}
        />
      </Uploady>

      <Content>
        {currentFolder ? (
          <DndProvider backend={HTML5Backend}>
            {folderContent.map((item) => {
              const { id, type } = item;
              return (
                <Box
                  key={id}
                  idDrag={id}
                  idDrop={id}
                  typeDrag={type}
                  typeDrop={type}
                  onDragEnd={(dropResults) => onDragEnd(dropResults)}
                >
                  <FileItem
                    item={item}
                    disabledButtons={loading}
                    onItemClick={() => (type !== "file" ? onFolderClick(item) : "")}
                    onEditClick={(itemModal) => showModal(itemModal)}
                    onDownloadClick={() =>
                      type === "file" ? downloadFileClick(id, "download") : downloadFolderClick(id)
                    }
                    onDeleteClick={(itemModal) => showModal(itemModal)}
                  />
                </Box>
              );
            })}
          </DndProvider>
        ) : (
          tiles.map((tile) => (
            <Tile
              key={tile.id}
              title={tile.title || tile.folder || ""}
              icon={tile.icon}
              onClick={() => onClickTile(tile)}
            />
          ))
        )}
      </Content>
      {!!currentFolder && (
        <ButtonsContainer>
          <Button
            disabled={loading}
            text={"Upload Files"}
            variant={"dotted-border-light"}
            icon={faUpload as IconDefinition}
            iconPosition={"left"}
            onClick={() => setModalUploadState(true)}
          />
          <Button
            disabled={loading}
            text={"Create Folder"}
            variant={"dotted-border-light"}
            icon={faPlus as IconDefinition}
            iconPosition={"left"}
            onClick={() => showModal({ modalType: "create", type: "folder" })}
          />
          <div style={{ marginLeft: "auto" }}></div>
          <Button
            disabled={loading}
            text={"Download Folder"}
            variant={"dotted-border-light"}
            icon={faDownload as IconDefinition}
            iconPosition={"left"}
            onClick={() => downloadFolderClick(currentFolder)}
          />
        </ButtonsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;

  & > :first-child {
    margin-left: 0;
  }
`;

const EmptyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: baseline;
  flex-wrap: wrap;
  height: inherit;
  padding: 15px 0 30px;

  a {
    margin-bottom: 30px;
    margin-right: 30px;
  }
`;

const Title = styled.label`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.grey.dark};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ButtonsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
`;
