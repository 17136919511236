import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { colors } from "../../../../styles";
import ModalView from "../../../ModalView";

export interface PhotoContainerProps {
  image: any;
}

export const PhotoContainer = ({ image }: PhotoContainerProps) => {
  const { isMobile } = useIsMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const body = document.body;
  const [loading, setLoading] = useState(false);

  return (
    <StyledPhotoContainer $isMobile={isMobile}>
      <img
        src={image}
        alt="Image"
        style={loading ? {} : { display: "none" }}
        onClick={() => {
          body.style.overflow = "hidden";
          setModalOpen(true);
        }}
        onLoad={() => setLoading(true)}
      />
      <ModalView modalOpen={modalOpen}>
        <CloseButton
          icon={faTimes as any}
          onClick={() => {
            body.style.overflow = "initial";
            setModalOpen(false);
          }}
        />
        <img style={{ maxWidth: "100vw", maxHeight: "100vh" }} src={image} alt="Image" />
      </ModalView>
    </StyledPhotoContainer>
  );
};

const CloseButton = styled(FontAwesomeIcon)`
  padding: 0;
  position: absolute;
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  background-color: #aaa;
  border: none;
  outline: none;
  z-index: 4;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

export interface StyledPhotoContainerProps {
  $isMobile: boolean;
}

const StyledPhotoContainer = styled.div<StyledPhotoContainerProps>`
  position: relative;

  img {
    max-height: 100px;
    max-width: 100px;
    object-fit: fill;
  }

  img:hover {
    cursor: pointer;
  }

  ${Icon} {
    width: 20px;
    height: 20px;

    ${(props) => props.$isMobile && `width: 15px; height: 15px;`}
  }
`;
