import { FirebaseDataService, NoAuthDataForTicketCreatePageResponse } from "src/services/Firebase/data";
import {
  Client,
  DBLocation,
  IServiceTypeQuestion,
  MaximoDocument,
  PriorityLevelList,
  ServiceTypeItem,
  ServiceTypeList,
  WorkType,
} from "src/types";
import { debugError } from "src/utils/log";
import { CustomWorkRequestFieldList, FormData } from "./types";
import { getBase64File } from "src/utils/convertToBase64";
import moment from "moment-timezone";
import { UseFormReturn } from "react-hook-form";
import { DropdownItem } from "src/components/Dropdown/types";
import { objToArray } from "src/utils/objToArray";
import { clearWorkRequestMessage } from "src/redux/actions/workRequest";
import { Dispatch } from "redux";

export const getServiceTypeQuestions = async (
  clientId: string,
  serviceTypeId: string,
  setFormData: React.Dispatch<React.SetStateAction<FormData>>,
  setServiceTypeQuestions: React.Dispatch<React.SetStateAction<IServiceTypeQuestion[]>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
) => {
  try {
    const { data, status, message } = await FirebaseDataService.getServiceTypeQuestions(clientId, serviceTypeId);

    if (status === "error") {
      throw new Error(message);
    }

    const additionalFormData = data.reduce((acc: { [key: string]: string }, item: IServiceTypeQuestion) => {
      return {
        ...acc,
        [item.id]: "",
      };
    }, {});

    setFormData((old) => ({ ...old, serviceTypeQuestions: { ...additionalFormData } }));
    setServiceTypeQuestions(data);
  } catch (error) {
    debugError(error);
    setError(error.message);
  }
};

export const uploadImages = async (workOrderId: string, files: MaximoDocument[]) => {
  if (files?.length && workOrderId) {
    for (const item of files) {
      const file = item.file;
      if (file) {
        const folderName = file.type.includes("image") ? "Images" : "Attachments";

        const convertedFile = (await getBase64File(file)) as string;
        const fileToUpload = convertedFile?.slice(convertedFile.indexOf(",") + 1);
        await FirebaseDataService.addMaximoWorkOrderAttachment(
          fileToUpload,
          workOrderId,
          file.name,
          file.type,
          folderName,
        );
      }
    }
  }
};

export const fetchData = async (
  setIsFormLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isNoAuth: boolean,
  noAuthData: NoAuthDataForTicketCreatePageResponse | undefined,
  setWorkTypes: React.Dispatch<React.SetStateAction<WorkType[]>>,
  setPriorityLevels: React.Dispatch<React.SetStateAction<PriorityLevelList | undefined>>,
  setLocations: React.Dispatch<React.SetStateAction<DBLocation[]>>,
  setCustomWorkRequestFields: React.Dispatch<React.SetStateAction<CustomWorkRequestFieldList | undefined>>,
  id: string | undefined,
  clientUserId: string,
  isManager: boolean,
  setServiceTypes: React.Dispatch<React.SetStateAction<ServiceTypeList | undefined>>,
  setClients: React.Dispatch<React.SetStateAction<Client[]>>,
) => {
  setIsFormLoading(true);
  if (isNoAuth && noAuthData) {
    setPriorityLevels(noAuthData?.priorityLevels);
    setWorkTypes(noAuthData?.workTypes);
    setLocations(noAuthData?.locations);
    setCustomWorkRequestFields(noAuthData.customWorkRequestFields);
  }

  if (!isNoAuth) {
    const { data: createTicketData } = await FirebaseDataService.getDataForTicketCreateForm(
      id as string,
      clientUserId,
      isManager,
    );

    const formattedClients = createTicketData.clients.map((client) => {
      return {
        ...client,
        id: client.id,
        value: client.name,
      };
    });

    setPriorityLevels(createTicketData.priorityLevels || {});
    setWorkTypes(createTicketData.workTypes);
    setLocations(createTicketData.locations);
    setServiceTypes(createTicketData.serviceTypes);
    setClients(formattedClients);
    setCustomWorkRequestFields(createTicketData.customWorkRequestFields);
  }

  setIsFormLoading(false);
};

export const getTimeZone = (
  clients: Client[],
  locations: DBLocation[],
  location: string,
  maximoTimezone: string,
  clientId: string,
) => {
  const clientTimeZone = clients.find((client) => client.id === clientId)?.settings?.timezone;
  const locationTimeZone = locations.find((locationValue: DBLocation) => locationValue.id === location)?.timezone;
  if (locationTimeZone) {
    return locationTimeZone;
  } else if (clientTimeZone) {
    return clientTimeZone;
  } else {
    return maximoTimezone;
  }
};

export const getButtonDisableCondition = (formData: FormData, serviceTypeQuestions: IServiceTypeQuestion[]) => {
  if (!Object.values(formData).every((item) => item)) {
    return true;
  }

  if (formData.serviceTypeQuestions) {
    return !serviceTypeQuestions.every(
      (el) =>
        el.rules?.required === false ||
        (Array.isArray(formData.serviceTypeQuestions[el.id])
          ? formData.serviceTypeQuestions[el.id].length
          : formData.serviceTypeQuestions[el.id]),
    );
  }

  return false;
};

export const getFormDescription = (serviceTypeQuestions: IServiceTypeQuestion[], formData: FormData) =>
  `${formData.description} ${serviceTypeQuestions
    .map((question) => {
      const questionResult = formData.serviceTypeQuestions[question.id];
      return `<br/><br/>${question.label}<br/>${
        questionResult.dateValue
          ? moment(questionResult.dateValue).format(
              `${questionResult.date ? "YYYY-MM-DD " : ""}${questionResult.time ? "hh:mm:ss a" : ""}`,
            )
          : Array.isArray(questionResult)
            ? questionResult.join("; ")
            : questionResult || "-"
      }`;
    })
    .join("")}<!-- RICH TEXT -->`;

export const getWorkOrderObj = (
  formData: FormData,
  clients: Client[],
  targetStart: string,
  targetFinish: string,
  classstructureid: string,
  form: UseFormReturn,
  isManager: boolean,
  clientId: string,
  additionalInfo: object,
) => {
  return {
    siteid: "KLEENWAY",
    description: formData.ticketName.trim(),
    location: formData.location,
    client: clients.find((client) => client.id === clientId),
    worktype: formData.workType,
    wopriority: formData.priority,
    targstartdate: targetStart,
    targcompdate: targetFinish,
    description_longdescription: formData.description,
    ...additionalInfo,
    ...form.getValues(),
    classstructureid,
    isManager,
  };
};

export const onSubmitManagerForm = (
  serviceTypes: ServiceTypeList | undefined,
  clientId: string,
  dropdownClassifications: DropdownItem[],
  classificationId: string,
  defaultServiceTypeName: string | undefined,
  firstName: string | undefined,
  lastName: string | undefined,
  phoneNumber: string | undefined,
  timezone: string,
  email: string,
  formData: FormData,
  maximoTimezone: string,
  serviceTypeQuestions: IServiceTypeQuestion[],
  clients: Client[],
  form: UseFormReturn,
  serviceTypeId: string,
) => {
  const currentClassifications = serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)
    ?.name;
  const classifications = dropdownClassifications?.find(
    (classification) =>
      classification?.hierarchypath === `${defaultServiceTypeName || currentClassifications} \\ ${classificationId}`,
  );
  const additionalInfo = {
    firstname: firstName,
    lastname: lastName,
    phonenumber: phoneNumber,
    deviceTimezone: moment.tz.guess(),
    locationTimezone: timezone,
    email,
    clientId,
  };
  const targetStart = moment(formData.targetStart).tz(maximoTimezone, true).toISOString();
  const targetFinish = moment(formData.targetFinish).tz(maximoTimezone, true).toISOString();
  if (serviceTypeQuestions.length) {
    formData.description = getFormDescription(serviceTypeQuestions, formData);
  }
  return getWorkOrderObj(
    formData,
    clients,
    targetStart,
    targetFinish,
    classifications?.classstructureid,
    form,
    true,
    clientId,
    additionalInfo,
  );
};

export const onSubmitClientForm = (
  serviceTypes: ServiceTypeList | undefined,
  clientId: string,
  dropdownClassifications: DropdownItem[],
  classificationId: string,
  defaultServiceTypeName: string | undefined,
  timezone: string,
  formData: FormData,
  maximoTimezone: string,
  serviceTypeQuestions: IServiceTypeQuestion[],
  clients: Client[],
  form: UseFormReturn,
  serviceTypeId: string,
) => {
  const currentClassifications = serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)
    ?.name;
  const classifications = dropdownClassifications?.find(
    (classification) =>
      classification?.hierarchypath === `${defaultServiceTypeName || currentClassifications} \\ ${classificationId}`,
  );
  const additionalInfo = {
    deviceTimezone: moment.tz.guess(),
    locationTimezone: timezone,
  };
  const targetStart = moment(formData.targetStart).tz(maximoTimezone, true).toISOString();
  const targetFinish = moment(formData.targetFinish).tz(maximoTimezone, true).toISOString();
  if (serviceTypeQuestions.length) {
    formData.description = getFormDescription(serviceTypeQuestions, formData);
  }
  return getWorkOrderObj(
    formData,
    clients,
    targetStart,
    targetFinish,
    classifications?.classstructureid,
    form,
    true,
    clientId,
    additionalInfo,
  );
};

export const onSubmitNoAuthForm = (
  serviceTypes: ServiceTypeItem[],
  clientId: string,
  dropdownClassifications: DropdownItem[],
  classificationId: string,
  defaultServiceTypeName: string | undefined,
  firstName: string | undefined,
  lastName: string | undefined,
  phoneNumber: string | undefined,
  email: string | undefined,
  timezone: string,
  formData: FormData,
  maximoTimezone: string,
  serviceTypeQuestions: IServiceTypeQuestion[],
  clients: Client[],
  form: UseFormReturn,
  serviceTypeId: string,
) => {
  const currentClassifications = serviceTypes?.find((serviceType) => serviceType.id === serviceTypeId)?.name;
  const classifications = dropdownClassifications?.find(
    (classification) =>
      classification?.hierarchypath === `${defaultServiceTypeName || currentClassifications} \\ ${classificationId}`,
  );
  const additionalInfo = {
    firstname: firstName,
    lastname: lastName,
    phonenumber: phoneNumber,
    deviceTimezone: moment.tz.guess(),
    locationTimezone: timezone,
    email,
    clientId,
  };
  const targetStart = moment(formData.targetStart).tz(maximoTimezone, true).toISOString();
  const targetFinish = moment(formData.targetFinish).tz(maximoTimezone, true).toISOString();
  if (serviceTypeQuestions.length) {
    formData.description = getFormDescription(serviceTypeQuestions, formData);
  }
  return getWorkOrderObj(
    formData,
    clients,
    targetStart,
    targetFinish,
    classifications?.classstructureid,
    form,
    true,
    clientId,
    additionalInfo,
  );
};

export const getDropdownClassification = (
  isManager: boolean,
  serviceTypes: ServiceTypeList | undefined,
  clientId: string,
  serviceTypeId: string,
  defaultServiceType: ServiceTypeItem,
  isNoAuthMode: boolean,
  noAuthData: NoAuthDataForTicketCreatePageResponse | undefined,
) => {
  const managerClassifications = isManager
    ? serviceTypes?.[clientId]?.find((serviceType) => serviceType.id === serviceTypeId)
    : defaultServiceType;

  const targetServiceType = isNoAuthMode
    ? (noAuthData?.serviceTypes?.serviceTypes as unknown as ServiceTypeItem[])?.find(
        (serviceType) => serviceType.id === serviceTypeId,
      )
    : managerClassifications;

  const classifications = objToArray(targetServiceType?.classifications, (item, id) => ({
    ...item,
    id,
  }));

  const filteredClassifications = isNoAuthMode
    ? classifications.filter((item) => item.showOnPublic !== false)
    : classifications;

  return filteredClassifications.map((classification) => {
    return {
      id: classification.name,
      value: classification.description,
      hierarchypath: classification.hierarchypath,
      classstructureid: classification.classstructureid,
      longDescription: classification.longDescription,
    };
  }) as DropdownItem[];
};
export const clearWorkRequestMessageTimeout = (dispatch: Dispatch) => {
  const timeout = 10000;
  setTimeout(() => {
    dispatch(clearWorkRequestMessage());
  }, timeout);
};
