import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
import { PaginationContainerProps, PaginationItemProps } from "./types";

export const PaginationContainer = styled.ul<PaginationContainerProps>`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding-left: 0;

  ${(props) => props.$isMobile && `margin-bottom: 10px;`}
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const PaginationItem = styled.li<PaginationItemProps>`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: ${colors.grey.dark3};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid ${colors.grey.light13};
  border-radius: 2px;
  font-size: 14px;
  min-width: 32px;

  &:hover {
    border-color: ${colors.kleenway.green};
    color: ${colors.kleenway.green};
    cursor: pointer;
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 26px;
      min-width: 26px;
      padding: 0 8px;
    `}

  ${(props) => props.disabled && "pointer-events: none;"}
  ${(props) =>
    props.selected &&
    `border-color: ${colors.kleenway.green};
    color: ${colors.kleenway.green};`}
`;

export const DotsIcon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark4};
`;

export const Icon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  color: ${colors.grey.dark};
  cursor: pointer;
  color: ${({ disabled }) => disabled && colors.grey.light13};
`;

export const GoToPage = styled.div`
  label {
    margin-left: 5px;
  }
  input {
    width: 50px;
    padding: 5px;
    margin-left: 5px;
    background: none;
    border: 1px solid ${colors.grey.dark};
  }
  justify-content: flex-end;
`;
