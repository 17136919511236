import React from "react";
import { Stack } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import Button from "src/components/Button";
import { AdjustmentFormButtonsProps } from "../types";

export const AdjustmentFormButtons = ({ onCancel }: AdjustmentFormButtonsProps) => {
  const {
    formState: { isSubmitting },
    reset,
  } = useFormContext();
  return (
    <Stack direction="horizontal" className="mx-auto" gap={4}>
      <Button
        text="Reset"
        type="reset"
        disabled={isSubmitting}
        variant="text"
        onClick={() => reset({ adjustmentComment: "", adjustmentAmount: "0", adjustmentReasonCode: "" })}
      />
      <Button
        text="Cancel"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault();
          onCancel?.();
        }}
      />
      <Button text="Save Changes" loading={isSubmitting} />
    </Stack>
  );
};
