import { Stack } from "react-bootstrap";
import { MobileProps } from "src/hooks/useIsMobile";
import { colors } from "src/styles";
import styled from "styled-components";

export const NoEmployeesMessage = styled.h4<MobileProps>`
  text-align: left;
  margin-top: 10px;
  background-color: #fff1bf;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ffc700;
  font-size: 14px;
`;

export const ErrorMessage = styled.div`
  color: ${colors.red};
  padding: 10px;
`;
export const Content = styled(Stack)`
  padding: 30px 30px 70px 30px;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
