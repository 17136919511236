import React, { TextareaHTMLAttributes, forwardRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { colors } from "src/styles";
import styled from "styled-components";

interface ITextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  countSymbols?: boolean;
  value: string;
  width: string;
  error?: FieldError;
}

const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(function Textarea(
  { disabled, placeholder, onChange, countSymbols, width, error, value = "", ...props }: ITextareaProps,
  ref,
) {
  const [numberSymbols, setNumberSymbols] = useState(value.length);

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const MAX_LENGTH = 500;
    const textValue = e.target.value;
    if (textValue.length > MAX_LENGTH) {
      return;
    }
    onChange(e);

    setNumberSymbols(textValue.length);
  };

  return (
    <Wrapper width={width}>
      <StyledTextArea
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={changeHandler}
        {...props}
        ref={ref}
        $error={!!error}
      ></StyledTextArea>
      {countSymbols && (
        <CountWrapper>
          <span>Maximum 500 characters</span>
          <span>{numberSymbols}/500</span>
        </CountWrapper>
      )}
    </Wrapper>
  );
});

const Wrapper = styled.div<{ width: string }>`
  width: 100%;
  max-width: ${({ width }) => width};
`;

const StyledTextArea = styled.textarea<{ $error: boolean }>`
  border: 1px solid;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  min-height: 100px;
  border-color: ${({ $error }) => ($error ? colors.kleenway.error : colors.grey.light1)};

  &:focus {
    border-color: ${({ $error }) => ($error ? colors.kleenway.error : colors.grey.light1)};
    outline: unset;
  }
`;

const CountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 4px;
  color: ${colors.black.light3};
  & span:first-child {
    color: ${colors.grey.light17};
  }
`;

export default Textarea;
