import React, { ReactNode } from "react";
import { colors } from "src/styles";
import styled from "styled-components";
import Button from "../Button";

interface IFilterPanelProps {
  isFilterBoxOpen: boolean;
  clearAllHandler: () => void;
  areFiltersApplied: boolean;
  onCancelHandler: () => void;
  applyHandler: () => Promise<void>;
  isApplyButtonDisabled: boolean;
  filterItems: ReactNode;
  appliedFiltersItems: ReactNode;
  removeAllHandler: () => void;
}

const FilterPanel = ({
  isFilterBoxOpen,
  clearAllHandler,
  areFiltersApplied,
  onCancelHandler,
  isApplyButtonDisabled,
  applyHandler,
  filterItems,
  appliedFiltersItems,
  removeAllHandler,
}: IFilterPanelProps) => {
  return (
    <Container>
      {isFilterBoxOpen ? (
        <>
          <SelectedFiltersBox areFiltersApplied={areFiltersApplied}>
            <p>Select Filters:</p>
            <Button text="Clear All Filters" onClick={clearAllHandler} variant="text" />
          </SelectedFiltersBox>
          <FiltersBox>
            {filterItems}
            <ActionButtons>
              <Button text="Cancel" variant="text" onClick={onCancelHandler} />
              <Button
                text="Apply Filters"
                disabled={isApplyButtonDisabled}
                variant="small-primary"
                onClick={applyHandler}
              />
            </ActionButtons>
          </FiltersBox>
        </>
      ) : (
        <SelectedFiltersBox>
          <BadgesWrapper>
            <FiltersTitle>Applied Filters:</FiltersTitle>
            {appliedFiltersItems}
          </BadgesWrapper>
          <Button text="Remove All Filters" onClick={removeAllHandler} variant="text" />
        </SelectedFiltersBox>
      )}
    </Container>
  );
};
export const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid ${colors.grey.light4};
`;

export const SelectedFiltersBox = styled.div<{ areFiltersApplied?: boolean }>`
  background-color: ${colors.grey.light1};
  padding: 10px 20px;
  border-bottom: ${({ areFiltersApplied }) => areFiltersApplied && `1px solid ${colors.grey.light4}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FiltersBox = styled.div`
  padding: 20px;
  background-color: ${colors.white.default};
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 35px;
`;

const BadgesWrapper = styled.div`
  display: flex;
`;

const FiltersTitle = styled.p`
  font-size: 14px;
  color: ${colors.grey.dark};
  font-weight: 400;
`;

export default FilterPanel;
