import React from "react";

import ManagerTicketsWrapper from "./components/ManagerTicketsWrapper";
import { TicketsContainerProps } from "./types";
import ClientTicketsWrapper from "./components/ClientTicketsWrapper";
import VendorTicketsWrapper from "./components/VendorTicketsWrapper";
import { useSelector } from "react-redux";
import { EmptyContainer, NoTicketsMessage } from "src/components/ListScreen";
import { useIsMobile } from "src/hooks/useIsMobile";
import { getIsSearchApplied, getIsTicketsLoading, getTickets } from "src/redux/selectors/workRequest";
import { Spinner } from "react-bootstrap";

const TicketsContainer = ({ isManager, isVendor }: TicketsContainerProps) => {
  const { isMobile } = useIsMobile();
  const tickets = useSelector(getTickets);
  const isLoading = useSelector(getIsTicketsLoading);
  const isSearchJustApplied = useSelector(getIsSearchApplied);
  if (isLoading) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }
  if (!tickets?.length && !isLoading)
    return (
      <NoTicketsMessage $isMobile={isMobile}>
        {isSearchJustApplied
          ? `We couldn't find anything matching your search. Please, try again with different search criteria.`
          : "No work requests available"}
      </NoTicketsMessage>
    );

  if (isManager) {
    return <ManagerTicketsWrapper />;
  }
  if (isVendor) {
    return <VendorTicketsWrapper />;
  } else {
    return <ClientTicketsWrapper />;
  }
};

export default TicketsContainer;
