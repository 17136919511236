import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { MAX_PHOTO_SIZE } from "src/config/сonstants";
import { useModal } from "src/hooks/useModal";

import { useParams } from "react-router-dom";
import DeleteModal from "src/components/DeleteModal";
import { ALL_IMAGE_TYPE } from "src/constants/validation";
import { IMAGES } from "../../../assets";
import Button from "../../Button";
import { PhotoContainer } from "./PhotoContainer";

export interface PhotoUploadProps {
  setFile?: any;
  file?: File | null | string;
  elementName?: string;
  placeholderImage?: string;
  renamedInfo?: string;
}

const SinglePhotoUpload = ({
  setFile,
  file,
  elementName = "image",
  placeholderImage = IMAGES.DEFAULT_PROFILE_ICON,
  renamedInfo,
}: PhotoUploadProps) => {
  const { id } = useParams();
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<File | null>(null);

  const { isShowing, toggle } = useModal();

  useEffect(() => {
    if (file && typeof file !== "string") {
      setPreviewImage(file);
    }
  }, [file]);

  const handleAddButtonClick = () => {
    hiddenFileInput.current !== null && hiddenFileInput.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    const newFileName = renamedInfo ? `${id}_${renamedInfo}_${selectedFile?.name}` : `${id}_${selectedFile?.name}`;
    const renamedFile = new File([selectedFile || ""], newFileName);

    if (selectedFile) {
      if (selectedFile.size > MAX_PHOTO_SIZE) {
        alert("file size should not exceed 7 MB");
        return;
      }

      setFile(renamedFile);
      setPreviewImage(renamedFile);
    }
  };

  const handleDelete = (event: React.MouseEvent) => {
    event.preventDefault();
    if (hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = "";
    }
    setFile(null);
    setPreviewImage(null);
    toggle();
  };

  return (
    <>
      <DeleteModal
        onApproveClick={handleDelete}
        onCancelClick={toggle}
        title={elementName}
        isShowing={isShowing}
        hide={toggle}
      />
      <PhotoUploadContainer>
        <PhotoContainer image={previewImage ? URL.createObjectURL(previewImage) : file || placeholderImage} />
        <div>
          <div>
            <Button type="button" text={`Upload new ${elementName}`} variant="text" onClick={handleAddButtonClick} />
            <input
              type="file"
              accept={ALL_IMAGE_TYPE}
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
          <Button
            type="button"
            disabled={!previewImage && !file}
            text={`Delete ${elementName}`}
            variant="text"
            onClick={toggle}
          />
        </div>
      </PhotoUploadContainer>
    </>
  );
};

export default React.memo(SinglePhotoUpload);

const PhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 25px;
`;
