import React from "react";
import { UseFormRegister } from "react-hook-form";
import { UseFormProps } from "src/pages/ClientDetails/ClientPortalTiles/SingleTile";
import { OptionItem } from "../SelectSearch";
import InputField from "./InputField";
import SelectField from "./SelectField";
import { TileInputValue } from "./styles";
import { TileInputProps } from "./types";

export const TileInput = ({
  label,
  editMode = false,
  value,
  inputWidth,
  onChange,
  onSelectChange,
  type,
  options,
  required = false,
  register,
  errors = {},
  placeholder = "",
  filteredFoldersNameList = [],
  pattern = /^(\w){1,}/,
}: TileInputProps) => {
  return (
    <>
      {editMode ? (
        <>
          {type === "input" ? (
            <InputField
              inputWidth={inputWidth}
              value={value}
              editMode={editMode}
              placeholder={placeholder}
              register={register as UseFormRegister<UseFormProps>}
              label={label}
              required={required}
              pattern={pattern}
              onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
              errors={errors}
              filteredFoldersNameList={filteredFoldersNameList}
            />
          ) : (
            type === "select" && (
              <SelectField
                value={value}
                options={options}
                onSelectChange={onSelectChange as (option: OptionItem) => void}
              />
            )
          )}
        </>
      ) : (
        <TileInputValue>
          {(type === "select" && options?.find((rec) => rec.id === value.toLowerCase())?.value) || value}
        </TileInputValue>
      )}
    </>
  );
};

export default TileInput;
