import React, { InputHTMLAttributes, forwardRef } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  controlId?: string;
  validation?: UseFormRegisterReturn;
  error?: FieldError;
}

export enum InputTypes {
  text = "text",
  password = "password",
  email = "email",
}

const Input = forwardRef<HTMLInputElement, InputProps & FormControlProps>(function Input(
  { label = "", disabled, type = InputTypes.text, controlId = "", validation, error, ...inputProps },
  ref,
) {
  const { isMobile } = useIsMobile();
  return (
    <StyledInputBox controlId={controlId}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput
        ref={ref}
        disabled={disabled ? 1 : 0}
        $isMobile={isMobile ? 1 : 0}
        type={type}
        $error={!!error}
        {...validation}
        {...inputProps}
      />
    </StyledInputBox>
  );
});

const StyledInput = styled(Form.Control)<{ $isMobile: number; disabled: number; $error: boolean }>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $error }) => ($error ? colors.kleenway.error : colors.grey.light1)};
  border-radius: 4px;
  height: 40px;
  padding: 10px;
  font-size: 14px;
  color: ${colors.black.light1};
  background: ${({ disabled }) => (disabled ? colors.grey.light2 : "none")};

  &:focus {
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ $error }) => ($error ? colors.kleenway.error : colors.grey.light1)};
    outline: unset;
  }

  &:focus-visible {
    outline: unset;
  }

  ::placeholder {
    font-style: italic;
    color: ${colors.grey.light5};
  }

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      height: 25px;
    `}
`;

const StyledLabel = styled(Form.Label)`
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
  color: ${colors.grey.dark};

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-weight: 400;
    `}
`;

const StyledInputBox = styled(Form.Group)`
  text-align: left;
  width: 100%;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: 15px;
    `}
`;

export default Input;
