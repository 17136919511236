import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { AssignWOResourceForm } from "src/components/molecules/AssignWOResource";
import { Icon, ModalContent, ModalHeader, ModalWindow, ModalWrapper } from "./styles";
import { AssignWOResourceModalProps } from "./types";

const AssignWOResourceModal = ({
  closeModal,
  employees,
  vendors,
  defaultValues,
  onSubmit,
  errorMsg,
}: AssignWOResourceModalProps) => {
  return (
    <ModalWrapper>
      <ModalWindow style={{ maxWidth: "315px" }}>
        <ModalHeader>
          <div>
            <span>Assign WorkOrder</span>
            <Icon onClick={closeModal} icon={faTimesCircle as IconProp} />
          </div>
        </ModalHeader>
        <ModalContent>
          <AssignWOResourceForm
            defaultValues={defaultValues}
            onCancel={closeModal}
            employees={employees}
            vendors={vendors}
            onSubmit={onSubmit}
            errorMsg={errorMsg}
          />
        </ModalContent>
      </ModalWindow>
    </ModalWrapper>
  );
};
export default AssignWOResourceModal;
