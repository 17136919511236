import isEqual from "lodash.isequal";
import { UseFormReturn } from "react-hook-form";
import { FirebaseDataService } from "src/services/Firebase/data";
import { FontSettingsProps, GlobalSettingsData } from "./types";

/**
 * A helper to isolate the extra data handling and enable a convinient use of Promise.all() in `fetchDefaultValues`
 */
const fetchGlobalInfoMessages = async (fontSettings: React.MutableRefObject<FontSettingsProps>) => {
  const {
    data: { globalMessages, ...extraData },
  } = await FirebaseDataService.getInfoMessages();

  fontSettings.current = {
    fontColors: extraData.colors || [],
    fontSizes: extraData.fontSizes || [],
  };

  return globalMessages;
};

/**
 * Fetches global settings data from the backend
 */
export const fetchDefaultValues = async (
  setError: (error: string) => void,
  fontSettings: React.MutableRefObject<FontSettingsProps>,
) => {
  try {
    const [templates, contacts, messages] = await Promise.all([
      (await FirebaseDataService.getGeneralSchedules()).data,
      (await FirebaseDataService.getContacts()).data,
      fetchGlobalInfoMessages(fontSettings),
    ]);

    return {
      templates,
      contacts,
      messages,
    };
  } catch (error) {
    setError(error);

    return { templates: [], contacts: [], messages: [] };
  }
};

/**
 * Send the global settings data to backend upon form submission.
 */
export const onSubmitHandler = async (form: UseFormReturn<GlobalSettingsData>, data: GlobalSettingsData) => {
  if (!isEqual(form.formState.defaultValues?.templates, data.templates)) {
    await FirebaseDataService.updateGlobalScheduleTemplatesNew(data.templates);
  }

  if (!isEqual(form.formState.defaultValues?.contacts, data.contacts)) {
    await FirebaseDataService.updateGlobalContacts(data.contacts);
  }

  if (!isEqual(form.formState.defaultValues?.messages, data.messages)) {
    await FirebaseDataService.updateGlobalInfoMessages(data.messages);
  }

  // this is necessary to update defaultValues so any subsequent attempts to save the form will correctly prevent
  // sending data to the backend if it did not actually change again
  // the `keepValues` flag is necessary to not cause a rerender which would collapse any template that was updated
  form.reset(data, { keepValues: true });
};
