import React, { useContext, useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { NoTicketsMessage } from "src/components/ListScreen";
import { HorizontalTabs } from "../../../components/Tabs/HorizontalTabs";
import ClientDetailsContext from "../context";
import { PayPeriodFilter } from "./PayPeriodFilter";
import { PayPeriodTypeTab } from "./PayPeriodTypeTab";

export const TimeAndAttendance = () => {
  const { payPeriods, payPeriodsTypes } = useContext(ClientDetailsContext);

  const filteredPayPeriodTypes = payPeriodsTypes.filter((item) => item.employeesNumber);

  const [activeTab, setActiveTab] = useState<string>("");

  useEffect(() => {
    setActiveTab(filteredPayPeriodTypes[0]?.id.toString() || "");
  }, [filteredPayPeriodTypes.length]);

  return payPeriodsTypes.some((item) => item.employeesNumber) ? (
    <Stack style={{ position: "relative" }}>
      <HorizontalTabs
        tabs={filteredPayPeriodTypes.map(({ id, label }) => ({
          eventKey: id.toString(),
          content: <PayPeriodTypeTab payPeriods={payPeriods[id] || []} />,
          label,
        }))}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <PayPeriodFilter />
    </Stack>
  ) : (
    <NoTicketsMessage>There are no employees assigned yet</NoTicketsMessage>
  );
};
