import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { HEIC_TYPE, MAX_PHOTO_SIZE } from "src/config/сonstants";
import { ALL_IMAGE_TYPE } from "src/constants/validation";
import convertHeicToJpg from "src/helpers/convertHeicToJPG";
import { useIsMobile } from "src/hooks/useIsMobile";
import { useModal } from "src/hooks/useModal";
import { colors } from "src/styles";
import styled from "styled-components";
import DeleteModal from "../DeleteModal";
import ModalView from "../ModalView";
export interface PhotoUploadProps {
  isViewMode: boolean;
  setFiles?: any;
  images?: any;
  isLoading: boolean;
  files: File[];
}

const PhotoUpload = ({ setFiles, isViewMode, images, files }: PhotoUploadProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [previewImages, setPreviewImages] = useState<File[]>([]);

  const { isShowing, toggle } = useModal();
  const [indexToDelete, setIndexToDelete] = useState(0);
  const { isMobile } = useIsMobile();

  const handleAddButtonClick = () => {
    hiddenFileInput.current !== null && hiddenFileInput.current.click();
  };

  /**
   * This method used to verify image type and if HEIC found then convert to PNG then set to File Array.
   *
   */
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileImages: File[] = [];
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files.item(i);
        if (file) {
          putFileIntoArray(fileImages, file);
        }
      }
    }
  };
  /**
   * This method basically put the file into Files Array after validating.
   */
  const putFileIntoArray = async (fileImages: File[], file: File) => {
    if (file.size > MAX_PHOTO_SIZE) {
      alert("file size should not exceed 7 MB");
      return;
    }
    if (file.type === HEIC_TYPE) {
      const convertedImage = await convertHeicToJpg(file);
      if (convertedImage) {
        setStateForImageFileAndPreview(convertedImage, fileImages);
      }
    } else {
      setStateForImageFileAndPreview(file, fileImages);
    }
  };
  /**
   *This method is used to update the states of image priveiew array.
   */
  const setStateForImageFileAndPreview = (convertedImage: File, fileImages: File[]) => {
    fileImages.push(convertedImage);
    setImageFilePlusPreview(fileImages);
  };
  /**
   *
   * This method is common method to update image File Array and Preview Image Array.
   */
  const setImageFilePlusPreview = (fileImages: File[]) => {
    setFiles([...files, ...fileImages]);
    setPreviewImages([...previewImages, ...fileImages]);
  };

  const handleDelete = (event: React.MouseEvent, index: number) => {
    event.preventDefault();
    const remainingFiles = previewImages.filter((data: any, idx: number) => idx !== index);
    setFiles(remainingFiles);
    setPreviewImages(remainingFiles);
    setIndexToDelete(0);
    toggle();
  };

  return (
    <>
      <DeleteModal
        onApproveClick={(e: any) => handleDelete(e, indexToDelete)}
        onCancelClick={() => toggle()}
        title="Photo"
        isShowing={isShowing}
        hide={toggle}
      />
      <PhotoUploadContainer>
        {previewImages?.map((file: File, index: number) => (
          <PhotoContainer
            isViewMode={isViewMode}
            key={index}
            image={URL.createObjectURL(file)}
            onDeleteClick={(e) => {
              e.preventDefault();
              toggle();
              setIndexToDelete(index);
            }}
          />
        ))}
        {images?.map((file: File, index: number) => (
          <PhotoContainer
            isViewMode={isViewMode}
            key={index}
            image={file}
            onDeleteClick={(e) => {
              e.preventDefault();
              toggle();
              setIndexToDelete(index);
            }}
          />
        ))}
        {!isViewMode && (
          <div>
            <UploadButtonContainer $isMobile={isMobile} onClick={() => handleAddButtonClick()}>
              <Icon icon={faPlus as any} />
              <input
                multiple
                accept={ALL_IMAGE_TYPE}
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </UploadButtonContainer>
          </div>
        )}
      </PhotoUploadContainer>
    </>
  );
};

export interface PhotoContainerProps {
  onDeleteClick: (e: React.MouseEvent) => void;
  image: any;
  isViewMode: boolean;
}

export const PhotoContainer = ({ onDeleteClick, image, isViewMode }: PhotoContainerProps) => {
  const { isMobile } = useIsMobile();
  const [modalOpen, setModalOpen] = useState(false);
  const body = document.body;
  const [loading, setLoading] = useState(false);

  return (
    <StyledPhotoContainer $isMobile={isMobile}>
      {!loading && (
        <PhotoFrame>
          <Spinner animation="border" />
        </PhotoFrame>
      )}
      <img
        src={image}
        alt="Image"
        style={loading ? {} : { display: "none" }}
        onClick={() => {
          body.style.overflow = "hidden";
          setModalOpen(true);
        }}
        onLoad={() => setLoading(true)}
      />
      {isViewMode ? null : (
        <DeleteButton onClick={(e: React.MouseEvent) => onDeleteClick(e)}>
          <Icon icon={faTimes as any} />
        </DeleteButton>
      )}
      <ModalView modalOpen={modalOpen}>
        <CloseButton
          icon={faTimes as any}
          onClick={() => {
            body.style.overflow = "initial";
            setModalOpen(false);
          }}
        />
        <img style={{ maxWidth: "100vw", maxHeight: "100vh" }} src={image} alt="Image" />
      </ModalView>
    </StyledPhotoContainer>
  );
};

export default React.memo(PhotoUpload);

export const PhotoUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CloseButton = styled(FontAwesomeIcon)`
  padding: 0;
  position: absolute;
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  background-color: #aaa;
  border: none;
  outline: none;
  z-index: 4;
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.grey.dark};
  cursor: pointer;
`;

const DeleteButton = styled.button`
  border: none;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface StyledPhotoContainerProps {
  $isMobile: boolean;
}

export interface UploadButtonProps {
  $isMobile: boolean;
}

const StyledPhotoContainer = styled.div<StyledPhotoContainerProps>`
  position: relative;
  margin: 8px 8px;

  img {
    height: 130px;
    width: 100%;
    object-fit: cover;

    ${(props) => props.$isMobile && `width: 100%; height: 60px;`}
  }

  img:hover {
    cursor: pointer;
  }

  ${DeleteButton} {
    position: absolute;
    right: 0px;
    top: 0px;
  }

  ${Icon} {
    width: 20px;
    height: 20px;

    ${(props) => props.$isMobile && `width: 15px; height: 15px;`}
  }
`;

const UploadButtonContainer = styled.div<UploadButtonProps>`
  margin: 8px 8px;

  cursor: pointer;
  width: 131px;
  height: 131px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px dashed ${colors.grey.default};

  ${(props) => props.$isMobile && `width: 61px; height: 61px;`}

  ${Icon} {
    width: 40px;
    height: 40px;

    ${(props) => props.$isMobile && `width: 20px; height: 20px;`}
  }
`;

const PhotoFrame = styled.div`
  width: 150px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px #f0f0f0 solid;
`;
