import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import { EmptyContainer } from "src/components/ListScreen";
import Modal from "src/components/Modal";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { ShiftData } from "src/components/molecules/ShiftForm/types";
import { ScheduleTemplate } from "src/components/molecules/ShiftTemplateForm/types";
import { PAY_TYPE } from "src/constants/validation";
import { generateBreadcrumbs } from "src/helpers/breadcrumbs";
import { DEFAULT_PAY_PERIOD_TYPE } from "src/pages/ClientDetails/constants";
import { setOnSiteTeamMemberHeader, setPageNameHeader } from "src/redux/actions/header";
import { getPreviousURLTeamMembers } from "src/redux/selectors/openedPages";
import { getUserData } from "src/redux/selectors/user";
import { FirebaseDataService } from "src/services/Firebase/data";
import FirebaseStorageService from "src/services/Firebase/storage";
import { colors, Content, ContentFrame, TopBar } from "src/styles";
import { Assignment, Client, DBLocation, ID, TeamMemberById } from "src/types";
import { toNumber } from "src/utils/helpers";
import styled from "styled-components";
import TeamMemberGeneralInfo from "./TeamMemberGeneralInfo";
import TeamMemberPayroll from "./TeamMemberPayroll";
import { PayrollFormValues } from "./TeamMemberPayroll/types";
import TeamMemberSchedule from "./TeamMemberSchedule";
import { CUSTOM_SCHEDULE, TEAM_MEMBER_DETAILS_BREADCRUMBS } from "./constants";

export const customScheduleTemplate = {
  id: CUSTOM_SCHEDULE,
  label: CUSTOM_SCHEDULE,
  shifts: [] as ShiftData[],
  isUsedByEmployees: false,
};

interface AssignmentsFormValues {
  assignments?: Assignment[];
}

const TeamMemberDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { id: managerId } = useSelector(getUserData);
  const previousURL = useSelector(getPreviousURLTeamMembers);

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [isValidGeneralForm, setIsValidGeneralForm] = useState(true);

  const [activeTab, setActiveTab] = useState("generalInfo");
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);
  const [teamMemberData, setTeamMemberData] = useState<TeamMemberById>({} as TeamMemberById);
  const [logo, setLogo] = useState<File | string | null>(null);

  const [scheduleTemplates, setScheduleTemplates] = useState<ScheduleTemplate[]>([]);
  const [scheduleTypes, setScheduleTypes] = useState<Record<string, number>>();
  const [clients, setClients] = useState<Client[]>();
  const [locations, setLocations] = useState<DBLocation[]>();
  const [assignments, setAssignments] = useState<Assignment[]>([]);

  const assignmentsForm = useForm<AssignmentsFormValues>({ mode: "onChange" });

  const redirectToOpenedPage = () => navigate(`/team-members${previousURL}`);

  const onClickBreadcrumb = (route: ID | null) => {
    typeof route === "string" && navigate(route);
  };

  const getEmployeeInfo = async (): Promise<PayrollFormValues> => {
    const defaultValues = {
      payType: PAY_TYPE.hourly,
    };

    if (!id) {
      return defaultValues;
    }

    setIsLoading(true);
    setError("");

    try {
      const { data } = await FirebaseDataService.getTeamMemberById({ managerId, employeeId: id });
      if (data && Object.keys(data).length) {
        setTeamMemberData(data);
        return (({ payType, salaryAmount, hourlyRate }) => ({
          payType: payType ?? PAY_TYPE.hourly,
          salaryAmount,
          hourlyRate,
        }))(data);
      } else {
        navigate("/404");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }

    return defaultValues;
  };

  const form = useForm<PayrollFormValues>({ defaultValues: getEmployeeInfo });
  const uploadNewLogo = async () => {
    setError("");
    try {
      if (!logo) {
        return;
      }

      //this condition is used for upload new photo when previous was not set.
      if (typeof teamMemberData.profilePicture !== "string" && typeof logo === "object") {
        await FirebaseStorageService.uploadImageToStorage(logo, process.env.REACT_APP_CLIENT_LOGO_FOLDER, logo.name);
      }

      //this condition is used for upload new photo when previous was  set.
      if (typeof teamMemberData.profilePicture === "string" && typeof logo === "object") {
        await FirebaseStorageService.deleteChildFromStorage(
          teamMemberData.profilePicture,
          process.env.REACT_APP_CLIENT_LOGO_FOLDER,
        );
        await FirebaseStorageService.uploadImageToStorage(logo, process.env.REACT_APP_CLIENT_LOGO_FOLDER, logo.name);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const findClient = (allClients: Client[], clientId: string) => {
    if (!allClients) {
      return null;
    }
    const client = Object.entries(allClients).find(([key]) => key === clientId);
    return client ? client[1] : null;
  };

  const findLocation = (allLocations: DBLocation[], locationId: string) => {
    if (!allLocations) {
      return null;
    }
    const location = Object.entries(allLocations).find(([key]) => key === locationId);
    return location ? location[1] : null;
  };

  const getEmployeeDisplayName = (memberData: TeamMemberById) => {
    const { firstName = "", middleName = "", lastName = "" } = memberData;
    return `${firstName} ${middleName} ${lastName}`;
  };

  const getSchedule = (item: Assignment, assignmentsFormValues: AssignmentsFormValues, index: number) => {
    const scheduleType = item.schedule?.type || "";
    const scheduleTemplate = item.schedule?.template;

    if (scheduleTemplate === CUSTOM_SCHEDULE) {
      const shifts = assignmentsFormValues.assignments?.[index]?.schedule?.shifts || [];
      return { type: scheduleType, shifts };
    }

    return { type: scheduleType, template: scheduleTemplate };
  };

  const prepareAssignmentsForSaving = () => {
    const assignmentsFormValues = assignmentsForm.getValues();

    const { payType } = form.getValues();

    return assignments.map((item, index) => {
      const currentClient = findClient(clients ?? [], item.clientId);
      const currentLocation = findLocation(locations ?? [], item.locationId);

      return {
        ...item,
        clientDisplayName: currentClient ? currentClient.name : "",
        locationDisplayName: currentLocation ? currentLocation.label : "",
        countryCode: currentLocation ? currentLocation.countryCode : "",
        regionCode: currentLocation ? currentLocation.regionCode : "",
        payPeriodType: teamMemberData.payPeriodType || DEFAULT_PAY_PERIOD_TYPE,
        payType: payType,
        employeeDisplayName: getEmployeeDisplayName(teamMemberData),
        schedule: getSchedule(item, assignmentsFormValues, index),
      };
    });
  };

  const saveTeamMembersData = async () => {
    if (!id) {
      return;
    }

    // manualy trigger reackt-hook-form validation as we only use it for a part of the form
    if (!(await form.trigger())) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isOnSite, ...dataForDb } = teamMemberData;
    setError("");
    try {
      const fileName =
        typeof teamMemberData.profilePicture === "string"
          ? teamMemberData.profilePicture
          : teamMemberData.profilePicture?.name;
      //check if previous image name includes location id, save as it is.Otherwise - add location id to the name
      const profileImageName = fileName?.includes(id) ? fileName : `${id}_${fileName}`;

      const { salaryAmount, hourlyRate, ...payrollValues } = form.getValues();

      const formattedData = {
        ...dataForDb,
        ...payrollValues,
        salaryAmount: toNumber(salaryAmount),
        hourlyRate: toNumber(hourlyRate),
        profilePicture: fileName ? profileImageName : null,
        vacationAccrualRate: teamMemberData.vacationAccrualRate || "0.04",
      };

      await FirebaseDataService.updateTeamMember({
        employeeId: id,
        teamMemberData: formattedData,
      });
      await uploadNewLogo();
    } catch (err) {
      setError(err.message);
    }
  };

  const saveEmployeeAssignments = async () => {
    setError("");
    if (!id) {
      return;
    }
    try {
      if (!assignments) {
        return;
      }
      await FirebaseDataService.updateEmployeeAssignments({
        assignments: prepareAssignmentsForSaving(),
        employeeId: id.toString(),
        managerId: managerId.toString(),
      });
    } catch (err) {
      setError(err.message);
    }
  };

  const getInitialDataSchedules = async () => {
    setIsLoading(true);
    setError("");
    if (!id) {
      return;
    }
    try {
      const [schedules, initialScheduleTemplates, clientsData, locationsData, employeeAssignments] =
        await Promise.allSettled([
          FirebaseDataService.getScheduleTemplateTypes(),
          FirebaseDataService.getGeneralSchedules(),
          FirebaseDataService.getClientsByManagerId({ managerId }),
          FirebaseDataService.getLocationsByManagerId({ managerId }),
          FirebaseDataService.getTeamMemberAssignments({
            employeeId: id,
            managerId,
          }),
        ]);
      const templates = initialScheduleTemplates.status === "fulfilled" ? initialScheduleTemplates.value.data : [];
      const newScheduleTemplates = [customScheduleTemplate, ...templates];

      setScheduleTemplates(newScheduleTemplates);
      setScheduleTypes(schedules.status === "fulfilled" ? schedules.value.data : {});
      setClients(clientsData.status === "fulfilled" ? clientsData.value.data : []);
      setLocations(locationsData.status === "fulfilled" ? locationsData.value.data : []);

      const assignmentsData = employeeAssignments.status === "fulfilled" ? employeeAssignments.value.data : [];
      const formattedAssignmentsData = assignmentsData.map((item: Assignment) => {
        return {
          ...item,
          schedule: {
            ...item.schedule,
            template: item.schedule?.template ?? CUSTOM_SCHEDULE,
          },
        };
      });

      updateAssignments(formattedAssignmentsData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAssignments = (newAssignments: Assignment[]) => {
    const formValues = assignmentsForm.getValues();

    // combines data previously saved in state with the most recent updates made to the form so the
    // updates are not lost
    // BUT only updates the shifts-related data is it is the only data controlled by react-hook-form :(
    const finalAssignments = newAssignments.map((assignment) => {
      const existingScheduleData = formValues.assignments?.find(
        (existingAssignment) => existingAssignment.id === assignment.id,
      );

      return {
        ...assignment,
        ...(existingScheduleData && {
          schedule: {
            ...assignment.schedule,
            shifts: existingScheduleData.schedule?.shifts,
          },
        }),
      };
    });

    setAssignments(finalAssignments);
    assignmentsForm.setValue("assignments", finalAssignments);
  };

  const submitHandler = async (onSuccess?: () => void) => {
    setIsSavingForm(true);
    if (await form.trigger()) {
      await Promise.all([saveTeamMembersData(), saveEmployeeAssignments()]);
      /**
       * After updating assingments in our DB, function to update assignments will be triggered in OpenSearch,
       * that's why we should wait for a couple of seconds while all assignments in OpenSearch will be updated.
       */
      await new Promise((resolve) => setTimeout(resolve, 3000));
      onSuccess?.();
    }

    setIsSavingForm(false);
  };

  const teamMemberName = `${teamMemberData.firstName || ""} ${teamMemberData.middleName || ""} ${
    teamMemberData.lastName || ""
  }`;

  useEffect(() => {
    getInitialDataSchedules();
  }, [id]);

  //set page name and on site status to the Header
  useEffect(() => {
    dispatch(setPageNameHeader(teamMemberName));
    dispatch(setOnSiteTeamMemberHeader(!!teamMemberData.isOnSite));
    return () => {
      dispatch(setPageNameHeader(""));
      dispatch(setOnSiteTeamMemberHeader(false));
    };
  }, [teamMemberData]);

  if (isLoading) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }

  return (
    <div>
      <Modal
        onApproveClick={redirectToOpenedPage}
        onCancelClick={() => setShowConfirmClosePopup(false)}
        isShowing={showConfirmClosePopup}
        hide={() => setShowConfirmClosePopup(false)}
        title="Close without Saving"
        textContent={`Do you want to close the page without saving?\nAll entered or updated information will be lost.`}
        approveButtonText="Close without Saving"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
      />
      <TopBar>
        <BreadCrumbs
          folderStack={generateBreadcrumbs(TEAM_MEMBER_DETAILS_BREADCRUMBS, {
            id: teamMemberData?.employeeId?.toString(),
            name: teamMemberName,
          })}
          onClickItem={onClickBreadcrumb}
        />
      </TopBar>
      <Content>
        <ContentFrame>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <HorizontalTabs
            tabs={[
              {
                eventKey: "generalInfo",
                label: "General Information",
                content: (
                  <TeamMemberGeneralInfo
                    setIsValidForm={setIsValidGeneralForm}
                    logo={logo}
                    setLogo={setLogo}
                    setTeamMemberData={setTeamMemberData}
                    teamMemberData={teamMemberData}
                    setError={setError}
                  />
                ),
                tabColor: isValidGeneralForm ? colors.kleenway.green : colors.red.default,
              },
              {
                eventKey: "schedules",
                label: "Schedules",
                content: (
                  <FormProvider {...assignmentsForm}>
                    <TeamMemberSchedule
                      employeeInfo={teamMemberData}
                      managerId={managerId.toString()}
                      scheduleTemplates={scheduleTemplates}
                      scheduleTypes={scheduleTypes || {}}
                      clients={clients || []}
                      locations={locations || []}
                      assignments={assignments}
                      setAssignments={updateAssignments}
                    />
                  </FormProvider>
                ),
                tabColor: assignmentsForm.formState.isValid ? colors.kleenway.green : colors.red.default,
              },
              {
                eventKey: "payroll",
                label: "Payroll",
                content: (
                  <TeamMemberPayroll
                    payPeriodType={teamMemberData.payPeriodType}
                    vacationAccrualRate={teamMemberData.vacationAccrualRate || ""}
                    setTeamMemberData={setTeamMemberData}
                    form={form}
                  />
                ),
              },
            ]}
            isSaveButtonVisible={true}
            isLoading={isSavingForm}
            disableSave={isSavingForm || !isValidGeneralForm || !assignmentsForm.formState.isValid}
            backHandler={() => setShowConfirmClosePopup(true)}
            saveHandler={async () => {
              await submitHandler(!assignments.length ? () => navigate("/team-members") : undefined);
            }}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </ContentFrame>
      </Content>
    </div>
  );
};

const ErrorMessage = styled.p`
  padding-left: 30px;
  padding-top: 15px;
`;

export default TeamMemberDetails;
