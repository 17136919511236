import heic2any from "heic2any";
import { HEIC_TYPE, JPEG_TYPE } from "src/config/сonstants";
import { debugError } from "src/utils/log";

/**
 * Read the HEIC file as an array buffer and  Convert HEIC to PNG
 */
export default async function convertHeicToJpg(heicFile: File): Promise<File | null> {
  try {
    const heicArrayBuffer = await heicFile.arrayBuffer();
    const jpgBlobArray = await heic2any({
      blob: new Blob([heicArrayBuffer], { type: HEIC_TYPE }),
      toType: JPEG_TYPE,
      quality: 0.85,
    });
    const jpgBlob = jpgBlobArray instanceof Blob ? jpgBlobArray : jpgBlobArray[0];
    return new File([jpgBlob], heicFile.name.replace(/\.heic$/, ".jpeg"), {
      type: JPEG_TYPE,
    });
  } catch (error) {
    debugError("Error converting HEIC to JPEG:", error.message);
    return null;
  }
}
