import React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

interface ModalViewProps {
  modalOpen: boolean;
  children: React.ReactNode;
}

const ModalView: React.FC<ModalViewProps> = ({ modalOpen, children }) => {
  if (!modalOpen) {
    return null;
  }

  return createPortal(
    <Wrapper>
      <div>{children}</div>
    </Wrapper>,
    document.body,
  );
};

const Wrapper = styled.div`
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #555;
  z-index: 1000;
`;

export default ModalView;
