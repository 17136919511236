import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldValues, UseFormRegister } from "react-hook-form";

import Alert, { AlertVariants } from "src/components/Alert";
import Button from "src/components/Button";
import Input, { InputTypes } from "src/components/Input";
import { useIsMobile } from "src/hooks/useIsMobile";
import { FirebaseDataService } from "src/services/Firebase/data";

import { VALIDATION_MESSAGES } from "src/constants/validation";
import { ButtonContainer, Content, FormLabel, InputForm } from "./styles";

interface UserInfoProps {
  captchaRef?: any;
  onSubmit: () => void;
  onChangeCaptcha: (value: any) => void;
  isValid: boolean;
  register: UseFormRegister<FieldValues>;
  loading: boolean;
  error: string;
  errors: any;
  labelMessage: string;
  askCompanyCode: boolean;
}

export const UserInfo: React.FC<UserInfoProps> = ({
  captchaRef,
  onSubmit,
  onChangeCaptcha,
  register,
  isValid,
  loading,
  error,
  errors,
  labelMessage,
  askCompanyCode,
}) => {
  const [sitekey, setSitekey] = useState("");
  const { isMobile } = useIsMobile();

  const asyncScriptOnLoad = () => {
    captchaRef.current.executeAsync().then((value: string) => {
      onChangeCaptcha(value);
    });
  };
  useEffect(() => {
    const getKey = async () => {
      const key = await FirebaseDataService.getRecaptchaKey();
      if (key.success) {
        setSitekey(key.data);
      }
    };
    getKey();
  }, []);

  return (
    <Content>
      <FormLabel>{labelMessage}</FormLabel>

      <InputForm $isMobile={isMobile}>
        <Input
          controlId="first-name"
          type={InputTypes.text}
          label="First Name"
          validation={register("firstName", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
          })}
        />

        <Input
          controlId="last-name"
          type={InputTypes.text}
          label="Last Name"
          validation={register("lastName", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
          })}
        />

        <Input
          controlId="sign-in-email"
          type={InputTypes.email}
          label="Email Address"
          validation={register("email", {
            required: {
              value: true,
              message: VALIDATION_MESSAGES.required,
            },
            pattern: {
              value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: "Please, enter correct email.",
            },
          })}
        />
        {errors.email && <Alert text={errors.email.message} variant={AlertVariants.danger} />}

        <Input
          controlId="phone-number"
          type={InputTypes.text}
          validation={register("phoneNumber", {
            required: {
              value: false,
              message: VALIDATION_MESSAGES.required,
            },
          })}
          label="Phone (optional)"
        />

        {askCompanyCode && (
          <Input
            controlId="company-code"
            type={InputTypes.text}
            label="Company code"
            validation={register("companyCode", {
              required: {
                value: true,
                message: VALIDATION_MESSAGES.required,
              },
            })}
          />
        )}

        {error && <Alert text={error} variant={AlertVariants.danger} />}

        {sitekey ? (
          <ReCAPTCHA ref={captchaRef} sitekey={sitekey} size="invisible" asyncScriptOnLoad={asyncScriptOnLoad} />
        ) : null}
      </InputForm>

      <ButtonContainer>
        <Button text="Create New Work Request" type="submit" disabled={!isValid} loading={loading} onClick={onSubmit} />
      </ButtonContainer>
    </Content>
  );
};
