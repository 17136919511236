import { SelectBoxContainer } from "src/components/Dropdown/styles";
import { StyledTableCell } from "src/components/Table/TableCell";
import { colors } from "src/styles";
import styled, { css } from "styled-components";
import { MobileProps } from "../TicketForm/types";

export const StyledCell = styled(StyledTableCell)`
  height: 50px;
  box-sizing: content-box;
  padding: 10px;
`;

export const NoItems = styled(StyledCell)`
  text-align: center;
`;

export const LineDescription = styled(StyledCell)`
  height: 25px;
  text-align: right;
`;

export const LineCost = styled(StyledCell)`
  height: 25px;
`;

export const SubCell = styled(StyledCell)`
  padding: 15px;
`;

export const Container = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  margin: 30px;
  background-color: ${colors.white.default};
`;
export const InputsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  .tooltip {
    max-width: 70%;
  }
`;

export const InputsContainer = styled.div<MobileProps>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.$isMobile &&
    css`
      ${InputRow} {
        margin-bottom: 20px;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      label {
        width: 100px;
      }

      input {
        width: 100%;
      }
    `}

  ${(props) =>
    props.$isMobile &&
    css`
      ${SelectBoxContainer} {
        height: 25px;
      }
    `}
`;

export const ContactWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Wrapper = styled.div`
  flex: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContractIdContainer = styled.label`
  display: flex;
  align-item: center;
`;
export const ContentFrame = styled.div`
  padding: 30px;
  background-color: ${colors.white.default};
  box-shadow: 0px 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const InputRow = styled.div<MobileProps>`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  max-width: unset;
  width: 100%;
  position: relative;
  margin-right: 40px;
  max-width: 600px;
  ${(props) =>
    props.$isMobile &&
    css`
      max-width: unset;
    `}

  & div {
    width: "100%";
  }

  div {
    p {
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;
export const TextAreaRow = styled.div<MobileProps>`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  max-width: unset;
  width: 100%;
  position: relative;
  margin-right: 40px;
  max-width: 1240px;
  ${(props) =>
    props.$isMobile &&
    css`
      max-width: unset;
    `}

  & div {
    width: "100%";
  }

  div {
    p {
      font-size: 14px;
      margin-top: 8px;
      color: ${colors.grey.dark};
      max-width: 400px;
    }
  }

  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
  }

  a {
    font-weight: 500;
    color: ${colors.grey.dark};
  }
`;
export const RawValue = styled.p<{ color?: string }>`
  border-bottom: 1px solid ${colors.grey.light4};
  width: 100%;
  font-size: 14px;
  padding-bottom: 4px;
  color: ${({ color }) => (color ? color : colors.grey.dark)};
`;

export const ListContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
`;
export const ContractListContainer = styled.div`
  background-color: white;
  border: 1px solid ${colors.grey.default};
  color: ${colors.grey.dark};
  padding: 5px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ListHeading = styled.div`
  font-size: 14px;
  color: ${colors.grey.dark};
`;

export const ListSubtitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;

  p,
  div {
    margin: 0;
    margin-right: 4px;
  }
`;

export const ContactTableContainer = styled.div`
  label {
    font-size: 14px;
    font-weight: 600;
    width: 200px;
    color: ${colors.black.light2};
    white-space: nowrap;
    flex: 0 0 200px;
    margin-right: 20px;
  }
`;

export const ContactContainer = styled.div`
  margin-bottom: 10px;
`;

export const ContractDetailsContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  padding-left: 0px;
  border-top: 1px solid ${colors.grey.light1};
  font-size: 14px;
`;

export const ContractDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`;
