export const onDeleteHandler = (
  event: React.MouseEvent<HTMLButtonElement>,
  deleteRef: React.MutableRefObject<number | undefined>,
  indexToDelete: number,
  setIsDeleteConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  event.preventDefault();
  deleteRef.current = indexToDelete;
  setIsDeleteConfirmation(true);
};
