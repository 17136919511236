import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { UseFormProps } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "src/components/Button/BackButton";
import DynamicFormContainer from "src/components/DynamicFormContainer";
import { DynamicFormConfig } from "src/components/DynamicFormContainer/types";
import { getUserData } from "src/redux/selectors/user";
import { Client, DBLocation } from "src/types";
import { fetchFormConfig } from "./handler";
import { Container, Content, ContentFrame } from "./styles";
import { setPageNameHeader } from "src/redux/actions/header";

const DynamicForm = () => {
  const { id } = useParams();
  const [formConfig, setFormConfig] = useState<DynamicFormConfig>();
  const [clientsArray, setClientsArray] = useState<Client[]>([]);
  const [locationsArray, setLocationsArray] = useState<DBLocation[]>([]);
  const [defaultValues, setDefaultValues] = useState<UseFormProps["defaultValues"]>({});
  const { id: managerId } = useSelector(getUserData);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchFormConfig(setFormConfig, setClientsArray, setLocationsArray, setDefaultValues, id, managerId);
  }, [id]);

  useEffect(() => {
    dispatch(setPageNameHeader(formConfig?.name ?? ""));
  }, [formConfig?.name]);

  const navigate = useNavigate();
  const onExitClick = () => {
    navigate(-1);
  };
  return (
    <Container>
      <Content>
        <BackButton backHandler={onExitClick} />
        <ContentFrame>
          {formConfig ? (
            <DynamicFormContainer
              clientsArray={clientsArray}
              defaultValues={defaultValues}
              locationsArray={locationsArray}
              formConfig={formConfig}
            />
          ) : (
            <Spinner />
          )}
        </ContentFrame>
      </Content>
    </Container>
  );
};

export default DynamicForm;
