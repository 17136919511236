import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "src/components/Modal";
import { AssignWOResourceFormValues } from "src/components/molecules/AssignWOResource/types";
import AssignWOResourceModal from "src/components/organisms/AssignWOResourseModal";
import { getAllActiveResources } from "src/redux/selectors/resources";
import { WorkRequestModalsProps } from "../types";

const WorkRequestModals = ({
  clientId,
  isShowing,
  isResourceAssignedModalVisible,
  assignResourceValues,
  toggle,
  setIsResourceAssignedModalVisible,
  setAssignResourceValues,
}: WorkRequestModalsProps) => {
  const activeResources = useSelector(getAllActiveResources);
  const navigate = useNavigate();
  const onDiscardClick = () => {
    navigate("/workrequests");
  };
  const onSubmitAssignResourceHandler = (data: AssignWOResourceFormValues) => {
    setAssignResourceValues({
      activetab: data.activetab,
      employees: {
        laborCode: data.employees.laborCode,
      },
      vendors: {
        approvedamount: data.vendors.approvedamount,
        vendorId: data.vendors.vendorId,
        woacceptscharges: data.vendors.woacceptscharges,
      },
    });

    setIsResourceAssignedModalVisible(false);
  };
  return (
    <>
      <Modal
        onApproveClick={onDiscardClick}
        onCancelClick={() => toggle()}
        title="Discard New Work Request"
        textContent="Are you sure you want to discard this Work Request? All entered information will be lost."
        isShowing={isShowing}
        hide={toggle}
      />
      {isResourceAssignedModalVisible && (
        <AssignWOResourceModal
          closeModal={() => {
            setIsResourceAssignedModalVisible(false);
          }}
          errorMsg={""}
          onSubmit={onSubmitAssignResourceHandler}
          employees={activeResources.employees}
          vendors={activeResources.vendors.filter(
            (vendor) => vendor.clients && vendor.clients.includes(clientId ?? ""),
          )}
          defaultValues={{
            employees: {
              laborCode: assignResourceValues?.employees?.laborCode
                ? assignResourceValues.employees.laborCode
                : undefined,
            },
            vendors: {
              woacceptscharges: assignResourceValues ? !!assignResourceValues.vendors.woacceptscharges : true,
              approvedamount: assignResourceValues?.vendors?.approvedamount
                ? assignResourceValues.vendors.approvedamount
                : undefined,
              vendorId: assignResourceValues?.vendors?.vendorId ? assignResourceValues.vendors.vendorId : undefined,
            },
            // commented out temporarily to hide the Employees tab. Will be reverted in the future
            activetab: assignResourceValues?.activetab ?? "vendors",
          }}
        />
      )}
    </>
  );
};

export default WorkRequestModals;
