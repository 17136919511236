import React from "react";
import { Controller } from "react-hook-form";
import { PAY_TYPE } from "src/constants/validation";

import SelectSearch from "../../../../components/SelectSearch";
import { PayTypeSelectDropDownProps } from "../types";

/**
 * A helper component to render the  paytype dropdown at payroll form.
 */

export const PayTypeSelectDropDown = ({ form }: PayTypeSelectDropDownProps) => {
  const payTypeOptions = [
    { id: PAY_TYPE.hourly, value: PAY_TYPE.hourly },
    { id: PAY_TYPE.salary, value: PAY_TYPE.salary },
  ];

  return (
    <Controller
      control={form.control}
      name={"payType"}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <SelectSearch
          onChange={(option) => onChange(option.id)}
          ref={ref}
          options={payTypeOptions}
          defaultValue={value ?? ""}
          error={error}
        />
      )}
    />
  );
};
