import React from "react";
import { Stack } from "react-bootstrap";
import "react-datetime-picker/dist/DateTimePicker.css";
import { EmailMessage } from "src/components/atoms/EmailMessage";
import { EmailTo } from "src/components/atoms/EmailTo";
import styled from "styled-components";
import { InputRow } from "../styles";
import { ShareFormViewProps } from "../types";
import { ShareFormButtons } from "./ShareFormButtons";

export const ShareFormView = ({ onCancel, error }: ShareFormViewProps) => {
  return (
    <Stack gap={3}>
      <InputRow>
        <EmailTo isRequired={true} disabled={false} />
      </InputRow>
      <InputRow>
        <EmailMessage isFieldVisible={true} disabled={false} />
      </InputRow>
      {error && <ErrorMessage>Work Request has not been shared successfully. </ErrorMessage>}
      <ShareFormButtons onCancel={onCancel} />
    </Stack>
  );
};

const ErrorMessage = styled.p`
  color: red;
  padding-top: 18px;
  font-size: 10px;
  font-family: Manrope;
  text-align: center;
`;
