import React from "react";
import { Stack } from "react-bootstrap";
import { useFormState } from "react-hook-form";
import Button from "src/components/Button";
import { PayPeriodFilterFormProps } from "../types";

export const PayPeriodFilterFormButtons = ({ onCancel }: PayPeriodFilterFormProps) => {
  const { isLoading, isSubmitting } = useFormState();
  return (
    <Stack direction="horizontal" className="mt-4" gap={3}>
      <Button
        additionalStyles={{
          width: 90,
        }}
        text="Submit"
        loading={isSubmitting ?? isLoading}
        disabled={isSubmitting ?? isLoading}
      />
      <Button
        text="Cancel"
        variant="secondary"
        onClick={(event) => {
          event.preventDefault();
          onCancel?.();
        }}
      />
    </Stack>
  );
};
