import { v4 as uuidv4 } from "uuid";
import { shiftFactory } from "../ShiftForm/utils";
import { ScheduleTemplate } from "./types";

export const scheduleTemplateFactory = {
  createTemplate: (): ScheduleTemplate => {
    return {
      id: uuidv4(),
      label: "",
      shifts: [shiftFactory.createShift()],
      isNew: true,
    };
  },
};
