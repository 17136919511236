import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { PayPeriodFilterForm } from "src/components/molecules/PayPeriodFilterForm";
import { Icon, ModalContent, ModalHeader, ModalWindow, ModalWrapper } from "./styles";

interface PayPeriodFilterModalProps {
  closeModal: () => void;
}

const PayPeriodFilterModal = ({ closeModal }: PayPeriodFilterModalProps) => {
  return (
    <ModalWrapper>
      <ModalWindow style={{ maxWidth: "250px" }}>
        <ModalHeader>
          <div>
            <h3>Filter Time & Attendance</h3>
            <Icon onClick={closeModal} icon={faTimes as IconProp} />
          </div>
        </ModalHeader>
        <ModalContent>
          <PayPeriodFilterForm onCancel={closeModal} />
        </ModalContent>
      </ModalWindow>
    </ModalWrapper>
  );
};
export default PayPeriodFilterModal;
