/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";
import { IBreadCrumb, ID } from "src/types";

interface BreadCrumbsProps {
  folderStack: IBreadCrumb[];
  onClickItem?: (id: ID | null) => void;
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ folderStack = [], onClickItem = () => {} }) => {
  const onClickFolder = (id: ID | null) => {
    onClickItem(id);
  };
  return (
    <Container>
      {folderStack.map((el, i, array) => (
        <Item key={el.id}>
          {!!i && <Separator>/</Separator>}
          <Title $active={array.length - 1 === i} onClick={() => onClickFolder(el.id)}>
            {el.name}
          </Title>
        </Item>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-left: 30px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  color: ${colors.grey.dark7};
  margin-left: 8px;
  margin-right: 8px;
`;

const Title = styled.a<{ $active: boolean }>`
  font-weight: 400;
  font-size: 14px;
  color: ${({ $active }) => ($active ? colors.black.dark1 : colors.grey.dark7)};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${colors.kleenway.green};
  }
`;

export default BreadCrumbs;
