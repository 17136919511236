import React from "react";
import styled from "styled-components";

import { colors } from "src/styles";

export interface SelectSearchItemProps {
  option: any;
  onSelectItem: (item: any) => void;
}

const SelectSearchItem = ({ option, onSelectItem }: SelectSearchItemProps) => {
  return <SelectItem onClick={onSelectItem}>{option.value}</SelectItem>;
};

export default SelectSearchItem;

export const SelectItem = styled.li`
  border-bottom: 1px solid ${colors.grey.default};
  padding: 5px 10px;
  cursor: pointer;
`;
