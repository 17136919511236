import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { colors } from "src/styles";
import styled from "styled-components";

interface ClickInputProps {
  inputComponent: React.ReactNode;
  outputComponent: React.ReactNode;
  isLoading: boolean;
  onRemoveFocusFromInput: () => void;
}

const ClickInput = ({ inputComponent, outputComponent, isLoading, onRemoveFocusFromInput }: ClickInputProps) => {
  const [isInputVisible, setIsInputVisible] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setIsInputVisible(false);
        onRemoveFocusFromInput();
      }
    };
    if (isInputVisible) {
      document.body.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isInputVisible]);
  useEffect(() => {
    if (isInputVisible && inputRef.current) {
      focusFirstElement(inputRef.current);
    }
  }, [isInputVisible]);

  const focusFirstElement = (container: HTMLElement) => {
    const elements = container.querySelectorAll("input, textarea, select, button, [tabindex]:not([tabindex='-1'])");
    const firstElement = Array.from(elements).find((element) => !element.hasAttribute("disabled"));
    if (firstElement) {
      (firstElement as HTMLElement).focus();
    }
  };

  return (
    <div ref={inputRef}>
      {!isInputVisible ? (
        <OutputWrapper
          onClick={(e) => {
            e.stopPropagation();
            setIsInputVisible(true);
          }}
        >
          <OutputComponent>{outputComponent}</OutputComponent>
          {isLoading && (
            <SpinnerWrapper>
              <Spinner size="sm" animation="border" />
            </SpinnerWrapper>
          )}
        </OutputWrapper>
      ) : (
        <>{inputComponent}</>
      )}
    </div>
  );
};

export default ClickInput;
const SpinnerWrapper = styled.div`
  margin-left: 10px;
  & > div {
    border-color: ${colors.kleenway.greenLight};
    border-right-color: transparent;
  }
`;
const OutputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${colors.grey.light1} !important;
  }
`;
const OutputComponent = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  min-width: 180px;
  min-height: 40px;
`;
