import React, { PropsWithChildren, useState } from "react";
import { Stack } from "react-bootstrap";
import { useIsMobile } from "src/hooks/useIsMobile";
import styled from "styled-components";
import Footer from "../Footer";
import Header from "../Header";
import Navbar from "../Navbar";

interface LayoutProps {
  name: string;
  logo: string;
  loggedIn: boolean;
}

const Layout = ({ name, logo, loggedIn, children }: PropsWithChildren<LayoutProps>) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const { isMobile } = useIsMobile();

  return loggedIn ? (
    <>
      <Navbar isVisible={isVisible} setIsVisible={setIsVisible} />

      <Container $isVisible={isVisible ? 1 : 0} $isMobile={isMobile ? 1 : 0}>
        <Header name={name} logo={logo} />
        <Content>{children}</Content>
        <Footer />
      </Container>
    </>
  ) : (
    <> {children} </>
  );
};
const getMaxWidth = (isvisible: number, ismobile: number) => {
  if (ismobile) {
    return "100%";
  }
  const widthOffset = isvisible ? "250px" : "100px";
  return `calc(100% - ${widthOffset})`;
};
const Container = styled(Stack)<{ $isVisible: number; $isMobile: number }>`
  width: 100%;
  max-width: ${({ $isMobile, $isVisible }) => getMaxWidth($isVisible, $isMobile)};

  display: flex;
  flex-direction: column;
  position: relative;
  float: right;
`;

const Content = styled(Stack)`
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
`;

export default Layout;
