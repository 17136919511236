import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { useIsMobile } from "src/hooks/useIsMobile";
import { colors } from "src/styles";

interface NavbarItemProps {
  title: string;
  href: string;
  icon: IconDefinition;
  isVisible: boolean;
  onClick?: () => void;
  isActive: boolean;
}

export const NavbarItem: React.FC<NavbarItemProps> = ({ title, href, icon, isVisible, onClick, isActive }) => {
  const { isMobile } = useIsMobile();

  return (
    <>
      <Container to={href} onClick={onClick}>
        <ItemBox $isMobile={isMobile ? 1 : 0} $isVisible={isVisible ? 1 : 0} $isActive={isActive ? 1 : 0}>
          <Icon
            icon={icon as IconProp}
            $isMobile={isMobile ? 1 : 0}
            $isActive={isActive ? 1 : 0}
            $isVisible={isVisible ? 1 : 0}
          />
          <Title
            $isActive={isActive ? 1 : 0}
            $isVisible={isVisible ? 1 : 0}
            style={{
              display: isVisible ? "block" : "none",
            }}
            $isMobile={isMobile ? 1 : 0}
          >
            {title}
          </Title>
        </ItemBox>
      </Container>
    </>
  );
};

export interface ItemBoxProps {
  $isVisible: number;
  $isMobile: number;
  $isActive: number;
}

const Container = styled(NavLink)`
  text-decoration: none;
`;

const ItemBox = styled.div<ItemBoxProps>`
  display: flex;
  justify-content: ${({ $isVisible }) => ($isVisible ? "flex-start" : "center")};
  align-items: center;
  padding: 12px;
  margin-bottom: 6px;
  background-color: ${({ $isActive }) => $isActive && `${colors.kleenway.green}`};
  border-radius: ${({ $isActive, $isVisible }) => $isActive && ($isVisible ? "4px" : "12px")};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      border-bottom: 0;
      height: 30px;
      display: block;
    `};
`;

const Title = styled.label<ItemBoxProps>`
  color: ${colors.grey.dark6};
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  color: ${colors.white.default};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: block;
    `}
`;

const Icon = styled(FontAwesomeIcon)<ItemBoxProps>`
  color: ${colors.grey.dark};
  height: 22px;
  padding-right: ${({ $isVisible }) => $isVisible && "10px"};
  width: 22px;
  color: ${colors.white.default};

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      display: none;
    `};
`;
