import { useEffect, useState } from "react";

import FirebaseStorageService from "../services/Firebase/storage";

const RETRY_NUMBER = 3;
const RETRY_TIMEOUT = 1000;

export const useLoadProfilePicture = (url?: string) => {
  const [profileImageData, setProfileImageData] = useState("");
  const [attempt, setAttempt] = useState(0);

  const getProfilePicture = async (url: string) => {
    try {
      const { data } = await FirebaseStorageService.getDownloadURL(process.env.REACT_APP_PROFILE_PICTIRE_FOLDER, url);
      setProfileImageData(data);
    } catch (e) {
      console.log(e);
      if (attempt < RETRY_NUMBER) {
        setTimeout(() => {
          setAttempt(attempt + 1);
        }, RETRY_TIMEOUT);
      }
      setProfileImageData("");
    }
  };

  useEffect(() => {
    if (url && attempt < RETRY_NUMBER) {
      getProfilePicture(url);
    }
  }, [attempt, url]);

  return { profileImageData };
};
