import { FieldValues, FormState, UseFormTrigger } from "react-hook-form";
import { NavigateFunction } from "react-router-dom";
import { ID } from "src/types";

/**
 * Navigates to a clicked bread crumb
 */
export const onBreadcrumbClickHandler = (navigate: NavigateFunction, route: ID | null) => {
  typeof route === "string" && navigate(route);
};

/**
 * Makes sure the user is "navigated" to the first field with validation errors so they do not have to look for it
 * manually. Even (and especially) if it is on the tab that is currently not visible.
 */
export const onSaveClickHandler = async (
  trigger: UseFormTrigger<FieldValues>,
  errors: FormState<FieldValues>["errors"],
  activeTab: string,
  setActiveTab: React.Dispatch<React.SetStateAction<string>>,
) => {
  const isFormValid = await trigger();

  if (!isFormValid) {
    // if the error(s) are on a tab(s) that is not currently active
    if (!errors[activeTab]) {
      // activate the first tab that has form errors on it
      setActiveTab(Object.keys(errors)[0]);
    }
  }
};
