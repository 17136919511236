import moment from "moment";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { DATEPICKER_FORMAT } from "src/components/atoms/DatePicker/constants";
import { useGetExcel } from "src/hooks/useGetExcel";
import { Client } from "src/types";
import { PayPeriodFilterFormView } from "./components/PayPeriodFilterFormView";
import { fetchClientData } from "./handler";
import { ErrorMessage } from "./styles";
import { PayPeriodFilterFormProps, PayPeriodFilterFormValues } from "./types";

export const PayPeriodFilterForm = ({ onCancel }: PayPeriodFilterFormProps) => {
  const { id } = useParams();
  const [client, setClient] = useState<Client>();
  const [error, setError] = useState("");

  const { excelHandler } = useGetExcel({
    baseURL: `${process.env.REACT_APP_FIREBASE_BASE_URL}/clientPortal-getExcelRawFile`,
    id: id ?? "",
    excelType: "raw",
    clientName: client?.name ?? "",
  });

  const form = useForm<PayPeriodFilterFormValues>({
    defaultValues: async () => {
      try {
        setClient(await fetchClientData(id ?? ""));
      } catch (err) {
        setError(err);
      }
      return {
        fromDate: moment().subtract(1, "month").startOf("month").format("L"),
        toDate: moment().subtract(1, "month").endOf("month").format("L"),
      };
    },
  });

  const onSubmitHandler = async (data: PayPeriodFilterFormValues) => {
    setError("");
    if (moment(data.fromDate, DATEPICKER_FORMAT.date).isAfter(moment(data.toDate, DATEPICKER_FORMAT.date))) {
      setError("From Date must be same or before To Date");
    } else {
      await excelHandler(data.fromDate, data.toDate);
    }
  };

  return (
    <FormProvider {...form}>
      <form style={{ width: "100%" }} onSubmit={form.handleSubmit(onSubmitHandler)}>
        <PayPeriodFilterFormView onCancel={onCancel} />
        <ErrorMessage>{error}</ErrorMessage>
      </form>
    </FormProvider>
  );
};
