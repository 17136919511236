import React from "react";
import Modal from "../Modal";

export interface DeleteModalProps {
  isShowing: boolean;
  hide: () => void;
  title: string;
  onCancelClick: () => void;
  onApproveClick: (() => void) | ((e: React.MouseEvent) => void);
  textContent?: string;
  approveButtonText?: string;
  disableApproveButton?: boolean;
  disableCancelButton?: boolean;
  isLoadingApproveButton?: boolean;
}

const DeleteModal = ({
  onCancelClick,
  onApproveClick,
  title,
  isShowing,
  hide,
  textContent,
  approveButtonText,
  disableApproveButton,
  disableCancelButton,
  isLoadingApproveButton,
}: DeleteModalProps) => {
  return (
    <Modal
      isLoadingApproveButton={isLoadingApproveButton}
      disableCancelButton={disableCancelButton}
      disableApproveButton={disableApproveButton}
      onApproveClick={onApproveClick}
      onCancelClick={onCancelClick}
      isShowing={isShowing}
      hide={hide}
      title={`Delete ${title}`}
      textContent={textContent || `Please confirm that you would like to delete the ${title}.`}
      approveButtonText={approveButtonText || `Delete ${title}`}
      cancelButtonText="Cancel and Return"
      cancelButtonStyle="text"
      buttonsFlexDirection="column"
    />
  );
};

export default DeleteModal;
