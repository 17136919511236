import moment from "moment";
import React from "react";
import { Stack } from "react-bootstrap";
import { DATEPICKER_FORMAT } from "src/components/atoms/DatePicker/constants";
import { DATEPICKER_VARIANT } from "src/components/atoms/DatePicker/types";
import { Label, Text } from "../styles";

export const CloseWorkOrderTargetDates = ({ value, label }: CloseWorkOrderTargetDatesProps) => {
  return (
    <Stack direction="horizontal" gap={2}>
      <Label>{label}</Label>
      <Text>{moment(value).format(DATEPICKER_FORMAT[DATEPICKER_VARIANT.DATE_TIME])} </Text>
    </Stack>
  );
};
interface CloseWorkOrderTargetDatesProps {
  value: string;
  label: string;
}
