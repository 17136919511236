import { faPlus } from "@fortawesome/pro-light-svg-icons";
import React, { useState } from "react";
import Button from "src/components/Button";
import DeleteModal from "src/components/DeleteModal";
import { Assignment, Client, DBLocation, TeamMemberById } from "src/types";
import { v4 as uuidv4 } from "uuid";
import AssignmentForm from "./AssignmentForm";

interface TeamMemberScheduleProps {
  managerId: string;
  employeeInfo: TeamMemberById;
  scheduleTemplates: any[];
  scheduleTypes: Record<string, number>;
  clients: Client[];
  locations: DBLocation[];
  assignments: Assignment[];
  setAssignments: (newAssignments: Assignment[]) => void;
}

const TeamMemberSchedule = ({
  employeeInfo,
  managerId,
  scheduleTemplates,
  scheduleTypes,
  clients,
  locations,
  assignments,
  setAssignments,
}: TeamMemberScheduleProps) => {
  const [idToDelete, setIdToDelete] = useState("");
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);

  const assignmentFieldsHandler = (id: string, value: string, fieldName: string) => {
    const newAssignments = assignments.map((item) => {
      return item.id === id
        ? {
            ...item,
            [fieldName]: value,
          }
        : item;
    });
    setAssignments(newAssignments);
  };

  const scheduleHandler = (assignmentId: string, newSchedule: Assignment["schedule"]) => {
    const newAssignments = assignments.map((item) => {
      return item.id === assignmentId
        ? ({
            ...item,
            schedule: newSchedule,
          } as Assignment)
        : item;
    });

    setAssignments(newAssignments);
  };

  const addAssignemnt = () => {
    if (!employeeInfo.employeeId) return;

    const newAssignment: Assignment = {
      id: uuidv4(),
      employeeId: employeeInfo.employeeId.toString(),
      clientId: "",
      locationId: "",
      managerId,
    };
    const newAssignments = [...assignments, newAssignment];

    setAssignments(newAssignments);
  };
  const deleteAssignment = () => {
    const assignmentsAfterDelete = assignments.filter((item) => item.id !== idToDelete);
    setAssignments(assignmentsAfterDelete);
    setShowConfirmClosePopup(false);
  };

  return (
    <>
      <DeleteModal
        onApproveClick={deleteAssignment}
        onCancelClick={() => setShowConfirmClosePopup(false)}
        isShowing={showConfirmClosePopup}
        hide={() => setShowConfirmClosePopup(false)}
        title="Assignment"
        approveButtonText={assignments.length === 1 ? "Proceed" : ""}
        textContent={
          assignments.length === 1
            ? "This employee will be removed from the list of your employees, and you will no longer be able to see them. Do you want to proceed?"
            : ""
        }
      />
      {assignments.map((item, index) => {
        const deleteHandler = () => {
          setIdToDelete(item.id);
          setShowConfirmClosePopup(true);
        };
        return (
          <AssignmentForm
            scheduleTypes={scheduleTypes || {}}
            scheduleTemplates={scheduleTemplates}
            clients={clients || []}
            locations={locations || []}
            assignment={item}
            deleteHandler={deleteHandler}
            assignmentFieldsHandler={assignmentFieldsHandler}
            scheduleHandler={scheduleHandler}
            index={index}
            key={item.id}
          />
        );
      })}
      <Button icon={faPlus as any} variant="dotted-border" text="Add Assignment" onClick={addAssignemnt} />
    </>
  );
};

export default TeamMemberSchedule;
