import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import BreadCrumbs from "src/components/BreadCrumbs";
import { Tabs } from "src/components/Tabs";
import { GLOBAL_SETTINGS_BREADCRUMBS } from "src/pages/GlobalSettings/constants";
import { ContentFrame, TopBar } from "src/styles";
import { ContactListForm } from "./components/ContactListForm";
import { InfoMessageListForm } from "./components/InfoMessageListForm";
import { ScheduleTemplateListForm } from "./components/ScheduleTemplateListForm";
import { onBreadcrumbClickHandler, onSaveClickHandler } from "./handlers";
import { useTab } from "./hooks";
import { Content, Wrapper } from "./styles";
import { GeneralSettingsFormProps } from "./types";

/**
 * Provides components configuration to assemble the General Settings form look and feel.
 *
 * Requires react-hook-form context and does not handle data fetching or saving.
 */
export const GeneralSettingsForm = ({ fontSizes, fontColors }: GeneralSettingsFormProps) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("templates");

  const {
    formState: { isLoading, isSubmitting, errors },
    trigger,
  } = useFormContext();

  const tabs = [
    useTab("templates", "Schedule Templates", (name) => <ScheduleTemplateListForm name={name} />),

    useTab("contacts", "Contacts", (name) => <ContactListForm name={name} />),

    useTab("messages", "Info Messages", (name) => (
      <InfoMessageListForm name={name} fontColors={fontColors} fontSizes={fontSizes} />
    )),
  ];

  return (
    <Wrapper fluid>
      <TopBar>
        <BreadCrumbs
          folderStack={GLOBAL_SETTINGS_BREADCRUMBS}
          onClickItem={(route) => onBreadcrumbClickHandler(navigate, route)}
        />
      </TopBar>

      <Content>
        <ContentFrame>
          {isLoading && <Spinner animation="border" />}

          {!isLoading && (
            <Tabs
              tabs={tabs}
              isLoading={isSubmitting}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              saveHandler={async () => onSaveClickHandler(trigger, errors, activeTab, setActiveTab)}
            />
          )}
        </ContentFrame>
      </Content>
    </Wrapper>
  );
};
