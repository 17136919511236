import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import BackButton from "src/components/Button/BackButton";
import { HorizontalTabs } from "src/components/Tabs/HorizontalTabs";
import { useIsMobile } from "src/hooks/useIsMobile";
import { setPageNameHeader } from "src/redux/actions/header";
import { FirebaseDataService } from "src/services/Firebase/data";
import { AdjustmentReason, Client, EmployeePayrollData, ID } from "src/types";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import Modal from "../../../../components/Modal";
import { generateBreadcrumbs } from "../../../../helpers/breadcrumbs";
import { DEFAULT_PAY_PERIOD_TYPE, PAY_PERIODS_BY_DESCRIPTION } from "../../constants";
import ClientDetailsContext from "../../context";
import {
  determineClientLocation,
  generateEmployeesApprovedHoursForm,
  generatePayPeriodId,
  generatePayPeriodName,
  getPayPeriodDataFromId,
  getPayPeriods,
} from "../utils";
import PayPeriodRawTab from "./PayPeriodRawTab";
import PayPeriodActionButtons from "./PayPeriodRawTab/components/PayperiodActionButtons";
import { PayPeriodSummaryTab } from "./PayPeriodSummaryTab";
import { PayPeriodsSwitcher } from "./PayPeriodsSwitcher";
import { MANAGER_PAY_PERIOD_DETAILS } from "./constants";
import { Container, Content, EmptyContainer, ErrorMessage, NoEmployeesMessage } from "./styles";

export const PayPeriod = () => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const { id, payPeriodId } = useParams();
  const dispatch = useDispatch();

  const { payPeriods, setPayPeriods, setLocations, payPeriodsTypes, setEmployeesApprovedHoursForm } =
    useContext(ClientDetailsContext);

  const [client, setClient] = useState<Client>({} as Client);
  const [isLoading, setIsLoading] = useState(false);
  const [payPeriodIdFormatted, setPayPeriodIdFormatted] = useState("");
  const [error, setError] = useState("");
  const [showConfirmClosePopup, setShowConfirmClosePopup] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("raw");
  const [employeesPayrollData, setEmployeesPayrollData] = useState<EmployeePayrollData[]>([]);
  const [adjustmentReasons, setAdjustmentReasons] = useState<AdjustmentReason[]>([]);

  const getPayPeriodName = () => {
    if (payPeriodId) {
      const { year, number, type } = getPayPeriodDataFromId(payPeriodId);
      const payPeriod = payPeriods?.[PAY_PERIODS_BY_DESCRIPTION[type]]?.[year]?.find(
        ({ number: periodNumber }) => Number(periodNumber) === number,
      );
      return payPeriod ? generatePayPeriodName(payPeriod) : "";
    }

    return "";
  };

  const fetchClientData = async (): Promise<void> => {
    if (!id) return;

    const { data: clientObject } = await FirebaseDataService.getEmployeeClient({ clientId: id });

    if (!clientObject) return;

    setClient(clientObject);
    const clientLocation = await determineClientLocation(clientObject, id, setLocations);
    await getPayPeriods(clientLocation, setPayPeriods);

    if (!payPeriodId) return;

    const { year, type, number } = getPayPeriodDataFromId(payPeriodId);
    const payPeriodIdParam = generatePayPeriodId({
      year: year.toString(),
      payPeriodTypeCode: type,
      number: number.toString(),
      countryCode: clientLocation,
    });

    setPayPeriodIdFormatted(payPeriodIdParam);

    const { data: clientPayrollData } = await FirebaseDataService.getClientPayrollData(payPeriodIdParam, id);
    setEmployeesPayrollData(clientPayrollData.sortedByEmployeeName);
    setAdjustmentReasons(clientPayrollData.adjustmentReasons);
    setEmployeesApprovedHoursForm(generateEmployeesApprovedHoursForm(clientPayrollData.sortedByEmployeeName));
  };

  const loadClientData = async () => {
    setIsLoading(true);

    await fetchClientData();

    setIsLoading(false);
  };

  useEffect(() => {
    loadClientData();
  }, [payPeriodId]);

  const onClickBreadcrumb = (route: ID | null) => typeof route === "string" && navigate(route);

  const getBaseURL = () =>
    `${process.env.REACT_APP_FIREBASE_BASE_URL}/${
      activeTab === "raw" ? "clientPortal-getExcelRawFile" : "clientPortal-getExcelSummaryFile"
    }`;
  useEffect(() => {
    dispatch(setPageNameHeader(getPayPeriodName()));
    return () => {
      dispatch(setPageNameHeader(""));
    };
  }, [payPeriodId]);

  const payPeriodTypeLabel =
    payPeriodsTypes.find(({ payPeriodPayloadId }) => payPeriodPayloadId === payPeriodId?.split("-")[1])?.label ||
    DEFAULT_PAY_PERIOD_TYPE;

  if (isLoading || !payPeriodsTypes.length || !payPeriodId || !id) {
    return (
      <EmptyContainer>
        <Spinner animation="border" size="sm" />
      </EmptyContainer>
    );
  }

  return (
    <Container>
      <Modal
        onApproveClick={() => navigate(`/clients/${id}`)}
        onCancelClick={() => setShowConfirmClosePopup(false)}
        isShowing={showConfirmClosePopup}
        hide={() => setShowConfirmClosePopup(false)}
        title="Close without Saving"
        textContent={`Do you want to close the page without saving?\nAll entered or updated information will be lost.`}
        approveButtonText="Close without Saving"
        cancelButtonText="Cancel and Return"
        cancelButtonStyle="text"
        buttonsFlexDirection="column"
      />
      <BreadCrumbs
        folderStack={generateBreadcrumbs(MANAGER_PAY_PERIOD_DETAILS, {
          id,
          payPeriodId,
          name: client.name,
          payPeriodLabel: payPeriodTypeLabel,
        })}
        onClickItem={onClickBreadcrumb}
      />

      <Content>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <BackButton backHandler={() => setShowConfirmClosePopup(true)} />
        {employeesPayrollData.length ? (
          <>
            <PayPeriodsSwitcher payPeriods={payPeriods} currentPeriodId={payPeriodId} />
            <PayPeriodActionButtons
              onSuccessHandler={fetchClientData}
              onErrorHandler={setError}
              id={id}
              payPeriodId={payPeriodId}
              client={client}
              excelType={activeTab}
              payPeriod={payPeriodIdFormatted}
              payPeriodName={getPayPeriodName()}
              baseURL={getBaseURL()}
            />

            <HorizontalTabs
              tabs={[
                {
                  eventKey: "raw",
                  label: "Raw",
                  content: <PayPeriodRawTab employeesPayrollData={employeesPayrollData} />,
                },
                {
                  eventKey: "summary",
                  label: "Summary",
                  content: (
                    <PayPeriodSummaryTab
                      employeesPayrollData={employeesPayrollData}
                      payPeriod={payPeriodIdFormatted}
                      adjustmentReasons={adjustmentReasons}
                      setEmployeesPayrollData={setEmployeesPayrollData}
                    />
                  ),
                },
              ]}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </>
        ) : (
          <NoEmployeesMessage $isMobile={isMobile}>
            There are no employees with the {payPeriodTypeLabel} assigned.
          </NoEmployeesMessage>
        )}
      </Content>
    </Container>
  );
};
