import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export interface LoadingContainerProps {
  isLoading?: boolean;
  isVisible?: boolean;
  children: React.ReactNode;
}

const LoadingContainer = ({ isLoading, isVisible = true, children }: LoadingContainerProps) => {
  if (!isVisible) {
    return null;
  }

  return !isLoading ? (
    <>{children}</>
  ) : (
    <SpinnerContainer style={{ display: "flex", justifyContent: "center" }}>
      <Spinner animation="border" size="sm" />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 20px;
`;

export default LoadingContainer;
