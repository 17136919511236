import React from "react";
import { Stack } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Input from "src/components/Input";
import { FormLabel } from "src/components/atoms/FormLabel";

export const ApprovedAmount = () => {
  const { control, watch } = useFormContext();
  const shouldBeRequired = (watch("vendors.woacceptscharges") && !watch("employees.laborCode")) ?? false;
  const fieldName = "vendors.approvedamount";

  return (
    <Stack style={{ maxHeight: 80 }} gap={3}>
      <FormLabel htmlFor={fieldName} required={shouldBeRequired}>
        Approved Amount
      </FormLabel>
      <Controller
        control={control}
        name={fieldName}
        rules={{ required: shouldBeRequired }}
        render={({ field: { name, value, onChange, ref }, fieldState: { error } }) => (
          <Input
            placeholder="Enter amount"
            onChange={onChange}
            value={value}
            width="100%"
            name={name}
            ref={ref}
            error={error}
            type={"number"}
            controlId={fieldName}
            step={0.01}
          />
        )}
      />
    </Stack>
  );
};
