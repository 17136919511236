import React, { useContext } from "react";
import { Cell } from "src/components/Table/Cell";
import TableCell from "src/components/Table/TableCell";
import TableRow from "src/components/Table/TableRow";
import { colors } from "src/styles";
import { Ticket } from "src/types";
import { useSelector } from "react-redux";
import { getUserData } from "src/redux/selectors/user";
import { getPriorityLevels } from "src/redux/selectors/workRequest";
import { getApprovedAmount } from "../handler";
import { TablePriorityBadge, TicketButton } from "../styles";
import { noop } from "src/components/atoms/DatePicker/utils";
import WorkRequestListContext from "src/pages/WorkRequests/context";

const VendorTableRow = ({ ticket }: { ticket: Ticket }) => {
  const { setSelectedTicketForCloseAction, searchText } = useContext(WorkRequestListContext);

  const { clientId } = useSelector(getUserData);
  const priorityLevels = useSelector(getPriorityLevels);

  const pickedPriorityLevel = priorityLevels?.[ticket.clientId ?? clientId]?.find(
    ({ priority }) => priority === ticket.priorityId,
  );

  const splittedDate = ticket.dateCreated.split(", ");

  return (
    <TableRow onClick={noop} $clickable={false} key={ticket.wonum}>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.wonum}
      </Cell>
      <Cell highlight={searchText ?? ""} bold={splittedDate[0] ?? ""}>
        {searchText ?? ""?.trim().length ? ticket.dateCreated : `, ${splittedDate[1] ?? ""}`}
      </Cell>

      <Cell showMark highlight={searchText ?? ""}>
        {ticket.client ?? ""}
      </Cell>

      <Cell showMark highlight={searchText ?? ""}>
        {ticket.location}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.description ?? ""}
      </Cell>
      <Cell showMark highlight={searchText ?? ""}>
        {ticket.serviceType}
      </Cell>

      <TableCell>
        <TablePriorityBadge color={pickedPriorityLevel ? pickedPriorityLevel.color : colors.grey.default}>
          {ticket.priority}
        </TablePriorityBadge>
      </TableCell>
      <TableCell>{getApprovedAmount(ticket)}</TableCell>
      <TableCell $isLink={true}>
        <TicketButton onClick={() => setSelectedTicketForCloseAction(ticket)}>{"Close"}</TicketButton>
      </TableCell>
    </TableRow>
  );
};

export default VendorTableRow;
